import { Module } from 'vuex';
import { Group } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    groups: [] as Group[],
});

const GroupModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setGroups(state, groups): void {
            state.groups = groups;
        }
    },

    actions: {
        setGroups({ commit }, groups): void {
            commit('setGroups', groups);
        }
    },

    getters: {
        getGroups(state): Group[] {
            return state.groups;
        },
    },
    namespaced: true
}

export default GroupModule;
