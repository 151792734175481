import { ACMService } from '@/services';
import { Constants, UniperConnection } from '@/utils';
import { AcmUnitAlert, EnerlyticsCmSiteData } from '@/utils/interfaces';

export class EnerlyticsService {
    public siteData: EnerlyticsCmSiteData = {
        areas: [],
        items: [],
        units: []
    };
    private uniperApi: UniperConnection = new UniperConnection();
    private acmService: ACMService = new ACMService();
    public plants = [];

    public async getACMAlertsForPlant(siteId: string): Promise<{ data: AcmUnitAlert[] }> {
        return (
            await this.uniperApi.get(
                Constants.UNIPER.API +
                    process.env.APPLICATION_ACM_ID +
                    Constants.UNIPER.SITE +
                    '/' +
                    siteId +
                    Constants.UNIPER.ALERTS +
                    '?' +
                    Constants.UNIPER.VERSION +
                    process.env.ACM_VERSION
            )
        ).data;
    }

    public async getACMAlerts(siteId: string, unitId: number): Promise<any> {
        const data = await this.acmService.getCredentials();
        this.uniperApi.setToken(data.token);
        /* get data */
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (siteId) {
            // for Rotterdam unit, enerlytics has alerts only for unit 3
            case Constants.HARDCODED_PLANTS.ROCA:
                if (unitId === Constants.HARDCODED_DB_IDS.ROCA_U3) {
                    return (
                        await this.getACMAlertsForPlant(
                            Constants.HARDCODED_PLANTS.ROCA3
                        )
                    ).data;
                } else {
                    return [];
                }
                break;
            default:
                return (await this.getACMAlertsForPlant(siteId)).data;
        }
    }

    public async getSites(): Promise<any> {
        if(this.plants.length === 0) {

            const data = await this.acmService.getCredentials();
            this.uniperApi.setToken(data.token);

            this.plants = (
                await this.uniperApi.get(
                    Constants.UNIPER.API +
                    process.env.APPLICATION_ACM_ID +
                    Constants.UNIPER.SITES +
                    '?' +
                    Constants.UNIPER.VERSION +
                    process.env.ACM_VERSION
                )
            ).data.data;
        }


        return this.plants;
    }

    public async getUnitsForPlant(siteId: string): Promise<any> {
        if (siteId) {
            const { data } = (
                await this.uniperApi.get(
                    Constants.UNIPER.API +
                        process.env.APPLICATION_ACM_ID +
                        Constants.UNIPER.SITE_STRUCTURE +
                        '/' +
                        siteId +
                        '?' +
                        Constants.UNIPER.VERSION +
                        process.env.ACM_VERSION
                )
            ).data;

            return data;
        }

        return [];
    }

    public async getUnits(siteId: string, unitId: number): Promise<EnerlyticsCmSiteData> {
        const credentials = await this.acmService.getCredentials();
        this.uniperApi.setToken(credentials.token);

        /* get data */
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (siteId) {
            case Constants.HARDCODED_PLANTS.ROCA:
                if (unitId === Constants.HARDCODED_DB_IDS.ROCA_U3) {
                    this.siteData = await this.getUnitsForPlant(
                        Constants.HARDCODED_PLANTS.ROCA3
                    );
                }
                break;
            default:
                this.siteData = await this.getUnitsForPlant(siteId);
                break;
        }

        return this.siteData;
    }
}
