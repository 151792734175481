const PHASES = {
    initiation: 0,
    scoping: 1,
    preparation: 2,
    implementation: 3,
    closeOut: 4,
};

const KPI_INPUT_TYPES = {
    checkbox: 0,
    number: 1,
};

export {PHASES, KPI_INPUT_TYPES}
