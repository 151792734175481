






/* eslint-disable no-undef */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';

@Component({
    name: 'snackbar'
})

export default class Snackbar extends Vue {

    private message = '';
    private snackbarClass = '';
    private isOpen = false;

    constructor () {
        super();
        this.attachListener();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.SHOW_SNACKBAR, this.handleShowSnackbar);
    }

    private attachListener(): void {
        EventBus.$on(EventBus.GLOBAL.SHOW_SNACKBAR, this.handleShowSnackbar);
    }

    private handleShowSnackbar(data: any): void {
        if (!this.isOpen) {
            const el = $('#snackbar');
            this.snackbarClass = data.class !== undefined ? data.class : '';

            if (data.message !== undefined) {
                if (data.translate !== undefined && data.translate === true) {
                    const translated = this.$i18n.t(data.message);
                    this.message = translated.toString();
                } else {
                    this.message = data.message;
                }
            } else {
                this.message = '';
            }

            let duration = 3000;
            if (data.duration) {
                duration = data.duration;
            }

            this.isOpen = true;
            el.addClass('open ' + this.snackbarClass);
            el.addClass(this.snackbarClass);
            setTimeout(() => {
                el.removeClass('open ' + this.snackbarClass);
                el.removeClass('open');
                this.isOpen = false;
            }, duration);
        }
    }
}
