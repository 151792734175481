import { Currency } from './interfaces';

export class CurrencyHelper {
    private readonly currencies: Currency[];

    constructor() {
        this.currencies = [
            {
                code: 'EUR',
                value: 0,
                symbol: '€',
            },
            {
                code: 'GBP',
                value: 1,
                symbol: '£',
            },
            {
                code: 'USD',
                value: 2,
                symbol: '$',
            },
            {
                code: 'HUF',
                value: 3,
                symbol: 'Ft',
            },
            {
                code: 'RUB',
                value: 4,
                symbol: '₽',
            },
            {
                code: 'SEK',
                value: 5,
                symbol: 'kr',
            },
        ];
    }

    public getCurrencies(): Currency[] {
        return this.currencies;
    }

    public getCurrencySymbolByValue(val: number): string {
        const currency = this.currencies.find(
            (currency: Currency) => currency.value === val
        );
        return currency !== null && currency !== undefined
            ? currency.symbol
            : '';
    }

    public getCurrencyCodeByValue(val: number): string {
        const currency = this.currencies.find(
            (currency: Currency) => currency.value === val
        );
        return currency !== null && currency !== undefined ? currency.code : '';
    }
}
