import Vue from 'vue';
import Component from 'vue-class-component';
import { JobsService } from '@/services';
import { EventBus, DocumentHelper } from '@/utils';
import { JobHistory } from '@/utils/interfaces';

@Component({
})
export default class JobsComponent extends Vue {
    private loading = true;
    private jobsService: JobsService = new JobsService();
    private genevaImportHistory: JobHistory[] = [];
    private portfolioReviewImportHistory: JobHistory[] = [];
    private outageCommercialValueImportHistory: JobHistory[] = [];
    private outageCommercialNominationDatesImportHistory: JobHistory[] = [];
    private documentHelper: DocumentHelper = new DocumentHelper();
    private unitOperatingParametersImportHistory: JobHistory[] = [];
    private plantItemOperatingParametersImportHistory: JobHistory[] = [];
    private updateMaintenancePtRiskJobHistory: JobHistory[] = [];
    private piReportJobHistory: JobHistory[] = [];
    private alternateLinkingJobHistory: JobHistory[] = [];

    private tabs: any = {
        geneva: { active: true, load: this.loadGenevaImportHistory },
        portfolioReview: { actie: false, load: this.loadPortfolioReviewImportHistory },
        commercialValue: { active: false, load: this.loadOutageCommercialValueImportHistory },
        commercialNominatedDates: { active: false, load: this.loadOutageCommercialNominationDatesImportHistory },
        unitPi: { active: false, load: this.loadUnitOperatingParametersImportHistory },
        plantItemPi: { active: false, load: this.loadPlantItemOperatingParametersImportHistory },
        ptRisk: { active: false, load: this.loadUpdateMaintenancePtRiskJobHistory },
        piReportJob: { actie: false, load: this.loadPiReportJobHistory },
        alternateLinkingJob: { actie: false, load: this.loadAlternateLinkingJobHistory }
    };

    created(): void {
        this.loadGenevaImportHistory();
        this.loading = false;
    }

    private toggleTab(tab: any): void {
        for (const key in this.tabs) {
            this.tabs[key].active = false;
        }

        tab.load();
        tab.active = true;
    }

    private async loadGenevaImportHistory(): Promise<void> {
        try {
            this.loading = true;
            this.genevaImportHistory = await this.jobsService.getGenevaImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runGenevaImportJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runGenevaImportJob();
            this.loadGenevaImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async loadPortfolioReviewImportHistory(): Promise<void> {
        try {
            this.loading = true;
            this.portfolioReviewImportHistory = await this.jobsService.getPortfolioReviewImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runPortfolioReviewImportJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runPortfolioReviewImportJob();
            this.loadPortfolioReviewImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async downloadGenevaData(): Promise<void> {
        try {
            const file = await this.jobsService.downloadGenevaData();
            await this.documentHelper.decodeAndDownloadBase64File(file.title, file.content);
        } catch (error) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorDownloadingData')
            });
        }
    }

    private async loadOutageCommercialValueImportHistory(): Promise<void> {
        try {
            this.loading = true;
            this.outageCommercialValueImportHistory = await this.jobsService.getOutageCommercialValueImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runOutageCommercialValueImportJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runOutageCommercialValueImportJob();
            this.loadOutageCommercialValueImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async downloadOutageCommercialValueData(): Promise<void> {
        try {
            const file = await this.jobsService.downloadOutageCommercialValueData();
            await this.documentHelper.decodeAndDownloadBase64File(file.title, file.content);
        } catch (error) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorDownloadingData')
            });
        }
    }

    private async loadOutageCommercialNominationDatesImportHistory(): Promise<void> {
        try {
            this.loading = true;
            this.outageCommercialNominationDatesImportHistory = await this.jobsService.getOutageCommercialNominationDatesImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runOutageCommercialNominationDatesImportJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runOutageCommercialNominationDatesImportJob();
            this.loadOutageCommercialNominationDatesImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async loadUnitOperatingParametersImportHistory(): Promise<void> {
        try {
            this.loading = true;
            this.unitOperatingParametersImportHistory = await this.jobsService.getUnitOperatingParametersImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runUnitOperatingParametersImportJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runUnitOperatingParametersImportJob();
            this.loadUnitOperatingParametersImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async loadPlantItemOperatingParametersImportHistory(): Promise<void> {
        try {
            this.loading = true;
            this.plantItemOperatingParametersImportHistory = await this.jobsService.getPlantItemOperatingParametersImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runPlantItemOperatingParametersImportJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runPlantItemOperatingParametersImportJob();
            this.loadPlantItemOperatingParametersImportHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async loadUpdateMaintenancePtRiskJobHistory(): Promise<void> {
        try {
            this.loading = true;
            this.updateMaintenancePtRiskJobHistory = await this.jobsService.getUpdateMaintenancePtRiskJobHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runUpdateMaintenancePtRiskJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runUpdateMaintenancePtRiskJob();
            this.loadUpdateMaintenancePtRiskJobHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async loadPiReportJobHistory(): Promise<void> {
        try {
            this.loading = true;
            this.piReportJobHistory = await this.jobsService.getPiReportJobHistory();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runPiReportJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runPiReportJob();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private async loadAlternateLinkingJobHistory(): Promise<void> {
        try {
            this.loading = true;
            const history = await this.jobsService.getAlternateLinkingJobHistory();
            history.forEach((element: any) => {
                element.expanded = false;
            });
            this.alternateLinkingJobHistory = history;
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingJobHistory')
            });
        }
    }

    private async runAlternateLinkingJob(): Promise<void> {
        try {
            this.loading = true;
            await this.jobsService.runAlternateLinkingJob();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorRunningJob')
            });
        }
    }

    private toggleHistoryItem(item: any): void {
        item.expanded = !item.expanded;
    }
}
