

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ScheduledMajorOutage, GenericSort } from '@/utils/interfaces';

@Component({
})
export default class ColumnSortingComponent extends Vue {

    @Prop()
    private fields!: Array<keyof ScheduledMajorOutage>;

    @Prop()
    private activeSort!: GenericSort;

    private sort(direction: number): void {
        this.$emit('sort', this.fields, direction);
    }

    private isActive(direction: number): boolean {
        if (!this.activeSort)
            return false;

        if (this.fields.length !== this.activeSort.fields.length)
            return false;

        for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i] !== this.activeSort.fields[i])
                return false;
        }

        return direction === this.activeSort.direction;
    }
}
