/* eslint-disable @typescript-eslint/no-this-alias */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { PtRisk, RiskDetailTransferItem } from '@/utils/interfaces';
import { EventBus, CurrencyHelper } from '@/utils';
import { PlantMaintenanceItemService } from '@/services';

@Component({
    filters: {
        formatRiskCostValue(value: number): string {
            const result = value / 1000;
            if (Number.isInteger(result)) {
                return result.toString();
            }
            return result.toFixed(2);
        }
    }
})
export default class PlantItemRiskDetailsModalComponent extends Vue {
    private risk: PtRisk = {} as PtRisk;
    private currency = 0;
    private currencyHelper: CurrencyHelper = new CurrencyHelper();
    private plantMaintenanceItemService: PlantMaintenanceItemService = new PlantMaintenanceItemService();

    constructor() {
        super();
    }

    private created(): void {
        EventBus.$on(EventBus.TABLE.OPEN_RISK_DETAILS_MODAL, this.handleOpenRiskDetailsModal);
    }

    beforeDestroy(): void {
        EventBus.$off(EventBus.TABLE.OPEN_RISK_DETAILS_MODAL, this.handleOpenRiskDetailsModal);
    }

    private async loadPtRisk(ptRiskNumber: string): Promise<void> {
        try {
            const self = this;
            self.risk = await this.plantMaintenanceItemService.getPtRisk(ptRiskNumber);
        } catch (error) {
            if (error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, { class: 'error', message: this.$i18n.t('errorGettingPtRisk') });
            }
        }
    }

    private getCurrencySymbol(): string {
        return this.currencyHelper.getCurrencySymbolByValue(this.currency);
    }

    private getCostColor(costScore: number): string {
        if (costScore >= 13.7)
            return 'orange';

        if (costScore < 12)
            return 'green';

        return 'yellow';
    }

    private getSafetyColor(safetyScore: number): string {
        if (safetyScore > 7.7)
            return 'red';

        if (safetyScore <= 5.7)
            return 'green';

        return 'yellow';
    }

    private getEnvironmentColor(environmentScore: number): string {
        if (environmentScore > 9.5)
            return 'red';

        if (environmentScore <= 8.5)
            return 'green';

        return 'yellow';
    }

    private getRegulatoryColor(regulatoryScore: number): string {
        if (regulatoryScore > 9.5)
            return 'red';

        if (regulatoryScore <= 8.5)
            return 'green';

        return 'yellow';
    }

    private getReputationalColor(reputationalScore: number): string {
        if (reputationalScore > 9.5)
            return 'red';

        if (reputationalScore <= 8.5)
            return 'green';

        return 'yellow';
    }

    private async handleOpenRiskDetailsModal(data: RiskDetailTransferItem): Promise<void> {
        this.currency = data.currency;
        await this.loadPtRisk(data.ptRiskNumber);
    }
}
