import { PERMISSIONS, ASSET_PERMISSION_ACTIONS } from './permissions-helper';
import { LocalStorageHelper } from '@coode/fe-sdk';

export default class AppSecurity {

    // Check if user has admin rights
    public hasAdminRights(): Promise<boolean> {
        return new Promise((resolve) => {
            const user = LocalStorageHelper.getPermissionsLocalStorage();
            if (!user) {
                return resolve(false);
            } else {
                const permissions = user.permissions;
                if (!permissions) return resolve(false);

                const hasAdminRights = permissions
                    .findIndex((permission: any) => permission.name === PERMISSIONS.HasAdminRights);
                return resolve(hasAdminRights !== -1);
            }
        });
    }

    // Check if user has access to plant
    public hasAccessToPlant(plantSid: number): Promise<boolean> {
        return new Promise((resolve) => {
            const user = LocalStorageHelper.getPermissionsLocalStorage();
            if (!user) {
                return resolve(false);
            } else {
                const assetPermission = user.assetPermissions
                    .findIndex((asset: any) => asset.assetSid === plantSid);
                return resolve(assetPermission !== null);
            }
        });
    }

    public hasWriteAccessToPlant(plantSid: number): Promise<boolean> {
        return new Promise((resolve) => {
            const user = LocalStorageHelper.getPermissionsLocalStorage();
            if (!user) {
                return resolve(false);
            } else {
                const assetPermission = user.assetPermissions
                    .findIndex((asset: any) =>
                        asset.assetSid === plantSid &&
                        asset.action === ASSET_PERMISSION_ACTIONS.Write
                    );
                return resolve(assetPermission !== -1);
            }
        });
    }

    public hasPermission(permissionName: string): Promise<boolean> {
        return new Promise((resolve) => {
            const user = LocalStorageHelper.getPermissionsLocalStorage();
            const permissions = user.permissions;
            if (!permissions) return resolve(false);
            const hasPermission = permissions
                .findIndex((permission: any) => permission.name === permissionName);
            return resolve(hasPermission !== -1);
        });
    }
}
