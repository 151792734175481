import { Constants, Connection } from '@/utils';
import { CancelToken } from 'axios';
export class OutageSummaryService {
    private api: Connection = new Connection();

    public async getOutage(
        startYear: number,
        endYear: number,
        isAnnualPreview: boolean,
        visibleValuesSelected: number,
        plants: any[],
        mostImportantOutagesOnly = false,
        language: string,
        cancelToken: CancelToken
    ): Promise<any> {
        if (plants.length === 0) return { plants: [] };

        const request = {
            startYear,
            endYear,
            isAnnualPreview,
            visibleValuesSelected,
            plants,
            mostImportantOutagesOnly,
            language,
        };

        return (
            await this.api.post(
                Constants.OUTAGE_SUMMARY.MAPPING,
                request,
                { cancelToken }
            )
        ).data.result;
    }
}
