
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Unit, Warning } from '@/utils/interfaces';
import { getters } from '@/store/types';
import { Data } from '@/utils';
import {
    PUICOLOR_WARM_GREY_50,
    PUICOLOR_SEVERITY_10
} from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({
})
export default class WarningDetailsComponent extends Vue {
    private data: Data = Data.Instance;
    private readonly COLOR_GREY = PUICOLOR_WARM_GREY_50;
    private readonly COLOR_RED = PUICOLOR_SEVERITY_10;
    private readonly POPOVER_REF = 'warning-popover';
    private readonly POPOVER_ANCHOR_REF = 'warning-popover-ref';

    @Prop({ default: null })
    private unit: Unit | undefined;

    get warningsData(): Warning { return this.$store.getters[getters.OVERVIEW__WARNINGS_BY_UNIT_ID](this.unit?.sid); }

    private get numberWarnings(): number {
        if (this.warningsData && this.warningsData.overrunToleranceWarnings !== undefined && this.warningsData.noMaintenancePlannedWarnings !== undefined) {
            return this.warningsData.overrunToleranceWarnings.length + this.warningsData.noMaintenancePlannedWarnings.length;
        }
        return 0;
    }

    private get hasWarnings(): boolean {
        return this.numberWarnings > 0;
    }

    private clickAway(): void {
        this.$refs[this.POPOVER_REF] && (this.$refs[this.POPOVER_REF] as any).$emit('pui-popover:close');
    }

    private setShowWarnings(): void {
        this.$refs[this.POPOVER_REF] && (this.$refs[this.POPOVER_REF] as any).$emit('pui-popover:open');
    }

    private toggleWarnings(): void {
        this.$refs[this.POPOVER_REF] && (this.$refs[this.POPOVER_REF] as any).$emit('pui-popover:toggle');
    }
}
