export const DRIVERS = {
    EOH: 1,
    OH: 2,
    'LTE/Ageing': 3,
    Years: 4,
    Starts: 5,
    Once: 6,
    Condition_Based: 7,
    Months: 8,
};
