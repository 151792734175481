





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Helper } from '@/utils';

@Component({})
export default class TypeaheadComponent extends Vue {
    private filteredSuggestions: any[] = [];
    private suggestionListId = '';
    private componentId = '';
    private listElement: any = null;
    private clickHandler: any = null;

    @Prop()
    private cssClass: any;

    @Prop()
    private placeholder: any;

    @Prop()
    private value: any;

    @Prop()
    private suggestions: any;

    constructor() {
        super();
    }

    mounted(): void {
        this.componentId = Helper.generateGUID(8);
        this.suggestionListId = Helper.generateGUID(8);
        this.clickHandler = (event: any): void => {
            this.listElement = document.getElementById(this.suggestionListId);
            if (!event.target.closest(`[id='${this.componentId}']`)) {
                this.showList(false);
            }
        };
        document.addEventListener('click', this.clickHandler);
    }

    beforeDestroy(): void {
        document.removeEventListener('click', this.clickHandler);
    }

    private modelChange(): void {
        const ref = (this.$refs.modelValue as any);
        if (ref) {
            this.filteredSuggestions = [];
            if (ref.value.length > 2) {
                this.filteredSuggestions = this.suggestions.filter((s: any) => s.value.indexOf(ref.value) > -1);
            }
            const show = this.filteredSuggestions.length > 0;
            this.showList(show);
            this.$emit('input', ref.value);
            this.$emit('change', ref.value);
        }
    }

    private selected(val: string): void {
        this.filteredSuggestions = [];
        this.$emit('input', val);
        this.$emit('change', val);
        this.showList(false);
    }

    private clickOnInput(): void {
        if (this.filteredSuggestions.length > 1) {
            this.showList(true);
        }
    }

    private showList(val: boolean): void {
        this.listElement.style = `display: ${val === true ? 'block' : 'none'};`;
    }
}
