/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    MaintenanceItem, ACMAlert, Table, TableItem, ACMUnitAlert, PlantItem, TableYearItem, TableFilter, ServerError, Project, TableAreaItem, AcmUnitAlert,
    TableItemYear, TableItemFlattened, DonutStyle
} from '@/utils/interfaces';
import { Data, EventBus, Helper, ACMHelper, CurrencyHelper, Constants, ObjectHelper } from '@/utils';
import { MAINTENANCEGROUPTYPES, MAINTENANCECATEGORIES, UEL_STATES } from '@/utils/maintenance-helper';
import  PlantItemModalComponent  from '@/components/table/plant-item-modal/plant-item-modal.vue';
import PlantItemMaintenanceModalComponent from '@/components/table/plant-item-maintenance-modal/plant-item-maintenance-modal.vue';
import PlantItemRiskDetailsModalComponent from '@/components/table/plant-item-risk-details-modal/risk-details-modal.vue';
import PlantItemOpportunityDetailsModalComponent from './plant-item-opportunity-details-modal/opportunity-details-modal.vue';
import ACMComponent from '@/components/table/acm-tooltip/acm-tooltip.vue';
import { DRIVERS } from '@/utils/driver-helper';
import AppSecurity from '@/utils/app-security';
import { TranslateResult } from 'vue-i18n';
import { MasterService } from '@/services/master-service';
import { MODELS_STRINGS } from '@/utils/model-helper';
import { actions, getters, mutations } from '@/store/types';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';

@Component({
    components: {
        'plant-item-maintenance-modal': PlantItemMaintenanceModalComponent,
        'plant-item-modal': PlantItemModalComponent,
        'acm-tooltip': ACMComponent,
        'plant-item-risk-details-modal': PlantItemRiskDetailsModalComponent,
        'plant-item-opportunity-details-modal': PlantItemOpportunityDetailsModalComponent,
    }
})
export default class TableComponent extends Vue {
    private readonly axios = require('axios');
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private appSecurity = new AppSecurity();
    private currencyHelper = new CurrencyHelper();
    private data: Data = Data.Instance;
    private acmHelper = new ACMHelper();
    private objectHelper = new ObjectHelper();

    /* switches */
    private loading = false;
    private userHasWriteAccess = false;
    private expanded = false;

    /* table settings */
    private table: Table = {
        areas: [],
        years: []
    };
    private unfilteredTable: Table = {
        areas: [],
        years: []
    };
    private filter: TableFilter = {
        visible: false,
        outageIds: [],
        projects: [],
        allProjects: [],
        globalProjectIds: [],
        selectedOutageId: null,
        selectedProject: null,
        selectedGlobalProjectId: null,
        maintenanceOrRiskIds: [],
        selectedMaintenanceOrRiskId: null,
        minimumCost: null,
        showOnlyFutureActivities: false,
        showOnlyMainActivities: false
    };
    private leftPaneWidth = 434;
    private tablewidth = 0;
    private driverTypes = DRIVERS;
    private maintenanceGroupTypes = MAINTENANCEGROUPTYPES;
    private acmDonutStyles = {} as Record<string, DonutStyle>;
    private ACMHelper = ACMHelper;
    private readonly ACM_UNIT_DONUT = 'acm-unit-donut';

    /* alerts structure */
    private acmUnitAlerts: AcmUnitAlert[] = [];
    private acmUnitItem: ACMUnitAlert = { alerts: [], numberRedAlerts: 0 };

    /* data */
    private selectedMaintenanceItem: MaintenanceItem = {} as MaintenanceItem;
    private months: string[] = [];
    private tableSearchTerm = '';
    private source = {} as CancelTokenSource;

    get tableAreas(): TableAreaItem[] { return this.$store.getters[getters.TABLE__GET_AREAS]; }
    get tableYears(): TableYearItem[] { return this.$store.getters[getters.TABLE__GET_YEARS]; }
    get acmAlerts(): AcmUnitAlert[] { return this.$store.getters[getters.TABLE__GET_ACM_ALERTS]; }

    constructor() {
        super();
        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }

    private async mounted(): Promise<void> {
        this.months = Helper.getMonths();
        if (this.data.selected.unit?.machineSID) {
            await this.load();
        } else {
            if (!this.data.selected.plant) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$t('PlantIsNotRegistered'),
                    duration: 5000
                });
            } else if (!this.data.selected.unit?.machineSID) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$t('UnitIsNotRegistered'),
                    duration: 5000
                });
            }
        }
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }

    private async load(): Promise<void> {
        this.loading = true;
        this.expanded = true;
        this.toggleOutages();
        this.resetFilter();
        const promises = [];

        try {
            if (this.data.selected.startDate && this.data.selected.endDate && this.data.selected.unit?.machineSID) {
                if ((!this.tableAreas?.length || !this.tableYears?.length)) {
                    this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
                    this.source = this.axios.CancelToken.source();
                    const tableResult = await this.masterService.tableService.getTable(this.data.selected.unit.machineSID, this.data.selected.startDate, this.data.selected.endDate, this.axios.cancelToken);
                    this.$store.commit(mutations.TABLE__SET_AREAS, Object.freeze(tableResult.areas));
                    this.$store.commit(mutations.TABLE__SET_YEARS, Object.freeze(tableResult.years));
                }
                this.table.areas = [...this.tableAreas];
                this.table.years = [...this.tableYears];
            }
            this.tablewidth = this.table.years.length;
            this.loading = false;
            promises.push(this.setAcmDonuts());
            promises.push(this.setupFilter());

            if (this.data.selected.plant) {
                this.userHasWriteAccess = await this.appSecurity.hasWriteAccessToPlant(this.data.selected.plant.plantSID) || await this.appSecurity.hasAdminRights();
            }
            await Promise.all(promises);
        }
        catch (error) {
            this.loading = false;
            if (this.axios.isCancel(error)) {
                return;
            }
            if (error !== undefined && (error as ServerError).response && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingTableData')
                });
            }
        }
    }

    private async loadAcmUnitAlerts(): Promise<AcmUnitAlert[]> {
        if (!this.data.selected.unit || !this.data.selected.unit.id || !this.data.selected.plant)
            return [];
        if (!this.data.selected.plant.enerlyticsSiteId) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'warning',
                message: this.$t('acmAlertsNotFound'),
                duration: 5000
            });
            return [];
        }
        if (!this.acmAlerts?.length) {
            try {
                const alerts = await this.acmHelper.getACMUnitAlerts(this.data.selected.plant.enerlyticsSiteId, this.data.selected.unit.id);
                if (!alerts) {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: this.$t('enerlyticsCallFailed')
                    });
                    this.$store.commit(mutations.TABLE__SET_ACM_ALERTS, []);
                } else {
                    this.$store.commit(mutations.TABLE__SET_ACM_ALERTS, Object.freeze(alerts));
                }
            } catch (error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$t('enerlyticsCallFailed')
                });
            }
        }
        return this.acmAlerts;
    }

    private hasValidACMCode(itemCode: string): boolean {
        if (!this.data.selected.plant || !this.data.selected.unit) {
            return false;
        }

        return this.data.selected.plant.enerlyticsSiteId !== null &&
            this.data.selected.unit.enerlyticsId !== null &&
            this.acmUnitAlerts &&
            itemCode !== null;
    }

    private populateUnitModal(): void {
        if(!this.data.selected.unit || !this.data.selected.unit.id)
            EventBus.$emit(EventBus.UNIT.OPEN_UNIT);
        EventBus.$emit(EventBus.UNIT.OPEN_UNIT_MODAL, this.data.selected.unit);
    }

    private isYearsTooltipVisible(year: TableItemYear, item: TableItem): boolean {
        return (this.objectHelper.propertyValueExists(year.summedValue) && item.driver !== this.driverTypes['LTE/Ageing'] || this.displayForYearsDriver(item, year.year)) && item.driver !== this.driverTypes.Once;
    }

    private generateTooltipName(year: TableItemYear, item: TableItem): string {
        const exceeded = this.$t('exceeded');
        if (item.driver2 === null && year.intervalExceeded) {
            return `${item.driverString} ${exceeded}`;
        }
        else if (this.objectHelper.propertyValueExists(item.driver2)) {
            return `${item.driverString}(${item.driverString2})`;
        }
        else {
            return item.driverString;
        }
    }

    private getCalculatedMessage(plantItem: TableItem, year: TableItemYear): string {
        let message = '';
        if (year.calculatedMaintenances.length > 0 && year.calculatedMaintenances[year.calculatedMaintenances.length - 1]) {
            message = plantItem.maintenanceDescription + '\n\n' + 'Calculated: ' + this.getTranslatedMonth(this.getMonth(year.calculatedMaintenances[year.calculatedMaintenances.length - 1].maintenanceDate)) + '\n';
            if (year.calculatedMaintenances[year.calculatedMaintenances.length - 1].outageId !== null) {
                message += 'Outage: ' + year.calculatedMaintenances[year.calculatedMaintenances.length - 1].outageId + '\n';
            }
            if (year.calculatedMaintenances[year.calculatedMaintenances.length - 1].linkedPlantItemsMaintenancePlanningId !== 0) {
                message += 'Planned: ' + this.getTranslatedMonth(this.getMonth(year.calculatedMaintenances[year.calculatedMaintenances.length - 1].linkedPlantItemsMaintenancePlanningDate));
            }

        }
        return message;
    }

    private populateNewMaintenanceItemModal(): void {
        this.selectedMaintenanceItem.plantItemMaintenanceId = 0;
        this.selectedMaintenanceItem.plantArea = '';
        this.selectedMaintenanceItem.plantItemId = -1;
        this.selectedMaintenanceItem.maintenanceActivity = 0;
        this.selectedMaintenanceItem.maintenanceActivityString = '';
        this.selectedMaintenanceItem.maintenanceType = null;
        this.selectedMaintenanceItem.cost = 0;
        this.selectedMaintenanceItem.driver = 0;
        this.selectedMaintenanceItem.driver2 = null;
        this.selectedMaintenanceItem.interval = 0;
        this.selectedMaintenanceItem.interval2 = null;
        this.selectedMaintenanceItem.itemModel = 0;
        this.selectedMaintenanceItem.model2 = null;
        this.selectedMaintenanceItem.shutdownDays = 0;
        this.selectedMaintenanceItem.duration = 0;
        this.selectedMaintenanceItem.tolerance = 10; // default
        this.selectedMaintenanceItem.tolerance2 = null;
        this.selectedMaintenanceItem.yearlyMaintenance = [];
        this.selectedMaintenanceItem.unitShutdown = 0;
        this.selectedMaintenanceItem.ptRiskNumber = '';
        this.selectedMaintenanceItem.customInputs = {
            boh: 1,
            en: 1,
            ez: 1,
            k1: 1,
            k2: 1,
            k3: 1,
            kb: 1,
            kg: 1,
            ks: 1,
            s: 1,
            linkedWithOH: 1,
            dtDr: 1,
            oHr: 1,
        };
        this.selectedMaintenanceItem.comment = '';
        this.selectedMaintenanceItem.globalProjectId = '';
        this.selectedMaintenanceItem.projectName = '';
        this.selectedMaintenanceItem.projectNameLocal = '';
        this.selectedMaintenanceItem.category = 0;
        this.selectedMaintenanceItem.justification = '';
        this.selectedMaintenanceItem.lumpSum = false;
        this.selectedMaintenanceItem.isProject = false;
        this.selectedMaintenanceItem.isOpex = false;
        this.selectedMaintenanceItem.linked = false;
        this.selectedMaintenanceItem.maintenanceDescription = '';
        this.selectedMaintenanceItem.maintenanceDescriptionLocal = '';
        this.selectedMaintenanceItem.maintenanceId = null;
        this.selectedMaintenanceItem.pleLimit = null;
        this.selectedMaintenanceItem.pleLimit2 = null;
        this.selectedMaintenanceItem.kksCode = null;
        this.selectedMaintenanceItem.uEL = null;
        this.selectedMaintenanceItem.uelLabel = UEL_STATES.None;
        EventBus.$emit(EventBus.TABLE.OPEN_MAINTENANCE_ITEM, { item: this.selectedMaintenanceItem, list: this.flattenAllPlantItems() });
    }

    private editMaintenanceItem(item: MaintenanceItem): void {
        this.$store.dispatch(actions.TABLE__RESET_STATE);
        this.selectedMaintenanceItem = Helper.deepCopy(item, {});
        this.selectedMaintenanceItem.uEL = this.selectedMaintenanceItem.uEL !== undefined ? this.selectedMaintenanceItem.uEL : null;
        this.selectedMaintenanceItem.uelLabel = this.selectedMaintenanceItem.uelLabel !== undefined ? this.selectedMaintenanceItem.uelLabel : UEL_STATES.None;
        EventBus.$emit(EventBus.TABLE.OPEN_MAINTENANCE_ITEM, { item: this.selectedMaintenanceItem, list: this.flattenAllPlantItems() });
    }

    private isPtRisk(item: MaintenanceItem): boolean {
        return item.ptRiskNumber != null && item.category === MAINTENANCECATEGORIES.Risk;
    }

    private isOpportunity(item: MaintenanceItem): boolean {
        return item.ptRiskNumber != null && item.category === MAINTENANCECATEGORIES.Opportunity;
    }

    private showRiskDetailsModal(item: MaintenanceItem): void {
        if (this.data.selected.plant) {
            EventBus.$emit(EventBus.TABLE.OPEN_RISK_DETAILS_MODAL, { ptRiskNumber: item.ptRiskNumber, currency: this.data.selected.plant.currency });
        }
    }

    private showOpportunityDetailsModal(item: MaintenanceItem): void {
        if (this.data.selected.plant) {
            EventBus.$emit(EventBus.TABLE.OPEN_OPPORTUNITY_DETAILS_MODAL, { ptRiskNumber: item.ptRiskNumber, currency: this.data.selected.plant.currency });
        }
    }

    private flattenAllPlantItems(): TableItemFlattened[] {
        const result: TableItemFlattened[] = [];
        this.unfilteredTable.areas.forEach(area => {
            area.items.forEach(item => {
                result.push({
                    projectName: item.projectName,
                    plantItemMaintenanceId: item.plantItemMaintenanceId,
                    plantArea: item.plantArea,
                    category: item.categoryString,
                    maintenanceActivityString: item.maintenanceActivityString,
                    driver: item.driverString,
                    driver2: item.driverString2,
                    plantItemId: item.plantItemId,
                    maintenanceDescription: item.maintenanceDescription,
                    tolerance: item.tolerance,
                    tolerance2: item.tolerance2,
                    interval: item.interval,
                    interval2: item.interval2,
                    driverId: item.driver,
                    driver2Id: item.driver2,
                    modelId: item.model,
                    model2Id: item.model2,
                    maintenanceId: item.maintenanceId,
                    customInputs: item.customInputs,
                    pleLimit: item.pleLimit,
                    pleLimit2: item.pleLimit2,
                    kksCode: item.kksCode
                });
            });
        });
        return result;
    }

    private async editPlantItem(item: PlantItem): Promise<void> {
        await this.$store.dispatch(actions.TABLE__RESET_STATE);
        EventBus.$emit(EventBus.TABLE.OPEN_PLANT_ITEM_MODAL, {
            id: item.plantItemId,
            kksCode: item.kksCode,
            acmCode: item.acmCode,
            name: item.plantItem,
            plantArea: item.plantArea,
            sapId: item.sapId,
            sapLocationCode: item.sapLocationCode,
            unitId: this.data.selected.unit && this.data.selected.unit.id,
            ohFactor: item.ohFactor,
            piTag: item.piTag || '',
            enerlyticsId: item.enerlyticsId,
        });
    }

    private addPlantItem(): void {
        EventBus.$emit(EventBus.TABLE.OPEN_PLANT_ITEM_MODAL, null);
    }

    private mapModelDriver(model: number): string {
        return MODELS_STRINGS[model];
    }

    private getProgressBarClass(remainingLife: number): string[] {
        const result = ['progress-bar'];
        if (remainingLife < 33) {
            result.push('bg-danger');
        }
        else if (remainingLife < 66) {
            result.push('bg-warning');
        }
        else result.push('bg-success');

        return result;
    }

    private setBackgroundClass(year: TableItemYear): string {
        if (year.calculatedMaintenances.length > 0) {
            const now = new Date();
            const lastCalculatedMaintenance = year.calculatedMaintenances[year.calculatedMaintenances.length - 1];
            const maintenanceDate = new Date(lastCalculatedMaintenance.maintenanceDate);
            if (now.getFullYear() === maintenanceDate.getFullYear() && now.getMonth() <= maintenanceDate.getMonth() - 1) {
                return 'bg-history';
            }
            return 'bg-calculated';
        } else if (year.calculatedMaintenances.length === 0 && year.history.length > 0) {
            return 'bg-history';
        } else {
            return '';
        }
    }

    private getMonth(dateString: string): string {
        const date = new Date(dateString);
        return `${this.months[date.getMonth()]} ${date.getFullYear()}`;
    }

    private getTranslatedMonth(dateString: string): string {
        const parts = dateString.split(' ');
        const monthPart = parts[0];
        const yearPart = parts[1];
        return `${this.$i18n.t(monthPart)} ${yearPart}`;
    }

    private getDonutChartClass(remainingLife: number): string {
        remainingLife = Math.round(remainingLife);
        if (remainingLife > 100) {
            remainingLife = 100;
        } else if (remainingLife < 0) {
            remainingLife = 0;
        }

        if (remainingLife < 33)
            return 'border-danger';

        if (remainingLife < 66)
            return 'border-warning';

        return 'border-success';
    }

    private getDonutChartStrokeDasharray(remainingLife: number): string {
        remainingLife = Math.round(remainingLife);
        if (remainingLife > 100) {
            remainingLife = 100;
        } else if (remainingLife < 0) {
            remainingLife = 0;
        }

        return remainingLife + ' ' + (100 - remainingLife);
    }

    private getDriverIcon(driver: number): string {
        switch (driver) {
            case this.driverTypes.EOH:
            case this.driverTypes.OH: return 'clock';
            case this.driverTypes.Years: return 'calendar';
            case this.driverTypes.Starts: return 'play-circle';
            case this.driverTypes.Months: return 'calendar-alt';
            default: return '';
        }
    }

    private displayForYearsDriver(item: TableItem, year: number): boolean {
        const lastMaintenanceYear = new Date(item.lastMaintenanceDate).getFullYear();
        return item.driver === this.driverTypes.Years && year >= lastMaintenanceYear;
    }

    private toggleOutages(): void {
        if (!this.expanded) {
            this.leftPaneWidth = 750;
        } else {
            this.leftPaneWidth = 434;
        }
        this.expanded = !this.expanded;
    }

    private getDateDifference(dateString: string): string {
        const difference = new Date(new Date(Date.now()).getTime() - new Date(dateString).getTime());
        if (difference.getFullYear() - 1970 >= 1) {
            return difference.getFullYear() - 1970 === 1 ?
                `${this.$i18n.t('aYear')} ${this.$i18n.t('ago')}` :
                `${difference.getFullYear() - 1970} ${this.$i18n.t('years')} ${this.$i18n.t('ago')}`;
        } else if (difference.getUTCMonth() >= 1) {
            return difference.getUTCMonth() === 1 ?
                `${this.$i18n.t('aMonth')} ${this.$i18n.t('ago')}` :
                `${difference.getUTCMonth()} ${this.$i18n.t('months')} ${this.$i18n.t('ago')}`;
        } else {
            return difference.getDate() - 1 <= 1 ?
                `${this.$i18n.t('aDay')} ${this.$i18n.t('ago')}` :
                `${difference.getDate() - 1} ${this.$i18n.t('days')} ${this.$i18n.t('ago')}`;
        }
    }

    private isFirstPlantItem(index: number, area: TableAreaItem): boolean {
        const previousItem = area.items[index - 1];
        const currentItem = area.items[index];

        if (!previousItem) {
            return true;
        }
        if (previousItem.plantItem === currentItem.plantItem) {
            return false;
        }

        return true;
    }

    private getPlantItemClass(index: number, area: TableAreaItem, includeCol = false): string {
        const nextItem = area.items[index + 1];
        const currentItem = area.items[index];

        const colClass = includeCol ? this.expanded ? 'col-5' : 'col-9' : '';
        if (index === area.items.length - 1) {
            return colClass;
        }

        if (nextItem.plantItem !== currentItem.plantItem) {
            return 'item-last-border ' + colClass;
        }

        return 'item-border-bottom ' + colClass;
    }

    /**
     * *******************************************************
     * MAINTENANCE GROUPS
     * *******************************************************
     */

    private getNextMaintenanceTitle(items: TableItem[], targetId: number): string {
        const found = this.findNextItemInAlternativeGroup(items, targetId);
        return found !== undefined ? `${this.$i18n.t('linkedTo')}: ${found.maintenanceActivityString}` : '';
    }

    private highlightLinkedItem(targetId: string): void {
        const el = document.getElementById(targetId);
        if (el) {
            el.classList.add('highlight-linked');
        }
    }

    private unHighlightLinkedItem(targetId: string): void {
        const el = document.getElementById(targetId);
        if (el) {
            el.classList.remove('highlight-linked');
        }
    }

    private highlightGroupedItems(items: TableItem[], groupId: number): void {
        const elements = items.slice().filter((i) => i.maintenanceGroupId === groupId)
            .map((el) => document.getElementById(`maintenanceItem-${el.plantItemMaintenanceId}`));
        if (elements && elements.length > 0) {
            elements.forEach((el: any) => { el.classList.add('highlight-linked'); });
        }
    }

    private unHighlightGroupedItems(items: TableItem[], groupId: number): void {
        const elements = items.slice().filter((i) => i.maintenanceGroupId === groupId)
            .map((el) => document.getElementById(`maintenanceItem-${el.plantItemMaintenanceId}`));
        if (elements && elements.length > 0) {
            elements.forEach((el: any) => { el.classList.remove('highlight-linked'); });
        }
    }

    private highlightNextMaintenanceYears(areaItems: TableItem[], item: TableItem, year: TableItemYear): void {
        const nextItemId = year.calculatedMaintenances[year.calculatedMaintenances.length - 1].nextMaintenanceIdInGroup;
        const nextItem = this.findNextItemInAlternativeGroup(areaItems, nextItemId);
        if (!nextItem) {
            return;
        }
        const currentYearLastMainteanceDate = new Date(year.calculatedMaintenances[year.calculatedMaintenances.length - 1].maintenanceDate);
        const nextItemStartYear = nextItem.years.find((y) => y.year === year.year);
        const startYearIndex = nextItemStartYear ? nextItem.years.indexOf(nextItemStartYear) : 0;
        const nextItemEndYear = nextItem.years.find(
            (y) => ((item.plantItemMaintenanceId === nextItemId && y.year > year.year) || (item.plantItemMaintenanceId !== nextItemId && y.year >= year.year)) &&
                y.calculatedMaintenances.length > 0 &&
                y.calculatedMaintenances.filter((m) => new Date(m.maintenanceDate) > currentYearLastMainteanceDate).length > 0)
        const endYearIndex = nextItemEndYear ? nextItem.years.indexOf(nextItemEndYear) : 0;

        for (let yearIndex = startYearIndex; yearIndex <= endYearIndex; yearIndex++) {
            const year = nextItem.years[yearIndex];
            const el = document.getElementById(`plantItemMaintenanceYear-${nextItem.plantItemMaintenanceId}-${year.year}`);
            el && el.classList.add('linked-maintenance-highlighted');
        }
    }

    private unHighlightNextMaintenanceYears(): void {
        const els = document.querySelectorAll('.linked-maintenance-highlighted');
        if (els && els.length > 0) {
            els.forEach((el) => { el.classList.remove('linked-maintenance-highlighted'); });
        }
    }

    private getAlternateMaintenanceTitle(items: TableItem[], year: TableItemYear): string {
        const nextItemId = year.calculatedMaintenances[year.calculatedMaintenances.length - 1].nextMaintenanceIdInGroup;
        const nextItem = this.findNextItemInAlternativeGroup(items, nextItemId);
        if (nextItem) {
            return `${this.$i18n.t('triggersMaintenanceCounterFor')}: ${nextItem.maintenanceActivityString}`;
        }
        return '';
    }

    private getParallelMaintenanceTitles(items: TableItem[], currentItemId: number, maintenanceGroupId: number): string {
        return this.getMaintenanceTitles(items, currentItemId, maintenanceGroupId, this.$i18n.t('linkedInParallelWith'));
    }

    private getSupportMaintenanceTitles(currentItemId: number, maintenanceGroupId: number): string {
        const items: TableItem[] = [];
        this.table.areas.forEach(a => {
            items.push(...a.items);
        });
        return this.getMaintenanceTitles(items, currentItemId, maintenanceGroupId, this.$i18n.t('linkedInSupportWith'));
    }

    private getMaintenanceTitles(items: TableItem[], currentItemId: number, maintenanceGroupId: number, title: TranslateResult): string {
        const els = items.filter((i) => i.maintenanceGroupId === maintenanceGroupId && i.plantItemMaintenanceId !== currentItemId);
        if (els && els.length > 0) {
            let titles = null;
            if (els.length === 1) {
                titles = els[0].maintenanceActivityString;
            } else {
                titles = els.reduce((acc, curr) => {
                    acc += `${curr.maintenanceActivityString}, `;
                    return acc
                }, '').slice(0 , -2);
            }
            return `${title}: ${titles}`;
        }
        return '';
    }

    private findNextItemInAlternativeGroup(items: TableItem[], nextId: number): TableItem | undefined {
        return items.find(i => i.plantItemMaintenanceId === nextId);
    }

    private showACMTooltip(item: TableItem): void {
        ($('#acmModal') as any).modal();
        EventBus.$emit(EventBus.TABLE.OPEN_ACM_TOOLTIP_ITEM, {
            alerts: item.alerts,
            numberAlertsRequireFeedback: item.alerts.length === 0 ? 0 : item.alerts.filter((obj: ACMAlert) => obj.status === ACMHelper.alertStatuses.REQUIRES_FEEDBACK).length,
            numberAlertsFeedbackReceived: item.alerts.length === 0 ? 0 : item.alerts.filter((obj: ACMAlert) => obj.status === ACMHelper.alertStatuses.FEEDBACK_RECEIVED).length,
            numberAlertsAcknoledged: item.alerts.length === 0 ? 0 : item.alerts.filter((obj: ACMAlert) => obj.status !== ACMHelper.alertStatuses.REQUIRES_FEEDBACK &&
                obj.status !== ACMHelper.alertStatuses.FEEDBACK_RECEIVED).length
        });
    }

    private setItemACMAlerts(code: string): ACMAlert[] {
        const itemAcmAlerts = this.acmUnitAlerts.filter(a => a.item_ids.find((item: string) => item === code));
        const result = [];

        for (const acmAlert of itemAcmAlerts) {
            result.push({
                name: acmAlert.alert_name,
                status: acmAlert.status_code,
                statusDescription: ACMHelper.getStatusDescription(acmAlert.status_code),
                statusClass: ACMHelper.getStatusClass(acmAlert.status_code),
                severity: ACMHelper.alertSeverities[ACMHelper.alertSeveritiesMapping[acmAlert.severity_code]],
                details: {
                    summary: acmAlert.alert_summary || '',
                    recommendation: acmAlert.recommendation || '',
                    feedback: acmAlert.feedback || '',
                    created: this.getDateDifference(acmAlert.date_created),
                    updated: this.getDateDifference(acmAlert.date_modified),
                }
            });
        }
        return result;
    }

    private mapAlertsToUnit(): AcmUnitAlert[] {
        const unitIds = Constants.HARDCODED_UNIT_IDS;

        if (!this.acmUnitAlerts || !this.data.selected.plant) return [];
        switch (this.data.selected.plant.enerlyticsSiteId) {
            case Constants.HARDCODED_PLANTS.DEN_HAAG:
            case Constants.HARDCODED_PLANTS.LEIDEN:
            case Constants.HARDCODED_PLANTS.STAUDINGER:
            case Constants.HARDCODED_PLANTS.GONYU:
                return this.acmUnitAlerts;
            case Constants.HARDCODED_PLANTS.GRAIN:
                return this.acmUnitAlerts.filter((item) => item.unit_ids &&
                        item.unit_ids.find((id: string) => this.data.selected.unit && id === this.data.selected.unit.enerlyticsId ||
                        item.unit_ids.find((id: string) => id === unitIds.GRAIN.COMMON_SYSTEMS)));
            case Constants.HARDCODED_PLANTS.IRSCHING:
                return this.acmUnitAlerts.filter((item) => item.unit_ids &&
                        item.unit_ids.find((id: string) => this.data.selected.unit && id === this.data.selected.unit.enerlyticsId ||
                        item.unit_ids.find((id: string) => !!~unitIds.IRSCHING.U5.indexOf(id))));
            case Constants.HARDCODED_PLANTS.ROCA:
                return this.acmUnitAlerts.filter((item) => item.unit_ids &&
                    item.unit_ids.find((id: string) => this.data.selected.unit && id === this.data.selected.unit.enerlyticsId ||
                        this.data.selected.unit && !!~unitIds.ROCA.U3.indexOf(this.data.selected.unit.enerlyticsId) && item.unit_ids.find((id: string) => !!~unitIds.ROCA.U3.indexOf(id)) ||
                        this.data.selected.unit && !!~unitIds.ROCA.COMMON_SYSTEMS.indexOf(this.data.selected.unit.enerlyticsId) && item.unit_ids.find((id: string) => !!~unitIds.ROCA.COMMON_SYSTEMS.indexOf(id))
                    )
                );
            default:
                return this.acmUnitAlerts.filter((item) => item.unit_ids && item.unit_ids.find((id: string) => this.data.selected.unit && id === this.data.selected.unit.enerlyticsId));
        }
    }

    private setUnitACMAlerts(): ACMAlert[] {
        const result = [];
        const alerts = this.mapAlertsToUnit();

        for (const acmAlert of alerts) {
            result.push({
                name: acmAlert.alert_name,
                status: acmAlert.status_code,
                statusDescription: ACMHelper.getStatusDescription(acmAlert.status_code),
                statusClass: ACMHelper.getStatusClass(acmAlert.status_code),
                severity: ACMHelper.alertSeverities[ACMHelper.alertSeveritiesMapping[acmAlert.severity_code]],
                details: {
                    summary: acmAlert.alert_summary || '',
                    recommendation: acmAlert.recommendation || '',
                    feedback: acmAlert.feedback || '',
                    created: this.getDateDifference(acmAlert.date_created),
                    updated: this.getDateDifference(acmAlert.date_modified),
                }
            });
        }
        return result;
    }

    /**
     * *******************************************************
     * FILTER LOGIC
     * *******************************************************
     */

    private async setupFilter(): Promise<void> {
        this.resetFilter();

        for (const area of this.table.areas) {
            for (const item of area.items) {
                const project = { id: item.globalProjectId, name: item.projectName } as Project;

                const existingProjects = this.filter.allProjects
                    .filter(function (p) { return p.id === project.id && p.name === project.name; });

                if (existingProjects.length === 0 && project.name)
                    this.filter.allProjects.push(project);

                if (item.globalProjectId && this.filter.globalProjectIds.indexOf(item.globalProjectId) < 0)
                    this.filter.globalProjectIds.push(item.globalProjectId);

                const maintenanceOrRiskId = item.maintenanceId || item.ptRiskNumber;
                if (maintenanceOrRiskId && this.filter.maintenanceOrRiskIds.indexOf(maintenanceOrRiskId) < 0)
                    this.filter.maintenanceOrRiskIds.push(maintenanceOrRiskId);
            }
        }

        this.filter.projects = Helper.deepCopy(this.filter.allProjects, {});
        this.filter.maintenanceOrRiskIds.sort();
        if (this.data.selected.unit?.id) {
            this.filter.outageIds = await this.masterService.tableService.getUnitOutageIds(this.data.selected.unit.id);
        }
        this.unfilteredTable = Object.assign({}, this.table);
    }

    private onGlobalProjectIdFilterChange(event: any): void {
        if (event.target.value) {
            this.filter.projects = this.filter.allProjects
                .filter(function (project) { return project.id === event.target.value; });
        } else {
            this.filter.projects = Helper.deepCopy(this.filter.allProjects, {});
        }
    }

    private resetFilter(): void {
        this.filter.selectedProject = null;
        this.filter.selectedOutageId = null;
        this.filter.outageIds = [];
        this.filter.projects = [];
        this.filter.globalProjectIds = [];
        this.filter.selectedGlobalProjectId = null;
        this.filter.maintenanceOrRiskIds = [];
        this.filter.selectedMaintenanceOrRiskId = null;
        this.filter.minimumCost = null;
        this.filter.showOnlyFutureActivities = false;
        this.filter.showOnlyMainActivities = false;
    }

    private async filterTable(): Promise<void> {
        const tableCopy = Object.assign({}, this.unfilteredTable);
        if (this.noFilterSelected()) {
            this.table = tableCopy;
            this.filter.visible = false;

            if (this.expanded)
                this.toggleOutages();

            return;
        }

        this.loading = true;
        const minimumCost = parseFloat(this.filter.minimumCost || '');
        const result: {
            years: TableYearItem[];
            areas: TableAreaItem[];
        } = {
            years: this.unfilteredTable.years,
            areas: []
        };

        const maintenanceIdsByGlobalId = this.filter.selectedGlobalProjectId
            ? await this.masterService.tableService.getMaintenanceIdsByGlobalId(this.filter.selectedGlobalProjectId)
            : [];

        const maintenanceIdsByOutageId = this.filter.selectedOutageId
            ? await this.masterService.tableService.getMaintenanceIdsByOutageAndUnitId(this.filter.selectedOutageId, this.data.selected.unit?.id )
            : [];

        for (const area of tableCopy.areas) {
            const areaCopy = Object.assign({}, area);
            let filteredItems = areaCopy.items;

            if (this.filter.selectedGlobalProjectId) {
                filteredItems = this.filterItemsByGlobalId(areaCopy.items, maintenanceIdsByGlobalId);
            }

            if (this.filter.selectedProject) {
                filteredItems = areaCopy.items.
                    filter(i => this.filter.selectedProject && i.projectName === this.filter.selectedProject.name && i.globalProjectId === this.filter.selectedProject.id);
            }

            if (this.filter.selectedOutageId) {
                filteredItems = this.filterItemsByOutageId(areaCopy.items, maintenanceIdsByOutageId);
            }

            if (this.filter.selectedMaintenanceOrRiskId !== null) {
                filteredItems = filteredItems.filter(i => i.maintenanceId === this.filter.selectedMaintenanceOrRiskId || (this.objectHelper.propertyValueExists(i.ptRiskNumber) && i.ptRiskNumber.toString() === this.filter.selectedMaintenanceOrRiskId));
            }

            if (minimumCost && minimumCost > 0) {
                filteredItems = filteredItems.filter(i => i.cost >= minimumCost);
            }

            if (this.filter.showOnlyFutureActivities) {
                filteredItems = filteredItems.filter(i => i.years.filter(y => y.calculatedMaintenances.length > 0).length > 0);
            }

            if (this.filter.showOnlyMainActivities) {
                filteredItems = areaCopy.items.filter(i => i.showAsMainStrategyItem);
            }

            areaCopy.items = filteredItems;
            result.areas.push(areaCopy);
        }

        this.table = result;
        this.filter.visible = false;

        if (this.expanded)
            this.toggleOutages();

        this.loading = false;
    }

    private async tableSearch(): Promise<void> {
        const result = {
            years: this.unfilteredTable.years,
            areas: [],
        };
        const tableCopy = JSON.parse(JSON.stringify(this.unfilteredTable));
        const searchTerm = this.tableSearchTerm.toLowerCase();
        let maintenanceIdsByGlobalId: string[] = [];
        let maintenanceIdsByOutageId: string[] = [];
        if (searchTerm.length > 0) {
            try {
                maintenanceIdsByGlobalId = await this.masterService.tableService.getMaintenanceIdsByGlobalId(searchTerm);
            } catch {
                maintenanceIdsByGlobalId = [];
            }

            try {
                maintenanceIdsByOutageId = await this.masterService.tableService.getMaintenanceIdsByOutageId(searchTerm);
            } catch {
                maintenanceIdsByOutageId = [];
            }
        }

        for (const area of tableCopy.areas) {
            if (searchTerm.length > 0) {
                let filteredItems = this.filterTableItemsByString(area.items, 'plantItem', searchTerm);
                if (filteredItems.length === 0) {
                    filteredItems = this.filterTableItemsByString(area.items, 'maintenanceDescription', searchTerm);
                }
                if (filteredItems.length === 0) {
                    filteredItems = this.filterTableItemsByString(area.items, 'projectName', searchTerm);
                }
                if (filteredItems.length === 0) {
                    filteredItems = this.filterTableItemsByString(area.items, 'maintenanceId', searchTerm);
                }

                if (filteredItems.length === 0) {
                    filteredItems = this.filterTableItemsByString(area.items, 'ptRisk', searchTerm);
                }

                if (filteredItems.length === 0) {
                    filteredItems = this.filterTableItemsByString(area.items, 'ptRiskNumber', searchTerm);
                }

                if (filteredItems.length === 0) {
                    filteredItems = this.filterItemsByGlobalId(area.items, maintenanceIdsByGlobalId);
                }
                if (filteredItems.length === 0) {
                    filteredItems = this.filterItemsByOutageId(area.items, maintenanceIdsByOutageId);
                }
                area.items = filteredItems;
            }
        }
        result.areas = tableCopy.areas;
        this.table = result;
    }

    private filterTableItemsByString(tableItems: TableItem[], key: keyof TableItem, searchTerm: string): TableItem[] {
        return tableItems.filter((i: TableItem) => i[key] ? i[key].toLowerCase().indexOf(searchTerm) > -1 : false);
    }

    private filterItemsByGlobalId(items: TableItem[], maintenanceIdsByGlobalId: string[]): TableItem[] {
        return maintenanceIdsByGlobalId ? items.filter((i: TableItem) => maintenanceIdsByGlobalId.indexOf(i.maintenanceId) > -1 ||
            (this.objectHelper.propertyValueExists(i.ptRiskNumber) ? maintenanceIdsByGlobalId.indexOf(i.ptRiskNumber.toString()) > -1 : false)) : items;
    }

    private filterItemsByOutageId(items: TableItem[], maintenanceIdsByOutageId: string[]): TableItem[] {
        return maintenanceIdsByOutageId ? items.filter((i: TableItem) => maintenanceIdsByOutageId.indexOf(i.maintenanceId) > -1 ||
            (this.objectHelper.propertyValueExists(i.ptRiskNumber) ? maintenanceIdsByOutageId.indexOf(i.ptRiskNumber.toString()) > -1 : false)) : items;
    }

    private async clearFilter(): Promise<void> {
        this.filter.selectedProject = null;
        this.filter.selectedOutageId = null;
        this.filter.selectedGlobalProjectId = null;
        this.filter.selectedMaintenanceOrRiskId = null;
        this.filter.minimumCost = null;
        this.filter.showOnlyFutureActivities = false;
        this.filter.showOnlyMainActivities = false;
        await this.filterTable();
    }

    private noFilterSelected(): boolean {
        const minimumCost = parseFloat(this.filter.minimumCost || '');
        return !this.filter.selectedGlobalProjectId &&
            !this.filter.selectedProject &&
            !this.filter.selectedOutageId &&
            !this.filter.selectedMaintenanceOrRiskId &&
            !this.filter.showOnlyFutureActivities &&
            !this.filter.showOnlyMainActivities &&
            (!minimumCost || minimumCost <= 0);
    }

    private getCurrencySymbol(): string {
        return this.data.selected.plant !== null ? this.currencyHelper.getCurrencySymbolByValue(this.data.selected.plant.currency) : '';
    }

    private async setAcmDonuts(): Promise<void> {
        this.acmUnitItem =  { alerts: [], numberRedAlerts: 0 };
        if (this.data.selected.startDate && this.data.selected.endDate && this.data.selected.unit?.machineSID) {
            this.acmUnitAlerts = await this.loadAcmUnitAlerts();
        }
        this.setAlerts();
    }

    private setAlerts(): void {
        this.acmUnitItem.alerts = this.setUnitACMAlerts();
        this.acmUnitItem.numberRedAlerts = this.acmUnitItem.alerts
            .filter(obj => obj.severity === ACMHelper.alertSeverities.SIGNIFICANT_INDICATIONS_OF_DAMAGE_REQUIRING_IMMEDIATE_ACTION)
            .length;
        this.acmDonutStyles[this.ACM_UNIT_DONUT] = ACMHelper.getACMDonutChart(this.acmUnitItem.alerts);

        for (let areaIndex = 0; areaIndex < this.table.areas.length; areaIndex++) {
            for (let itemIndex = 0; itemIndex < this.table.areas[areaIndex].items.length; itemIndex++) {
                const item = this.table.areas[areaIndex].items[itemIndex];
                if (!this.hasValidACMCode(item.enerlyticsId))
                    continue;

                item.alerts = this.setItemACMAlerts(item.enerlyticsId);
                item.numberRedAlerts = item.alerts
                    .filter(obj => obj.severity === ACMHelper.alertSeverities.SIGNIFICANT_INDICATIONS_OF_DAMAGE_REQUIRING_IMMEDIATE_ACTION)
                    .length;
                this.acmDonutStyles[areaIndex] = {
                    ...this.acmDonutStyles[areaIndex], ...{ [itemIndex]: ACMHelper.getACMDonutChart(item.alerts) }
                };
            }
        }
    }

    /**
     * *******************************************************
     * COMMON
     * *******************************************************
     */

    private isTableReadOnly(): boolean {
        return !this.data.selected.unit || !this.userHasWriteAccess;
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }
}
