












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class DasboardFlagComponent extends Vue {
    @Prop()
    private countryCode?: string;

    private get flagSource(): string {
        return this.countryCode ? `assets/img/${this.countryCode.toLowerCase()}-flag.png` : '';
    }
}
