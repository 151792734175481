import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Data } from '@/utils';
import { BudgetItem, Unit, PlannedCalculatedBudgetItem } from '@/utils/interfaces';
import { getters } from '@/store/types';
import { EnerlyticsECharts, getDefaultOption, getDefaultLineSeries } from '@enerlytics/echarts';
import {
    PUICOLOR_WARM_GREY,
    PUICOLOR_UNIPER_BLUE,
} from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({
    components: {
        echarts: EnerlyticsECharts,
    }
})
export default class PlannedCalculatedBudgetComponent extends Vue {
    private data: Data = Data.Instance;
    private currencySymbol = this.data.getCurrencySymbol;

    private readonly plannedCalculatedBudgetColors = [PUICOLOR_UNIPER_BLUE, PUICOLOR_WARM_GREY];

    private plannedBudget: number[][] = [];
    private calculatedBudget: number[][] = [];

    @Prop()
    private unit: Unit | undefined;

    get budget(): BudgetItem { return this.$store.getters[getters.OVERVIEW__GET__BUDGET_BY_UNIT_ID](this.unit?.sid); }

    @Watch('budget', { immediate: true })
    private watchPlannedCalculatedBudgetData(): void {
        this.setPlannedCalculatedBudget();
    }

    private get plannedCalculatedOption(): any {
        const currentYear = new Date().getFullYear();
        const defaultOption = [
            [currentYear, 0],
            [currentYear + 1, 0],
            [currentYear + 2, 0],
            [currentYear + 3, 0]
        ];
        const option = getDefaultOption({
            legendShow: true,
            xAxisType: 'category',
            yAxisType: 'value',
            yAxisFormatter: this.yAxisFormat,
            tooltipShow: true,
            series: [
                {
                    ...getDefaultLineSeries({ color: this.plannedCalculatedBudgetColors[0] }),
                    data: this.plannedBudget.length ? this.plannedBudget : defaultOption,
                    name: this.$t('planned'),
                },
                {
                    ...getDefaultLineSeries({ color: this.plannedCalculatedBudgetColors[1] }),
                    data: this.calculatedBudget.length ? this.calculatedBudget : defaultOption,
                    name: this.$t('calculated'),
                },
            ],
        });
        option.xAxis.axisLabel.overflow = 'none';
        option.xAxis.splitLine = false;
        option.yAxis.splitNumber = 2;
        option.yAxis.axisLine = false;
        option.yAxis.axisLabel.formatter = this.yAxisFormat;
        return option;
    }

    setPlannedCalculatedBudget(): void {
        if (this.budget?.plannedCalculatedBudgets) {
            this.plannedBudget = this.budget.plannedCalculatedBudgets.map((item: PlannedCalculatedBudgetItem) => [
                new Date(item.date).getFullYear(),
                item.plannedBudgetInThousands,
            ]);
            this.calculatedBudget = this.budget.plannedCalculatedBudgets.map((item: PlannedCalculatedBudgetItem) => [
                new Date(item.date).getFullYear(),
                item.calculatedBudgetInThousands,
            ]);
        }
    }

    yAxisFormat(value: string): string {
        return `${value} k${this.currencySymbol}`;
    }
}
