import { Constants, Connection } from '@/utils';
import { OutageDocumentDetails } from '@/utils/interfaces';

export class OutageDocumentsService {
    private api: Connection = new Connection();

    public getOutageRiskDetailLevels(): any {
        return {
            0: 'Low',
            1: 'Medium',
            2: 'High',
        };
    }

    public getOutageDocumentTypes(): any {
        return {
            OUTAGE_INITIATION: 0,
            SIX_YEARS_OUTAGE_PLAN: 1,
            OUTAGE_REPORT: 2,
            RISK_BASED_SCOPING: 3,
        };
    }

    public async getOutageDocumentDetails(
        unitMaintenancePlanningId: number
    ): Promise<any> {
        return (
            await this.api.get(
                Constants.OUTAGE_DOCUMENTS.MAPPING + unitMaintenancePlanningId
            )
        ).data.result;
    }

    public async saveOutageDocumentDetails(
        outageDocumentDetails: OutageDocumentDetails
    ): Promise<any> {
        return (
            await this.api.post(
                Constants.OUTAGE_DOCUMENTS.MAPPING,
                outageDocumentDetails
            )
        ).data.result;
    }
}
