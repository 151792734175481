export const actions = {
    GROUP__SET_GROUPS: 'group/setGroups',
    PLANT__SET_PLANTS: 'plant/setPlants',
    PLANT__SET_PLANTS_WITH_ATTRIBUTES: 'plant/setPlantsWithAttributes',
    PLANT__RESET_PLANTS: 'plant/resetPlants',
    PLANT__RESET_PLANTS_WITH_ATTRIBUTES: 'plant/resetPlantsWithAttributes',
    UNIT__SET_UNITS: 'unit/setUnits',
    UNIT__SET_UNITS_WITH_ATTRIBUTES: 'unit/setUnitsWithAttributes',
    UNIT__RESET_UNIT_WITH_ATTRIBUTES: 'unit/resetUnitWithAttributes',
    OUTAGE__SET_OUTAGES_FOR_FLEET: 'outage/setOutagesForFleet',
    OUTAGE__SET_OUTAGES: 'outage/setOutages',
    OUTAGE__RESET_OUTAGES_FOR_FLEET: 'outage/resetOutagesForFleet',
    OUTAGE__RESET_STATE: 'outage/resetState',
    TABLE__SET_AREAS: 'table/setAreas',
    TABLE__SET_YEARS: 'table/setYears',
    TABLE__SET_ACM_ALERTS: 'table/setAcmAlerts',
    TABLE__RESET_STATE: 'table/resetState',
    MAINTENANCE__SUMMARY__SET_FILTER_DATA: 'maintenanceSummary/setFilterData',
    MAINTENANCE__SUMMARY__SET_MAINTENANCE_DATA: 'maintenanceSummary/setMaintenanceData',
    MAINTENANCE__SUMMARY__RESET_MAINTENANCE_DATA: 'maintenanceSummary/resetMaintenanceData',
    MAINTENANCE__SUMMARY__RESET_STATE: 'maintenanceSummary/resetState',
    GENEVA__SET_GENEVA: 'geneva/setGeneva',
    GENEVA__RESET_STATE: 'geneva/resetState',
    OUTAGE__STRATEGY__SET_OUTAGE_STRATEGY_DATA: 'outageStrategy/setOutageStrategyData',
    OUTAGE__STRATEGY__SET_FILTER_DATA: 'outageStrategy/setFilterData',
    OUTAGE__STRATEGY__RESET_STRATEGY_DATA: 'outageStrategy/resetStrategyData',
    OUTAGE__STRATEGY__RESET_STATE: 'outageStrategy/resetState',
    OUTAGE__SUMMARY__SET_OUTAGE_SUMMARY_DATA: 'outageSummary/setOutageSummaryData',
    OUTAGE__SUMMARY__RESET_STATE: 'outageSummary/resetState',
    OVERVIEW__SET_UNIT_DATA: 'overview/setUnitData',
    OVERVIEW__SET_NEXT_OUTAGE: 'overview/setNextOutage',
    OVERVIEW__SET_IS_LOADED: 'overview/setIsLoaded',
    OVERVIEW__RESET_STATE: 'overview/resetState',
    COUNTRY_SET_COUNTRIES: 'country/setCountries'
};

export const getters = {
    GROUP__GET_GROUPS: 'group/getGroups',
    PLANT__GET_PLANTS: 'plant/getPlants',
    PLANT__GET_PLANTS_WITH_ATTRIBUTES: 'plant/getPlantsWithAttributes',
    PLANT__GET_GROUP_SID_PLANTS_MAP: 'plant/getGroupSidPlantsMap',
    UNIT__GET_UNITS: 'unit/getUnits',
    UNIT__GET_UNITS_WITH_ATTRIBUTES: 'unit/getUnitsWithAttributes',
    OUTAGE__GET_OUTAGES_FOR_FLEET: 'outage/getOutagesForFleet',
    OUTAGE__GET_OUTAGES: 'outage/getOutages',
    TABLE__GET_AREAS: 'table/getAreas',
    TABLE__GET_YEARS: 'table/getYears',
    TABLE__GET_ACM_ALERTS: 'table/getAcmAlerts',
    MAINTENANCE__GET_FILTER_DATA: 'maintenanceSummary/getFilterData',
    MAINTENANCE__GET_MAINTENANCE_DATA: 'maintenanceSummary/getMaintenanceData',
    GENEVA__GET_GENEVA: 'geneva/getGeneva',
    OUTAGE__STRATEGY__GET_FILTER_DATA: 'outageStrategy/getFilterData',
    OUTAGE__STRATEGY__GET_OUTAGE_STRATEGY_DATA: 'outageStrategy/getOutageStrategyData',
    OUTAGE__SUMMARY_GET_OUTAGE_SUMMARY_DATA: 'outageSummary/getOutageSummaryData',
    OVERVIEW__GET_TIME_TO_MAINTENANCE_BY_UNIT_ID: 'overview/getTimeToMaintenanceByUnitId',
    OVERVIEW__GET__BUDGET_BY_UNIT_ID: 'overview/getBudgetByUnitId',
    OVERVIEW__GET_NEXT_OUTAGE_BY_UNIT_ID: 'overview/getNextOutageByUnitId',
    OVERVIEW__GET__HAS_NEXT_OUTAGE_BY_UNIT_ID: 'overview/getHasNextOutageByUnitId',
    OVERVIEW__GET_IS_LOADED: 'overview/getIsLoaded',
    OVERVIEW__WARNINGS_BY_UNIT_ID: 'overview/getWarningsByUnitId',
    OVERVIEW__UNIT_DATA_BY_ID: 'overview/getUnitDataById',
    COUNTRY_GET_COUNTRIES: 'country/getCountries'
};

export const mutations = {
    TABLE__SET_AREAS: 'table/setAreas',
    TABLE__SET_YEARS: 'table/setYears',
    TABLE__SET_ACM_ALERTS: 'table/setAcmAlerts'
};
