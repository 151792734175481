/* eslint-disable @typescript-eslint/no-this-alias */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Opportunity, RiskDetailTransferItem } from '@/utils/interfaces';
import { EventBus, CurrencyHelper } from '@/utils';
import { PlantMaintenanceItemService } from '@/services';

@Component({
})
export default class PlantItemOpportunityDetailsModalComponent extends Vue {
    private opportunity: Opportunity = {} as Opportunity;
    private currency = 0;
    private currencyHelper: CurrencyHelper = new CurrencyHelper();
    private plantMaintenanceItemService: PlantMaintenanceItemService = new PlantMaintenanceItemService();

    constructor() {
        super();
    }

    private created(): void {
        EventBus.$on(EventBus.TABLE.OPEN_OPPORTUNITY_DETAILS_MODAL, this.handleOpenOpportunityDetailsModal);
    }

    beforeDestroy(): void {
        EventBus.$off(EventBus.TABLE.OPEN_OPPORTUNITY_DETAILS_MODAL, this.handleOpenOpportunityDetailsModal);
    }

    private async loadOpportunity(ptRiskNumber: string): Promise<void> {
        try {
            const self = this;
            self.opportunity = await this.plantMaintenanceItemService.getOpportunity(ptRiskNumber);
        } catch (error) {
            if (error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, { class: 'error', message: this.$i18n.t('errorGettingPtRisk') });
            }
        }
    }

    private getCurrencySymbol(): string {
        return this.currencyHelper.getCurrencySymbolByValue(this.currency);
    }

    private async handleOpenOpportunityDetailsModal(data: RiskDetailTransferItem): Promise<void> {
        this.currency = data.currency;
        await this.loadOpportunity(data.ptRiskNumber);
    }
}
