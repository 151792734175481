import { Connection } from '@/utils';

export class JobsService {
    private api: Connection = new Connection();

    public async getGenevaImportHistory(): Promise<any> {
        return (await this.api.get('jobs/getJobHistory/ExpertViewImportJob')).data.result.response;
    }

    public async downloadGenevaData(): Promise<any> {
        return (await this.api.get('jobs/downloadgenevadata')).data.result;
    }

    public async runGenevaImportJob(): Promise<any> {
        return (await this.api.get('jobs/runJobByName/ExpertViewImportJob')).data.result;
    }

    public async getPortfolioReviewImportHistory(): Promise<any> {
        return (await this.api.get('jobs/getportfolioreviewimporthistory'))
            .data.result;
    }

    public async runPortfolioReviewImportJob(): Promise<any> {
        return (await this.api.post('jobs/runportfolioreviewimportjob')).data.result;
    }

    public async getOutageCommercialValueImportHistory(): Promise<any> {
        return (
            await this.api.get('jobs/getJobHistory/OutageCommercialValueImportJob')
        ).data.result.response;
    }

    public async runOutageCommercialValueImportJob(): Promise<any> {
        return (await this.api.get('jobs/runJobByName/OutageCommercialValueImportJob'))
            .data.result;
    }

    public async downloadOutageCommercialValueData(): Promise<any> {
        return (await this.api.get('jobs/downloadoutagecommercialvaluedata'))
            .data.result;
    }

    public async getOutageCommercialNominationDatesImportHistory(): Promise<any> {
        return (
            await this.api.get(
                'jobs/getJobHistory/OutageCommercialNominationDatesImportJob'
            )
        ).data.result.response;
    }

    public async runOutageCommercialNominationDatesImportJob(): Promise<any> {
        return (
            await this.api.get(
                'jobs/runJobByName/OutageCommercialNominationDatesImportJob'
            )
        ).data.result;
    }

    public async getUnitOperatingParametersImportHistory(): Promise<any> {
        return (
            await this.api.get('jobs/getJobHistory/UnitOperatingParametersImportJob')
        ).data.result.response;
    }

    public async runUnitOperatingParametersImportJob(): Promise<any> {
        return (await this.api.get('jobs/runJobByName/UnitOperatingParametersImportJob'))
            .data.result;
    }

    public async getPlantItemOperatingParametersImportHistory(): Promise<any> {
        return (
            await this.api.get(
                'jobs/getJobHistory/PlantItemOperatingParametersImportJob'
            )
        ).data.result.response;
    }

    public async runPlantItemOperatingParametersImportJob(): Promise<any> {
        return (
            await this.api.get('jobs/runJobByName/PlantItemOperatingParametersImportJob')
        ).data.result;
    }

    public async getUpdateMaintenancePtRiskJobHistory(): Promise<any> {
        return (await this.api.get('jobs/getJobHistory/UpdateMaintenancePtRiskJob'))
            .data.result.response;
    }

    public async runUpdateMaintenancePtRiskJob(): Promise<any> {
        return (await this.api.get('jobs/runJobByName/UpdateMaintenancePtRiskJob')).data.result;
    }

    public async getPiReportJobHistory(): Promise<any> {
        return (await this.api.get('jobs/getJobHistory/PiReportJob')).data.result.response;
    }

    public async runPiReportJob(): Promise<any> {
        return (await this.api.get('jobs/runJobByName/PiReportJob')).data.result;
    }

    public async runAlternateLinkingJob(): Promise<any> {
        return (await this.api.get('jobs/runJobByName/AlternateLinkingJob')).data.result;
    }

    public async getAlternateLinkingJobHistory(): Promise<any> {
        return (await this.api.get('jobs/getJobHistory/AlternateLinkingJob')).data.result.response;
    }
}
