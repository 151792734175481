












import Vue from 'vue';
import Component from 'vue-class-component';

import { Data } from '@/utils';

@Component({
    name: 'loader'
})

export default class Loader extends Vue {
    private data: Data;

    constructor() {
        super();
        this.data = Data.Instance;
    }
}

