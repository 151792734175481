import { Module } from 'vuex';
import { OutageSummaryData, OutagePlant, OutageUnit, OutageSummaryItem } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    outageSummaryData: {} as OutageSummaryData,
});


function getMergedItems(firstArr: OutageSummaryItem[], secondArr: OutageSummaryItem[]): OutageSummaryItem[] {
    const map = new Map();
    const items = firstArr.concat(secondArr);
    items.forEach(item => {
        if(!map.has(item.startDate + '-' + item.endDate)){
            map.set(item.startDate + '-' + item.endDate, item);
        }
    });
    return Array.from(map.values());
}

const OutageSummaryModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setOutageSummaryData(state, { outageSummaryData, isAnnualPreview }: { outageSummaryData: OutageSummaryData; isAnnualPreview: string }): void {
            const plants = (state.outageSummaryData[isAnnualPreview]?.plants || []).concat(outageSummaryData.plants).reduce((accPlants: OutagePlant[], currPlant: OutagePlant) => {
                const existingPlant = accPlants.find((p) => p.id === currPlant.id);
                if (!existingPlant) {
                    accPlants.push(currPlant);
                } else {
                    existingPlant.units = existingPlant.units.concat(currPlant.units).reduce((accUnits: OutageUnit[], currUnit: OutageUnit) => {
                        const existingUnit = accUnits.find((p) => p.id === currUnit.id);
                        if (!existingUnit) {
                            accUnits.push(currUnit);
                        } else {
                            existingUnit.budgets = getMergedItems(existingUnit.budgets, currUnit.budgets);
                            existingUnit.calculated = getMergedItems(existingUnit.calculated, currUnit.calculated);
                            existingUnit.nominatedDates = getMergedItems(existingUnit.nominatedDates, currUnit.nominatedDates);
                        }
                        return accUnits;
                    }, []);
                }
                return accPlants;
            }, []);

            state.outageSummaryData[isAnnualPreview] = {
                startDate: state.outageSummaryData[isAnnualPreview]?.startDate < outageSummaryData.startDate ? state.outageSummaryData[isAnnualPreview].startDate : outageSummaryData.startDate,
                endDate: state.outageSummaryData[isAnnualPreview]?.endDate > outageSummaryData.endDate ? state.outageSummaryData[isAnnualPreview].endDate : outageSummaryData.endDate,
                plants
            }
        },
        resetState(state): void {
            Object.assign(state, getDefaultState());
        }
    },

    actions: {
        setOutageSummaryData({ commit }, { outageSummaryData, isAnnualPreview}): void {
            commit('setOutageSummaryData', { outageSummaryData, isAnnualPreview });
        },
        resetState({ commit }): void {
            commit('resetState');
        }
    },

    getters: {
        getOutageSummaryData(state): OutageSummaryData {
            return state.outageSummaryData;
        },
    },
    namespaced: true
}

export default OutageSummaryModule;
