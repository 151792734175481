export const MAINTENANCE_ACTIVITIES = {
    Major_Overhaul: 1,
    Minor_Overhaul: 2,
    Inspection_A_Revision: 3,
    Inspection_B_Revision: 4,
    Inspection_C_Revision: 5,
    Overhaul: 6,
    Day_To_Day_Maintenance: 7,
    Requalification: 8,
    Replacement: 9,
    Statutory_Inspection: 10,
    Upgrade: 11,
    Modification: 12
};

export const MAINTENANCECATEGORIES = {
    Maintenance: 0,
    Risk: 1,
    Opportunity: 2,
    Statutory: 3
};

export const MAINTENANCEGROUPTYPES = {
    ALTERNATE: 0,
    PARALLEL: 1,
    SUPPORT: 2
};

export const UEL_STATES = {
    None: null,
    Imported: 0,
    Overwritten: 1,
    NoValue: 2,
};
