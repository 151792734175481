import { Connection } from '@/utils';
import { MaintenanceGroup } from '@/utils/interfaces';

export class MaintenanceGroupService {
    private api: Connection = new Connection();
    private url = 'maintenance-group/';

    public async getForPlantItemMaintenance(
        plantItemMaintenanceId: number
    ): Promise<MaintenanceGroup> {
        return ( await this.api.get(this.url + plantItemMaintenanceId )).data.result;
    }

    public async add(request: any): Promise<any> {
        return (await this.api.post(this.url, request)).data.result;
    }

    public async update(request: any): Promise<any> {
        return (await this.api.put(this.url, request)).data.result;
    }
}
