export type ValidationValue = undefined | null | string | number | Date;

export enum ValidationType {
    noValue = 0,
    negativeZero,
    undefined,
    greaterThan1,
}

export type ValidationConfig = Record<ValidationType, boolean>;

export class DataInputHelper {
    public static handleError(error: any, instance: any): void {
        if (error.response) {
            instance.validate = error.response.data;
            instance.waitingForSave = false;
        } else {
            DataInputHelper.showResult(error.message, true, instance);
            instance.waitingForSave = false;
        }
    }

    public static getValidateObject(name: string, validate: Record<string, any> | null): any {
        if (!validate) return false;
        if (!validate[name]) return false;
        return validate[name];
    }

    public static showResult(
        message: string,
        isError: boolean,
        instance: any
    ): void {
        instance.result = message;
        instance.resultError = isError;
        instance.showResultBox = true;
    }
}
