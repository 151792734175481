import { i18n } from '@/main';
import { EnerlyticsService } from '@/services';
import { EventBus } from './eventbus';
import { ACMAlert, DonutStyle } from './interfaces';

export class ACMHelper {
    public static alertStatuses: any = {
        ONGOING: 'o',
        REQUIRES_FEEDBACK: 'rf',
        MAINTENANCE_PLANNED: 'mp',
        PENDING_RETURN_TO_SERVICE: 'prs',
        FEEDBACK_RECEIVED: 'fr',
        MAINTENANCE_CONSIDERED: 'mc'
    };
    public static alertSeverities: any = {
        SIGNIFICANT_INDICATIONS_OF_DAMAGE_REQUIRING_IMMEDIATE_ACTION: 10,
        PLANT_OPERATING_WITH_SUSPECTED_OR_KNOWN_FAULT_WITH_SIGNS_OF_SLOW_DEGRADATION: 20,
        DEVIATIONS_HAVE_BEEN_NOTED_CLOSE_MONITORING_BEING_APPLIED: 30,
        COMMUNICATION_INSTRUMENTATION_NO_DATA: 40,
        PLANT_UNAVAILABLE: 50,
        SATISFACTORY: 60
    };
    public static alertSeveritiesMapping: any = {
        ria: 'SIGNIFICANT_INDICATIONS_OF_DAMAGE_REQUIRING_IMMEDIATE_ACTION',
        skf: 'PLANT_OPERATING_WITH_SUSPECTED_OR_KNOWN_FAULT_WITH_SIGNS_OF_SLOW_DEGRADATION',
        dn: 'DEVIATIONS_HAVE_BEEN_NOTED_CLOSE_MONITORING_BEING_APPLIED',
        ci: 'COMMUNICATION_INSTRUMENTATION_NO_DATA',
        pu: 'PLANT_UNAVAILABLE',
        s: 'SATISFACTORY'
    };
    public static SEVERITY1 = 'severity1';
    public static SEVERITY2 = 'severity2';
    public static SEVERITY3 = 'severity3';
    public static SEVERITY4 = 'severity4';
    public static SEVERITY5 = 'severity5';
    public static SEVERITY6 = 'severity6';
    public static STROKE_DASHOFFSET = 'stroke-dashoffset';
    public static STROKE_DASHARRAY = 'stroke-dasharray';

    private enerlyticsService: EnerlyticsService = new EnerlyticsService();

    public async getACMUnitAlerts(plantId: string, unitId: number): Promise<any> {
        try {
            return await this.enerlyticsService.getACMAlerts(plantId, unitId);
        } catch (error) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'warning',
                message: i18n.t('acmAlertsNotFound'),
                duration: 5000
            });
            return [];
        }
    }

    public createPlantItemOptions(itemsResult: any): any {
        return [
            { name: 'N/A', itemId: null },
            ...itemsResult.map((item: any) => {
                return { name: item.name, itemId: item.itemId };
            })
        ];
    }

    public createUnitOptions(unitsResult: any): any {
        return [
            { name: 'N/A', unitId: null },
            ...unitsResult.map((unit: any) => {
                return { name: unit.name, unitId: unit.unitId };
            })
        ];
    }

    public static getStatusDescription(status: string): string {
        switch (status) {
            case this.alertStatuses.ONGOING: {
                return 'statusOngoing';
            }
            case this.alertStatuses.REQUIRES_FEEDBACK: {
                return 'statusRequiresFeedback';
            }
            case this.alertStatuses.MAINTENANCE_PLANNED: {
                return 'statusMaintenancePlanned';
            }
            case this.alertStatuses.PENDING_RETURN_TO_SERVICE: {
                return 'statusPendingReturnService';
            }
            case this.alertStatuses.FEEDBACK_RECEIVED: {
                return 'statusFeedbackReceived';
            }
            case this.alertStatuses.MAINTENANCE_CONSIDERED: {
                return 'statusMaintenanceConsidered';
            }
            default:
                return '';
        }
    }

    public static getStatusClass(status: string): string {
        const secondary = 'badge-secondary';

        switch (status) {
            case this.alertStatuses.ONGOING: {
                return secondary;
            }
            case this.alertStatuses.REQUIRES_FEEDBACK: {
                return 'badge-primary';
            }
            case this.alertStatuses.MAINTENANCE_PLANNED: {
                return secondary;
            }
            case this.alertStatuses.PENDING_RETURN_TO_SERVICE: {
                return secondary;
            }
            case this.alertStatuses.FEEDBACK_RECEIVED: {
                return 'badge-success';
            }
            case this.alertStatuses.MAINTENANCE_CONSIDERED: {
                return secondary;
            }
            default:
                return '';
        }
    }

    public static getACMDonutChart(alerts: ACMAlert[]): DonutStyle {
        const totalNumber = alerts.length === 0 ? 1 : alerts.length;
        let severity1 = 0;
        let severity2 = 0;
        let severity3 = 0;
        let severity4 = 0;
        let severity5 = 0;
        let severity6 = 0;
        for (const alert of alerts) {
            if (alert.severity === this.alertSeverities.SIGNIFICANT_INDICATIONS_OF_DAMAGE_REQUIRING_IMMEDIATE_ACTION) {
                severity1++;
            } else if (alert.severity === this.alertSeverities.PLANT_OPERATING_WITH_SUSPECTED_OR_KNOWN_FAULT_WITH_SIGNS_OF_SLOW_DEGRADATION) {
                severity2++;
            } else if (alert.severity === this.alertSeverities.DEVIATIONS_HAVE_BEEN_NOTED_CLOSE_MONITORING_BEING_APPLIED) {
                severity3++;
            } else if (alert.severity === this.alertSeverities.COMMUNICATION_INSTRUMENTATION_NO_DATA) {
                severity4++;
            } else if (alert.severity === this.alertSeverities.PLANT_UNAVAILABLE) {
                severity5++;
            } else if (alert.severity === this.alertSeverities.SATISFACTORY) {
                severity6++;
            }
        }
        const deg1 = Math.round(100 * (severity1 / totalNumber));
        const deg2 = Math.round(100 * (severity2 / totalNumber));
        const deg3 = Math.round(100 * (severity3 / totalNumber));
        const deg4 = Math.round(100 * (severity4 / totalNumber));
        const deg5 = Math.round(100 * (severity5 / totalNumber));
        const deg6 = Math.round(100 * (severity6 / totalNumber));

        const noDeg1 = 125 - deg1;
        const noDeg2 = noDeg1 - deg2;
        const noDeg3 = noDeg2 - deg3;
        const noDeg4 = noDeg3 - deg4;
        const noDeg5 = noDeg4 - deg5;

        return {
            [this.SEVERITY1]: {
                [this.STROKE_DASHARRAY]: deg1 + ' ' + (100 - deg1),
                [this.STROKE_DASHOFFSET]: 25
            },
            [this.SEVERITY2]: {
                [this.STROKE_DASHARRAY]: deg2 + ' ' + (100 - deg2),
                [this.STROKE_DASHOFFSET]: noDeg1
            },
            [this.SEVERITY3]: {
                [this.STROKE_DASHARRAY]: deg3 + ' ' + (100 - deg3),
                [this.STROKE_DASHOFFSET]: noDeg2
            },
            [this.SEVERITY4]: {
                [this.STROKE_DASHARRAY]: deg4 + ' ' + (100 - deg4),
                [this.STROKE_DASHOFFSET]: noDeg3
            },
            [this.SEVERITY5]: {
                [this.STROKE_DASHARRAY]: deg5 + ' ' + (100 - deg5),
                [this.STROKE_DASHOFFSET]: noDeg4
            },
            [this.SEVERITY6]: {
                [this.STROKE_DASHARRAY]: deg6 + ' ' + (100 - deg6),
                [this.STROKE_DASHOFFSET]: noDeg5
            }
        }
    }
}
