import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
})
export default class LegendComponent extends Vue {

    @Prop()
    private color!: string;

    @Prop()
    private labelText!: string;
}
