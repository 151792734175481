export class Link {
    name: string;
    path: string;
    children?: Link[];
    constructor(name: string, path: string, children?: Link[]) {
        this.name = name;
        this.path = path;
        this.children = children;
    }
}
