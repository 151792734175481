/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable sonarjs/cognitive-complexity */
import { MasterService } from '@/services/master-service';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, Helper, EventBus, DataInputHelper, DocumentHelper, CurrencyHelper, ObjectHelper, OutageCommon } from '@/utils';
import {
    MaintenanceSummary, MaintenanceSimulation, MaintenancePlanningFilter,
    MaintenanceCustomModelItemValidate, MaintenanceFilterPlantArea, MaintenanceFilterPlantItem,
    OutageItem, ItemsByUnitId, UnitAttributes, MaintenanceSummaryFilter, GenevaOperatingHours, PlantFilter, MaintenancePlanningFilterPayload
} from '@/utils/interfaces';
import { MAINTENANCEGROUPTYPES } from '@/utils/maintenance-helper';
import { Common } from '@/components/summary';
import { DRIVERS } from '@/utils/driver-helper';
import { dateFormat } from 'vue-filter-date-format';
import { actions, getters } from '@/store/types';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';

const MAINTENANCE_SUMMARY_VIEWS: any = {
    MAINTENANCE_SUMMARY_OVERVIEW: 0,
    FINANCIAL_VIEW: 1,
    COMPONENTS_STRATEGY_VIEW: 2
};

@Component({})
export default class MaintenanceSummaryComponent extends Vue {
    private readonly axios = require('axios');
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private data: Data = Data.Instance;
    private currencyHelper: CurrencyHelper = new CurrencyHelper();
    private documentHelper: DocumentHelper = new DocumentHelper();
    private objectHelper = new ObjectHelper();
    private outageCommon = new OutageCommon();
    private viewModes = Common.getViewModes();

    /* switches */
    private showZoom = false;
    private simulationEnableYears = false;
    private filterVisible = false;
    private loading = false;
    private waitingForSimulation = false;
    private showPlannedTotals = false;
    private calculatedItemClicked = false;

    /* data */
    private numOfCols = 0;
    private headerCellWidth = 93;
    private tableRowHeight = 41;
    private granularityCoeficient = 1;
    private dayWidthInPixel = 1;
    private leftSideCellWidth = 150;
    private rowCounter = 0;
    private viewMode: number = this.viewModes.QUARTERS;

    private startDate: Date = new Date();
    private endDate: Date = new Date();

    private validate: MaintenanceCustomModelItemValidate;
    private simulation: MaintenanceSimulation;
    private filter: MaintenancePlanningFilter;

    private modalData: any;
    private tableHeader: any;
    private tableData: any;
    private tableTotals: any;
    private selectedProjectId: number | null = null;
    private yearLines: any[] = [];

    /* static data */
    private maintenanceSummaryViews = MAINTENANCE_SUMMARY_VIEWS;
    private selectedView: number = MAINTENANCE_SUMMARY_VIEWS.MAINTENANCE_SUMMARY_OVERVIEW;
    private drivers = DRIVERS;
    private language = this.data.getLanguage();
    private getFilterSource = {} as CancelTokenSource;
    private getDataSource = {} as CancelTokenSource;

    get plants(): any { return this.$store.getters[getters.PLANT__GET_PLANTS]; }
    get unitsWithAttributes(): UnitAttributes[] { return this.$store.getters[getters.UNIT__GET_UNITS_WITH_ATTRIBUTES]; }
    get filterData(): MaintenanceSummaryFilter { return this.$store.getters[getters.MAINTENANCE__GET_FILTER_DATA]; }
    get maintenanceData(): MaintenanceSummary { return this.$store.getters[getters.MAINTENANCE__GET_MAINTENANCE_DATA]; }
    get genevaData(): GenevaOperatingHours { return this.$store.getters[getters.GENEVA__GET_GENEVA]; }

    constructor() {
        super();
        this.validate = {} as MaintenanceCustomModelItemValidate;
        this.tableHeader = {};
        this.tableData = {};
        this.tableTotals = [];
        this.modalData = null;
        this.filter = {
            plantAreas: [],
            outageIds: [],
            maintenanceActivities: [],
            plantItemNames: [],
            plants: [],
            globalProjectIds: [],
            plantItemName: null,
            selectedOutageId: null,
            selectedGlobalProjectId: null,
            selectedMaintenanceOrRiskId: null,
            maintenanceOrRiskIds: [],
        } as MaintenancePlanningFilter;
        this.simulation = this.initSimulationData();

        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }

    private get hasSelectedUnit(): boolean {
        return this.data.selected.unit !== null && this.data.selected.unit.id !== null;
    }

    private getValidateObject(name: string): boolean {
        return DataInputHelper.getValidateObject(name, this.validate);
    }

    async created(): Promise<any> {
        await this.load();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        this.getFilterSource.cancel && this.getFilterSource.cancel(CANCEL_MESSAGE);
        this.getDataSource.cancel && this.getDataSource.cancel(CANCEL_MESSAGE);
    }

    beforeUpdate(): void {
        this.rowCounter = 0;
    }

    private async load(): Promise<void> {
        if (!this.data || !this.data.selected || !this.data.selected.unit || !this.data.selected.unit.id) {
            return;
        }

        this.loading = true;
        this.rowCounter = 0;

        try {
            await this.populateFilter();
            await Promise.all([this.loadTableData(null), this.populateUnitGenevaValues()]);
            this.loading = false;
            Common.initHorizontalScrollHandler();
            Common.initVerticalScrollHandler();
        } catch (error) {
            this.loading = false;
        }
    }

    private getSelectedPlants(): MaintenancePlanningFilterPayload {
        const { globalProjectIds, ...filterPayload } = this.filter;

        const plants = this.outageCommon.buildFilterWithSelectedOption(filterPayload.plants);

        return {
            ...filterPayload,
            plants
        };
    }

    private async loadTableData(simulation: MaintenanceSimulation | null): Promise<void>  {
        try {
            await this.getTableData(simulation);
            this.startDate = new Date(this.tableData.startDate);
            this.endDate = new Date(this.tableData.endDate);
            this.changeViewMode(this.viewMode);
        }
        catch (error) {
            if (this.axios.isCancel(error)) {
                return;
            }
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingMaintSummary')
                });
            }
        }
    }

    private async getTableData(simulation: any): Promise<void> {
        if (this.data.selected.startDate && this.data.selected.endDate) {
            const filters = this.getSelectedPlants();
            if (!Object.keys(this.maintenanceData).length && filters.plants.length && filters.plantAreas.length) {
                this.getDataSource.cancel && this.getDataSource?.cancel(CANCEL_MESSAGE);
                this.getDataSource = this.axios.CancelToken.source();
                await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__SET_MAINTENANCE_DATA, await this.masterService.maintenanceSummaryService.getMaintenances(
                    this.language,
                    this.data.selected.startDate,
                    this.data.selected.endDate,
                    simulation,
                    filters,
                    this.showPlannedTotals,
                    this.getDataSource.token));
            }
            this.tableData = this.maintenanceData;
        }
    }

    private async refresh(hideFilter = false): Promise<void> {
        await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__RESET_MAINTENANCE_DATA);
        this.filterVisible = hideFilter ? false : !this.filterVisible;
        this.loading = true;
        try {
            await this.loadTableData(null);
            this.loading = false;
            const container = document.getElementById('vertical-scroll');
            container && container.scrollLeft++;
            container && container.scrollLeft--;
        }
        catch (error) {
            this.loading = false;
        }
    }

    private async onSelectedViewChanged(): Promise<void> {
        const viewMode = this.selectedView === MAINTENANCE_SUMMARY_VIEWS.FINANCIAL_VIEW ? this.viewModes.YEARS : this.viewModes.QUARTERS;
        this.changeViewMode(viewMode);
        this.collapseAllPlantAreas();
    }

    private onPlantFilterChange(event: Event, plantFilter: PlantFilter): void {
        this.outageCommon.handlePlantFilterChange(event, plantFilter);
    }

    private getPlantAreas(plantAreas: MaintenanceFilterPlantArea[]): MaintenanceFilterPlantArea[] {
        if (this.selectedView !== MAINTENANCE_SUMMARY_VIEWS.COMPONENTS_STRATEGY_VIEW)
            return plantAreas;

        const filteredAreas = Object.values<any>(Helper.deepCopy(plantAreas, {}));
        filteredAreas.forEach(area => {
            area.items = area.items.filter((i: any) => i.showAsMainStrategyItem);
        });

        return filteredAreas.filter(pa => pa.items.length > 0);
    }

    private async generatePmlTemplate(): Promise<void> {
        const selectedPlant = this.data.selected.plant;
        const selectedUnit = this.data.selected.unit;
        const selectedGroup = this.data.selected.group;

        if (selectedPlant && selectedUnit && selectedGroup) {
            const payload = {
                plantName: selectedPlant.name,
                unitName: selectedUnit.name,
                unitMappingId: selectedUnit.id,
                plantMappingId: selectedPlant.id,
                unitSID: selectedUnit.sid.toString(),
                plantSID: selectedPlant.sid,
                groupName: selectedGroup.name,
                technologySID: selectedGroup.sid
            };
            const document = await this.masterService.maintenanceSummaryService.generatePmlTemplate(payload);
            this.documentHelper.decodeAndDownloadBase64File(document.title, document.content);
        }
    }

    private async exportFinancialView(): Promise<void> {
        try {
            this.loading = true;
            if (this.data.selected.startDate && this.data.selected.endDate) {
                const { globalProjectIds, ...filterPayload } = this.filter;
                const document = await this.masterService.maintenanceSummaryService.exportFinancialView(
                    this.data.selected.startDate,
                    this.data.selected.endDate,
                    filterPayload,
                    this.showPlannedTotals);

                this.documentHelper.decodeAndDownloadBase64File(document.title, document.content);
            }
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorExportingFinacialView')
                });
            }
        }
    }

    private async exportComponentsStrategy(): Promise<void> {
        try {
            this.loading = true;
            if (this.data.selected.startDate && this.data.selected.endDate) {
                const { globalProjectIds, ...filterPayload } = this.filter;
                const document = await this.masterService.maintenanceSummaryService.exportComponentsStrategyView(
                    this.data.selected.startDate,
                    this.data.selected.endDate,
                    filterPayload,
                    this.showPlannedTotals);

                this.documentHelper.decodeAndDownloadBase64File(document.title, document.content);
            }
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorExportingFinacialView')
                });
            }
        }
    }

    private onUnitFilterChange(event: Event, plant: PlantFilter, unit: any): void {
        this.outageCommon.handleFilterUnitClick(event, plant, unit);
    }

    private async populateFilter(): Promise<void> {
        try {
            if (this.data.selected.group && this.data.selected.plant) {
                const plantIds = this.plants.map((plant: any) => plant.sid);
                this.unitsWithAttributes?.length || await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes(plantIds));

                const allUnits = this.unitsWithAttributes?.map((unit: UnitAttributes) => ({
                    machineSID: unit.machineSid.toString(),
                    name: this.unitsWithAttributes?.find((mdUnit: any) => mdUnit.unitName === unit.unitName)?.businessAbbreviation || unit.unitName,
                    selected: true
                }));

                if (!Object.keys(this.filterData).length) {
                    this.getFilterSource.cancel && this.getFilterSource?.cancel(CANCEL_MESSAGE);
                    this.getFilterSource = this.axios.CancelToken.source();
                    await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__SET_FILTER_DATA, await this.masterService.maintenanceSummaryService.populateFilter(this.language, allUnits, this.getFilterSource.token));
                }

                this.filter.plantAreas = this.filterData.plantAreas.map(item => ({
                    name: item.name,
                    selected: item.selected,
                    plantItems: [],
                }));

                this.filter.plants = this.outageCommon.getPlantFilter(this.plants, this.unitsWithAttributes, false, this.data.selected.plant.businessName);

                this.setUnitDependentFilters();

                this.filter.maintenanceActivities = this.masterService.outageStrategyService.getMaintenanceActivities();

                const self = this;
                this.filter.plants.forEach(plant => {
                    let everyUnitChecked = false;
                    plant.units.forEach((unit: any) => {
                        if (self.data.selected.unit && unit.machineSID === self.data.selected.unit.sid)
                            unit.selected = true;

                        if (!unit.selected)
                            everyUnitChecked = false;
                    });

                    if (everyUnitChecked)
                        plant.selected = true;
                });
            }
        }
        catch (error) {
            if (this.axios.isCancel(error)) {
                return;
            }
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingFilterValues')
                });
            }
        }
    }

    private clearAllFilters(): void {
        this.filter.plants.forEach(p => {
            p.selected = false;
            p.units.forEach(u => {
                u.selected = false;
            });
        });

        this.filter.plantAreas.forEach(pa => { pa.selected = false; });
        this.filter.maintenanceActivities.forEach(mt => { mt.selected = false; });
        this.filter.plantItemName = null;
        this.filter.selectedOutageId = null;
        this.filter.selectedGlobalProjectId = null;
        this.filter.selectedMaintenanceOrRiskId = null;
    }

    private filterAreaClick(area: MaintenanceFilterPlantArea): void {
        area.plantItems = area.plantItems.map((el) => {
            el.selected = !area.selected;
            el.maintenanceItems = el.maintenanceItems.map((maintenance) => { maintenance.selected = !area.selected; return maintenance; }); return el;
        });
    }

    private filterPlantItemClick(plantItem: MaintenanceFilterPlantItem): void {
        plantItem.maintenanceItems = plantItem.maintenanceItems.map((el) => { el.selected = !plantItem.selected; return el; });
    }

    private async populateUnitGenevaValues(): Promise<void> {
        try {
            if (this.data.selected.unit && this.data.selected.endDate) {
                if (!this.genevaData.years || !this.genevaData.years.length) {
                    await this.$store.dispatch(actions.GENEVA__SET_GENEVA, await this.masterService.genevaService.getAll(this.data.selected.unit.sid, this.data.selected.endDate));
                }
                const response = this.genevaData;
                this.simulation.years = this.buildGenevaYears(response.years);
            }
        }
        catch (error) {
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingGenevaOH')
                });
            }
        }
    }

    private buildGenevaYears(years: any[]): any[] {
        const result: any[] = [];
        years.forEach((el) => {
            result.push(this.buildGenevaYear(el));
        });
        return result;
    }

    private buildGenevaYear(year: any): any {
        return {
            year: year.year,
            operatingHours: Helper.sumProperty(year.months, 'operatingHours'),
            hotStarts: Helper.sumProperty(year.months, 'starts'),
            warmStarts: 0,
            coldStarts: 0,
        };
    }

    private toggleFilter(): void {
        this.filterVisible = !this.filterVisible;
        if (this.filterVisible) {
            this.setUnitDependentFilters();
        }
    }

    private createTableTotals(startDate: Date, endDate: Date, viewMode: number, data: any): any[] {
        const result = [];
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();

        // create header for year view
        if (viewMode === this.viewModes.YEARS) {
            let offset = 0;
            const yearArr = Common.range(startYear, endYear, 1);
            for (let i = 0; i < yearArr.length; i++) {
                const length = Common.getDaysInYear(i + startYear);
                result.push({
                    year: i + startYear,
                    length: length,
                    leftOffsetDays: offset,
                    opexCost: data.totalOpexCost[i],
                    capexCost: data.totalCapexCost[i],
                    duration: data.totalDuration[i]
                });
                offset += length;
            }
        }
        // create header for quarter view
        else if (viewMode === this.viewModes.QUARTERS) {
            let offset = 0;
            for (let i = startYear; i <= endYear; i++) {
                let length = 0;
                for (let j = 1; j <= 4; j++) {
                    length += Common.calculateQuarterLength(j, i);
                }
                result.push({
                    year: i,
                    length: length,
                    leftOffsetDays: offset,
                    opexCost: data.totalOpexCost[i - startYear],
                    capexCost: data.totalCapexCost[i - startYear],
                    duration: data.totalDuration[i - startYear]
                });
                offset += length;
            }
        }
        // create header for monthly view
        else if (viewMode === this.viewModes.MONTHS) {
            let offset = 0;
            for (let i = startYear; i <= endYear; i++) {
                let length = 0;
                for (let j = 1; j <= 12; j++) {
                    length += Common.getDaysInMonth(j, i);
                }
                result.push({
                    year: i,
                    length: length,
                    leftOffsetDays: offset,
                    opexCost: data.totalOpexCost[i - startYear],
                    capexCost: data.totalCapexCost[i - startYear],
                    duration: data.totalDuration[i - startYear]
                });
                offset += length;
            }
        }
        return result;
    }

    private changeViewMode(viewMode: number): void {
        this.viewMode = viewMode;
        this.showZoom = false;
        this.tableHeader = Common.createTableHeader(this);
        this.tableTotals = this.createTableTotals(this.startDate, this.endDate, this.viewMode, this.tableData);
        if (this.viewMode === this.viewModes.YEARS) {
            this.granularityCoeficient = 1;
            this.dayWidthInPixel = this.headerCellWidth / 372;
        } else if (this.viewMode === this.viewModes.QUARTERS) {
            this.granularityCoeficient = 4;
            this.dayWidthInPixel = this.headerCellWidth / 93;
        } else if (this.viewMode === this.viewModes.MONTHS) {
            this.granularityCoeficient = 12;
            this.dayWidthInPixel = this.headerCellWidth / 31;
        }

        this.tableData = this.createTableData(this.startDate, this.data.selected.endDate, this.tableData);
        const diff = this.endDate.getFullYear() - this.startDate.getFullYear();
        this.yearLines = [];
        for (let i = 1; i <= diff; i++) {
            this.yearLines.push(i);
        }
    }

    private createTableData(startDate: Date, endDate: any, data: MaintenanceSummary): MaintenanceSummary {
        data.plantAreas = data.plantAreas.map((area) => {
            area.budgetMaintenanceItems = area.budgetMaintenanceItems.map((budget) => Common.computeItemPosition(budget, startDate, endDate, this.dayWidthInPixel));
            area.calculatedMaintenanceItems = area.calculatedMaintenanceItems.map((calc) => Common.computeItemPosition(calc, startDate, endDate, this.dayWidthInPixel));
            area.items = area.items.map((item) => {
                item.budgetMaintenanceItems = item.budgetMaintenanceItems.map((budget) => Common.computeItemPosition(budget, startDate, endDate, this.dayWidthInPixel));
                item.calculatedMaintenanceItems = item.calculatedMaintenanceItems.map((calc) => Common.computeItemPosition(calc, startDate, endDate, this.dayWidthInPixel));
                return item;
            });
            return area;
        });
        return data;
    }

    private populateModal(item: any, isPlantArea = false, plantAreaId = 0, isCalculated = false): void {
        this.calculatedItemClicked = isCalculated;
        this.modalData = Helper.deepCopy(item, {});
        this.modalData.groupData = null;
        this.modalData.maintenances = [];
        const plantArea = this.tableData.plantAreas.find((el: any) => el.id === plantAreaId);
        const year = item.startDate.split('-')[0];
        if (isPlantArea === true) {
            if (plantArea) {
                this.modalData.name = plantArea.name;
                this.modalData.maintenances = [];
                plantArea.items.map((el: any) => {
                    const budget = el.budgetMaintenanceItems.find(this.itemInYear(item, year));
                    const calculated = el.calculatedMaintenanceItems.find(this.itemInYear(item, year));
                    if (budget !== undefined || calculated !== undefined) {
                        const parts = el.name.split('-');
                        this.modalData.maintenances.push({ plant: parts[0], unit: parts[1], plantItem: parts[2], maintenance: parts[3] });
                    }
                });
            }
        } else {
            const plantArea = this.tableData.plantAreas.find((area: any) => area.id === plantAreaId);
            if (plantArea) {
                const maintenanceItem = plantArea.items.find((m: OutageItem) => m.id === item.plantItemMaintenanceId);

                if (maintenanceItem && maintenanceItem.linked === true) {
                    this.modalData.groupData = this.createModalGroupData(maintenanceItem, plantArea);
                }
            }
        }
    }

    private createModalGroupData(maintenanceItem: any, plantArea: any): any {
        const groupData: any = {
            groupType: maintenanceItem.maintenanceGroupType,
            groupId: maintenanceItem.maintenanceGroupId,
            groupLabel: null,
            groupItems: null
        };

        if (maintenanceItem.maintenanceGroupType === MAINTENANCEGROUPTYPES.PARALLEL ||
            maintenanceItem.maintenanceGroupType === MAINTENANCEGROUPTYPES.SUPPORT) {
            const otherItemsInGroup = plantArea.items
                .filter((m: any) => m.maintenanceGroupId === maintenanceItem.maintenanceGroupId && m.id !== maintenanceItem.id);
            if (otherItemsInGroup && otherItemsInGroup.length > 0) {
                groupData.groupLabel = maintenanceItem.maintenanceGroupType === MAINTENANCEGROUPTYPES.PARALLEL
                    ? `${this.$i18n.t('linkedInParallelWith')}:`
                    : `${this.$i18n.t('linkedInSupportWith')}:`;
                let names = '';
                if (otherItemsInGroup.length === 1) {
                    names = otherItemsInGroup[0].name;
                } else {
                    names = otherItemsInGroup.reduce((a: any, b: any) => `${a.name}, ${b.name}`);
                }
                groupData.groupItems = names;
            }
        }

        return groupData;
    }

    private itemInYear(item: any, year: number): any {
        return (item: any): boolean => item.startDate.split('-')[0] === year;
    }

    private getProgressBarClass(remaininglife: number): string[] {
        return Common.getProgressBarClass(remaininglife);
    }

    private async refreshSimulation(): Promise<void> {
        await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__RESET_MAINTENANCE_DATA);
        this.waitingForSimulation = true;
        const simulation = {
            model: this.getSelectedValue(this.simulation.model),
            bundle: this.getSelectedValue(this.simulation.bundle),
            years: this.simulation.years
        };
        try {
            await this.getTableData(simulation);
            if (this.data.selected.startDate && this.data.selected.endDate) {
                this.changeViewMode(this.viewMode);
            }
            this.waitingForSimulation = false;
            ($('#maintenanceSimulation') as any).modal('hide');
        } catch (error) {
            this.waitingForSimulation = false;
            if (this.axios.isCancel(error)) {
                return;
            }
            if (error.response) {
                this.validate = error.response.data;
            }
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorRefreshSimulation')
                });
            }
        }
    }

    private getSelectedValue(list: any): number {
        for (const item of list) {
            if (item.selected === true) {
                return item.value;
            }
        }
        return 0;
    }

    private resetSimulation(): void {
        this.selectItem(this.simulation.bundle, this.simulation.bundle[0]);
        this.selectItem(this.simulation.model, this.simulation.model[0]);
        this.populateUnitGenevaValues();
    }

    private selectItem(list: any[], item: any): void {
        list.map(function (el: any) { el.selected = false; });
        item.selected = true;
        if (item.showYears !== undefined) {
            this.simulationEnableYears = item.showYears;
        }
    }

    private getCurrentViewMode(viewMode: number): any {
        if (this.selectedView === MAINTENANCE_SUMMARY_VIEWS.COMPONENTS_STRATEGY_VIEW)
            return this.$i18n.t('strategy');

        switch (viewMode) {
            case this.viewModes.YEARS:
                return this.$i18n.t('years');
            case this.viewModes.QUARTERS:
                return this.$i18n.t('quarters');
            case this.viewModes.MONTHS:
                return this.$i18n.t('months');
            default:
                return '';
        }
    }

    private initSimulationData(): MaintenanceSimulation {
        // why was this built like a json?
        return {
            model: [
                {
                    name: 'Geneva',
                    selected: true,
                    showYears: false,
                    value: 0
                },
                {
                    name: this.$i18n.t('custom'),
                    selected: false,
                    showYears: true,
                    value: 1
                }
            ],
            bundle: [
                {
                    name: this.$i18n.t('off'),
                    selected: true,
                    value: 0
                },
                {
                    name: this.$i18n.t('groupToLeadingItem'),
                    selected: false,
                    value: 1
                }
            ],
            years: []
        } as unknown as MaintenanceSimulation;
    }

    private getCurrencySymbol(): string {
        return this.tableData.currencySymbol;
    }

    private togglePlantItems(id: string, index: number): void {
        $('#' + id).find('.hideItems').toggleClass('item-wrapper-collapsed');
        $('#' + id).find('.totals-inner-wrapper').first().toggle();
        $('#plus-' + index).toggle();
        $('#minus-' + index).toggle();

        $('#' + id).find('.financial-item').children('.financial-item-number').toggle();
    }

    private collapseAllPlantAreas(): void {
        for (let i = 0; i < this.tableData.plantAreas.length; i++) {
            const id = 'plantArea-' + i;
            const collapsedItems = $('#' + id).find('.hideItems.item-wrapper-collapsed');
            if (collapsedItems.length === 0)
                this.togglePlantItems(id, i);
        }
    }

    private getRowClass(): string {
        this.rowCounter++;
        return this.rowCounter % 2 === 1 ? 'bg-white' : 'bg-gray';
    }

    private getFormattedInterval(interval: number, driver: number): string {
        if (driver !== DRIVERS.Condition_Based && driver !== DRIVERS.Once)
            return interval.toString();

        const intervalString = interval.toString();
        return `${intervalString.substring(0, 4)}-${intervalString.substring(4, 6)}-${intervalString.substring(6, 8)}`;
    }

    private getFormattedPleLimits(item: any): string {
        let pleString = this.getFormattedPleLimit(item.pleLimit, item.driver, item.kksCode);
        if (item.driver2)
            pleString += ` / ${this.getFormattedPleLimit(item.pleLimit2, item.driver2, item.kksCode)}`;

        return pleString;
    }

    private getFormattedPleLimit(pleLimit: number, driver: number, kksCode: string): string {
        if (!kksCode || !kksCode.startsWith('MB') || (driver !== DRIVERS.EOH && driver !== DRIVERS.OH && driver !== DRIVERS.Starts))
            return 'N/A';

        return pleLimit ? pleLimit.toString() : '';
    }

    private getNextRevisionDate(plannedItems: any): any {
        const nextDates = plannedItems
            .map((i: any) => i.startDate)
            .filter((date: string) => new Date(date) >= new Date())
            .sort();

        if (!nextDates.length)
            return null;

        return dateFormat(new Date(nextDates[0]), 'YYYY-MM-DD');
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }

    private setUnitDependentFilters(): void {
        const selectedUnits = this.filter.plants.reduce((acc: string[], curr: PlantFilter) => {
            const units = curr.units.filter((unit) => unit.selected && unit.machineSID).map((unit) => unit.machineSID);
            acc = acc.concat(units as string[]);
            return acc;
        }, []);
        this.filter.outageIds = this.getItemsForSelectedUnits(this.filterData.outageIdsDictionary, selectedUnits);
        this.filter.globalProjectIds =this.getItemsForSelectedUnits(this.filterData.globalIdsDictionary, selectedUnits);
        this.filter.maintenanceOrRiskIds =this.getItemsForSelectedUnits(this.filterData.maintenanceOrRiskIdsDictionary, selectedUnits);
    }

    private getItemsForSelectedUnits(items: ItemsByUnitId, selectedUnits: string[]): string[] {
        return items ? Object.keys(items).reduce((acc: string[], curr) => {
            if (selectedUnits.includes(curr)) {
                acc = acc.concat(items[curr].filter((item: string) => !acc.includes(item)));
            }
            return acc;
        }, []) : [];
    }
}
