/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MasterService } from '@/services/master-service';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Outage, Plant, UnitAttributes, PlantAttributes, UnitFilter, OutageClock, PlantFilter } from '@/utils/interfaces';
import { Data, EventBus, OutageCommon } from '@/utils';
import { Prop } from 'vue-property-decorator';
import { actions, getters } from '@/store/types';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';
import echarts from 'echarts';

@Component({
    components: {}
})
export default class OutageClockComponent extends Vue {
    private readonly axios = require('axios');
    private readonly MAX_LINE_LENGTH = 140;
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private outageCommon: OutageCommon = new OutageCommon();
    private data: Data = Data.Instance;

    /* switches */
    private filterVisible = false;
    private allSelected = true;
    private loading = false;

    /* data */
    private clockData: OutageClock[] = [];
    private filterUnitIds: UnitFilter[] = [];
    private filter: PlantFilter[] = [];
    private rowCounter = 0;
    private dataSets = [];
    private outageClockChart!: echarts.ECharts;
    private minDuration = 0;
    private maxDuration = 1095;
    private minScore = 0;
    private maxScore = 100;
    private source = {} as CancelTokenSource;

    get plants(): Plant[] { return this.$store.getters[getters.PLANT__GET_PLANTS]; }
    get plantsWithAttributes(): Partial<PlantAttributes>[] { return this.$store.getters[getters.PLANT__GET_PLANTS_WITH_ATTRIBUTES]; }
    get unitsWithAttributes(): UnitAttributes[] { return this.$store.getters[getters.UNIT__GET_UNITS_WITH_ATTRIBUTES]; }
    get outages(): Outage[] { return this.$store.getters[getters.OUTAGE__GET_OUTAGES]; }
    get outagesForFleet(): Outage[] { return this.$store.getters[getters.OUTAGE__GET_OUTAGES_FOR_FLEET]; }
    get filteredOutages(): Outage[] {
        if (this.isEmbedded) {
            return this.outages;
        }
        const unitNames: string[] = [];
        this.filter.forEach((plant) => {
            plant.units.forEach((unit) => {
                if (unit.selected) {
                    unitNames.push(unit.name);
                }
            });
        });
        return JSON.parse(JSON.stringify(this.outagesForFleet.filter((outage: Outage) => unitNames.includes(outage.unitName))));
    }

    constructor() {
        super();
        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        EventBus.$on(EventBus.OUTAGE_CLOCK.UPDATE_DISPLAYED_OUTAGES, this.updateDisplayedOutages);
    }

    async mounted() {
        this.isEmbedded || await this.load();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        EventBus.$off(EventBus.OUTAGE_CLOCK.UPDATE_DISPLAYED_OUTAGES, this.updateDisplayedOutages);
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }


    @Prop({required: false, default: false })
    isEmbedded!: boolean;

    private async load(): Promise<void> {
        this.$nextTick(async () => {
            this.loading = true;
            try {
                this.rowCounter = 0;
                await this.getFilter();
                await this.getClockData();
                this.drawChart();
            } catch (err) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingClockData')
                });
            }
            this.loading = false;
        })
    }

    private async getClockData(): Promise<void> {
        if (this.isEmbedded) {
            return;
        }
        const payload = this.outageCommon.buildFilter(this.filter);
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
        this.source = this.axios.CancelToken.source();
        try {
            this.outagesForFleet?.length || await this.$store.dispatch(actions.OUTAGE__SET_OUTAGES_FOR_FLEET, await this.masterService.outageClockService.getOutageClock({plants: payload}, this.axios.cancelToken));
        } catch (error) {
            if (this.axios.isCancel(error)) {
                return;
            }
        }
        /* filter it by score and duration */
        this.clockData = this.filteredOutages.filter((entry) => entry.score >= this.minScore && entry.score <= this.maxScore &&
                                    entry.duration >= this.minDuration && entry.score <= this.maxDuration);
    }

    private async getFilter(): Promise<void> {
        this.filter = [];
        if (this.data.selected.group) {
            this.plantsWithAttributes?.length || await this.$store.dispatch(actions.PLANT__SET_PLANTS_WITH_ATTRIBUTES, await this.masterService.plantService.getPlantsWithAttributes());
            const plantIds = this.plantsWithAttributes.map((plant) => (plant as Plant).sid);

            this.unitsWithAttributes?.length || await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes(plantIds));

            this.filter = this.outageCommon.getPlantFilter(this.plants, this.unitsWithAttributes);
        }
    }

    private async refresh(): Promise<void> {
        this.loading = true;
        this.rowCounter = 0;
        this.filterUnitIds = [];
        this.filter.forEach((plant) => {
            plant.units.forEach((unit) => {
                if (!unit.selected) {
                    this.filterUnitIds.push(unit);
                }
            });
        });
        await this.getClockData();
        this.drawChart();
        this.loading = false;
    }

    private getRowClass(itemIndex: number): string {
        return itemIndex % 2 === 1 ? 'bg-white' : 'bg-gray';
    }

    private onSelectAllChange(value: boolean): void {
        this.outageCommon.selectAll(value, this.filter);
    }

    private onPlantFilterChange(event: Event, plant: PlantFilter): void {
        this.outageCommon.handlePlantFilterChange(event, plant);
        this.allSelected = this.outageCommon.checkIfAllSelected(this.filter);
    }

    private onUnitFilterChange(event: Event, plant: PlantFilter, unit: UnitFilter): void {
        this.outageCommon.handleFilterUnitClick(event, plant, unit);
        this.allSelected = this.outageCommon.checkIfAllSelected(this.filter);
    }

    private drawChart(outagesToBeDisplayed: Outage[] = []): void {
        const days = [];
        for (let i = 1095; i >= -150; i--) {
            days.push(i);
        }

        const scores = [];
        for (let i = 0; i <= 150; i++) {
            scores.push(i);
        }

        let data: OutageClock[] = [];

        if (this.isEmbedded && outagesToBeDisplayed?.length) {
            this.clockData = JSON.parse(JSON.stringify(outagesToBeDisplayed));
        }

        if (this.clockData) {
            this.clockData = this.clockData
                .filter(unit =>
                    !this.filterUnitIds
                        .find(item => unit.unitName === item.name && unit.plantName === item.plantName)
                )
            this.clockData.forEach((outage) => {
                outage.positionOnClock = outage.daysToGo === 0
                    ? (60 / outage.duration) * outage.daysSinceOutageStart * -1
                    : outage.daysToGo < 0 ? outage.daysToGo - 60 : outage.daysToGo;
                data.push(outage);
            });
        }

        data = data.sort(this.sortByClockPosition);

        const lines: {
            type: string;
            silent: boolean;
            animation: boolean;
            coordinateSystem: string;
            data: [[number, number, number], [number, number | undefined, number]];
            lineStyle: { width: number };
            symbolSize: (data: number[]) => number;
            z: number;
            itemStyle: { color: string };
        }[] = [];
        const labelLines: {
            type: string; silent: boolean; animation: boolean; coordinateSystem: string; z: number;
            symbolSize: (data: number[]) => number;
            data: [[number, number, number], [number, number | undefined, number]];
            itemStyle: { color: string };
            lineStyle: { type: string; color: string; width: number };
            label: {
                show: boolean; formatter: string; position: string; color: string; backgroundColor: string;
                padding: number[]; borderRadius: number; borderWidth: number; fontWeight: string; fontSize: number;
                lineHeight: number; distance: number;
                emphasis: { show: boolean; textStyle: {} };
            };
        }[] = [];

        data.forEach(outage => {
            lines.push({
                type: 'line',
                silent: true,
                animation: false,
                coordinateSystem: 'polar',
                data: [[0, 0, 0], [0.6 * outage.score, outage.positionOnClock, 8]],
                lineStyle: { width: 3 },
                symbolSize: function (data: number[]) {
                    return data[2];
                },
                z: 2,
                itemStyle: { color: '#000' }
            });

            const score = outage.score.toFixed(0);
            let position = 'right';
            if (outage.positionOnClock && outage.positionOnClock < 480) {
                position = 'left';
            }

            const unit = this.getUnitNameForClock(outage);
            const bgColor = outage.score >= 50 ? '#00c853' : '#f21100';
            const labelText = unit + ': ' + outage.daysToGo + '/' + score + '%';

            const difference = outage.positionOnClock && ((outage.positionOnClock > 950) || (outage.positionOnClock < 650 && outage.positionOnClock > 300) || (outage.positionOnClock < 0)) ? 42 : 15;
            const existingLabelLines = labelLines.filter(l => (l.data[1][1] || 0) - (outage.positionOnClock || 0) <= difference && l.label.show);
            const lineLength = this.getLineLength(existingLabelLines.length, difference);

            labelLines.push({
                type: 'line',
                silent: false,
                animation: true,
                coordinateSystem: 'polar',
                z: 1,
                symbolSize: function (data: number[]) {
                    return data[2] * 20;
                },
                data: [[0, 0, 0], [lineLength, outage.positionOnClock, 1]],
                itemStyle: { color: '#a0a0a0' },
                lineStyle: { type: 'dashed', color: '#a0a0a0', width: 1 },
                label: {
                    show: true,
                    formatter: labelText,
                    position: position,
                    color: '#fff',
                    backgroundColor: bgColor,
                    padding: [2, 4, 0, 4],
                    borderRadius: 25,
                    borderWidth: 1,
                    fontWeight: 'bold',
                    fontSize: 10,
                    lineHeight: 10,
                    distance: -20,
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '15',
                            fontWeight: 'bold',
                            padding: [10, 10, 10, 10],
                        }
                    }
                }
            });
        });

        if (this.outageClockChart)
            this.outageClockChart.dispose();

        const container = document.getElementById('chart-container') as HTMLDivElement;
        this.outageClockChart = container && echarts.init(container);

        container && this.outageClockChart.resize({ width: container.clientWidth, height: 600, silent: true });

        const options = {
            polar: {
                radius: '100%'
            },
            angleAxis: {
                type: 'category',
                data: days,
                boundaryGap: false,
                axisLine: { show: false },
                axisLabel: false,
                axisTick: false,
                clockwise: false,
                min: -150,
                max: 1095
            },
            radiusAxis: {
                type: 'category',
                data: scores,
                axisLine: { show: false },
                axisLabel: false,
                axisTick: false
            },
            series: [
                {
                    name: 'phases',
                    type: 'pie',
                    radius: ['43%', '61%'],
                    silent: true,
                    animation: false,
                    z: 0,
                    itemStyle: {
                        color: '#0078dc',
                        borderColor: '#f3f5f7',
                        borderWidth: 5,
                        borderType: 'solid'
                    },
                    label: {
                        normal: {
                            position: 'inner',
                            padding: [15, 2, 2, 2],
                            fontSize: 12,
                            fontWeight: '600'
                        },
                    },
                    data: [
                        {
                            value: 545,
                            name: 'INITIATION',
                            label: { normal: { rotate: 282 } }
                        },
                        {
                            value: 365,
                            name: 'SCOPING',
                            label: { normal: { rotate: -30, padding: [2, 2, 6, 2] } }
                        },
                        {
                            value: 185,
                            name: 'PREPARATION',
                            label: { normal: { rotate: 70 } }
                        },
                        {
                            value: 60,
                            name: 'IMPL.',
                            label: { normal: { rotate: 35, fontSize: 10 } }
                        },
                        {
                            value: 90,
                            name: 'CLOSE OUT',
                            label: { normal: { rotate: 12, fontSize: 10 } }
                        }
                    ]
                }
            ],
            radar: [
                {
                    indicator: [{}],
                    center: ['50%', '50%'],
                    radius: 120,
                    splitNumber: 1,
                    shape: 'circle',
                    splitArea: {
                        areaStyle: {
                            opacity: 0.5,
                            color: ['rgba(0, 200, 83, 0.1)']
                        }
                    },
                    axisLine: false,
                    splitLine: {
                        lineStyle: {
                            color: ['rgba(0, 120, 50, 0.51)'],
                            type: 'solid',
                            width: 1
                        }
                    }
                },
                {
                    indicator: [{}],
                    center: ['50%', '50%'],
                    radius: 60,
                    splitNumber: 1,
                    shape: 'circle',
                    splitArea: {
                        areaStyle: {
                            opacity: 0.72,
                            color: ['rgba(242, 17, 0, 0.1)']
                        }
                    },
                    axisLine: false,
                    splitLine: {
                        lineStyle: {
                            color: ['#db0f00'],
                            type: 'dashed',
                            width: 2
                        }
                    }
                },

            ]
        };

        lines.forEach((line) => (options.series as any).push(line));
        labelLines.forEach((line) => (options.series as any).push(line));

        this.outageClockChart?.setOption(options);
    }

    private getLineLength(existingLabelLines: number, difference: number): number {
        const length = 95 + existingLabelLines * (difference === 42 ? 8 : 16);
        return length <= this.MAX_LINE_LENGTH ? length : this.MAX_LINE_LENGTH;
    }

    private getUnitNameForClock(outage: OutageClock): string {
        let unit = '';
        if (outage.plantAbbreviation) {
            unit += outage.plantAbbreviation;
        } else {
            if (!outage.plantName)
                return '';

            if (outage.plantName.indexOf(' ') !== -1) {
                const parts = outage.plantName.split(' ');
                parts.forEach((p) => {
                    if (p)
                        unit += p[0].toUpperCase();
                });
            } else {
                unit += outage.plantName.substring(0, 3).toUpperCase();
            }
        }
        unit += ' ';

        const masterDataUnit = this.unitsWithAttributes?.find((unit) => unit.unitName === outage.unitName);

        if (masterDataUnit?.businessAbbreviation) {
            unit += masterDataUnit?.businessAbbreviation;
        } else {
            if (outage.unitName.indexOf(' ') !== -1) {
                const parts = outage.unitName.split(' ');
                parts.forEach((p: string) => {
                    if (p)
                        unit += p.substring(0, 2).toUpperCase();
                });
            } else {
                unit += outage.unitName.toUpperCase();
            }
        }



        return unit;
    }

    private getOutagesNumberByScore(minScore: number, maxScore: number): number {
        return this.clockData ?
            this.clockData.filter((outage) => outage.score >= minScore && outage.score <= maxScore).length : 0;
    }

    private getOutagesInInitiationPhase(): number {
        return this.clockData ?
            this.clockData.filter((outage) => outage.daysToGo > 728).length : 0;
    }

    private getOutagesInScopingPhase(): number {
        return this.clockData ?
            this.clockData.filter((outage) => outage.daysToGo <= 728 && outage.daysToGo > 183).length : 0;
    }

    private getOutagesInPreparationPhase(): number {
        return this.clockData ?
            this.clockData.filter((outage) => outage.daysToGo <= 183 && outage.daysToGo > 0).length : 0;
    }

    private getOutagesInImplementationPhase(): number {
        return this.clockData ?
            this.clockData.filter((outage) => outage.daysToGo === 0).length : 0;
    }

    private getOutagesInCloseOutPhase(): number {
        return this.clockData ?
            this.clockData.filter((outage) => outage.daysToGo < 0).length : 0;
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }

    private async updateDisplayedOutages(outagesToBeDisplayed: Outage[]): Promise<void> {
        this.drawChart(outagesToBeDisplayed);
    }

    private sortByClockPosition(a: OutageClock, b: OutageClock): number {
        const pos1 = a.positionOnClock || 0;
        const pos2 = b.positionOnClock || 0;
        return pos1 < pos2 ? 1 : ((pos2 < pos1) ? -1 : 0);
    }
}
