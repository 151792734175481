import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { coodeSdk, MSP_SCOPE } from './coode-sdk-config'
import { Data } from './data';

export class Connection {
    private attempts!: number;
    private axiosInstance: AxiosInstance;
    private data: Data;

    public constructor(endpoint = '', identityRequest = false) {
        this.data = Data.Instance;
        this.axiosInstance = axios.create({
            baseURL: identityRequest ? endpoint : process.env.VUE_APP_API_BASE_URL + endpoint,
            headers: {
                language: 'EN',
            },
        });

        this.axiosInstance.interceptors.request.use(async (config) => {
            const accessToken = await coodeSdk.auth.getToken(MSP_SCOPE);
            config.headers['Authorization'] = `Bearer ${accessToken}`;
            config.headers['Accept-Language'] = this.data.getLanguage();
            if (this.data.selected.plant) {
                config.headers['PlantSID'] = this.data.selected.plant.sid;
            }
            return config;
        });

        this.axiosInstance.interceptors.response.use((success) => {
            if (success.config.method !== 'options') {
                this.attempts = 0;
            }

            return success;
        }, async (error) => {
            if (error.response && error.response.status === 401) {
                await coodeSdk.auth.login('loginRedirect');
            } else if (error.response === undefined || error.message === 'Network Error') {
                throw error;
            } else {
                return Promise.reject(error);
            }
        });
    }

    public get(url: string, config?: AxiosRequestConfig): Promise<any> {
        return this.axiosInstance.get(url, config);
    }

    public post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.axiosInstance.post(url, data, config);
    }

    public put(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.axiosInstance.put(url, data, config);
    }

    public delete(url: string, config?: AxiosRequestConfig): Promise<any> {
        return this.axiosInstance.delete(url, config);
    }
}
