import { Module } from 'vuex';
import { Country } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    countries: {} as Country[],
});

const CountryModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setCountries(state, countries): void {
            state.countries = countries;
        },
    },

    actions: {
        setCountries({ commit }, countries): void {
            commit('setCountries', countries);
        }
    },

    getters: {
        getCountries(state): Country {
            return state.countries;
        },
    },
    namespaced: true
}

export default CountryModule;
