import { Connection, Constants } from '@/utils';
import { NextOutage, OverviewItem } from '@/utils/interfaces';
import { CancelToken } from 'axios';

export class OverviewService {
    private api: Connection = new Connection();

    public async getNextOutageByUnitId(plantId: number, unitId: number, cancelToken: CancelToken): Promise<NextOutage | undefined> {
        try {
            const nextOutage = (
                await this.api.get(`${Constants.OVERVIEW.MAPPING}${Constants.OVERVIEW.NEXT_OUTAGE}${unitId}/${plantId}`, { cancelToken })
            ).data.result;
            nextOutage.unitId = unitId;
            return nextOutage as NextOutage;
        } catch {
            return undefined;
        }
    }

    public async getOverviewDataByUnitId(plantId: number, unitId: number, cancelToken: CancelToken): Promise<OverviewItem | undefined> {
        try {
            const overviewData = (
                await this.api.get(`${Constants.OVERVIEW.MAPPING}${Constants.OVERVIEW.UNIT}${unitId}/${plantId}`, { cancelToken })
            ).data.result;
            overviewData.unitId = unitId;
            return overviewData;
        } catch {
            return undefined;
        }
    }
}
