import { DateHelper } from './date-helper';
import { PUICOLOR_DATA_GREEN,
    PUICOLOR_DATA_AMBER,
    PUICOLOR_DATA_ORANGE,
    PUICOLOR_DATA_MAGENTA,
    PUICOLOR_DATA_LIGHT_BLUE,
    PUICOLOR_WARM_GREY_50,
    PUICOLOR_PALE_GREY,
    PUICOLOR_SEVERITY_10,
    PUICOLOR_SEVERITY_30,
    PUICOLOR_SEVERITY_60,
    PUICOLOR_SEVERITY_20
} from '@enerlytics/pebble-ui/dist/constants/colors.js';


export const CANCEL_MESSAGE = 'cancel search'

const Constants = {
    COUNTRIES: {
        MASTERDATA: {
            MODULE: 'countries/module/',
        },
    },
    GROUPS: {
        ALL: 'Groups',
        FLEETS: 'fleets/module/',
    },
    PLANTS: {
        MASTERDATA: {
            MODULE: 'power-plants/module/',
            MODULE_ATTRIBUTES: 'power-plants/attributes/module/',
        },
        MAPPING: 'plant-mapping/',
        ALL: 'Plants',
        FILTER: 'Filter/',
        FLEETID: 'fleetIds',
    },
    PLANT_ITEM_MAINTENANCE: {
        MAPPING: 'plant-item-maintenance/',
        PT_RISK: 'ptRisk/',
        OPPORTUNITY: 'opportunity/',
        COPY: 'copy',
        UEL: 'uel',
    },
    PLANT_ITEM_PLANNING: {
        MAPPING: 'PlantItemsMaintenancePlanningMD/',
        BY_ID: 'byPlantItemMaintenanceId/',
        MANY: 'manyByPlantItemMaintenanceId/',
    },
    PLANT_ITEM: {
        MAPPING: 'plant-item/',
        BY_UNIT: 'kks/',
        LIST: 'list/',
        KKS_PLANT_AREAS: '/withKksPlantAreas',
    },
    PLANT_ITEM_OP_PARAMETERS: {
        MAPPING: 'plant-item-operating-parameters/',
    },
    UNIT: {
        MASTERDATA: {
            MODULE: 'module/',
            ATTRIBUTES: 'attributes/',
            UNITS: 'units/',
        },
        MAPPING: 'unit-mapping/',
        MSP_ATONYX_MAPPING: 'atonyx-mapping/',
        UNITS: 'unit',
        PLANT_ITEMS: 'plant-item/',
        UNIT_NAMES: 'GetUnitNames/',
        DELETE: 'DeleteUnit/',
        PLANTID: 'plantId',
        ALL_BY_SID: 'list'
    },
    UNIT_PLANNING: {
        MAPPING: 'unit-mappings-maintenance-planning/',
        MANY: 'manyByUnitMappingId/',
        MULTIPLE: 'list/maintenance-plannings',
        BY_UNIT: 'byUnitMappingId/',
        OUTAGES: 'list/outage-ids/',
    },
    UNIT_OP_PARAMETERS: {
        MAPPING: 'unit-operating-parameters/',
    },
    GENEVA: {
        MAPPING: 'geneva-md/',
        EXPERT_VIEW: 'get-expert-view-date/',
        MACHINE_SIDS: 'machineSid',
    },
    TABLE: {
        MAPPING: 'table-view/',
        BY_ID: 'list/',
        OUTAGES: 'get-by-unit/',
        MAINTENANCE_IDS: 'get-by-outage/',
    },
    KKS: {
        MAPPING: 'KksMD',
    },
    MAINTENANCE_GROUPS: {
        MAPPING: 'MaintenanceGroupsMD/',
        BY_MAINTENANCE_ID: 'byPlantItemMaintenanceId/',
    },
    OUTAGE_SUMMARY: {
        MAPPING: 'outage-summary',
        GET: 'Get',
    },
    OUTAGE_STRATEGY: {
        MAPPING: 'outage-strategy/',
        GET: 'Get',
        EXPORT_PDF: 'export',
        FILTER: 'filter',
    },
    OUTAGE_DOCUMENTS: {
        MAPPING: 'outage-documents/',
    },
    DOCUMENTS_GENERATOR: {
        MAPPING: 'document-generator/',
        GENERATE_PLAN: 'generate-outage-plan',
        GENERATE_EXCEL: 'generate-outage-initiation-excel',
        DOWNLOAD: 'download-document/',
        GET_DOCUMENTS: 'get-outage-documents/',
        UPLOAD: 'upload-document/',
    },
    OUTAGE_CLOCK: {
        MAPPING: 'outage-clock',
        GET: 'Get',
    },
    OUTAGE_SCORECARD: {
        MAPPING: 'outage-scorecards/',
    },
    MAINTENANCE_SUMMARY: {
        MAPPING: 'MaintenanceSummaryMD/',
        GET: 'Get',
        FILTER: 'GetFilter',
        GENERATE: 'GeneratePmlTemplate',
        EXPORT: 'ExportComponentsStrategy',
        EXPORT_FINANCIAL: 'ExportFinancialView',
    },
    REMAINING_LIFE: {
        MAPPING: 'remaining-life/',
        GET: 'Get',
        FOR_UNIT: 'ForUnit/'
    },
    OVERVIEW: {
        MAPPING: 'overview/',
        NEXT_OUTAGE: 'nextOutage/',
        UNIT: 'unit/',
    },
    DRIVERS: {
        GET: 'drivers',
    },
    /* ACM ALERTS */
    ACM_SERVICE: {
        MAPPING: 'acm-md/',
        CREDENTIALS: 'credentials',
    },
    UNIPER: {
        API: 'enerlytics/cm/app/',
        SITE: '/site',
        SITES: '/sites',
        ALERTS: '/open_alerts',
        SITE_STRUCTURE: '/site/structure',
        VERSION: 'version=',
    },
    HARDCODED_PLANTS: {
        LEIDEN: '65042549-18e4-41c6-9327-15471c7754f8',
        DEN_HAAG: '18408540-61d4-4ed9-b4b9-464d6a5a11f2',
        STAUDINGER: 'd85b37ee-b112-4073-a6a3-35be210c61eb',
        GRAIN: 'e8cdc9f7-8e79-4b80-8536-e319c471cd31',
        GONYU: '741cb2ba-e860-4b84-a588-3f9ea2ca9f78',
        ROCA: 'd3b10e99-1fd7-405e-ba73-c08e47659988',
        ROCA3: '1197d374-4a6d-4f95-ae6f-deda0b79ee66',
        IRSCHING: '69640aad-8cc1-491d-b579-489212742708',
    },
    HARDCODED_UNITS: {
        STAUDINGER: 'STA5',
        GONYU: 'GO10',
        ROCA3: 'Unit 3',
        ROCA_COMMON_SYSTEMS: 'Common Systems',
        LEIDEN: 'ELD',
        DEN_HAAG: 'EDH5',
        IRSCHING_U4: 'I4IRS4',
        IRSCHING_U5: 'I5IRS5',
    },
    HARDCODED_UNIT_IDS: {
        GRAIN: {
            U6: 'e2a1fc78-054e-4517-ac64-ebcfbc256598',
            U7: 'e1f19fdf-6853-4912-9535-b763cf798392',
            U8: '944ec5f2-69a9-49c6-a27f-24d75601aecb',
            COMMON_SYSTEMS: '7ddf0f4b-fe0f-4d90-ab3e-0e545ddd3535',
        },
        ROCA: {
            U3: ['8079ce56-212b-4062-b6d8-c5cc2beb0f25', 'b4ba2ff2-5813-4824-b647-594eaeff0f27'],
            COMMON_SYSTEMS: ['0cc4d281-410c-4892-85ce-f9052b6466de', '78f5f28b-9bc7-4b2b-9a8f-5931ef4db7a8'],
        },
        IRSCHING: {
            U4: 'ef5cef8e-3ba4-45e2-9f28-74e18d523225',
            U5: ['ee8bd78a-6ea2-41b9-99f7-b23525e51edf', 'af8be080-b1d1-4d27-aeb1-54045d560e07', 'bc0ccb28-19ed-43fb-b113-31596f39fe50'],
        },
    },
    HARDCODED_DB_IDS: {
        ROCA_U3: 25,
    },
    MD_ERRORS: {
        UNIT_NOT_FOUND: 'UnitNotFound',
        PLANT_NOT_FOUND: 'PlantNotFound',
    },
    /* PML CALLS */
    PML_CALLS: {
        BUDGETS: {
            MAINTENANCE_IDS:
                'external/budget-request/get-maintenance-ids-by-global-id',
        },
    },
    /* default values */
    DEFAULTS: {
        START_YEAR: 2005,
        CURRENT_YEAR: DateHelper.getYear(new Date()),
        END_YEAR: 2040,
    },
    PHASES_COLORS_MAP: {
        Initiation: PUICOLOR_DATA_GREEN,
        Scoping: PUICOLOR_DATA_AMBER,
        Preparation: PUICOLOR_DATA_ORANGE,
        Implementation: PUICOLOR_DATA_MAGENTA,
        CloseOut: PUICOLOR_DATA_LIGHT_BLUE,
        Finished: PUICOLOR_WARM_GREY_50,
        NotStarted: PUICOLOR_PALE_GREY,
        DaysRemaining: PUICOLOR_PALE_GREY,
        Red: PUICOLOR_SEVERITY_10,
        Amber: PUICOLOR_SEVERITY_30,
        Orange: PUICOLOR_SEVERITY_20,
        Green: PUICOLOR_SEVERITY_60,
    },
};

export { Constants };
