import { Connection, Constants } from '@/utils';
import { CancelToken } from 'axios';
export class OutageClockService {
    private api: Connection = new Connection();

    public async getOutageClock(payload: any, cancelToken: CancelToken): Promise<any> {
        return (
            await this.api.post(Constants.OUTAGE_CLOCK.MAPPING, payload, { cancelToken })).data.result.outageClock;
    }
}
