import { Constants, Connection } from '@/utils';
import { AddedUnit, Unit, DbUnit, UnitAttributes } from '@/utils/interfaces';
import qs from 'qs';
import { coodeSdk } from '@/utils/coode-sdk-config'

export class UnitService {
    private api: Connection = new Connection();
    public units: Unit[] = [];

    // TEMPORARY FIX FOR FILTERING UNITS
    // VALID UNIT IDS
    private VALID_UNIT_IDS = [
        9007,
        9013,
        9014,
        20109,
        9246,
        9064,
        4545,
        9021,
        9026,
        9024,
        9027,
        9028,
        9031,
        'xbxbx',
        9030,
        9032,
        'Expert view',
        25080,
        9054,
        9055,
        9042,
        9043,
        9145,
        9144,
        9058,
        9221,
        9273,
        121212,
        1212121212,
        'GT Mothball V1',
        20130,
        26225,
        26315,
        9513,
        9514,
        9515,
        9516,
        20214,
        9281,
        9282,
        'fsddgafewagf',
        9505,
        9504,
        9487,
        9485,
        24701,
        9488,
        9486,
        9489,
        9491,
        9522,
        9523,
        9494,
        9492,
        9493,
        9479,
        9498,
        9499,
        9537,
        20595,
        20597,
        20592,
        45454,
        9291,
        9506,
        26821,
        26825,
        4,
        25774,
        27017,
        9221,
        9034,
        9480,
        20845,
        9535,
        26563,
        9508,
        9509,
        26932,
        9022,
        25708,
    ];

    public async getUnits(plantSID: number): Promise<any> {
        /* restore units if plant changes */
        this.units = [];
        let result = (
            await coodeSdk.core.masterData.request.api.get<any>(
                Constants.UNIT.MASTERDATA.UNITS +
                    Constants.UNIT.MASTERDATA.MODULE +
                    process.env.MSPT_CODE +
                    '?' +
                    Constants.UNIT.PLANTID +
                    '=' +
                    plantSID,
                {
                    paramsSerializer: (params: any) =>
                        qs.stringify(params, { arrayFormat: 'repeat' }),
                })
        ).data.result.items;

        // TEMPORARY FIX TO FILTER OUT INVALID UNITS
        result = result.filter((unit: any) => this.VALID_UNIT_IDS.indexOf(unit.sid) !== -1);
        return result;
    }

    public async getUnitsAttributes(plantIds: number[]): Promise<UnitAttributes[]> {
        let units = (
            await coodeSdk.core.masterData.request.api.get<any>(
                Constants.UNIT.MASTERDATA.UNITS +
                        Constants.UNIT.MASTERDATA.ATTRIBUTES +
                        Constants.UNIT.MASTERDATA.MODULE +
                        process.env.MSPT_CODE,
                {
                    params: {
                        plantIds,
                    },
                    paramsSerializer: (params: any) =>
                        qs.stringify(params, { arrayFormat: 'repeat' }),
                }
            )
        ).data.result.items;

        // TEMPORARY FIX TO FILTER OUT INVALID UNITS
        units = units.filter((unit: any) => this.VALID_UNIT_IDS.indexOf(unit.machineSid) !== -1);
        return units;
    }

    // MSPT Api unit-mapping/
    public async getDBUnits(plantSID: number): Promise<DbUnit[]> {
        const units = await this.getUnits(plantSID);
        const unitSIDs = units.map((unit: Unit) => unit.sid);
        return (
            await this.api.get( Constants.UNIT.MAPPING + Constants.UNIT.ALL_BY_SID,
                {
                    params: {
                        unitSIDs,
                    },
                    paramsSerializer: (params: any) =>
                        qs.stringify(params, { arrayFormat: 'repeat' }),
                }
            )
        ).data.result.units;
    }

    public async getUnit(unitId: number): Promise<any> {
        return (await this.api.get(Constants.UNIT.MAPPING + unitId)).data.result;
    }

    public async addUnit(unit: AddedUnit): Promise<any> {
        return (await this.api.post(Constants.UNIT.MAPPING, unit)).data.result;
    }

    public async editUnit(unitId: number, unit: AddedUnit): Promise<any> {
        return (await this.api.put(Constants.UNIT.MAPPING + unitId, unit)).data.result;
    }

    // end MSPT Api unit-mapping

    public async deleteUnit(plantSID: number, unitId: number): Promise<any> {
        return (
            await this.api.delete(
                Constants.UNIT.UNITS +
                    Constants.UNIT.DELETE +
                    plantSID +
                    '/' +
                    unitId
            )
        ).data.result;
    }


    public async getUnitNamesForPlant(plantSID: number): Promise<any> {
        return (
            await this.api.get(
                Constants.UNIT.UNITS + Constants.UNIT.UNIT_NAMES + plantSID
            )
        ).data.result;
    }

    //Atonyx mappings endpoint
    public async getUnitPiTags(): Promise<any> {
        try {
            return (
                await this.api.get(Constants.UNIT.MSP_ATONYX_MAPPING + Constants.UNIT.UNITS)).data.result.unitPiTags;
        } catch (e) {
            return []
        }
    }
}
