/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Selected, } from './interfaces';
import { CurrencyHelper } from '@/utils';

export class Data {
    private static instance: Data;
    private selectedKey = 'mspt.selected';
    private langKey = 'mspt.lang';
    private currencyHelper: CurrencyHelper = new CurrencyHelper();

    public static get Instance(): Data {
        return this.instance || (this.instance = new this());
    }

    public get getCurrencySymbol(): string {
        return this.selected.plant !== null
            ? this.currencyHelper.getCurrencySymbolByValue(this.selected.plant.currency)
            : '';
    }

    public selected: Selected = {
        group: null,
        plant: null,
        plants: [],
        unit: null,
        startDate: this.getStartDate(),
        endDate: 2026
    } as Selected;

    public navBarRefresh = false;
    public hasAccess = false;

    constructor() {
        if (localStorage.getItem(this.selectedKey) !== null) {
            this.selected = JSON.parse(localStorage.getItem(this.selectedKey) as string);
        }
    }

    public getSelectedFromLocalStorage(): Selected {
        return JSON.parse(localStorage.getItem(this.selectedKey) as string);
    }

    public saveSelected() {
        const pendingForStorage: any = {};
        for (const idx in this.selected) {
            const key = (this.selected as any)[idx];
            if (key !== undefined && key !== null) {
                pendingForStorage[idx.toString()] = key;
            } else {
                pendingForStorage[idx.toString()] = null;
            }
        }
        localStorage.setItem(this.selectedKey, JSON.stringify(pendingForStorage));
    }

    public resetSelected(resetGroup: boolean, resetPlant: boolean, resetUnit: boolean) {
        if (resetGroup)
            this.selected.group = null;
        if (resetPlant)
            this.selected.plant = null;
        if (resetUnit)
            this.selected.unit = null;
        this.saveSelected();
    }

    public getLanguage() {
        return localStorage.getItem(this.langKey) as string;
    }

    public setLanguage(val: string) {
        localStorage.setItem(this.langKey, val);
        window.location.reload();
    }

    private getStartDate(): number {
        return localStorage.getItem('savedStartDate') === null ? 2005 : parseInt(localStorage.getItem('savedStartDate') as string);
    }

    private incrementStartDate() {
        if(this.selected.startDate && this.selected.endDate && this.selected.unit) {
            const endDate = new Date(this.selected.unit.plannedEndDate);
            if (this.selected.startDate === this.selected.endDate && this.selected.endDate < endDate.getFullYear()) {
                this.selected.endDate++;
            }
            if (this.selected.startDate < this.selected.endDate) {
                this.selected.startDate++;
                this.saveSelected();
            }
        }
    }

    private decrementStartDate() {
        if (this.selected.startDate === 2005) return;
        this.selected.startDate && this.selected.startDate--;
        this.saveSelected();
    }

    private incrementEndDate() {
        if(this.selected.endDate && this.selected.unit) {
            const endDate = new Date(this.selected.unit.plannedEndDate);
            if (this.selected.endDate === endDate.getFullYear()) return;
            this.selected.endDate++;
            this.saveSelected();
        }
    }

    private decrementEndDate() {
        if (this.selected.startDate && this.selected.endDate === this.selected.startDate && this.selected.startDate > 2005) {
            this.selected.startDate--;
        }
        if (this.selected.startDate && this.selected.endDate && this.selected.endDate > this.selected.startDate) {
            this.selected.endDate--;
        }
        this.saveSelected();
    }
}
