export const MODELS: any = {
    'None': 0,
    'VGB_HP_IP': 1,
    'VGB_LP_Valves': 2,
    'Siemens': 3,
    'Alstom': 4,
    'Custom': 5,
    'Any': 6,
    'Direct_DCS_Value': 7,
    'VGB_Generator': 8,
    'Linked_with_OH': 9,
    'Not_linked_with_OH': 10
};

export const MODELS_STRINGS: any = {
    0: 'None',
    1: 'VGB HP/IP',
    2: 'VGB LP/Valves',
    3: 'Siemens',
    4: 'Alstom',
    5: 'Custom',
    6: 'Any',
    7: 'Direct DCS Value',
    8: 'VGB Generator',
    9: 'Linked with OH',
    10: 'Not linked with OH'
};
