import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus, DocumentHelper, Data } from '@/utils';
import { DocumentRevision, OutageDocumentDetails, UnitAttributes } from '@/utils/interfaces';
import { MasterService } from '@/services/master-service';
import AppSecurity from '@/utils/app-security';
import { actions, getters } from '@/store/types';

@Component({})
export default class OutageDocumentsComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private appSecurity = new AppSecurity();
    private documentHelper: DocumentHelper = new DocumentHelper();
    private data: Data = Data.Instance;

    /* switches */
    private userHasWriteAccessToPlant = false;
    private loading = false;
    private noDocumentSelectedForUpload = false;

    /* data */
    private outageDocuments: DocumentRevision[] = [];
    private outageDocumentDetails: OutageDocumentDetails | null = null;
    private uploadedFileName: string | null = null;
    private tabs = {
        downloadTab: { active: true, load: this.loadOutageDocuments },
        generateTab: { active: false, load: this.loadOutageDocumentDetails }
    };

    /* static data */
    private outageDocumentType: any = this.masterService.outageDocumentsService.getOutageDocumentTypes();
    private outageRiskDetailLevels: any = this.masterService.outageDocumentsService.getOutageRiskDetailLevels();

    private documentSelection: any = {
        selectedOutageId: undefined,
        invalidOutageId: false,
        value: this.outageDocumentType.SIX_YEARS_OUTAGE_PLAN,
        extension: '.pdf',
        outageIds: []
    };

    get unitsWithAttributes(): UnitAttributes[] { return this.$store.getters[getters.UNIT__GET_UNITS_WITH_ATTRIBUTES]; }
    get unitsWithAttributesForSelectedPlant(): UnitAttributes[] {
        if (!this.data?.selected?.plant?.sid || this.unitsWithAttributes.length === 0) return [];
        return this.unitsWithAttributes.filter((unit: UnitAttributes) => unit.plantSid === this.data?.selected?.plant?.sid);
    }

    constructor() {
        super();
        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }

    async created(): Promise<void> {
        if (this.data.selected.unit)
            await this.load();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }

    async onChangedNavbar(): Promise<void> {
        if (this.data.selected.unit)
            await this.load();
    }

    private async load(): Promise<void> {
        this.documentSelection.selectedOutageId = null;
        this.outageDocumentDetails = null;
        await Promise.all([
            this.loadOutageDocuments(),
            this.getOutageIds()
        ]);
        this.userHasWriteAccessToPlant = this.data.selected.plant
            ? await this.appSecurity.hasWriteAccessToPlant(this.data.selected.plant.plantSID) || await this.appSecurity.hasAdminRights()
            : false;
    }

    private async loadOutageDocuments(): Promise<void> {
        if (this.tabs.generateTab.active) {
            this.outageDocuments = [];
            return;
        }

        try {
            this.loading = true;
            const selectedPlant = this.data.selected.plant;
            const selectedUnit = this.data.selected.unit;
            let units: UnitAttributes[] = [];
            if (selectedPlant && selectedUnit) {
                if (this.unitsWithAttributesForSelectedPlant?.length) {
                    units = this.unitsWithAttributesForSelectedPlant;
                }

                if (units.length === 0) {
                    await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes([selectedPlant.sid]));
                }

                const machineSIDs = this.unitsWithAttributesForSelectedPlant.map((unit: UnitAttributes) => unit.machineSid.toString());
                this.outageDocuments = await this.documentHelper.getOutageDocuments(
                    selectedUnit.machineSID, this.documentSelection.value, encodeURIComponent(this.documentSelection.selectedOutageId) || '', machineSIDs);
            }
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingOutageSummary')
                });
            }
        }
    }

    private async loadOutageDocumentDetails(): Promise<void> {
        try {
            this.outageDocumentDetails = null;
            if (!this.documentSelection.selectedOutageId)
                return;

            const outage: any = this.documentSelection.outageIds.filter((oid: any) => oid.id === this.documentSelection.selectedOutageId)[0];
            if (!outage)
                return;

            this.loading = true;
            this.outageDocumentDetails = await this.masterService.outageDocumentsService.getOutageDocumentDetails(outage.unitMaintenancePlanningId);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingOutageSummary')
                });
            }
        }
    }

    private async getOutageIds(): Promise<void> {
        if (this.data.selected.unit) {
            this.documentSelection.outageIds = await this.masterService.unitBudgetService.getOutageIds(this.data.selected.unit.id);
        }
    }

    private toggleTab(tabItem: any): void {
        this.tabs.downloadTab.active = false;
        this.tabs.generateTab.active = false;
        tabItem.active = true;
        tabItem.load();
        this.documentSelection.invalidOutageId = false;
        this.noDocumentSelectedForUpload = false;
    }

    private onDocumentTypeSelectionChange(): void {
        this.documentSelection.selectedOutageId = undefined;
        this.outageDocumentDetails = null;
        this.loadOutageDocuments();
    }

    private onSelectedOutageIdChange(): void {
        if (this.documentSelection.value === this.outageDocumentType.SIX_YEARS_OUTAGE_PLAN) {
            this.outageDocuments = [];
            this.outageDocumentDetails = null;
            return;
        }

        if (this.tabs.downloadTab.active) {
            this.loadOutageDocuments();
        } else {
            if (this.documentSelection.value === this.outageDocumentType.OUTAGE_INITIATION)
                this.loadOutageDocumentDetails();
            else
                this.outageDocumentDetails = null;
        }
    }

    private async saveData(): Promise<void> {
        this.loading = true;
        try {
            if (this.outageDocumentDetails) {
                await this.masterService.outageDocumentsService.saveOutageDocumentDetails(this.outageDocumentDetails);
            }
        } catch {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorGeneratingDocument')
            });
        }
        this.loading = false;
    }

    private async generateDocument(): Promise<void> {
        this.documentSelection.invalidOutageId = false;
        this.noDocumentSelectedForUpload = false;

        if (!this.areSelectionsValidForDocumentGeneration())
            return;

        this.loading = true;

        try {

            switch (this.documentSelection.value) {
                case this.outageDocumentType.SIX_YEARS_OUTAGE_PLAN:
                    await this.generateSixYearOutagePlan();
                    break;
                case this.outageDocumentType.OUTAGE_INITIATION:
                    await this.generateOutageInitiationDocument();
                    break;
                case this.outageDocumentType.OUTAGE_REPORT:
                case this.outageDocumentType.RISK_BASED_SCOPING:
                    await this.uploadOutageReportDocument();
                    break;
            }

            this.loading = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'success',
                message: this.$i18n.t('documentGenerated'),
                duration: 5000
            });

            this.toggleTab(this.tabs.downloadTab);

        } catch (err) {
            if (err && err.response.status === 404) {
                if (err.response.data === 'UnitPlanningForOutageInitiationNotFound') {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'info',
                        message: this.$i18n.t('noEligibleOIDForOutageInitiation'),
                        duration: 5000
                    });
                } else if (err.response.data === 'DocumentDoesNotExist') {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'info',
                        message: this.$i18n.t('noDocumentGenerated'),
                        duration: 5000
                    });
                }
            } else {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGeneratingDocument')
                });
            }
            this.loading = false;
        }
    }

    private areSelectionsValidForDocumentGeneration(): boolean {
        switch (this.documentSelection.value) {
            case this.outageDocumentType.SIX_YEARS_OUTAGE_PLAN:
                return true;
            case this.outageDocumentType.OUTAGE_INITIATION:
                if (!this.documentSelection.selectedOutageId) {
                    this.documentSelection.invalidOutageId = true;
                    return false;
                }

                return true;
            case this.outageDocumentType.OUTAGE_REPORT:
            case this.outageDocumentType.RISK_BASED_SCOPING:
                if (!this.documentSelection.selectedOutageId)
                    this.documentSelection.invalidOutageId = true;

                if (!this.uploadedFileName)
                    this.noDocumentSelectedForUpload = true;

                if (!this.documentSelection.selectedOutageId || !this.uploadedFileName)
                    return false;

                return true;
            default:
                return true;
        }
    }

    private async generateSixYearOutagePlan(): Promise<void> {
        const selectedPlant = this.data.selected.plant;
        let units: UnitAttributes[] = [];
        if (selectedPlant) {
            if (this.unitsWithAttributesForSelectedPlant?.length) {
                units = this.unitsWithAttributesForSelectedPlant;
            }

            if (units.length === 0) {
                await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes([selectedPlant.sid]));
            }
            const payload = {
                plantName: selectedPlant.name,
                plantSID: selectedPlant.sid,
                units: units.map((unit: UnitAttributes) => ({
                    name: units?.find((mdUnit: any) => mdUnit.unitName === unit.unitName)?.businessAbbreviation || unit.unitName,
                    sid: unit.machineSid.toString()
                }))
            };
            await this.documentHelper.generateSixYearOutagePlan(payload);
        }
    }

    private async generateOutageInitiationDocument(): Promise<void> {
        const selectedPlant = this.data.selected.plant;
        const selectedUnit = this.data.selected.unit;
        let units: UnitAttributes[] = [];

        if (selectedPlant && selectedUnit && this.outageDocumentDetails) {
            const language = this.data.getLanguage();
            if (this.unitsWithAttributesForSelectedPlant?.length) {
                units = this.unitsWithAttributesForSelectedPlant;
            }

            if (units.length === 0) {
                await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes([selectedPlant.sid]));
            }
            const machineSIDs = units.map((unit: UnitAttributes) => unit.machineSid.toString())
                .filter((unit: string) => unit !== selectedUnit.sid.toString());

            const payload = {
                plantName: selectedPlant.name,
                plantSID: selectedPlant.sid,
                unitName: selectedUnit.name,
                machineSID: selectedUnit.sid.toString(),
                language,
                restOfMachineSIDs: machineSIDs,
                outageId: this.documentSelection.selectedOutageId
            };

            await this.masterService.outageDocumentsService.saveOutageDocumentDetails(this.outageDocumentDetails);
            await this.documentHelper.generateOutageInitiationExcel(payload);
        }
    }

    private async uploadOutageReportDocument(): Promise<void> {
        const fileToUpload: any = this.$refs.fileToUpload;
        const file = fileToUpload.files[0];
        const formData = new FormData();
        formData.append('file', file);
        if (this.documentSelection.selectedOutageId && this.data.selected.plant && this.data.selected.unit) {
            await this.documentHelper.uploadOutageReportDocument(
                this.data.selected.plant.plantSID, this.data.selected.unit.machineSID, encodeURIComponent(this.documentSelection.selectedOutageId), this.documentSelection.value, formData);
        }
    }

    private async downloadDocument(documentId: number): Promise<void> {
        try {
            this.loading = true;
            const isPdf = this.documentSelection.value === this.outageDocumentType.SIX_YEARS_OUTAGE_PLAN && this.documentSelection.extension === '.pdf';
            if (this.data.selected.plant) {
                await this.documentHelper.downloadDocument(documentId, isPdf, this.data.selected.plant.name);
            }
            this.loading = false;
        } catch (err) {
            this.loading = false;
            if (err && err.response.status === 404 && err.response.data === 'DocumentDoesNotExist') {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('documentNotFound'),
                    duration: 6000
                });
            } else {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorDownloadingDcoument')
                });
            }
        }
    }

    private getSixYearOutagePlanYears(freezeDate: string): string {
        const date = new Date(freezeDate);
        return (date.getFullYear() + 1) + ' - ' + (date.getFullYear() + 6);
    }

    private setUploadedFileName(event: any): void {
        this.uploadedFileName = event.target.value ? event.target.value.split('\\').pop() : null;
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }
}
