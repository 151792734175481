import { Constants, Connection } from '@/utils';

export class DocumentGeneratorService {
    private api: Connection = new Connection();
    private url = 'document-generator/';
    public async generateSixYearOutagePlan(payload: any): Promise<any> {
        return (
            await this.api.post(
                Constants.DOCUMENTS_GENERATOR.MAPPING +
                    Constants.DOCUMENTS_GENERATOR.GENERATE_PLAN,
                payload
            )
        ).data.result;
    }

    public async getOutageInitiationExcelDocument(payload: any): Promise<any> {
        return (
            await this.api.post(
                Constants.DOCUMENTS_GENERATOR.MAPPING +
                    Constants.DOCUMENTS_GENERATOR.GENERATE_EXCEL,
                { ...payload }
            )
        ).data.result;
    }

    public async getOutageDocuments(
        machineSID: string,
        documentType: number,
        outageId: string,
        unitMachineSids: any[]
    ): Promise<any> {
        let url = Constants.DOCUMENTS_GENERATOR.MAPPING +
                        Constants.DOCUMENTS_GENERATOR.GET_DOCUMENTS +
                        machineSID +
                        '/' +
                        documentType +
                        `?outageId=${outageId}`;

        unitMachineSids?.forEach(element => {
            url += `&unitMachineSids=${element}`;
        });

        return (
            await this.api.get(
                url
            )
        ).data.result.outageDocuments;
    }

    public async downloadDocument(
        documentId: number,
        isPdf: boolean,
        plantName: string
    ): Promise<any> {
        return (
            await this.api.get(this.url +'download-document/' +
                    documentId +
                    '/' +
                    isPdf +
                    '/' +
                    btoa(plantName)
            )
        ).data.result;
    }

    public async uploadOutageReportDocument(
        plantSID: number,
        machineSID: string,
        documentType: number,
        outageId: string,
        formData: FormData
    ): Promise<any> {
        return (
            await this.api.post(
                Constants.DOCUMENTS_GENERATOR.MAPPING +
                    Constants.DOCUMENTS_GENERATOR.UPLOAD +
                    plantSID +
                    '/' +
                    machineSID +
                    '/' +
                    documentType +
                    '/' +
                    outageId,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )
        ).data.result;
    }
}
