import axios, { AxiosInstance } from 'axios';

export class VersionCheckService {
    private connection: AxiosInstance = axios.create({ baseURL: '/' });

    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    public initVersionCheck(callback = null): void {
        if (window.location.href.indexOf('localhost') !== -1) return;

        setInterval(async () => {
            await this.checkVersion('/version.txt', callback);
        }, 30000);
    }

    private async checkVersion(url: string, callback: any): Promise<any> {
        try {
            const timeStampedUrl = url + '?t=' + new Date().getTime();
            await this.connection.get(timeStampedUrl).then((response) => {
                const hash = response.data.hash;
                const hashChanged = this.hasHashChanged(this.currentHash, hash);

                if (hashChanged) {
                    location.reload();
                    if (callback) callback();
                }

                this.currentHash = hash;
            });
        } catch (ex) {
            console.log('Error getting version. ' + ex);
        }
    }

    private hasHashChanged(currentHash: string, newHash: string): boolean {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }
}
