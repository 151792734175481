import { Module } from 'vuex';
import { TimeToMaintenance, NextOutage, OverviewItem, BudgetItem, Warning } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    loadedUnits: [] as number[],
    nextOutageItems: [] as NextOutage[],
    unitData: [] as OverviewItem[]
});

const OverviewModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setIsLoaded(state, unitId): void {
            state.loadedUnits.push(unitId);
        },
        setNextOutage(state, nextOutage: NextOutage): void {
            if (!nextOutage) {
                return;
            }
            state.nextOutageItems = state.nextOutageItems.filter((item: NextOutage) => item.unitId !== nextOutage.unitId);
            state.nextOutageItems.push(nextOutage);
        },
        setUnitData(state, unitData: OverviewItem): void {
            if (!unitData) {
                return;
            }
            state.unitData = state.unitData.filter((item: OverviewItem) => item.unitId !== unitData.unitId);
            state.unitData.push(unitData);
        },
        resetState(state): void {
            Object.assign(state, getDefaultState());
        },
    },

    actions: {
        setIsLoaded({ commit }, unitId): void {
            commit('setIsLoaded', unitId);
        },
        setNextOutage({ commit }, payload): void {
            commit('setNextOutage', payload);
        },
        setUnitData({ commit }, payload): void {
            commit('setUnitData', payload);
        },
        resetState({ commit }): void {
            commit('resetState');
        },
    },

    getters: {
        getIsLoaded:(state) => (unitId: number): boolean => {
            return state.loadedUnits.includes(unitId);
        },
        getTimeToMaintenanceByUnitId: (state) => (unitId: number): TimeToMaintenance | null => {
            const unitData = state.unitData.find((data: OverviewItem) => data.unitId === unitId);
            return unitData ? unitData.unitRemainingLife : null;
        },
        getNextOutageByUnitId: (state) => (unitId: number): NextOutage => {
            return state.nextOutageItems.find((data: NextOutage) => data.unitId === unitId);
        },
        getHasNextOutageByUnitId: (state) => (unitId: number): boolean => {
            return !!state.nextOutageItems.find((data: NextOutage) => data.unitId === unitId);
        },
        getBudgetByUnitId: (state) => (unitId: number): BudgetItem | null => {
            const unitData = state.unitData.find((data: OverviewItem) => data.unitId === unitId);
            return unitData ? {
                plannedCalculatedBudgets: unitData.plannedCalculatedBudgets,
                budgetDeviations: unitData.budgetDeviations,
                budgetDeviationTableView: unitData.budgetDeviationTableView
            } : null;
        },
        getWarningsByUnitId: (state) => (unitId: number): Warning | null => {
            const unitData = state.unitData.find((data: OverviewItem) => data.unitId === unitId);
            return unitData ? unitData.unitWarningsView : null;
        },
        getUnitDataById: (state) => (unitId: number): Warning => {
            return state.unitData.find((data: OverviewItem) => data.unitId === unitId);
        }
    },
    namespaced: true
}

export default OverviewModule;
