import { Connection } from '@/utils';

export class DataCopyService {
    private api: Connection = new Connection();

    public async copyPlantItemMaintenance(copyRequest: any): Promise<void> {
        return (
            await this.api.post('plant-item-maintenance/copy', copyRequest)
        ).data.result;
    }
}
