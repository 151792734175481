/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/cognitive-complexity */
import { MasterService } from '@/services/master-service';
import { Constants } from '@/utils/constants';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Link } from './link';
import { Data, Helper, DocumentHelper } from '@/utils';
import { Group, Plant, Unit, PUIStructure, GroupPlantMap, ActionItem, PlantForNavigation, UnitForNavigation, ServerError, PlantSimpleObject } from '@/utils/interfaces'
import DropDownComponent from '@/components/dropdown/dropdown.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { EventBus } from '@/utils';
import AppSecurity from '@/utils/app-security';
import { PERMISSIONS } from '@/utils/permissions-helper';
import { actions, getters } from '@/store/types';
import { Application } from '@/models';
import { coodeSdk } from '@/utils/coode-sdk-config'

const START_YEAR = 2005;
const YEARS_PER_PAGE = 28;

@Component({
    name: 'navbar',
    components: {
        'dropdown': DropDownComponent,
        'snackbar': Snackbar,
    }
})
export default class NavbarComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private data: Data;
    private appSecurity = new AppSecurity();
    private documentHelper: DocumentHelper = new DocumentHelper();

    /* data */
    private userProfile: any = null;
    private links: Link[] = [];
    private additionalLinks: Link[] = [];
    private languages: any = null;
    private selectedLanguage = null;
    private startDateCalendarOpen = false;
    private endDateCalendarOpen = false;
    private startDateCalendarPage = 0;
    private endDateCalendarPage = 0;
    private startDateYearsArray: any[] = [];
    private endDateYearsArray: any[] = [];
    private applications: MenuEntry[] = [];
    private structureMapping: PUIStructure[] = [];
    private isUserAdmin = false;
    private hasPlantRights = false;
    private dataLoaded = false;
    private labelScope = 'medium';

    constructor() {
        super();
        this.data = Data.Instance;
        this.initListeners();
    }

    @Watch('dataLoaded')
    private onDataLoadedChanged(): void {
        this.$emit('navbarLoading', this.dataLoaded);
    }

    @Watch('data.selected.startDate')
    private async onStartDateChanged(): Promise<void> {
        await this.resetDateDependentStoreData();
    }

    @Watch('data.selected.endDate')
    private async onEndDateChanged(): Promise<void> {
        await this.resetDateDependentStoreData();
    }

    async created(): Promise<void> {
        this.$emit('navbarLoading', true);
        const accountDetails: any = coodeSdk.auth.getAccount();
        const { name, username } = accountDetails;
        this.userProfile = {
            name,
            email: username,
        }
        this.languages = this.buildLanguages();
        await this.initLinks();
        await this.load();
        await this.loadApps();
        this.isUserAdmin = await this.appSecurity.hasAdminRights();
        if (this.data.selected.plants?.length) {
            this.hasPlantRights = this.data.selected.plants.every(async (plant) => {
                return await this.appSecurity.hasWriteAccessToPlant(plant.sid);
            });
        }
        this.$emit('navbarLoading', false);
    }

    private get actionItems(): ActionItem[] {
        const actionItems = [];
        if (this.data.selected.plant) {
            actionItems.push({ actionName: 'editPlant', iconName: 'icon-station', label: 'Edit Plant' });
        }
        if (this.data.selected.unit) {
            actionItems.push({ actionName: 'populateUnitModal', iconName: 'icon-unit', label: 'Edit Unit' });
        }
        return actionItems;
    }

    private get closeTitle(): string {
        const closeString = this.$t('close').toString();
        return closeString.charAt(0).toUpperCase() + closeString.slice(1);
    }

    private get selectedId(): string {
        return this.data.selected.unit ? `U${this.data.selected.unit.sid}` : this.data.selected.plants?.length ? `P${this.data.selected.plants.map((plant) => plant.sid)}` : `G${this.data.selected.group?.sid}`
    }

    /* store */
    get groups(): Group[] { return this.$store.getters[getters.GROUP__GET_GROUPS]; }
    get plants(): Plant[] { return this.$store.getters[getters.PLANT__GET_PLANTS]; }
    get units(): Unit[] {
        if (!this.data?.selected?.plant || this.storeUnits.length === 0) {
            return [];
        }
        return this.data?.selected?.plants.reduce((units: Unit[], plant) => {
            units = units.concat(this.storeUnits.filter((unit: Unit) => unit.plantId === plant.sid));
            return units;
        }, []);
    }
    get storeUnits(): Unit[] { return this.$store.getters[getters.UNIT__GET_UNITS]; }
    get groupSidPlantMap(): GroupPlantMap { return this.$store.getters[getters.PLANT__GET_GROUP_SID_PLANTS_MAP]; }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private async loadApps(): Promise<void> {
        const apps = (await this.masterService.appService.getApps());
        this.applications = this.processApps(apps);
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.id.toString(),
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private handleNavigation(location: { href: string }): void {
        this.$router.push(location.href);
        if (typeof (this.$refs.unitModal as any)?.close === 'function') {
            (this.$refs.unitModal as any)?.close();
        }
        if (typeof (this.$refs.plantModal as any)?.close === 'function') {
            (this.$refs.unitModal as any)?.close();
        }
    }

    private getUnitOptions(plant: any, selectedPlants = [] as Plant[]): UnitForNavigation[] {
        if (selectedPlants.find((p) => +p.sid === +plant.sid)) {
            return this.units.map((unit) => ({
                text: unit.businessAbbreviation || unit.name,
                id: `U${unit.sid}`
            })).sort((a, b) => a.text.localeCompare(b.text));
        }

        /* return default value for displaying the units level */
        return [{
            text: '',
            id: Math.random().toString()
        }]
    }

    private getPlantsOptions(group: any, idGroup = 0, selectedPlants = [] as Plant[]): PlantForNavigation[] {
        if (+idGroup === +group.sid) {
            return this.plants.reduce((plants: PlantForNavigation[], plant) => {
                const existingPlant = plants.find((p) => p.text === plant.businessName);
                if (existingPlant) {
                    existingPlant.id += `,${plant.sid}`;
                } else {
                    plants.push({
                        text: plant.businessName,
                        id: `P${plant.sid}`,
                        options: this.getUnitOptions(plant, selectedPlants)
                    });
                }
                return plants;
            }, []);
        }

        /* return default value for displaying the plants level */
        return [{
            text: '',
            id: Math.random().toString()
        }]
    }

    /**
     * Pebble UI needs a structure for displaying the date on three levels
     * Levels differ by the first letter: G - group, P - plant, U - unit
     **/
    private initSelectStructure(idGroup = 0, selectedPlants = [] as Plant[]): void {
        this.structureMapping = this.groups.map((group) => ({
            text: group.name,
            id: `G${group.sid}`,
            options: this.getPlantsOptions(group, idGroup, selectedPlants)
        }));
    }

    private async changeEntry(entry: string): Promise<void> {
        const id = entry && entry.slice(1);

        if (!entry || entry.length === 0) {
            return;
        }

        switch(entry[0]) {
            case 'G': {
                await this.$store.dispatch(actions.PLANT__RESET_PLANTS);
                await this.$store.dispatch(actions.UNIT__RESET_UNIT_WITH_ATTRIBUTES);
                await this.$store.dispatch(actions.OUTAGE__RESET_OUTAGES_FOR_FLEET);
                await this.$store.dispatch(actions.TABLE__RESET_STATE);
                await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__RESET_STATE);
                await this.$store.dispatch(actions.OUTAGE__STRATEGY__RESET_STATE);
                await this.$store.dispatch(actions.OUTAGE__SUMMARY__RESET_STATE);
                this.masterService.enerlyticsService.siteData = {
                    areas: [],
                    items: [],
                    units: []
                };

                this.data.selected.group = this.groups && this.groups.find((group) => +group.sid === +id) as any;
                await this.loadNavbarDropdowns(false, true, true, false);

                this.data.selected.plant = this.plants && this.plants[0];
                this.data.selected.plants = this.getSelectedPlants();

                this.data.saveSelected();
                this.initSelectStructure(+id, this.data.selected.plants);

                break;
            }
            case 'P': {
                await this.$store.dispatch(actions.UNIT__RESET_UNIT_WITH_ATTRIBUTES);
                await this.$store.dispatch(actions.TABLE__RESET_STATE);
                await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__RESET_STATE);
                await this.$store.dispatch(actions.OUTAGE__STRATEGY__RESET_STATE);
                await this.$store.dispatch(actions.OVERVIEW__RESET_STATE);
                this.masterService.enerlyticsService.siteData = {
                    areas: [],
                    items: [],
                    units: []
                };

                const selectedPlants = [] as Plant[];
                for (const plantId of id.split(',')) {
                    const existingPlant = this.plants && this.plants.find((plant) => +plant.sid === +plantId)
                    if (existingPlant) {
                        selectedPlants.push(existingPlant);
                    }
                }
                this.data.selected.plants = selectedPlants;
                this.data.selected.plant = selectedPlants.length === 1 ? selectedPlants[0] : this.plants && this.plants[0];
                this.data.saveSelected();
                await this.loadNavbarDropdowns(false, false, true, false);
                if (this.data.selected.plants?.length && this.data.selected.group) {
                    this.initSelectStructure(this.data.selected.group.sid, this.data.selected.plants);
                }
                break;
            }
            case 'U': {
                await this.$store.dispatch(actions.TABLE__RESET_STATE);
                await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__RESET_STATE);
                await this.$store.dispatch(actions.GENEVA__RESET_STATE);

                this.data.selected.unit = this.units && this.units.find((unit) => +unit.sid === +id) as any;
                this.data.selected.plant = this.plants && this.plants.find((p) => this.data.selected.unit && +p.sid === +this.data.selected.unit.plantId) || null;
                this.data.saveSelected();
                await this.loadNavbarDropdowns(false, false, false);

                break;
            }
        }
    }

    private async initLinks(): Promise<void> {
        this.links.push(new Link('home', '/'));

        this.links.push(new Link('overview', '/overview'));

        if (await this.appSecurity.hasPermission(PERMISSIONS.HasAccessToTable))
            this.links.push(new Link('table', '/table'));

        if (await this.appSecurity.hasPermission(PERMISSIONS.HasAccessToOutagePlan))
            this.links.push(new Link('outagePlanNav', '/outage-plan'));

        if (await this.appSecurity.hasPermission(PERMISSIONS.HasAccessToOutageStrategy))
            this.links.push(new Link('outageStrategy', '/outage-strategy'));

        if (await this.appSecurity.hasPermission(PERMISSIONS.HasAccessToOutageClock))
            this.links.push(new Link('outageClock', '/outage-clock'));

        if (await this.appSecurity.hasPermission(PERMISSIONS.HasAccessToOutageDocuments))
            this.links.push(new Link('outageDocuments', '/outage-documents'));

        if (await this.appSecurity.hasPermission(PERMISSIONS.HasAccessToMaintenanceSummary))
            this.links.push(new Link('maintenanceOverview', '/maintenance-overview'));

        if (await this.appSecurity.hasPermission(PERMISSIONS.HasAccessToRemainingLife))
            this.links.push(new Link('remainingLifeStatus', '/remaining-life-status'));
    }

    private initListeners(): void {
        EventBus.$on(EventBus.GLOBAL.ADD_GROUP, this.handleAddGroup);
        EventBus.$on(EventBus.GLOBAL.ADD_PLANT, this.handleAddPlant);
        EventBus.$on(EventBus.UNIT.ADD_TRIGGER, this.handleAddTrigger);
        EventBus.$on(EventBus.GLOBAL.REFRESH, this.handleGlobalRefresh);
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.ADD_GROUP, this.handleAddGroup);
        EventBus.$off(EventBus.GLOBAL.ADD_PLANT, this.handleAddPlant);
        EventBus.$off(EventBus.UNIT.ADD_TRIGGER, this.handleAddTrigger);
        EventBus.$off(EventBus.GLOBAL.REFRESH, this.handleGlobalRefresh);
    }

    private async load(): Promise<void> {
        this.dataLoaded = false

        this.setSelectedLanguage();
        await this.loadNavbarDropdowns(true, true, true, false);
        if (this.data.selected.plants?.length && this.data.selected.group) {
            this.initSelectStructure(this.data.selected.group.sid, this.data.selected.plants);
        }

        this.dataLoaded = true
    }

    private async getUnits(): Promise<void> {
        try {
            if (this.data.selected.plants?.length && this.data.selected.unit) {
                for (const plant of this.data.selected.plants) {
                    await this.masterService.enerlyticsService.getUnits(plant.enerlyticsSiteId, this.data.selected.unit.id);
                }
            }
        }catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'warning',
                message: this.$t('enerlyticsCallFailed')
            });
        }

    }

    private emitMDError(message: string, name: string, sid: number): void {
        EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
            class: 'error',
            message: this.$t(message, {name, sid}),
            duration: 5000
        });
    }

    private async loadNavbarDropdowns(loadGroups: boolean, loadPlants: boolean, loadUnits: boolean, refresh = true): Promise<void> {
        this.data.navBarRefresh = true;

        try {
            const selectedFromStorage = this.data.getSelectedFromLocalStorage();
            if (loadGroups) {
                if(!this.groups.length) {
                    await this.$store.dispatch(actions.GROUP__SET_GROUPS, await this.masterService.groupService.getGroups());
                }
                if (this.groups.length) {
                    const selectedGroup = selectedFromStorage && selectedFromStorage.group ?
                        this.groups.find((c) => selectedFromStorage.group && c.sid === selectedFromStorage.group.sid) : null;
                    this.data.selected.group = selectedGroup || this.groups[0];
                } else {
                    this.data.resetSelected(true, true, true);
                    this.data.navBarRefresh = false;
                    return;
                }
            }

            if (loadPlants && this.data.selected.group) {
                const setPlantsPayload = {
                    groupSid: this.data.selected.group.sid,
                    plants: [] as Plant[] | PlantSimpleObject[],
                    force: true
                }

                setPlantsPayload.plants = this.groupSidPlantMap[this.data.selected.group.sid] ? this.groupSidPlantMap[this.data.selected.group.sid] :
                    await this.masterService.plantService.getPlants(this.data.selected.group.sid);

                await this.$store.dispatch(actions.PLANT__SET_PLANTS, setPlantsPayload);

                if (this.plants.length) {
                    let selectedPlants = [] as Plant[];
                    if (selectedFromStorage && selectedFromStorage.plants) {
                        for (const plant of selectedFromStorage.plants) {
                            const existingPlant = this.plants && this.plants.find((p) => +p.sid === +plant.sid)
                            if (existingPlant) {
                                selectedPlants.push(existingPlant);
                            }
                        }
                    }
                    if (!selectedPlants.length) {
                        selectedPlants = this.groupSidPlantMap[this.data.selected.group.sid].reduce((plants: Plant[], plant) => {
                            if (this.data.selected.group && plant.businessName === this.groupSidPlantMap[this.data.selected.group.sid][0].businessName) {
                                plants.push(plant);
                            }
                            return plants;
                        }, []);
                    }

                    this.data.selected.plants = selectedPlants;
                    this.data.selected.plant = this.plants && this.plants.find((p) => this.data.selected.unit && +p.sid === +this.data.selected.unit.plantId) || this.data.selected.plants[0];
                    this.data.hasAccess = this.data.selected.plants?.length
                        ? this.data.selected.plants.every(async (plant) => {
                            return await this.appSecurity.hasAccessToPlant(plant.sid);
                        })
                        : false;
                } else {
                    this.data.resetSelected(false, true, true);
                    this.data.navBarRefresh = false;
                    return;
                }
            }

            if (loadUnits) {
                /* get extra information from MSPT DB */
                if (this.data.selected.plants?.length) {
                    for (const plant of this.data.selected.plants) {
                        let plantData = {};
                        try {
                            plantData = await this.masterService.plantService.getPlant(plant.sid);
                        } catch (e) {
                            // continue regardless of error
                        }
                        /* update plant object and list */
                        this.data.selected.plants = this.data.selected.plants.map((p) => +p.sid === +plant.sid ? { ...p, ...(plantData || {}) } : p);
                        if (this.data.selected.plant && +this.data.selected.plant.sid === +plant.sid) {
                            this.data.selected.plant = { ...this.data.selected.plant, ...plantData };
                        }
                        const setPlantsPayload = {
                            groupSid: this.data.selected.group?.sid,
                            plants: this.plants.map((p) =>
                                this.data.selected.plants.find((selected) => +selected.sid === p.sid) || p),
                            force: true
                        };

                        await this.$store.dispatch(actions.PLANT__SET_PLANTS, setPlantsPayload);
                    }
                }

                if (this.data.selected.plants?.length && this.units.length === 0) {
                    for (const plant of this.data.selected.plants) {
                        await this.$store.dispatch(actions.UNIT__SET_UNITS, {
                            units: await this.masterService.unitService.getUnits(plant.sid),
                            plantId: plant.sid,
                        });
                    }
                }

                if (this.units.length) {
                    const selectedUnit = selectedFromStorage && selectedFromStorage.unit ?
                        this.units.find((c) => selectedFromStorage.unit && c.sid === selectedFromStorage.unit.sid) : null;
                    this.data.selected.unit = selectedUnit || null;
                } else {
                    this.data.resetSelected(false, false, true);
                    this.data.navBarRefresh = false;
                    return;
                }

                /* gets information from Enelytics about units, areas and plant items */
                if (this.data.selected.unit) {
                    await this.getUnits();
                }
            }

            /* update master-data unit information with MSPT database */
            if (!this.data.selected.unit?.id) {
                await this.updateUnitInformation();
            }

            const plantsBelongToRoca = this.data.selected.plants?.length ? this.data.selected.plants.every((plant) => plant.enerlyticsSiteId === Constants.HARDCODED_PLANTS.ROCA) : false;
            /* for RoCa plant update mapping at every unit change */
            if (!loadGroups && !loadPlants && !loadUnits && this.data.selected.plants?.length && plantsBelongToRoca) {
                /* gets information from Enelytics about units, areas and plant items */
                /* for plant Roca units are split in Unit 3 (mapped to Plant RoCa3 from Enerlytics) and others (mapped to RoCa) */
                await this.getUnits();
            }

            this.setSelectedStartAndEndDate();
            this.startDateYearsArray = this.getYearsArray(this.startDateCalendarPage);
            this.endDateYearsArray = this.getYearsArray(this.endDateCalendarPage);
            this.data.saveSelected();
            refresh && this.refreshRouter();

        } catch (error) {
            this.handleError(error as ServerError);
        }

        this.data.navBarRefresh = false;
    }

    private handleError(error: ServerError): void {
        if (this.data.selected.unit && this.data.selected.plant) {
            switch (error.response && error.response.data) {
                case Constants.MD_ERRORS.UNIT_NOT_FOUND:
                    this.emitMDError(error.response.data, this.data.selected.unit.name, this.data.selected.unit.sid);
                    break;
                case Constants.MD_ERRORS.PLANT_NOT_FOUND:
                    this.emitMDError(error.response.data, this.data.selected.plant.name, this.data.selected.plant.sid);
                    break;
            }
        }
    }

    private async logout(): Promise<void> {
        await coodeSdk.auth.logout();
    }

    private refreshRouter(): void {
        EventBus.$emit(EventBus.GLOBAL.COMPONENT_REFRESH);
        Helper.removeOpenBackdrops();
    }

    private async handleGlobalRefresh(): Promise<void> {
        await this.$store.dispatch(actions.OUTAGE__RESET_OUTAGES_FOR_FLEET);
        await this.$store.dispatch(actions.OUTAGE__RESET_STATE);
        await this.$store.dispatch(actions.TABLE__RESET_STATE);
        await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__RESET_STATE);
        await this.$store.dispatch(actions.OUTAGE__STRATEGY__RESET_STATE);
        await this.$store.dispatch(actions.OUTAGE__SUMMARY__RESET_STATE);
        this.refreshRouter();
    }

    private buildLanguages(): any {
        return {
            EN: {
                label:'EN',
                value: 'en-GB',
            },
            DE: {
                label:'DE',
                value: 'de-DE',
            },
            RU: {
                label:'RU',
                value: 'ru-RU',
            },
        };
    }

    private setSelectedLanguage(): void {
        const langVal = this.data.getLanguage();
        for (const idx in this.languages) {
            if (langVal === this.languages[idx].value) {
                this.selectedLanguage = this.languages[idx];
            }
        }
    }

    private setLanguage(langVal: any): void {
        const lang = this.languages[langVal]
        if (lang.value === this.data.getLanguage()) {
            return lang.value;
        }
        this.selectedLanguage = lang;
        this.data.setLanguage(lang.value);
    }

    private setSelectedStartAndEndDate(): void {
        if (this.data.selected.unit) {
            this.data.selected.endDate = new Date(this.data.selected.unit.plannedEndDate).getFullYear();
            this.data.selected.startDate = new Date(this.data.selected.unit.commissioningDate).getFullYear();
        }
        if (this.data.selected.startDate) {
            this.data.selected.startDate = this.data.selected.startDate < 2005 ? 2005 : this.data.selected.startDate;
        }
        this.data.saveSelected();
    }

    private checkDateConstraints(): void {
        if (!this.data.selected.unit || !this.data.selected.endDate || !this.data.selected.startDate)
            return;

        const startYear = new Date(this.data.selected.unit.commissioningDate).getFullYear();
        const endYear = new Date(this.data.selected.unit.plannedEndDate).getFullYear();
        let save = false;
        if (this.data.selected.startDate < startYear) {
            this.data.selected.startDate = startYear;
            save = true;
        }
        if (this.data.selected.endDate > endYear) {
            this.data.selected.endDate = endYear;
            save = true;
        }
        if (save) {
            this.data.saveSelected();
        }
    }

    private openStartDateCalendar(): void {
        this.startDateCalendarOpen = !this.startDateCalendarOpen;
    }

    private openEndDateCalendar(): void {
        this.endDateCalendarOpen = !this.endDateCalendarOpen;
    }

    private getYearsArray(page: number): any[] {
        const list = [];
        if (this.data.selected.unit) {
            const unitEndDate = new Date(this.data.selected.unit.plannedEndDate).getFullYear();
            const firstYear = START_YEAR + YEARS_PER_PAGE * page;
            const endYear = firstYear + YEARS_PER_PAGE - 1 <= unitEndDate ? firstYear + YEARS_PER_PAGE - 1 : unitEndDate;
            for (let i = firstYear; i <= endYear; i++) {
                list.push(i);
            }
        }
        return list;
    }

    private decrementStartDateCalendar(): void {
        if (this.startDateCalendarPage > 0) {
            this.startDateCalendarPage--;
        }
        this.startDateYearsArray = this.getYearsArray(this.startDateCalendarPage);
    }

    private decrementEndDateCalendar(): void {
        if (this.endDateCalendarPage > 0) {
            this.endDateCalendarPage--;
        }
        this.endDateYearsArray = this.getYearsArray(this.endDateCalendarPage);
    }

    private incrementStartDateCalendar(): void {
        if (this.data.selected.unit) {
            const unitEndDate = new Date(this.data.selected.unit.plannedEndDate);
            if (START_YEAR + YEARS_PER_PAGE * this.startDateCalendarPage * 2 < unitEndDate.getFullYear()) {
                this.startDateCalendarPage++;
            }
            this.startDateYearsArray = this.getYearsArray(this.startDateCalendarPage);
        }
    }

    private incrementEndDateCalendar(): void {
        if (this.data.selected.unit) {
            const unitEndDate = new Date(this.data.selected.unit.plannedEndDate);
            if (START_YEAR + YEARS_PER_PAGE * this.endDateCalendarPage * 2 < unitEndDate.getFullYear()) {
                this.endDateCalendarPage++;
            }
            this.endDateYearsArray = this.getYearsArray(this.endDateCalendarPage);
        }
    }

    private setStartDate(year: any): void {
        if (this.data.selected.endDate && this.data.selected.endDate >= year) {
            this.data.selected.startDate = year;
            this.data.saveSelected();
        }
    }

    private setEndDate(year: any): void {
        if (this.data.selected.startDate && year >= this.data.selected.startDate) {
            this.data.selected.endDate = year;
            this.data.saveSelected();
        }
    }

    private refreshRouterFromCalendar(): void {
        this.endDateCalendarOpen = false;
        this.startDateCalendarOpen = false;
        this.refreshRouter();
    }

    private handlerFunction(actionName: string): void {
        if (actionName === 'editPlant' && this.data.selected.plant) {
            (this.$refs.plantModal as any).openModalForEdit();
        } else if (actionName === 'populateUnitModal' && this.data.selected.unit) {
            (this.$refs.unitModal as any).openModalForEdit(this.data.selected.unit);
        }
    }

    private async handleAddGroup(group: Group): Promise<void> {
        this.data.selected.group = group;
        this.data.saveSelected();
        await this.loadNavbarDropdowns(true, true, true, false);
    }

    private async handleAddPlant(plant: Plant): Promise<void> {
        this.data.selected.plant = plant;
        this.data.selected.plants = this.getSelectedPlants();
        this.data.saveSelected();
        await this.loadNavbarDropdowns(false, true, true, false);
    }

    private async handleAddTrigger(unit: Unit): Promise<void> {
        this.data.selected.unit = unit;
        this.data.saveSelected();
        await this.updateUnitInformation();
        await this.loadNavbarDropdowns(false, false, true);
    }

    private async resetDateDependentStoreData(): Promise<void> {
        await this.$store.dispatch(actions.TABLE__RESET_STATE);
        await this.$store.dispatch(actions.MAINTENANCE__SUMMARY__RESET_STATE);
        await this.$store.dispatch(actions.GENEVA__RESET_STATE);
    }

    private closePlanner(): void {
        this.endDateCalendarOpen = false;
        this.startDateCalendarOpen = false;
    }

    private async updateUnitInformation(): Promise<void> {
        if (this.data.selected.unit) {
            const unitData = await this.masterService.unitService.getUnit(this.data.selected.unit.sid);
            if (unitData) {
                this.data.selected.unit = { ...this.data.selected.unit, ...unitData };
                const plantId = this.data.selected.unit?.plantId;
                const units = this.units.filter((u) => u.plantId === plantId).map((u) => u.sid === this.data.selected.unit?.sid ? this.data.selected.unit : u)
                await this.$store.dispatch(actions.UNIT__SET_UNITS, {
                    units,
                    plantId,
                });
            }
        }
    }

    private getSelectedPlants(): Plant[] {
        const selectedPlantBusinessName = this.data.selected.plant?.businessName;
        return this.plants.filter((p) => p.businessName === selectedPlantBusinessName);
    }

    private async downloadHandbook(): Promise<void> {
        try {
            const file = await this.masterService.fileService.getUserHandbook();
            await this.documentHelper.decodeAndDownloadBase64File(file.title, file.content);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorDownloadingDcoument')
            });
        }
    }

    private sendToIncidentReporting(): void {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID ?? 0);
        const appStoreUrl = `${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/incident-page/${useCaseId}`;

        window.open(appStoreUrl, '_blank');
    }
}
