import Vue from 'vue';
import App from './App.vue';
import { Router } from './router';
import store from './store';
import { IconDefinition, IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import VueI18n from 'vue-i18n';
import '../web.config';
import Loading from 'vue-loading-overlay';
import LazyComponent from 'v-lazy-component';
import DatePicker from 'vue2-datepicker';
import VueFilterDateFormat from 'vue-filter-date-format';
import 'adam.ui-core/dist/umd';
import { Data } from './utils';
import Loader from './components/loading-spinner/loader.vue';
import GroupModalComponent from './components/data-input/group-modal/group-modal.vue';
import PlantModalComponent from './components/data-input/plant-modal/plant-modal.vue';
import UnitModalComponent from './components/data-input/unit-modal/unit-modal.vue';
import { VersionCheckService } from './services/version-check-service';
type IconDefinitionOrPack = IconDefinition | IconPack;

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap';

import 'vue-loading-overlay/dist/vue-loading.min.css';
import 'vue2-datepicker/index.css';

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';


type D = Icon.Default & {
    _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

/* icons */
import { faCalendarAlt,
    faChevronCircleRight, faChevronCircleUp,
    faChevronCircleDown, faChevronCircleLeft,
    faCalculator, faHistory, faCube, faMoneyBillAlt,
    faCog, faPlus, faEdit, faInfoCircle, faLink, faUser, faUsers,
    faTimes, faCalendar, faPlusSquare, faPlayCircle,
    faClock, faStopwatch, faMinus, faSearch, faBell,
    faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faExpand, faCopy,
    faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines
}
    from '@fortawesome/free-solid-svg-icons';

library.add(faCalendarAlt as IconDefinitionOrPack,
    faChevronCircleRight as IconDefinitionOrPack, faChevronCircleUp as IconDefinitionOrPack,
    faChevronCircleDown as IconDefinitionOrPack, faChevronCircleLeft as IconDefinitionOrPack,
    faCalculator as IconDefinitionOrPack, faHistory as IconDefinitionOrPack, faCube as IconDefinitionOrPack, faMoneyBillAlt as IconDefinitionOrPack,
    faCog as IconDefinitionOrPack, faPlus as IconDefinitionOrPack, faEdit as IconDefinitionOrPack, faInfoCircle as IconDefinitionOrPack, faLink as IconDefinitionOrPack, faUser as IconDefinitionOrPack, faUsers as IconDefinitionOrPack,
    faTimes as IconDefinitionOrPack, faCalendar as IconDefinitionOrPack, faPlusSquare as IconDefinitionOrPack, faPlayCircle as IconDefinitionOrPack,
    faClock as IconDefinitionOrPack, faStopwatch as IconDefinitionOrPack, faMinus as IconDefinitionOrPack, faSearch as IconDefinitionOrPack, faBell as IconDefinitionOrPack,
    faAngleDown as IconDefinitionOrPack, faAngleLeft as IconDefinitionOrPack, faAngleRight as IconDefinitionOrPack, faAngleUp as IconDefinitionOrPack, faExpand as IconDefinitionOrPack, faCopy as IconDefinitionOrPack,
    faCaretRight as IconDefinitionOrPack, faCaretUp as IconDefinitionOrPack, faCaretDown as IconDefinitionOrPack, faSort as IconDefinitionOrPack, faGripLines as IconDefinitionOrPack)

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('loading-spinner', Loader);
Vue.component('group-modal', GroupModalComponent);
Vue.component('plant-modal', PlantModalComponent);
Vue.component('unit-modal', UnitModalComponent);
Vue.component('loading', Loading);
Vue.use(LazyComponent);
Vue.component('date-picker', DatePicker as any);
Vue.use(VueFilterDateFormat);
Vue.config.productionTip = false;

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

// internationalization
Vue.use(VueI18n);
const data = Data.Instance;
let lang = data.getLanguage();
if (lang === null) {
    lang = 'en-GB';
    data.setLanguage(lang);
}

export const i18n = new VueI18n({
    locale: lang,
    messages: require('./locales/' + lang + '.json')
});

Vue.use(PebbleUI);

const versionCheckService = new VersionCheckService();
versionCheckService.initVersionCheck();

new Vue({
    i18n,
    router: Router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
