import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EnerlyticsECharts, getDefaultOption } from '@enerlytics/echarts';
import { TimeToMaintenance, Unit } from '@/utils/interfaces';
import { getters } from '@/store/types';
import { Data } from '@/utils';
import {
    PUICOLOR_SEVERITY_10,
    PUICOLOR_SEVERITY_30,
    PUICOLOR_SEVERITY_60,
    PUICOLOR_WARM_GREY_25
} from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({
    components: {
        echarts: EnerlyticsECharts,
    }
})
export default class TimeToMaintenanceComponent extends Vue {
    private data: Data = Data.Instance;
    private readonly REMAINING_LIFE_UPPER_LIMIT = 66;
    private readonly REMAINING_LIFE_LOWER_LIMIT = 33;
    private readonly CHART_FONT = 'Roboto, sans-serif';

    @Prop()
    private unit: Unit | undefined;

    get timeToMaintenanceData(): TimeToMaintenance { return this.$store.getters[getters.OVERVIEW__GET_TIME_TO_MAINTENANCE_BY_UNIT_ID](this.unit?.sid); }

    get activitiesTotal(): number {
        return this.timeToMaintenanceData
            ? this.timeToMaintenanceData.numberOfMaintenancesThresholdOne +
                this.timeToMaintenanceData.numberOfMaintenancesThresholdTwo +
                this.timeToMaintenanceData.numberOfMaintenancesThresholdThree
            : 0;
    }

    private get showToleranceExceeded(): boolean {
        return this.timeToMaintenanceData && this.timeToMaintenanceData.numberOfMaintenancesToleranceExceeded !== 0;
    }

    private get showWithinTolerance(): boolean {
        return this.timeToMaintenanceData && this.timeToMaintenanceData.numberOfMaintenancesWithinTolerance !== 0;
    }

    private get timeToMaintenanceOption(): any {
        const option = getDefaultOption({
            legendShow: false,
            tooltipShow: false,
            xAxisShow: false,
            yAxisShow: false,
        });
        const series = this.getDefaultDonutSeries();
        series.selectedMode = false;
        series.radius = ['82%', '100%'];
        series.data = [
            {
                itemStyle: {
                    color: this.timeToMaintenanceData ? this.getColorByUnitRemainingLife(Math.round(this.timeToMaintenanceData?.remainingLife)) : PUICOLOR_WARM_GREY_25,
                },
                value: this.timeToMaintenanceData ? Math.round(this.timeToMaintenanceData.remainingLife) : 0,
            },
            {
                itemStyle: {
                    color: PUICOLOR_WARM_GREY_25,
                },
                value: this.timeToMaintenanceData ? 100 - Math.round(this.timeToMaintenanceData.remainingLife) : 100,
            },
        ];
        series.label = {
            ...series.label,
            show: true,
            formatter: [
                `{unitRemainingLife|${this.timeToMaintenanceData ? Math.round(this.timeToMaintenanceData.remainingLife) : 0}}%`,
                `{activitiesTotal|${this.activitiesTotal} ${this.$t(
                    'activitiesTotal',
                )}}`,
            ].join('\n'),
            rich: {
                unitRemainingLife: {
                    fontSize: '2.4rem',
                    fontFamily: this.CHART_FONT,
                },
                activitiesTotal: {
                    fontSize: '0.8rem',
                    fontFamily: this.CHART_FONT,
                },
            },
            fontSize: '2rem',
            width: 110,
        };
        series.silent = true;
        option.series = series;
        return option;
    }

    private getDefaultDonutSeries(name = ''): any {
        return {
            name,
            type: 'pie',
            label: {
                show: true,
                position: 'center',
                fontFamily: this.CHART_FONT,
                fontSize: '1.4rem',
                fontWeight: 400,
            },
        }
    }

    public getColorByUnitRemainingLife(value: number): string {
        if (value < this.REMAINING_LIFE_LOWER_LIMIT) {
            return PUICOLOR_SEVERITY_10;
        }
        else if (value < this.REMAINING_LIFE_UPPER_LIMIT) {
            return PUICOLOR_SEVERITY_30;
        }
        return PUICOLOR_SEVERITY_60;
    }
}
