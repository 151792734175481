export class DateHelper {
    /**
     * @param date source date
     * @returns string
     */
    public static getISODate(date: Date): string {
        return new Date(date).toISOString();
    }

    /**
     * @param date source date
     * @returns string
     */
    public static formatDateWithTime(date: Date | string): string {
        const iso = this.formatISOShort(new Date(date));
        const zonedDate = new Date(date);

        // current hours
        const hours =
            zonedDate.getHours() < 10
                ? '0' + zonedDate.getHours()
                : zonedDate.getHours();

        // current minutes
        const minutes =
            zonedDate.getMinutes() < 10
                ? '0' + zonedDate.getMinutes()
                : zonedDate.getMinutes();
        return iso + ' ' + hours + ':' + minutes;
    }

    /**
     * @param date source date
     * @returns string
     */
    public static formatISOShort(date = new Date()): string {
        return this.getISODate(date).slice(0, 10);
    }

    /**
     * @param date source date or string
     * @returns number
     */
    public static getYear(date: Date | string): number {
        return new Date(date).getFullYear();
    }
}
