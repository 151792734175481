import { Helper } from './';
import { OperatingHoursYear, OH, OperatingHours } from './interfaces';

export class OHHelper {
    public static initYears(oh: OperatingHours, yearValue: Record<number, OH>): void {
        oh.years.map((year: OperatingHoursYear) =>
            this.monthChange(year, yearValue)
        );
    }

    public static filterFutureMonths (futureMonths: any, currentMonth: number): any {
        return currentMonth !== 0 ? futureMonths.slice(0, currentMonth) : futureMonths;
    }

    public static yearChange(year: any, t: number, yearValueParam: any): void {
        let value = 0;
        switch (t) {
            case 0:
                value = yearValueParam['operatingHours'];
                break;
            case 1:
                value = yearValueParam['hotStarts'];
                break;
            case 2:
                value = yearValueParam['warmStarts'];
                break;
            case 3:
                value = yearValueParam['coldStarts'];
                break;
            case 4:
                value = yearValueParam['eoh'];
                break;
            default:
                value = 0;
        }

        const monthValue =
            Math.floor(value / year.months.length) < 1
                ? Math.floor(value / year.months.length) + 1
                : Math.floor(value / year.months.length);
        let remaining = value - monthValue * year.months.length;

        for (const month of year.months) {
            let val = value - monthValue >= 0 ? monthValue : 0;
            if (remaining > 0) {
                val++;
            }
            switch (t) {
                case 0:
                    month['operatingHours'] = val;
                    break;
                case 1:
                    month['hotStarts'] = val;
                    break;
                case 2:
                    month['warmStarts'] = val;
                    break;
                case 3:
                    month['coldStarts'] = val;
                    break;
                case 4:
                    month['eoh'] = val;
                    break;
                default:
                    value = 0;
            }
            remaining--;
            value -= val;
        }
    }

    public static monthChange(year: OperatingHoursYear, yearValueParam: any): void {
        const months = year.months.map((m) => {
            if (!m.operatingHours) {
                m.operatingHours = 0;
            }
            if (!m.eoh) {
                m.eoh = 0;
            }
            if (!m.hotStarts) {
                m.hotStarts = 0;
            }
            if (!m.warmStarts) {
                m.warmStarts = 0;
            }
            if (!m.coldStarts) {
                m.coldStarts = 0;
            }
            return m;
        });
        yearValueParam[year.year] = {};
        yearValueParam[year.year]['operatingHours'] = Helper.sumProperty(
            months,
            'operatingHours'
        );
        yearValueParam[year.year]['eoh'] = Helper.sumProperty(months, 'eoh');
        yearValueParam[year.year]['hotStarts'] = Helper.sumProperty(
            months,
            'hotStarts'
        );
        yearValueParam[year.year]['warmStarts'] = Helper.sumProperty(
            months,
            'warmStarts'
        );
        yearValueParam[year.year]['coldStarts'] = Helper.sumProperty(
            months,
            'coldStarts'
        );
    }

    public static enforceMonthlyHourLimit(year: OperatingHoursYear): void {
        year.months.forEach((month) => {
            if (month.operatingHours > 732) {
                month.operatingHours = 732;
            }
            if (month.eoh > 732) {
                month.eoh = 732;
            }
            Helper.enforcePositiveNumber(month, 'operatingHours');
            Helper.enforcePositiveNumber(month, 'eoh');
        });
    }

    public static enforceYearlyHourLimit(
        year: OperatingHoursYear,
        yearValueParam: any
    ): void {
        const numberOfMonths = year.months.length;
        if (yearValueParam[year.year].operatingHours > numberOfMonths * 732) {
            yearValueParam[year.year].operatingHours = numberOfMonths * 732;
        }
        Helper.enforcePositiveNumber(
            yearValueParam[year.year],
            'operatingHours'
        );
    }

    public static enforceYearlyEstimatedHourLimit(
        year: OperatingHoursYear,
        yearValueParam: any
    ): void {
        const numberOfMonths = year.months.length;
        if (yearValueParam[year.year].eoh > numberOfMonths * 732) {
            yearValueParam[year.year].eoh = numberOfMonths * 732;
        }
        Helper.enforcePositiveNumber(yearValueParam[year.year], 'eoh');
    }
}
