import Vue from 'vue';
import Mixin from 'vue-class-component';
import { differenceInDays, isPast, addDays, subDays, isWithinInterval } from '@enerlytics/time-helper/dist/date-fns';
import { OutageClockArc, OutagePhase, OutageClockRadialSection, OutageStartEndDates } from '@/utils/interfaces';
import {
    PUICOLOR_SEVERITY_10,
    PUICOLOR_SEVERITY_60,
    PUICOLOR_WARM_GREY,
    PUICOLOR_WHITE,
    PUICOLOR_SEVERITY_20,
    PUICOLOR_DARK_GREY
} from '@enerlytics/pebble-ui/dist/constants/colors.js';
@Mixin
export default class OutageClockMixin extends Vue {

    private readonly phaseNameObj = {
        init: 'Initiation',
        scop: 'Scoping',
        plan: 'Preparation',
        impl: 'Implementation',
        clos: 'CloseOut',
    };

    private readonly phaseDurationObject = {
        init: 545,
        scop: 365,
        plan: 183,
        impl: 60,
        clos: 91,
    } as { [key: string]: number };

    private readonly phaseDurationObjectForOutageStatus = this.phaseDurationObject;

    private readonly outageClockArcs = [
        {
            color: PUICOLOR_WARM_GREY,
            opacity: 100,
            strokeColor: PUICOLOR_WARM_GREY,
            outerRadiusCoefficient: 0.003,
            innerRadiusCoefficient: 0.13535714285714284,
            padAngle: 0,
            textFillColor: PUICOLOR_WHITE,
            showText: true,
        },
        {
            color: PUICOLOR_SEVERITY_60,
            opacity: 30,
            strokeColor: PUICOLOR_WHITE,
            outerRadiusCoefficient: 0.14285714285714285,
            innerRadiusCoefficient: 0.3214285714285714,
            padAngle: 0.0075,
            textFillColor: PUICOLOR_WHITE,
            showText: false,
        },
        {
            color: PUICOLOR_SEVERITY_20,
            opacity: 20,
            strokeColor: PUICOLOR_WHITE,
            outerRadiusCoefficient: 0.3214285714285714,
            innerRadiusCoefficient: 0.5357142857142857,
            padAngle: 0.0075,
            textFillColor: PUICOLOR_WHITE,
            showText: false,
        },
        {
            color: PUICOLOR_SEVERITY_10,
            opacity: 15,
            strokeColor: PUICOLOR_WHITE,
            outerRadiusCoefficient: 0.5357142857142857,
            innerRadiusCoefficient: 0.8571428571428572,
            padAngle: 0.0075,
            textFillColor: PUICOLOR_WHITE,
            showText: false,
        },
    ] as OutageClockArc[];

    private readonly innerCircleStrokeColor = PUICOLOR_WHITE;
    private readonly innerCircleFill = PUICOLOR_WHITE;
    private readonly outageStrokeColor = PUICOLOR_DARK_GREY;
    private readonly innerCircleOuterRadius = 0.8571428571428572;
    private readonly phasesInnerRadius = 0.13535714285714284;

    get totalDaysOutageClock(): number {
        return (
            this.phaseDurationObjectForOutageStatus['init'] +
            this.phaseDurationObjectForOutageStatus['scop'] +
            this.phaseDurationObjectForOutageStatus['plan'] +
            this.phaseDurationObjectForOutageStatus['impl'] +
            this.phaseDurationObjectForOutageStatus['clos']
        );
    }

    getOutageStatus(startDate: Date, endDate: Date): string {
        const currentDate = new Date();
        let status = 'NotStarted';

        if (isPast(new Date(endDate))) {
            status = 'Finished';
        } else if (isWithinInterval(currentDate, { start: new Date(startDate), end: new Date(endDate) })) {
            status = 'Current';
        }

        return status;
    }

    getPhaseNames(): { [key: string]: string } {
        return {
            init: this.$t('initiation') as string,
            scop: this.$t('scoping') as string,
            plan: this.$t('preparation') as string,
            impl: this.$t('implementation') as string,
            clos: this.$t('closeOut') as string,
        };
    }

    /**
     * This function builds the data required to generate radial section of the countDownClock
     */
    getRadialData(milestoneCompletionPercent: number | undefined): OutageClockRadialSection[] {
        const radialDataArray = [];
        const completed = {} as OutageClockRadialSection;
        const remaining = {} as OutageClockRadialSection;
        completed.value = 0;
        if (milestoneCompletionPercent !== undefined) {
            completed.value = milestoneCompletionPercent;
        }

        completed.name = this.getRadialColorName(completed.value);
        radialDataArray.push(completed);

        remaining.value = 100 - completed.value;
        remaining.name = 'DaysRemaining';
        radialDataArray.push(remaining);
        return radialDataArray;
    }

    /**
     * This method build the data required to generate pie section of the countDownClock
     */
    getChartData(outageStartDate: Date, outageEndDate: Date): OutagePhase[] {
        const outagePhaseArray: OutagePhase[] = [];

        const outageDuration = differenceInDays(outageStartDate, outageEndDate);
        const totalDays = this.getTotalDays(outageDuration);
        const phasesStartEndDates = this.getPhaseStartAndEndDates(outageStartDate, outageEndDate);

        // Build data for "Inititaion" phase
        this.buildOutagePhaseData(
            'Initiation',
            outagePhaseArray,
            'init',
            totalDays,
            phasesStartEndDates.initStartDate,
            phasesStartEndDates.initEndDate,
        );

        // Build data for "Scoping" phase
        this.buildOutagePhaseData(
            'Scoping',
            outagePhaseArray,
            'scop',
            totalDays,
            phasesStartEndDates.scopStartDate,
            phasesStartEndDates.scopEndDate,
        );

        // Build data for "Preparation" phase
        this.buildOutagePhaseData(
            'Preparation',
            outagePhaseArray,
            'plan',
            totalDays,
            phasesStartEndDates.planStartDate,
            phasesStartEndDates.planEndDate,
        );

        // Build data for "Implementation" phase
        this.buildOutagePhaseData('Implementation', outagePhaseArray, 'impl', totalDays, outageStartDate, outageEndDate);

        // Build data for "CloseOut" phase
        this.buildOutagePhaseData(
            'CloseOut',
            outagePhaseArray,
            'clos',
            totalDays,
            phasesStartEndDates.closStartDate,
            phasesStartEndDates.closEndDate,
        );

        return outagePhaseArray;
    }

    getTotalDays(outageDuration: number): number {
        return (
            outageDuration +
            this.phaseDurationObject['init'] +
            this.phaseDurationObject['scop'] +
            this.phaseDurationObject['plan'] +
            this.phaseDurationObject['clos']
        );
    }

    /**
     * This method returns the Phase Name
     */
    getPhaseName(phaseNameKey: string): string {
        const phaseNameObj = this.getPhaseNames();
        return phaseNameObj[phaseNameKey];
    }

    /**
     * This method returns the Current Phase Key
     */
    getCurrentPhase(outageStartDate: string, outageEndDate: string): string {
        let phaseName = '';

        const phasesStartEndDates = this.getPhaseStartAndEndDates(new Date(outageStartDate), new Date(outageEndDate));
        const currentDate = new Date();

        if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.initStartDate),
                end: new Date(phasesStartEndDates.initEndDate),
            })
        ) {
            phaseName = this.phaseNameObj.init;
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.scopStartDate),
                end: new Date(phasesStartEndDates.scopEndDate),
            })
        ) {
            phaseName = this.phaseNameObj.scop;
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.planStartDate),
                end: new Date(phasesStartEndDates.planEndDate),
            })
        ) {
            phaseName = this.phaseNameObj.plan;
        } else if (isWithinInterval(currentDate, { start: new Date(outageStartDate), end: new Date(outageEndDate) })) {
            phaseName = this.phaseNameObj.impl;
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.closStartDate),
                end: new Date(phasesStartEndDates.closEndDate),
            })
        ) {
            phaseName = this.phaseNameObj.clos;
        }

        return phaseName;
    }

    /**
     * This method returns the Current Phase Name
     */
    getCurrentPhaseName(outageStartDate: string, outageEndDate: string): string {
        let phaseName = '';

        const phasesStartEndDates = this.getPhaseStartAndEndDates(new Date(outageStartDate), new Date(outageEndDate));
        const currentDate = new Date();

        if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.initStartDate),
                end: new Date(phasesStartEndDates.initEndDate),
            })
        ) {
            phaseName = this.getPhaseName('init');
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.scopStartDate),
                end: new Date(phasesStartEndDates.scopEndDate),
            })
        ) {
            phaseName = this.getPhaseName('scop');
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.planStartDate),
                end: new Date(phasesStartEndDates.planEndDate),
            })
        ) {
            phaseName = this.getPhaseName('plan');
        } else if (isWithinInterval(currentDate, { start: new Date(outageStartDate), end: new Date(outageEndDate) })) {
            phaseName = this.getPhaseName('impl');
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.closStartDate),
                end: new Date(phasesStartEndDates.closEndDate),
            })
        ) {
            phaseName = this.getPhaseName('clos');
        }

        return phaseName;
    }

    /**
     * This method returns the Current Phase Completion Percentage
     */
    getCurrentPhaseCompletedPercentage(outageStartDate: Date, outageEndDate: Date): number {
        let phaseCompPerc = 0;

        const phasesStartEndDates = this.getPhaseStartAndEndDates(new Date(outageStartDate), new Date(outageEndDate));
        const currentDate = new Date();

        if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.initStartDate),
                end: new Date(phasesStartEndDates.initEndDate),
            })
        ) {
            phaseCompPerc =
                (differenceInDays(currentDate, phasesStartEndDates.initStartDate) * 100) / this.phaseDurationObject['init'];
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.scopStartDate),
                end: new Date(phasesStartEndDates.scopEndDate),
            })
        ) {
            phaseCompPerc =
                (differenceInDays(currentDate, phasesStartEndDates.scopStartDate) * 100) / this.phaseDurationObject['scop'];
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.planStartDate),
                end: new Date(phasesStartEndDates.planEndDate),
            })
        ) {
            phaseCompPerc =
                (differenceInDays(currentDate, phasesStartEndDates.planStartDate) * 100) / this.phaseDurationObject['plan'];
        } else if (isWithinInterval(currentDate, { start: new Date(outageStartDate), end: new Date(outageEndDate) })) {
            const implementationDuration = differenceInDays(new Date(outageEndDate), new Date(outageStartDate));
            if (implementationDuration !== 0) {
                phaseCompPerc = (differenceInDays(currentDate, new Date(outageStartDate)) * 100) / implementationDuration;
            }
        } else if (
            isWithinInterval(currentDate, {
                start: new Date(phasesStartEndDates.closStartDate),
                end: new Date(phasesStartEndDates.closEndDate),
            })
        ) {
            phaseCompPerc =
                (differenceInDays(currentDate, phasesStartEndDates.closStartDate) * 100) / this.phaseDurationObject['clos'];
        }

        return phaseCompPerc;
    }

    getPhaseStartAndEndDates(outageStartDate: Date, outageEndDate: Date): OutageStartEndDates {
        const planEndDate = subDays(outageStartDate, 1);
        const planStartDate = subDays(planEndDate, this.phaseDurationObject['plan']);
        const scopEndDate = subDays(planStartDate, 1);
        const scopStartDate = subDays(scopEndDate, this.phaseDurationObject['scop']);
        const initEndDate = subDays(scopStartDate, 1);
        const initStartDate = subDays(initEndDate, this.phaseDurationObject['init']);
        const closStartDate = addDays(outageEndDate, 1);
        const closEndDate = addDays(outageEndDate, this.phaseDurationObject['clos']);

        return {
            initStartDate: initStartDate,
            initEndDate: initEndDate,
            scopStartDate: scopStartDate,
            scopEndDate: scopEndDate,
            planStartDate: planStartDate,
            planEndDate: planEndDate,
            closStartDate: closStartDate,
            closEndDate: closEndDate,
        };
    }

    /**
     * This method builds the data required for depicting the outage phase in a pie chart
     */
    buildOutagePhaseData(phaseName: string, outagePhaseArray: OutagePhase[], key: string, totalDays: number, phaseStartDate: Date, phaseEndDate: Date): void {
        const obj = {} as OutagePhase;
        const totalPhaseDays = this.phaseDurationObject[key];
        obj.name = phaseName;
        obj.status = this.getOutageStatus(phaseStartDate, phaseEndDate);
        obj.value = Math.round((totalPhaseDays / totalDays) * 100);
        outagePhaseArray.push(obj);
    }

    getRadialColorName(value: number): string {
        let color = 'Red';

        if (value >= 45 && value <= 75) {
            color = 'Orange';
        } else if (value > 75) {
            color = 'Green';
        }
        return color;
    }
}
