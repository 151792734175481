import { Constants, Connection, PMLConnection } from '@/utils';
import { CancelToken } from 'axios';
import { UnitOutage } from '@/utils/interfaces';

export class TableService {
    private api: Connection = new Connection();
    private pmlApi: PMLConnection = new PMLConnection();

    public async getTable(
        machineSID: string,
        startYear: number,
        endYear: number,
        cancelToken: CancelToken
    ): Promise<any> {
        return (await this.api.get(Constants.TABLE.MAPPING + Constants.TABLE.BY_ID + machineSID + `?startYear=${startYear}&endYear=${endYear}`, { cancelToken })).data.result;
    }

    public async getMaintenanceIdsByGlobalId(
        globalId: string
    ): Promise<string[]> {
        return (
            await this.pmlApi.get(
                `${Constants.PML_CALLS.BUDGETS.MAINTENANCE_IDS}?GlobalId=${globalId}`
            )
        ).data?.result?.maintenanceIds;
    }

    public async getMaintenanceIdsByOutageId(
        outageId: string
    ): Promise<string[]> {

        return (await this.api.get(Constants.TABLE.MAPPING + Constants.TABLE.MAINTENANCE_IDS + btoa(outageId))).data.result.maintenanceIds;
    }

    public async getMaintenanceIdsByOutageAndUnitId(
        outageId: string,
        unitId: any
    ): Promise<string[]> {

        const paramUnitId: string = unitId;
        return (await this.api.get(Constants.TABLE.MAPPING + Constants.TABLE.MAINTENANCE_IDS + btoa(outageId) + '/' + paramUnitId)).data.result.maintenanceIds;
    }

    public async getUnitOutageIds(unitId: number): Promise<UnitOutage[]> {
        return (await this.api.get(Constants.TABLE.MAPPING + Constants.TABLE.OUTAGES + unitId)).data.result.outageIds;
    }
}
