




























/* eslint-disable @typescript-eslint/no-unused-vars */

import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';


@Component({
    name: 'phase-header',
})
export default class PhaseHeaderComponent extends Vue {
    @Prop()
    private backgroundClass!: string;

    constructor() {
        super();
    }
}
