import { Constants, Connection } from '@/utils';
import { PlantSimpleObject } from '@/utils/interfaces';
import { coodeSdk } from '@/utils/coode-sdk-config'
import { ApiPaginatedResponse, ApiResponse } from '@/models'

export class PlantService {
    private api: Connection = new Connection();

    public async getPlants(id?: number): Promise<PlantSimpleObject[]> {
        let route = Constants.PLANTS.MASTERDATA.MODULE + process.env.MSPT_CODE;
        if (id) {
            route += '?' + Constants.PLANTS.FLEETID + '=' + id;
        }
        return (
            await coodeSdk.core.masterData.request.api.get<ApiResponse<ApiPaginatedResponse<PlantSimpleObject>>>(route)
        ).data.result.items;
    }

    public async getPlantsWithAttributes(): Promise<any> {
        return (
            await coodeSdk.core.masterData.request.api.get<ApiResponse<ApiPaginatedResponse<any>>>(Constants.PLANTS.MASTERDATA.MODULE_ATTRIBUTES + process.env.MSPT_CODE)
        ).data.result.items;
    }

    public async getPlant(plantId: number): Promise<any> {
        return (await this.api.get(Constants.PLANTS.MAPPING + plantId)).data.result;
    }

    public async addPlant(plant: any): Promise<any> {
        return (await this.api.post(Constants.PLANTS.MAPPING, plant)).data.result;
    }

    public async editPlant(plantId: number, plant: any): Promise<any> {
        return (await this.api.put(Constants.PLANTS.MAPPING + plantId, plant))
            .data.result;
    }
}
