import { Module } from 'vuex';
import { OutageStrategyData, OutageStrategyFilter } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    filterData: {} as OutageStrategyFilter,
    outageStrategyData: {} as OutageStrategyData,
});

const OutageStrategyModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setFilterData(state, filterData): void {
            state.filterData = filterData;
        },
        setOutageStrategyData(state, outageStrategyData): void {
            state.outageStrategyData = outageStrategyData;
        },
        resetState(state): void {
            Object.assign(state, getDefaultState());
        },
        resetStrategyData(state): void {
            state.outageStrategyData = {};
        }
    },

    actions: {
        setFilterData({ commit }, filterData): void {
            commit('setFilterData', filterData);
        },
        setOutageStrategyData({ commit }, outageStrategyData): void {
            commit('setOutageStrategyData', outageStrategyData);
        },
        resetState({ commit }): void {
            commit('resetState');
        },
        resetStrategyData({ commit }): void {
            commit('resetStrategyData');
        }
    },

    getters: {
        getFilterData(state): OutageStrategyFilter {
            return state.filterData;
        },
        getOutageStrategyData(state): OutageStrategyData {
            return state.outageStrategyData;
        },
    },
    namespaced: true
}

export default OutageStrategyModule;
