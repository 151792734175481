import GroupModule from './group.module';
import PlantModule from './plant.module';
import UnitModule from './unit.module';
import OutageModule from './outage.module';
import TableModule from './table.module';
import MaintenanceSummaryModule from './maintenance-summary.module';
import GenevaModule from './geneva.module';
import OutageStrategyModule from './outage-strategy.module';
import OutageSummaryModule from './outage-summary.module';
import OverviewModule from './overview.module';
import CountryModule from './country.module';

const modules = {
    group: GroupModule,
    plant: PlantModule,
    unit: UnitModule,
    outage: OutageModule,
    table: TableModule,
    maintenanceSummary: MaintenanceSummaryModule,
    geneva: GenevaModule,
    outageStrategy: OutageStrategyModule,
    outageSummary: OutageSummaryModule,
    overview: OverviewModule,
    country: CountryModule
}

export default modules;
