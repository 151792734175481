import { Constants } from '@/utils';
import { Country } from '@/utils/interfaces';
import { coodeSdk } from '@/utils/coode-sdk-config'
import { ApiPaginatedResponse, ApiResponse } from '@/models'

export class CountryService {

    public async getCountries(): Promise<Country[]> {
        return (
            await coodeSdk.core.masterData.request.api.get<ApiResponse<ApiPaginatedResponse<Country>>>(Constants.COUNTRIES.MASTERDATA.MODULE + process.env.MSPT_CODE)
        ).data.result.items;
    }
}
