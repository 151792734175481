import { Constants, Connection } from '@/utils';
import { ApiResponseList, Driver } from '@/utils/interfaces';

export class DriverService {
    private api: Connection = new Connection();

    public async getDrivers(): Promise<ApiResponseList<Driver>> {
        try {
            return (await this.api.get(Constants.DRIVERS.GET)).data.result;
        } catch {
            return { items: [] };
        }
    }
}
