import { Constants, Connection } from '@/utils';
import { OutageScorecardPayload, OutageScorecardItem } from '@/utils/interfaces';

export class OutageScorecardService {
    private api: Connection = new Connection();

    public async getForPlant(payload: OutageScorecardPayload): Promise<OutageScorecardItem[]> {
        return (
            await this.api.post(
                Constants.OUTAGE_SCORECARD.MAPPING,
                payload
            )
        ).data.result.outageScorecards;
    }

    public async updateForPlant(outageScorecard: OutageScorecardItem): Promise<any> {
        return (await this.api.put(Constants.OUTAGE_SCORECARD.MAPPING, outageScorecard))
            .data.result;
    }
}
