
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
})
export default class DropDownComponent extends Vue {
    @Prop()
    private text: any;

    @Prop()
    private values: any;

    @Prop()
    private selected: any;
}
