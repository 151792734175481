import { Helper } from './';
import {
    GenevaOperatingHoursYear,
    GenevaOperatingHours,
    Geneva,
    GenevaOperatingHoursMonth,
} from '@/utils/interfaces';

export class GenevaHelper {
    public static initYears(geneva: GenevaOperatingHours, yearValue: Geneva[]): any {
        geneva.years.map((year) => this.monthChange(year, yearValue));
    }

    public static yearChange(
        year: GenevaOperatingHoursYear,
        t: number,
        yearValueParam: Geneva
    ): any {
        let value = 0;
        switch (t) {
            case 0:
                value = yearValueParam['operatingHours'];
                break;
            case 1:
                value = yearValueParam['starts'];
                break;
            default:
                value = 0;
        }

        const monthValue =
            Math.floor(value / year.months.length) < 1
                ? Math.floor(value / year.months.length) + 1
                : Math.floor(value / year.months.length);
        let remaining = value - monthValue * year.months.length;

        for (const month of year.months) {
            let val = value - monthValue >= 0 ? monthValue : 0;
            if (remaining > 0) {
                val++;
            }
            switch (t) {
                case 0:
                    month['operatingHours'] = val;
                    break;
                case 1:
                    month['starts'] = val;
                    break;
                default:
                    value = 0;
            }
            remaining--;
            value -= val;
        }
    }

    public static monthChange(
        year: GenevaOperatingHoursYear,
        yearValueParam: any
    ): any {
        const months = year.months.map((m) => {
            if (!m.operatingHours) {
                m.operatingHours = 0;
            }
            if (!m.starts) {
                m.starts = 0;
            }
            return m;
        });
        yearValueParam[year.year] = {};
        yearValueParam[year.year]['operatingHours'] = Helper.sumProperty(
            months,
            'operatingHours'
        );
        yearValueParam[year.year]['starts'] = Helper.sumProperty(
            months,
            'starts'
        );
    }

    public static enforceMonthlyHourLimit(
        year: GenevaOperatingHoursYear
    ): void {
        year.months.forEach((month: GenevaOperatingHoursMonth) => {
            if (month.operatingHours > 732) {
                month.operatingHours = 732;
            }
            Helper.enforcePositiveNumber(month, 'operatingHours');
        });
    }

    public static enforceYearlyHourLimit(
        year: GenevaOperatingHoursYear,
        yearValueParam: any
    ): void {
        const numberOfMonths = year.months.length;
        if (yearValueParam[year.year].operatingHours > numberOfMonths * 732) {
            yearValueParam[year.year].operatingHours = numberOfMonths * 732;
        }
        Helper.enforcePositiveNumber(
            yearValueParam[year.year],
            'operatingHours'
        );
    }
}
