import Vue from 'vue';
import Component from 'vue-class-component';
import { ActivityLogParameters, PlantFilterSingleSelect, UnitAttributes, SelectItem, Page, UnitFilterSingleSelect, ChangeLogListItem } from '@/utils/interfaces';
import { EventBus, Data, Helper } from '@/utils';
import { ChangeLogService } from '@/services';
import { MasterService } from '@/services/master-service';

@Component({})
export default class ActivityLogComponent extends Vue {
    private loading = false;
    private masterService: MasterService = MasterService.Instance;
    private logs: ChangeLogListItem[] = [];
    private allLogs: ChangeLogListItem[] = [];
    private changeLogService: ChangeLogService = new ChangeLogService();
    private data: Data = Data.Instance;
    private dateSortTypes = { ASC: 0, DESC: 1 };
    private actionTypes = { NONE: '', ADD: 'Add', EDIT: 'Edit', DELETE: 'Delete' };
    private actionFilter: SelectItem[] = [];
    private actionFilterValue = '';
    private dateSort: SelectItem[] = [];
    private dateSortValue = this.dateSortTypes.DESC;
    private paging: Page[] = [];
    private emailFilter = '';
    private plantsUnitsData: UnitAttributes[] = [];
    private plantSids: number[] = [];
    private plantsFilter: PlantFilterSingleSelect[] = [];
    private plantForFilter: PlantFilterSingleSelect | null = null;
    private plantUnitsFilter: UnitFilterSingleSelect[] = [];
    private plantUnitForFilter: UnitFilterSingleSelect | null = null;
    private totalItems = 1;
    private months: string[];
    private params: ActivityLogParameters = {
        page: 1,
        number: 30,
        emailFilter: '',
        actionFilter: '',
        plantSidFilter: null,
        machineSidFilter: null,
        plantSids: null,
        sortDescending: true,
    };

    constructor() {
        super();
        this.months = Helper.getMonths();
    }

    created(): void {
        if (this.data.selected.plant !== null) {
            this.load();
        }
        this.actionFilter = [
            { label: this.$t('noAction'), value: this.actionTypes.NONE },
            { label: this.$t('addedAction'), value: this.actionTypes.ADD },
            { label: this.$t('modifiedAction'), value: this.actionTypes.EDIT },
            { label: this.$t('deletedAction'), value: this.actionTypes.DELETE }
        ];
        this.dateSort = [
            { label: this.$t('ascending'), value: this.dateSortTypes.ASC },
            { label: this.$t('descending'), value: this.dateSortTypes.DESC }
        ];
    }

    private async paginate(page: number): Promise<void> {
        this.params.page = page;
        this.params.emailFilter = this.emailFilter;
        this.params.actionFilter = this.actionFilterValue;
        this.params.sortDescending = this.dateSortValue === this.dateSortTypes.DESC;
        this.params.plantSidFilter = this.plantUnitForFilter !== null ? this.plantUnitForFilter.plantId : null;
        this.params.machineSidFilter = this.plantUnitForFilter !== null ? this.plantUnitForFilter.sid.toString() : null;
        this.params.plantSids = this.plantForFilter?.plantSIDS || [];

        await this.loadLogs(this.params);
        this.setUpPaging(page);
        $('.change-log-list').scrollTop(0);
    }

    private doFilterAndSort(): void {
        this.params.page = 1;
        this.params.emailFilter = this.emailFilter;
        this.params.actionFilter = this.actionFilterValue;
        this.params.sortDescending = this.dateSortValue === this.dateSortTypes.DESC;
        this.params.plantSidFilter = this.plantUnitForFilter !== null ? this.plantUnitForFilter.plantId : null;
        this.params.machineSidFilter = this.plantUnitForFilter !== null ? this.plantUnitForFilter.sid.toString() : null;
        this.params.plantSids = this.plantForFilter?.plantSIDS || [];

        this.load();
    }

    private filterAndSortOnEnter($event: KeyboardEvent): void {
        // 13 is Enter
        if ($event.keyCode === 13) {
            this.doFilterAndSort();
        }
    }

    private async load(): Promise<void> {
        this.loading = true;
        if (this.data.selected.group) {
            const plants = await this.masterService.plantService.getPlants();
            this.plantsUnitsData = await this.masterService.unitService.getUnitsAttributes([]);
            this.plantSids = plants.map((plant) => plant.sid);
            this.plantsFilter = plants.reduce((plants: PlantFilterSingleSelect[], plant) => {
                const existingPlant = plants.find((p) => p.name === plant.businessName);
                if (existingPlant) {
                    existingPlant.plantSIDS = existingPlant.plantSIDS.concat(plant.sid);
                } else {
                    plants.push({
                        name: plant.businessName,
                        plantSIDS: [plant.sid],
                        units: []
                    });
                }
                return plants;
            }, []);
            this.plantsFilter.forEach(plant => {
                plant.units = this.plantsUnitsData
                    .filter((unit) => {
                        return plant.plantSIDS.includes(unit.plantSid);
                    })
                    .map((unit) => {
                        return {
                            sid: unit.machineSid,
                            name: unit.unitName,
                            plantId: unit.plantSid,
                            plantName: plant.name
                        };
                    });
            });

            this.plantsFilter = this.plantsFilter.filter((plant) => plant.units.length);

            if (!this.params.plantSids?.length) {
                this.params.plantSids = this.plantSids;
            }

            await this.loadLogs(this.params);
            this.setUpPaging(this.params.page);
        }
        this.loading = false;
    }

    private async loadLogs(activityLogParams: ActivityLogParameters): Promise<void> {
        try {
            if (this.data.selected.group) {
                const logResults = await this.changeLogService.getChangeLogs(activityLogParams);
                this.allLogs = logResults.list.map((l) => ({
                    ...l,
                    date: this.parseDate(l.date).toString()
                }));
                this.totalItems = logResults.count;
                this.params.page = logResults.page;
                this.logs = this.allLogs.slice();
                this.logs.forEach((log) => {
                    const plantWithName = this.plantsUnitsData.find(({ plantSid }) => plantSid === log.plantSid);
                    log.plantName = plantWithName !== undefined ? plantWithName.plantName : 'N/A';
                    const unitWithName = this.plantsUnitsData.find(({ machineSid }) => machineSid.toString() === log.machineSid);
                    log.unitName = unitWithName !== undefined ? unitWithName.unitName : 'N/A';
                })
            }
        } catch (error) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorLoadingLogs')
            });
        }
    }

    private setUpPaging(currentPage: number): void {
        this.params.page = currentPage;
        this.paging = [];
        const nrOfPages = Math.ceil(this.totalItems / this.params.number);
        let beforeSet = false;
        for (let i = 1; i <= nrOfPages; i++) {
            if (beforeSet && i < currentPage - 2) {
                continue;
            }
            if (i < currentPage - 2 && i > 1 && !beforeSet) {
                this.addPage('...', true);
                beforeSet = true;
            } else if (i > currentPage + 2 && i < nrOfPages) {
                this.addPage('...', true);
                this.addPage(nrOfPages, false);
                break;
            } else {
                this.addPage(i, i === currentPage);
            }
        }
    }

    private addPage(page: number | string, disabled: boolean): void {
        this.paging.push({
            number: page,
            disabled
        });
    }

    private sortByDateDesc(a: ChangeLogListItem, b: ChangeLogListItem): number {
        return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
    }

    private sortByDateAsc(a: ChangeLogListItem, b: ChangeLogListItem): number {
        return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
    }

    private actionClass(value: string): string {
        switch (value) {
            case this.actionTypes.ADD:
                return 'add-action';
            case this.actionTypes.EDIT:
                return 'modify-action';
            case this.actionTypes.DELETE:
                return 'delete-action';
            default:
                return '';
        }
    }

    private getMonth(dateString: string): string {
        const date = new Date(dateString);
        return this.months[date.getMonth()] + ' ' + date.getFullYear();
    }

    private dateLabelFunction(date: Date): string {
        const parts = date.toString().split(' ');
        return `${this.$i18n.t(parts[0])} ${this.$i18n.t(parts[1])} ${parts[2]}, ${parts[3]}`;
    }

    private parseDate(date: string): Date {
        return new Date(date);
    }

    private assignFilteredUnits(): void {
        this.plantUnitsFilter = this.plantForFilter !== null ? this.plantForFilter.units.slice() : [];
        if (this.plantForFilter === null) {
            this.plantUnitForFilter = null;
        }
    }
}
