import { Connection } from '@/utils';

export class KksService {
    private api: Connection = new Connection();
    private url: any = 'kks';
    public async getKks(language: string): Promise<any> {
        try {
            return (
                await this.api.get(
                    this.url + '?language' + language
                )
            ).data.result.kksViews;
        } catch {
            return [];
        }
    }
}
