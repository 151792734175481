/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable sonarjs/cognitive-complexity */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, DocumentHelper, EventBus, OutageCommon } from '@/utils';
import { MasterService } from '@/services/master-service';
import { DateTime } from 'luxon';
import {
    Plant, UnitAttributes, OutageStrategyData, OutageStrategyFilter, OutageStrategyAllFilters,
    ServerError, PlantFilter, UnitFilter
} from '@/utils/interfaces';
import { actions, getters } from '@/store/types';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';

@Component({
    filters: {
        formatDate(value: string): string {
            const dateTimeFormat = 'dd/MM/yyyy HH:mm';
            return value && DateTime.fromISO(value).toFormat(dateTimeFormat);
        }
    }
})
export default class OutageStrategyComponent extends Vue {
    private readonly axios = require('axios');
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private documentHelper: DocumentHelper = new DocumentHelper();
    private outageCommon: OutageCommon = new OutageCommon();
    private data: Data = Data.Instance;

    /* switches */
    private loading = false;
    private filterVisible = false;

    /* data */
    private filter = {
        selectedOutageId: null,
        plants: [],
        maintenanceActivities: [],
        outageIds: [],
        showOnlyOutagesWithLeadingItem: false,
        minimumCost: null,
    } as OutageStrategyAllFilters;
    private outageStrategy: OutageStrategyData = { items: [] };
    private units: UnitAttributes[] = [];
    private selectAllPlants = false;
    private source = {} as CancelTokenSource;
    private getFilterSource = {} as CancelTokenSource;
    private language = this.data.getLanguage();

    get plants(): Plant[] { return this.$store.getters[getters.PLANT__GET_PLANTS]; }
    get unitsWithAttributes(): UnitAttributes[] { return this.$store.getters[getters.UNIT__GET_UNITS_WITH_ATTRIBUTES]; }
    get outageStrategyData(): OutageStrategyData { return this.$store.getters[getters.OUTAGE__STRATEGY__GET_OUTAGE_STRATEGY_DATA]; }
    get filterData(): OutageStrategyFilter { return this.$store.getters[getters.OUTAGE__STRATEGY__GET_FILTER_DATA]; }

    constructor() {
        super();
        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }

    async created(): Promise<void> {
        await this.load();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        this.getFilterSource.cancel && this.getFilterSource.cancel(CANCEL_MESSAGE);
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }

    private async load(): Promise<void> {
        this.loading = true;

        /* initial values */
        this.filter.showOnlyOutagesWithLeadingItem = false;
        this.filter.minimumCost = null;
        this.filter.selectedOutageId = null;

        await this.loadFilter();
        await this.loadOutageStrategy();

        this.filter.maintenanceActivities = this.masterService.outageStrategyService.getMaintenanceActivities();
        this.loading = false;
    }

    private async loadOutageStrategy(): Promise<void> {
        try {
            const mapPlants = this.outageCommon.buildFilterWithSelectedOption(this.filter.plants);
            if (!this.filter.minimumCost) {
                this.filter.minimumCost = null;
            }
            if (!this.outageStrategyData.items || !this.outageStrategyData.items.length) {
                this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
                this.source = this.axios.CancelToken.source();
                await this.$store.dispatch(actions.OUTAGE__STRATEGY__SET_OUTAGE_STRATEGY_DATA, await this.masterService.outageStrategyService.getOutageStrategy(
                    { ...this.filter, plants: mapPlants, language: this.language }, this.source.token));
            }
            this.outageStrategy = this.outageStrategyData;
            this.initOutageYears();
        } catch (error) {
            this.loading = false;
            if (this.axios.isCancel(error)) {
                return;
            }
            if (error !== undefined && (error as ServerError).response && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingOutageSummary')
                });
            }
        }
    }

    private async loadFilter(): Promise<void> {
        try {
            if (this.data.selected.group && this.data.selected.plant) {
                const plantIds = this.plants.map(plant => plant.sid);
                this.unitsWithAttributes?.length || await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes(plantIds));

                const allUnits = this.unitsWithAttributes?.map((unit: UnitAttributes) => ({
                    machineSID: unit.machineSid.toString(),
                    name: this.unitsWithAttributes?.find((mdUnit) => mdUnit.unitName === unit.unitName)?.businessAbbreviation || unit.unitName,
                    selected: true
                }));

                if (!Object.keys(this.filterData).length) {
                    this.getFilterSource.cancel && this.getFilterSource?.cancel(CANCEL_MESSAGE);
                    this.getFilterSource = this.axios.CancelToken.source();
                    await this.$store.dispatch(actions.OUTAGE__STRATEGY__SET_FILTER_DATA, await this.masterService.outageStrategyService.populateFilter(this.language, allUnits, this.getFilterSource.token));
                }

                this.filter.plants = this.outageCommon.getPlantFilter(this.plants, this.unitsWithAttributes, false, this.data.selected.plant.businessName);

                this.filter.outageIds =  this.getOutageIdsFilter();
            }

        } catch (error) {
            this.loading = false;
            if (error !== undefined && (error as ServerError).response && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingOutageSummary')
                });
            }
        }
    }

    private async exportOutageStrategyPdf(): Promise<void> {
        this.loading = true;
        const mapPlants = this.outageCommon.buildFilterWithSelectedOption(this.filter.plants);
        const outageStrategyPdf = await this.masterService.outageStrategyService.exportOutageStrategyPdf({ ...this.filter, plants: mapPlants });
        this.documentHelper.decodeAndDownloadBase64File(outageStrategyPdf.title, outageStrategyPdf.content);
        this.loading = false;
    }

    private async refresh(): Promise<void> {
        this.toggleFilter();
        this.loading = true;
        await this.$store.dispatch(actions.OUTAGE__STRATEGY__RESET_STRATEGY_DATA);
        await this.loadOutageStrategy();
        this.loading = false;
    }

    private initOutageYears(): void {
        this.outageStrategy.items.forEach((item) => {
            const currentYear = item.nextPlannedDate ? new Date(item.nextPlannedDate).getFullYear() : null;
            const outage = this.outageStrategy.items.filter((y) => y.outageYear === currentYear);
            if (outage.length < 1) {
                item.outageYear = currentYear;
                item.outagesInYear = this.outageStrategy.items.filter((y) => y.nextPlannedDate && new Date(y.nextPlannedDate).getFullYear() === currentYear).length;
            }
        });
    }

    private toggleFilter(): void {
        this.filterVisible = !this.filterVisible;
        if (this.filterVisible) {
            this.filter.outageIds = this.getOutageIdsFilter();
        }
    }

    private onSelectAllChange(value: boolean): void {
        this.outageCommon.selectAll(value, this.filter.plants);
    }

    private onPlantFilterChange(event: Event, plant: PlantFilter): void {
        this.outageCommon.handlePlantFilterChange(event, plant);
        this.selectAllPlants = this.outageCommon.checkIfAllSelected(this.filter.plants);
    }

    private onUnitFilterChange(event: Event, plant: PlantFilter, unit: UnitFilter): void {
        this.outageCommon.handleFilterUnitClick(event, plant, unit);
        this.selectAllPlants = this.outageCommon.checkIfAllSelected(this.filter.plants);
    }

    private clearAllFilters(): void {
        this.selectAllPlants = false;
        this.filter.plants.forEach(p => {
            p.selected = false;
            p.units.forEach(u => {
                u.selected = false;
            });
        });
        this.filter.maintenanceActivities.forEach((activity) => {
            activity.selected = false;
        });
        this.filter.selectedOutageId = null;
        this.filter.minimumCost = null;
        this.filter.showOnlyOutagesWithLeadingItem = false;
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }

    private getOutageIdsFilter(): string[] {
        const selectedUnits = this.filter.plants.reduce((acc: string[], curr: PlantFilter) => {
            const units = curr.units.filter((unit) => unit.selected && unit.machineSID).map((unit) => unit.machineSID);
            acc = acc.concat(units);
            return acc;
        }, []);
        return this.filterData.outageIdsDictionary ? Object.keys(this.filterData.outageIdsDictionary).reduce((acc: string[], curr) => {
            if (selectedUnits.includes(curr)) {
                acc = acc.concat(this.filterData.outageIdsDictionary[curr].filter((item: string) => !acc.includes(item)));
            }
            return acc;
        }, []) : [];
    }
}
