import { Module } from 'vuex';
import { GenevaOperatingHours } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    genevaData: {} as GenevaOperatingHours,
});

const GenevaModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setGeneva(state, genevaData): void {
            state.genevaData = genevaData;
        },
        resetState(state): void {
            Object.assign(state, getDefaultState());
        }
    },

    actions: {
        setGeneva({ commit }, genevaData): void {
            commit('setGeneva', genevaData);
        },
        resetState({ commit }): void {
            commit('resetState');
        }
    },

    getters: {
        getGeneva(state): GenevaOperatingHours {
            return state.genevaData;
        },
    },
    namespaced: true
}

export default GenevaModule;
