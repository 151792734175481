import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

export interface CreateStateOption<TC, TState> {
    id: (vc: TC) => string;
    data: (vc: TC) => TState;
}

export const createState = <TC, TState>(option: CreateStateOption<TC, TState>): any => {
    const store = Vue.observable({})
    class IDState extends Vue {
        idState: TState = option.data((this as unknown) as TC)
        $_ID?: string

        $GetID(): any {
            return option.id.call(this, (this as unknown) as TC)
        }

        $UpdateIDState(): void  {
            const id = this.$GetID()
            if (id == null) {
                console.warn(`No ID found`)
            }
            if (id !== this.$_ID && !(store as any).id) {
                this.$InitIDState(id)
            }
        }

        $InitIDState(id: string): void  {
            this.$set(store, id, option.data((this as unknown) as TC))
            this.$_ID = id
        }

        @Watch('$GetID', { immediate: true })
        onGetIDChange(val: string): void {
            this.$nextTick(() => {
                this.$_ID = val
            })
        }

        created(): void {
            this.$_ID = undefined
            this.$UpdateIDState()
        }

        beforeUpdate(): void {
            this.$UpdateIDState()
        }
    }
    return Component(IDState);
}
