export const PERMISSIONS: any = {
    'HasAdminRights': 'HasAdminRights',
    'HasAccessToTable': 'HasAccessToTable',
    'HasAccessToOutagePlan': 'HasAccessToOutagePlan',
    'HasAccessToMaintenanceSummary': 'HasAccessToMaintenanceSummary',
    'HasAccessToRemainingLife': 'HasAccessToRemainingLife',
    'HasAccessToOutageScorecard': 'HasAccessToOutageScorecard',
    'HasAccessToOutageClock': 'HasAccessToOutageClock',
    'HasAccessToOutageDocuments': 'HasAccessToOutageDocuments',
    'HasAccessToOutageStrategy': 'HasAccessToOutageStrategy'
};

export const ASSET_PERMISSION_ACTIONS: any = {
    'Write': 'Write',
    'Read': 'Read'
};

export interface AssetPermission {
    plantSID: number;
    action: string;
}

export class PermissionsHelper {

    public static getAssetPermissionForPlant(assetPermissionsClaim: string, plantSid: number): AssetPermission | null {
        if (!assetPermissionsClaim)
            return null;

        let parsedAssetPermission: AssetPermission | null = null;
        const assetPermissions = assetPermissionsClaim.split(',');
        assetPermissions.forEach(assetPermission => {
            const parts = assetPermission.split(':');
            if (parts.length === 2) {
                const sid = parseInt(parts[0]);
                const action = parts[1].trim();

                if (plantSid === sid) {
                    parsedAssetPermission = { plantSID: sid, action: action };
                }
            }
        });

        return parsedAssetPermission;
    }
}
