




































































































































/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */

import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { EventBus, DataInputHelper } from '@/utils';
import { OutageScorecardItemValue } from '@/utils/interfaces';
import { PHASES, KPI_INPUT_TYPES } from '@/utils/scorecard-constants';
import PhaseHeaderComponent from './phase-header-component.vue'

@Component({
    components: {
        'phase-header': PhaseHeaderComponent
    }
})
export default class MilestoneAccordionComponent extends Vue {
    private phases = PHASES;
    private kpiInputTypes = KPI_INPUT_TYPES;
    private validate: any = {};

    @Prop()
    private list!: OutageScorecardItemValue[];

    @Prop()
    private phaseString!: string;

    @Prop()
    private currentPhase!: number;

    @Prop()
    private phase!: number;

    constructor() {
        super();

        this.list.forEach((value) => {
            this.validate['outageScorecardValues[' + (value.outageMilestoneId - 1) + '].CompletionDate'] = null;
        });

        EventBus.$on(EventBus.OUTAGE_SCORECARD.VALIDATION_ERROR, this.handleValidationError);
    }

    mounted(): void {
        ($('[data-toggle="popover"]') as any).popover();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.OUTAGE_SCORECARD.VALIDATION_ERROR, this.handleValidationError);
    }

    private enforcePercentage(value: any): void {
        if (!value || !value.numericValue || isNaN(value.numericValue)) {
            value.numericValue = 0;
        }
        value.numericValue = value.numericValue < 0 ? 0 : value.numericValue > 100 ? 100 : value.numericValue;
    }

    private getTimeframeLabel(value: number): string {
        if (value === 0)
            return this.$i18n.t('throughoutOutage').toString();

        return value.toString();
    }

    private getValidateObject(name: string): void {
        return DataInputHelper.getValidateObject(name, this.validate);
    }

    private disabledAfterToday(date: Date): boolean {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return date > today;
    }

    private shouldShowInfo(value: OutageScorecardItemValue): boolean {
        return (value.outageMilestoneId === 7 || value.outageMilestoneId === 8) && value.timeframe < -180;
    }

    private handleValidationError (validationData: any): void {
        Object.keys(validationData).forEach(key => {
            this.validate[key] = validationData[key];
        });
    }
}
