import Vue from 'vue';
import Component from 'vue-class-component';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Prop, Watch } from 'vue-property-decorator';
import L from 'leaflet';
import { Country, CountryMarker, CountriesGeolocations, MapMarkerIcon } from '@/utils/interfaces';

@Component({
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
})

export default class DashboardMapComponent extends Vue {
    @Prop()
    private countries!: Country[];

    @Prop()
    private countryCode?: string;

    @Watch('countryCode')
    private watchOpen(newVal: string): void {
        if (!newVal) {
            this.selectedMarker = null;
        }
    }

    private selectedMarker: CountryMarker | null = null;

    private url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    private attribution = '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';

    private zoom = 15;
    private center = [51.505, -0.159];
    private options = {
        zoomControl: false,
        scrollWheelZoom: false,
        dragging: false,
        zoomSnap: false,
        doubleClickZoom: false,
        keyboard: false,
        boxZoom: false
    };

    private countriesGeolocations: CountriesGeolocations = {
        'NO' : [62.38583179, 16.321998712],
        'UK': [52.7726, -1.2052],
        'DE': [51.1657, 10.4515],
        'NL': [52.370216, 4.895168],
        'HU': [47.1625, 19.5033]
    };

    private get countryMarkers(): Array<CountryMarker> {
        if (!this.countries || !this.countries.length) return [];
        const uniqueCountries = [...new Set(this.countries.map((country) => country.code))];
        return uniqueCountries.filter(countryCode => countryCode !== this.selectedMarker?.countryCode && this.countriesGeolocations[countryCode]).map(countryCode => { return { latLong: this.countriesGeolocations[countryCode], countryCode }; })
    }

    private get icon(): MapMarkerIcon {
        return new L.Icon({
            iconUrl: 'assets/img/marker-icon-2x-green.png',
            shadowUrl: 'assets/img/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });
    }

    filterByCountry(marker: CountryMarker): void {
        this.selectedMarker = this.selectedMarker?.countryCode !== marker.countryCode ? marker : null;
        this.$emit('country-clicked', marker.countryCode);
    }
}
