/* eslint-disable sonarjs/no-duplicate-string */

import Vue from 'vue';
import Component from 'vue-class-component';
import { MasterService } from '@/services/master-service';
import { Data, Helper, ACMHelper, DataInputHelper, Constants, CurrencyHelper } from '@/utils';
import { Plant, PlantValidate, Currency } from '@/utils/interfaces';
import { EventBus } from '@/utils/eventbus';
import AppSecurity from '@/utils/app-security';

@Component({})
export default class PlantModalComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private appSecurity = new AppSecurity();
    private data: Data = Data.Instance;
    private currencyHelper: CurrencyHelper = new CurrencyHelper();

    /* switches */
    private editMode = false;
    private waitingForSave: boolean;
    private currencyError = false;
    private isUserAdmin = false;
    private showResultBox = false;
    private resultError = false;

    /* data */
    private plant: Plant = {
        id: 0,
        name: '',
        acmCode: '',
        businessName: '',
        currency: 0,
        groupId: 0,
        acmUnits: [],
        acmAlerts: [],
        plantSID: 0,
        abbreviation: '',
        enerlyticsSiteId: '',
        sid: 0
    } as Plant;
    private result = '';
    private currencies: Currency[];
    private validate = {} as PlantValidate;
    private enerlyticsPlants: any[] = [];
    private acmHelper = new ACMHelper();

    /* refs */
    $refs!: {
        plantModal: Pui.Lightbox;
    }

    async mounted(): Promise<void> {
        this.isUserAdmin = await this.appSecurity.hasAdminRights();
    }

    constructor() {
        super();
        this.waitingForSave = false;
        this.currencies = this.currencyHelper.getCurrencies();
        this.validate = {} as PlantValidate;
    }

    public async openModalForEdit(): Promise<void> {
        this.$refs.plantModal?.open();
        await this.editPlant();
    }

    public async openModalForInit(): Promise<void> {
        this.$refs.plantModal?.open();
        await this.initPlant();
    }

    public closeModal(): void {
        this.$refs.plantModal?.close();
    }

    private getValidateObject(name: string): boolean {
        return DataInputHelper.getValidateObject(name, this.validate);
    }

    private async getEnerlyticsPlants(): Promise<void> {
        const data = await this.masterService.enerlyticsService.getSites();
        /* plant RoCa3 should not be visible as an option */
        const dataWithoutR3 = data.filter((entry: Plant) => entry.enerlyticsSiteId !== Constants.HARDCODED_PLANTS.ROCA3);
        this.enerlyticsPlants = [
            {enerlyticsDisplayName: 'N/A', enerlyticsSiteId: ''},
            ...Helper.sortAlphabetically(dataWithoutR3, 'enerlyticsDisplayName')];
    }

    private async initPlant(): Promise<void> {
        if (this.data.selected.plant && this.data.selected.group) {
            this.plant = {
                id: 0, name: this.data.selected.plant.name,
                acmCode: null, currency: 0,
                plantSID: this.data.selected.plant.sid,
                groupId: this.data.selected.group.id
            } as unknown as Plant;
        }
        this.result = '';
        this.showResultBox = false;
        this.resultError = false;
        this.currencyError = false;
        this.validate = {} as PlantValidate;
        await this.getEnerlyticsPlants();
    }

    private async editPlant(): Promise<void> {
        if (!this.data.selected.plant) {
            return;
        }

        this.plant = Helper.deepCopy(this.data.selected.plant, {});
        this.validate = {} as PlantValidate;
        await this.getEnerlyticsPlants();
    }

    private async savePlant(): Promise<void> {
        this.currencyError = this.plant.currency === undefined;
        if (this.currencyError === true) {
            return;
        }
        this.waitingForSave = true;
        this.showResultBox = false;

        try {
            if (!this.data.selected.plant || !this.data.selected.plant.id) {
                const result = await this.masterService.plantService.addPlant({
                    acmCode: this.plant.acmCode,
                    abbreviation: this.plant.abbreviation,
                    currency: this.plant.currency,
                    plantSID: this.plant.plantSID,
                    enerlyticsSiteId: this.plant.enerlyticsSiteId
                });
                EventBus.$emit(EventBus.GLOBAL.ADD_PLANT, {...result, name: this.plant.name, sid: this.plant.sid });
            } else {
                await this.masterService.plantService.editPlant(this.data.selected.plant.id,
                    {
                        plantMappingId: this.data.selected.plant.id,
                        acmCode: this.plant.acmCode,
                        abbreviation: this.plant.abbreviation,
                        currency: this.plant.currency,
                        plantSID: this.plant.plantSID,
                        enerlyticsSiteId: this.plant.enerlyticsSiteId
                    });
                EventBus.$emit(EventBus.GLOBAL.ADD_PLANT, {...this.plant, name: this.plant.name, sid: this.plant.sid });
            }
            this.waitingForSave = false;
            this.closeModal()
        }
        catch (error) {
            DataInputHelper.handleError(error, this);
        }
    }
}
