import { Module } from 'vuex';
import { TableAreaItem, TableYearItem, AcmUnitAlert } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    areas: [] as TableAreaItem[],
    years: [] as TableYearItem[],
    acmAlerts: [] as AcmUnitAlert[],
});

const TableModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setAreas(state, areas): void {
            state.areas = areas;
        },
        setYears(state, years): void {
            state.years = years;
        },
        setAcmAlerts(state, acmAlerts): void {
            state.acmAlerts = acmAlerts;
        },
        resetState(state): void {
            Object.assign(state, getDefaultState());
        }
    },

    actions: {
        setAreas({ commit }, payload): void {
            commit('setAreas', payload);
        },
        setYears({ commit }, payload): void {
            commit('setYears', payload);
        },
        setAcmAlerts({ commit }, payload): void {
            commit('setAcmAlerts', payload);
        },
        resetState({ commit }): void {
            commit('resetState');
        }
    },

    getters: {
        getAreas(state): TableAreaItem[] {
            return state.areas;
        },
        getYears(state): TableYearItem[] {
            return state.years;
        },
        getAcmAlerts(state): AcmUnitAlert[] {
            return state.acmAlerts;
        }
    },
    namespaced: true
}

export default TableModule;
