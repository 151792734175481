import { render, staticRenderFns } from "./countdown-clock.vue?vue&type=template&id=5698bb4c"
import script from "./countdown-clock.ts?vue&type=script&lang=ts&external"
export * from "./countdown-clock.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports