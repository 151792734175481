import { Connection, Constants } from '@/utils';
export class UnitOperatingParamsService {
    private api: Connection = new Connection();

    public async getAll(
        unitId = 0,
        startYear: number,
        endYear: number
    ): Promise<any> {
        return (
            await this.api.get(`${Constants.UNIT_OP_PARAMETERS.MAPPING + unitId}/${startYear}/${endYear}`)).data.result;
    }

    public async update(unitId: number, data: any): Promise<any> {
        return (
            await this.api.put(Constants.UNIT_OP_PARAMETERS.MAPPING + unitId, data)
        ).data.result;
    }
}
