import { getters } from '@/store/types';
import { Unit, UnitDataForHomepage } from '@/utils/interfaces';
import { Data } from '@/utils';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import OverviewUnitComponent from './overview-unit/overview-unit.vue';
import RecycleScroller from 'vue-virtual-scroller/src/components/RecycleScroller.vue';

@Component({
    components: {
        'overview-unit': OverviewUnitComponent,
        RecycleScroller
    }
})

export default class OverviewComponent extends Vue {
    private data: Data = Data.Instance;
    private unitsData = [] as UnitDataForHomepage[];
    private readonly COLLAPSED_SIZE = 72;
    private readonly UNIT_SPACING = 60;

    get units(): Unit[] {
        if (!this.data?.selected?.plant?.sid || this.storeUnits.length === 0) return [];
        return this.storeUnits.filter((unit: Unit) => unit.plantId === this.data?.selected?.plant?.sid);
    }
    get storeUnits(): Unit[] { return this.$store.getters[getters.UNIT__GET_UNITS]; }

    @Watch('units')
    private onUnitsChanged(): void {
        this.setUnitsData();
    }

    private get minItemHeight(): number {
        const minItemHeight = {
            s: 447,
            m: 750,
        } as { [key: number]: number };
        return minItemHeight[(this as any).$puiBreakpoint] || 497;
    }

    created(): void {
        this.setUnitsData();
    }

    private setUnitsData(): void {
        this.unitsData = this.units.map((item) => {
            const subchart = this.unitsData.find((unit) => unit.sid === item.sid);
            return {
                ...item,
                collapsed: false,
                size: subchart ? subchart.size : this.minItemHeight,
            };
        });
    }

    private handleCollapsed(val: boolean, item: UnitDataForHomepage): void {
        item.collapsed = val;
        if (val) {
            item.size = this.COLLAPSED_SIZE;
        }
    }

    private handleHeight(val: number, item: UnitDataForHomepage): void {
        if (val) {
            item.size = val + this.UNIT_SPACING;
        }
    }
}
