import { DocumentGeneratorService } from '@/services';

export class DocumentHelper {
    private documentGeneratorService: DocumentGeneratorService =
        new DocumentGeneratorService();

    public async generateSixYearOutagePlan(payload: any): Promise<void> {
        await this.documentGeneratorService.generateSixYearOutagePlan(payload);
    }

    public async generateOutageInitiationExcel(payload: any): Promise<void> {
        await this.documentGeneratorService.getOutageInitiationExcelDocument(
            payload
        );
    }

    public async getOutageDocuments(
        machineSID: string,
        documentType: number,
        outageId: string,
        machineSIDs: any
    ): Promise<any> {
        return await this.documentGeneratorService.getOutageDocuments(
            machineSID,
            documentType,
            outageId,
            machineSIDs
        );
    }

    public async uploadOutageReportDocument(
        plantSID: number,
        machineSID: string,
        outageId: string,
        documentType: number,
        formData: FormData
    ): Promise<any> {
        return await this.documentGeneratorService.uploadOutageReportDocument(
            plantSID,
            machineSID,
            documentType,
            outageId,
            formData
        );
    }

    public async downloadDocument(
        documentId: number,
        isPdf: boolean,
        plantName: string
    ): Promise<any> {
        const res = await this.documentGeneratorService.downloadDocument(
            documentId,
            isPdf,
            plantName
        );
        await this.decodeAndDownloadBase64File(res.title, res.content);
    }

    public async decodeAndDownloadBase64File(
        title: string,
        encodedString: string
    ): Promise<void> {
        try {
            const label = document.createElement('a');
            label.setAttribute(
                'href',
                'data:application/octet-stream;base64,' + encodedString
            );
            label.setAttribute('download', title);
            label.style.display = 'none';
            document.body.appendChild(label);
            label.click();
            document.body.removeChild(label);
            Promise.resolve();
        } catch (error) {
            Promise.reject(error);
        }
    }
}
