import { actions, getters } from '@/store/types';
import { UnitDataForHomepage, OverviewItem } from '@/utils/interfaces';
import Component from 'vue-class-component';
import { Prop, Mixins } from 'vue-property-decorator';
import { LocaleMessage } from 'vue-i18n';
import { MasterService } from '@/services/master-service';
import { Data } from '@/utils';
import BudgetDeviationComponent from '../budget-deviation/budget-deviation.vue';
import NextOutageComponent from '../next-outage/next-outage.vue';
import PlannedCalculatedBudgetComponent from '../planned-calculated-budget/planned-calculated-budget.vue';
import TimeToMaintenanceComponent from '../time-to-maintenance/time-to-maintenance.vue';
import TableViewComponent from '../table-view/table-view.vue';
import WarningDetailsComponent from '../warning-details/warning-details.vue';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';
import { createState } from '@/utils/create-state';

interface State {
    componentsLoaded: number;
    showAsTable: boolean;
    source: CancelTokenSource;
}
interface KeyProp {
    unit: UnitDataForHomepage;
}
const IDStateComponent = createState<KeyProp, State>({
    id: (vm) => vm.unit.sid.toString(),
    data: () => ({ componentsLoaded: 0, showAsTable: false, source: {} as CancelTokenSource }),
})

@Component({
    components: {
        'time-to-maintenance': TimeToMaintenanceComponent,
        'table-view': TableViewComponent,
        'next-outage': NextOutageComponent,
        'warning-details': WarningDetailsComponent,
        'planned-calculated-budget': PlannedCalculatedBudgetComponent,
        'budget-deviation': BudgetDeviationComponent
    }
})

export default class OverviewUnitComponent extends Mixins(IDStateComponent) implements KeyProp {
    private readonly axios = require('axios');
    private readonly LARGE_SCREEN_WIDTH = 2560;
    private masterService: MasterService = MasterService.Instance;
    private data: Data = Data.Instance;
    private readonly UNIT_OVERVIEW_REF = 'mspUnitOverviewContent';
    private readonly MODULE_NAME = 'overview/overview-loader-';
    private readonly COMPONENTS_TO_BE_LOADED = 2;

    @Prop()
    unit!: UnitDataForHomepage;

    private screenSize = 0;

    get isLoaded(): boolean { return this.$store.getters[getters.OVERVIEW__GET_IS_LOADED](this.unit?.sid); }
    get hasNextOutage(): boolean { return this.$store.getters[getters.OVERVIEW__GET__HAS_NEXT_OUTAGE_BY_UNIT_ID](this.unit?.sid); }

    private get showAsTitle(): LocaleMessage {
        return (this as any).idState.showAsTable ? this.$t('showAsChart') : this.$t('showAsTable');
    }

    private get screenSizeLarge(): boolean {
        return this.screenSize >= this.LARGE_SCREEN_WIDTH;
    }

    get unitData(): OverviewItem { return this.$store.getters[getters.OVERVIEW__UNIT_DATA_BY_ID](this.unit?.sid); }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
        (this as any).idState.source.cancel && (this as any).idState.source.cancel(CANCEL_MESSAGE);
    }

    async created(): Promise<any> {
        await this.load();
    }

    async load(): Promise<void> {
        try {
            (this as any).idState.source.cancel && (this as any).idState.source.cancel(CANCEL_MESSAGE);
            (this as any).idState.source = this.axios.CancelToken.source();
            this.unitData || await this.$store.dispatch(actions.OVERVIEW__SET_UNIT_DATA,
                await this.masterService.overviewService.getOverviewDataByUnitId(this.data?.selected?.plant?.sid || 0, this.unit?.sid || 0, (this as any).idState.source.token));
        }
        catch (error) {
            if (this.axios.isCancel(error)) {
                return;
            }
        }
        finally {
            this.handleComponentLoaded();
        }
    }

    mounted(): void {
        window.addEventListener('resize', this.handleResize);
        this.screenSize = window.innerWidth;
        if (!this.isLoaded) {
            this.$store.commit(this.MODULE_NAME + this.unit?.sid + '/START');
        }
    }

    private handleComponentLoaded(): void {
        (this as any).idState.componentsLoaded++;
        if ((this as any).idState.componentsLoaded === this.COMPONENTS_TO_BE_LOADED) {
            this.$store.commit('overview/overview-loader-' + this.unit?.sid + '/STOP');
            this.$store.dispatch(actions.OVERVIEW__SET_IS_LOADED, this.unit?.sid);
            this.$nextTick(() => {
                this.emitHeightChanged();
            });
        }
    }

    private handleResize(): void {
        this.screenSize = window.innerWidth;
        this.$nextTick(() => {
            this.emitHeightChanged();
        });
    }

    private toggleShowAsTable(): void {
        (this as any).idState.showAsTable = !(this as any).idState.showAsTable;
        this.$nextTick(() => {
            this.emitHeightChanged();
        });
    }

    private handleCollapsibleToggle(e: any): void {
        this.$emit('collapsed', !e.isOpen, this.unit);
        if (e.isOpen) {
            this.$nextTick(() => {
                this.emitHeightChanged();
            });
        }
    }

    private emitHeightChanged(): void {
        this.$nextTick(() => {
            if (this.$refs[this.UNIT_OVERVIEW_REF + this.unit?.sid] && this.isLoaded) {
                this.$emit('emitHeightChanged', (this.$refs[this.UNIT_OVERVIEW_REF + this.unit?.sid] as any).clientHeight, this.unit);
            }
        });
    }
}
