import { Connection, Constants } from '@/utils';

export class PlantItemOperatingParametersService {
    private api: Connection = new Connection();

    public async getOperatingHours(
        plantItemId = 0,
        startYear: number,
        endYear: number
    ): Promise<any> {
        return (await this.api.get(Constants.PLANT_ITEM_OP_PARAMETERS.MAPPING + plantItemId + '/' + startYear + '/' + endYear)).data.result;
    }

    public async updateOperatingHours(
        plantItemId: number,
        oh: any
    ): Promise<any> {
        return (await this.api.put(Constants.PLANT_ITEM_OP_PARAMETERS.MAPPING + plantItemId, oh)).data.result;
    }
}
