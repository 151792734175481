import { Connection } from '@/utils';
import { ActivityLogParameters, ChangeLog } from '@/utils/interfaces';

export class ChangeLogService {
    private api: Connection = new Connection();
    public async getChangeLogs(
        params: ActivityLogParameters
    ): Promise<ChangeLog> {
        const query = {
            page: params.page,
            number: params.number,
            sortDescending: params.sortDescending,
            plantSidFilter: params.plantSidFilter,
            machineSidFilter: params.machineSidFilter,
            emailFilter: params.emailFilter,
            plantSids:  params.plantSids,
            actionFilter: params.actionFilter,
        };
        return (
            await this.api.post(`change-logs`, query)
        ).data.result;
    }


    public async getFilter(groupId: number): Promise<any> {
        return (await this.api.get(`ChangeLogs/Filter/${groupId}`)).data.result;
    }
}
