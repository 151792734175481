import { Module } from 'vuex';
import { Plant, PlantAttributes, GroupPlantMap } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    plants: [] as Plant[],
    plantsWithAttributes: [] as PlantAttributes[],
    groupPlantMap: {} as GroupPlantMap
});

const PlantModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setPlants(state, plants): void {
            state.plants = plants;
        },
        setPlantsWithAttributes(state, plantsWithAttributes): void {
            state.plantsWithAttributes = plantsWithAttributes;
        },
        resetPlants(state): void {
            state.plants = [...getDefaultState().plants];
        },
        resetPlantsWithAttributes(state): void {
            state.plantsWithAttributes = [...getDefaultState().plantsWithAttributes];
        },
        setGroupSidPlantMap(state, { plants, groupSid }): void {
            state.groupPlantMap[groupSid] = plants;
        }
    },

    actions: {
        setPlants({ state, commit }, { plants, groupSid, force }): void {
            if (!state.groupPlantMap[groupSid] || force) {
                commit('setGroupSidPlantMap', { plants, groupSid });
                commit('setPlants', plants);
            } else {
                const plants = state.groupPlantMap[groupSid];
                commit('setPlants', plants);
            }
        },
        setPlantsWithAttributes({ commit }, payload): void {
            commit('setPlantsWithAttributes', payload);
        },
        resetPlants({ commit }): void {
            commit('resetPlants');
        },
        resetPlantsWithAttributes({ commit }): void {
            commit('resetPlantsWithAttributes');
        }
    },

    getters: {
        getPlants(state): Plant[] {
            return state.plants;
        },
        getPlantsWithAttributes(state): Plant[] {
            return state.plantsWithAttributes;
        },
        getGroupSidPlantsMap(state): GroupPlantMap {
            return state.groupPlantMap;
        }
    },
    namespaced: true
}

export default PlantModule;
