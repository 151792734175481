import { Connection, Constants } from '@/utils';
import { CancelToken } from 'axios';
import { RemainingLifeSummary, PlantItemPayload } from '@/utils/interfaces';

export class RemainingLifeService {
    private api: Connection = new Connection();

    public async getRemainingLife(payload: PlantItemPayload, cancelToken: CancelToken): Promise<RemainingLifeSummary> {
        return (
            await this.api.post(Constants.REMAINING_LIFE.MAPPING,
                payload,
                { cancelToken }
            )
        ).data.result;
    }
}
