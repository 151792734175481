/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/cognitive-complexity */
import { MasterService } from '@/services/master-service';
import { Constants } from '@/utils/constants';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, CurrencyHelper, OHHelper, GenevaHelper, TabHandler, ACMHelper, Helper, DataInputHelper } from '@/utils';
import { ModalCommon } from '@/components/table/modal-common';
import {
    Unit, DbUnit, EnerlyticsUnit, UnitValidate,
    Plant, UnitPlanningObject,
    OH, OperatingHours, OperatingHoursYear,
    Geneva, GenevaOperatingHours, GenevaOperatingHoursYear,
    TabElement, TabItem, SelectItem,
} from '@/utils/interfaces';
import { EventBus } from '@/utils/eventbus';
import ConfirmSnackbarComponent from '@/components/confirm-snackbar/confirm-snackbar.vue';
import TypeaheadComponent from '@/components/type-ahead/type-ahead.vue';
import AppSecurity from '@/utils/app-security';
import { format } from '@enerlytics/time-helper/dist/date-fns';
import { Watch } from 'vue-property-decorator';
import { DateHelper } from '@/utils/date-helper';
import { ValidationValue, ValidationType, ValidationConfig } from '@/utils/data-input-helper';
import { actions, getters } from '@/store/types';

@Component({
    components: {
        confirmSnackbar: ConfirmSnackbarComponent,
        typeahead: TypeaheadComponent
    }
})
export default class UnitModalComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private appSecurity = new AppSecurity();
    private data: Data = Data.Instance;
    private acmHelper: ACMHelper = new ACMHelper();
    private tabs = this.buildTabs();
    private tabHandler = new TabHandler(this.tabs);

    /* switches */
    private showResultBox = false;
    private resultError = false;
    private confirmSnackbarOpen = false;
    private showUnvisitedTabsMessage = false;
    private loading = false;
    private deleteSnackbarOpen = false;
    private userHasWriteAccessToPlant = false;

    /* data */
    private unit: Unit = { enablePiConnection: false } as Unit;
    private unitBackup: Unit = {} as Unit;
    private unitPlanning: UnitPlanningObject[] = [];
    private unitOperatingHours: OperatingHours = {} as OperatingHours;
    private genevaOperatingHours: GenevaOperatingHours = {} as GenevaOperatingHours;
    private result = '';
    private validate: UnitValidate | null;
    private currencyHelper: CurrencyHelper = new CurrencyHelper();
    private modalCommon: ModalCommon = new ModalCommon();
    private waitingForSave: boolean;
    private genevaYearValue = {} as Geneva;
    private unitOHYearValue = {} as OH;
    private selector = '#unit-modal';
    private fuelTypes = this.getFuelTypes();
    private operatingRegimeTypes = this.getOperatingRegimeTypes();
    private technologyTypes = this.getTechnologyTypes();
    private unitPiTags = [];
    private planningItemNeedingConfirmation: UnitPlanningObject | null = null;
    private expertViewDate: string | null = null;
    private enerlyticsUnits: EnerlyticsUnit[] = [];
    private genevaEOHFactorItemChanged = false;
    private commissioningDateItemChanged = false;
    private plannedEndDateItemChanged = false;
    private errorMessage = '';
    private structureLoading = false;

    /* refs */
    $refs!: {
        unitModal: Pui.Lightbox;
    }

    get genevaData(): GenevaOperatingHours { return this.$store.getters[getters.GENEVA__GET_GENEVA]; }

    private readonly DATE_FORMAT = 'YYYY-MM-DD HH:mm';
    private readonly DATE_FORMAT_TO_DISPLAY = 'yyyy-MM-dd HH:mm';

    private async mounted(): Promise<void> {
        this.$nextTick(async () => {
            this.load();
            this.userHasWriteAccessToPlant = this.data.selected.plant
                ? await this.appSecurity.hasWriteAccessToPlant(this.data.selected.plant.plantSID) || await this.appSecurity.hasAdminRights()
                : false;
        });
    }

    @Watch('data.selected.plant')
    private async reloadUserHasWriteAccess(newVal: Plant): Promise<void> {
        this.userHasWriteAccessToPlant = newVal
            ? await this.appSecurity.hasWriteAccessToPlant(newVal.plantSID) || await this.appSecurity.hasAdminRights()
            : false;
    }

    private loadTabs = async (tab: TabItem): Promise<void> => {
        this.collapseAccordions();
        if (!this.tabs[tab.name].visited) {
            this.loading = true;
            await this.tabs[tab.name].load();
            this.loading = false;
        }
    }

    constructor() {
        super();
        this.unit = {} as Unit;
        this.validate = {} as UnitValidate;
        this.waitingForSave = false;
        this.loading = false;
        this.structureLoading = false;
    }

    private handleStartDateChanged(planningItem: UnitPlanningObject): void {
        planningItem.startDateChanged = true;
        this.onDateChange(planningItem);
    }

    private handleEndDateChanged(planningItem: UnitPlanningObject): void {
        planningItem.endDateChanged = true;
        this.onDateChange(planningItem);
    }

    private handleCommissioningDateItemChanged(): void {
        this.commissioningDateItemChanged = true;
    }

    private handlePlannedEndDateItemChanged(): void {
        this.plannedEndDateItemChanged = true;
    }

    private handlegenevaEOHFactorItemChanged(): void {
        this.genevaEOHFactorItemChanged = true;
    }

    private get warningMessage(): string {
        if (this.isInputInvalid(this.unit.genevaEOHFactor, true, 1) && this.errorFields.length === 0) {
            return `${this.$t('warning')}: ${this.$t('warningConversionFactor')}`;
        }
        return '';
    }

    private get subunitCheckWarningMessage(): string {
        if (this.unit.isSubUnit) {
            return `${this.$t('subunitCheckWarning')}`;
        }
        return '';
    }

    private get errorFields(): string[] {
        const errorFields: string[] = [];
        const fieldsToValidate = {
            'commissioningDate': this.isInputInvalid(this.unit.commissioningDate, this.commissioningDateItemChanged),
            'grossCapacity': this.isInputInvalid(this.unit.grossCapacity, true, 3),
            'minCapacity': this.isInputInvalid(this.unit.minCapacity, true, 3),
            'maxCapacity': this.isInputInvalid(this.unit.maxCapacity, true, 3),
            'outageCycle': this.isInputInvalid(this.unit.outageCycle, true, 3),
            'plannedEndDate': this.isInputInvalid(this.unit.plannedEndDate, this.plannedEndDateItemChanged),
        }
        for (const [name, value] of Object.entries(fieldsToValidate)) {
            const fieldIndex = errorFields.indexOf(name);
            if(value && fieldIndex === -1) {
                errorFields.push(name);
            } else if (!value && fieldIndex > -1) {
                errorFields.splice(fieldIndex, 1);
            }
        }
        return errorFields;
    }

    private get errorFieldMessage(): string {
        if(this.errorFields.length === 0) {
            return '';
        }
        const fields = this.errorFields.map(field => this.$t(field)).join(', ');
        return this.$t('errorUpdateFields', { fields });
    }

    private isInputInvalid(
        value: ValidationValue,
        inputChanged: boolean,
        inputType: ValidationType = 0
    ): boolean {
        const validationConfig: ValidationConfig = {
            0: (value === undefined || value === null || value === '' || value === -1),
            1: (value === undefined || value === null || value === '' || value <= 0),
            2: (value === undefined || value === null || value === ''),
            3: (value === undefined || value === null || value === '' || value <= 1),
        };

        return inputChanged && validationConfig[inputType];
    }

    private areDatesValid(firstDate: string, firstDateChanged: boolean, secondDate: string, secondDateChanged: boolean): boolean {
        if (this.isInputInvalid(firstDate, firstDateChanged, 2) ||
            this.isInputInvalid(secondDate, secondDateChanged, 2) ||
            new Date(firstDate).getTime() < new Date(secondDate).getTime()) {
            return true;
        }
        return false;
    }

    private isPiConnectionValid(): boolean {
        return this.unit.enablePiConnection
            ? !this.isInputInvalid(this.unit.piId, true, 0)
            : true
    }

    public async openModalForEdit(item: Unit | null): Promise<void> {
        this.setInputChanged(false);
        this.$refs.unitModal?.open();

        if (item !== null) {
            this.unit = item;
            this.validate = {} as UnitValidate;
            this.result = '';
            this.showResultBox = false;
            this.resultError = false;
            this.showUnvisitedTabsMessage = false;
            this.unitPlanning = [];
            this.resetMainTab();
            this.unitBackup = Object.assign({}, this.unit);
        } else {
            this.unit.acmCode = null;
            this.unit.genevaEOHFactor = 0;
        }

        if (!this.unit.enablePiConnection) {
            this.unit.piStartDate = DateHelper.formatISOShort();
        }

        await this.getUnits();
    }

    private getValidateObject(name: string): boolean {
        return DataInputHelper.getValidateObject(name, this.validate);
    }

    private async load(): Promise<void> {
        await this.initEventListeners();
    }

    private async getUnits(): Promise<void> {
        if (this.data.selected.plant && this.data.selected.unit && this.masterService.enerlyticsService?.siteData && (Object.keys(this.masterService.enerlyticsService?.siteData).length === 0 || this.masterService.enerlyticsService?.siteData?.units.length === 0)) {
            this.structureLoading = true;
            try {
                const res = await this.masterService.enerlyticsService.getUnits(this.data.selected.plant.enerlyticsSiteId, this.data.selected.unit.id);
                if (!res) {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: this.$t('enerlyticsCallFailed')
                    });
                }
            } catch (error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$t('enerlyticsCallFailed')
                });
            }
            this.structureLoading = false;
        }

        /* if no data was provided from enerlytics */
        if (this.data.selected.plant && !this.data.selected.plant.enerlyticsSiteId ||
            !this.masterService.enerlyticsService?.siteData ||
            Object.keys(this.masterService.enerlyticsService?.siteData).length === 0 ||
            this.masterService.enerlyticsService?.siteData?.units.length === 0) {
            this.enerlyticsUnits = [{ unit_id: '', unit_name: 'N/A' }];
            return;
        }

        if (this.data.selected.plant) {
            switch (this.data.selected.plant.enerlyticsSiteId) {
                case Constants.HARDCODED_PLANTS.STAUDINGER:
                    this.enerlyticsUnits = [{ unit_id: '', unit_name: Constants.HARDCODED_UNITS.STAUDINGER }];
                    break;
                case Constants.HARDCODED_PLANTS.GONYU:
                    this.enerlyticsUnits = [{ unit_id: '', unit_name: Constants.HARDCODED_UNITS.GONYU }];
                    break;
                case Constants.HARDCODED_PLANTS.GRAIN:
                    const filteredList = this.masterService.enerlyticsService.siteData.units.filter((item) => (item as unknown as EnerlyticsUnit).unit_id !== Constants.HARDCODED_UNIT_IDS.GRAIN.COMMON_SYSTEMS);
                    this.enerlyticsUnits = [{ unit_id: '', unit_name: 'N/A' }, ...Helper.sortAlphabetically(filteredList, 'unit_name')];
                    break;
                case Constants.HARDCODED_PLANTS.DEN_HAAG:
                    this.enerlyticsUnits = [{ unit_id: '', unit_name: Constants.HARDCODED_UNITS.DEN_HAAG }];
                    break;
                case Constants.HARDCODED_PLANTS.LEIDEN:
                    this.enerlyticsUnits = [{ unit_id: '', unit_name: Constants.HARDCODED_UNITS.LEIDEN }];
                    break;
                case Constants.HARDCODED_PLANTS.ROCA:
                    this.enerlyticsUnits = [
                        { unit_id: Constants.HARDCODED_UNIT_IDS.ROCA.COMMON_SYSTEMS[0], unit_name: Constants.HARDCODED_UNITS.ROCA_COMMON_SYSTEMS },
                        { unit_id: Constants.HARDCODED_UNIT_IDS.ROCA.U3[0], unit_name: Constants.HARDCODED_UNITS.ROCA3 },
                    ]
                    break;
                case Constants.HARDCODED_PLANTS.IRSCHING:
                    this.enerlyticsUnits = [
                        { unit_id: '', unit_name: 'N/A' },
                        { unit_id: Constants.HARDCODED_UNIT_IDS.IRSCHING.U4, unit_name: Constants.HARDCODED_UNITS.IRSCHING_U4 },
                        { unit_id: Constants.HARDCODED_UNIT_IDS.IRSCHING.U5[0], unit_name: Constants.HARDCODED_UNITS.IRSCHING_U5 },
                    ]
                    break;
                default:
                    this.enerlyticsUnits = [{ unit_id: '', unit_name: 'N/A' }, ...Helper.sortAlphabetically(this.masterService.enerlyticsService.siteData.units, 'unit_name')];
                    break;
            }
        }
    }

    private async initEventListeners(): Promise<void> {
        EventBus.$on(EventBus.UNIT.OPEN_UNIT_MODAL, this.handleOpenUnitModal);
        EventBus.$on(EventBus.UNIT.INIT_MD, this.handleInitModal);
        EventBus.$on(EventBus.UNIT.INIT, this.handleInit);
        this.$on('closeConfirmSnackbar', this.handleCloseConfirmSnackbar);
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.UNIT.OPEN_UNIT_MODAL, this.handleOpenUnitModal);
        EventBus.$off(EventBus.UNIT.INIT_MD, this.handleInitModal);
        EventBus.$off(EventBus.UNIT.INIT, this.handleInit);
        this.$off('closeConfirmSnackbar', this.handleCloseConfirmSnackbar);
    }

    private initWithMDInformation(): void {
        /* init name and sid received from MD in 404 case */
        if (this.data.selected.unit) {
            this.unit.name = this.data.selected.unit.name;
            this.unit.machineSID = this.data.selected.unit.sid.toString();
        }

    }

    private async initUnit(): Promise<void> {
        await this.resetMainTab();
        if (this.data.selected.plant) {
            this.unit = {} as Unit;
            this.validate = {} as UnitValidate;
            this.unit.plantId = this.data.selected.plant.id;
            this.unit.acmCode = null;
            this.unit.genevaEOHFactor = 0;
            this.unit.enableForecast = false;
            this.unit.enablePiConnection = false;
            this.unit.piId = '';
            this.unit.piStartDate = DateHelper.formatISOShort();
            this.result = '';
            this.showResultBox = false;
            this.resultError = false;
            this.showUnvisitedTabsMessage = false;
            this.unitPlanning = [];
        }

        this.initWithMDInformation();
        await this.getUnits();
    }

    private async populateUnitModal(): Promise<void> {
        EventBus.$emit(EventBus.UNIT.OPEN_UNIT_MODAL, this.data.selected.unit);
        if (!this.unit.enablePiConnection) {
            this.unit.piStartDate = DateHelper.formatISOShort();
        }
        await this.getUnits();
    }

    public switchPIConnection(event: Event): void {
        const { checked } = event.target as HTMLInputElement;

        if (checked) {
            this.unit.piStartDate = DateHelper.formatISOShort();
        }
    }

    private async resetMainTab(): Promise<void> {
        this.tabHandler.resetTabs();
        await this.tabHandler.toggleTab(this.tabs.item, [], false, true);
    }

    private triggerErrorMessage(message: string): void {
        /* emit notification for user */
        EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
            class: 'error',
            message
        });
    }

    private async addUnit(): Promise<void> {
        this.setInputChanged();
        let startDateInvalid = false;
        let endDateInvalid = false;
        let outageIdInvalid = false;
        let costInvalid = false;
        for (const item of this.unitPlanning) {
            if (this.isInputInvalid(item.startDate, item.startDateChanged || true)) {
                startDateInvalid = true;
                break;
            }
            if (this.isInputInvalid(item.endDate, item.endDateChanged || true)) {
                endDateInvalid = true;
                break;
            }
            if (this.isInputInvalid(item.outageId, item.outageIdChanged || true)) {
                outageIdInvalid = true;
                break;
            }
            if (this.isInputInvalid(item.cost, item.costChanged || true)) {
                costInvalid = true;
                break;
            }
            if(item.extensionDays?.toString() === '') {
                item.extensionDays = undefined;
            }
            if(item.technicalProbability?.toString() === '') {
                item.technicalProbability = undefined;
            }
        }
        if (!this.isPiConnectionValid()) {
            this.errorMessage = !this.tabs.unitOH.active ? this.$t('tabValidationIssues', { tabName: this.$t('oh') }).toString() : '';
            return;
        }
        if (this.isInputInvalid(this.unit.commissioningDate, this.commissioningDateItemChanged) ||
            this.isInputInvalid(this.unit.plannedEndDate, this.plannedEndDateItemChanged) ||
            !this.areDatesValid(this.unit.commissioningDate, this.commissioningDateItemChanged, this.unit.plannedEndDate, this.plannedEndDateItemChanged) ||
            this.isInputInvalid(this.unit.minCapacity, true, 3) ||
            this.isInputInvalid(this.unit.maxCapacity, true, 3) ||
            this.isInputInvalid(this.unit.grossCapacity, true, 3) ||
            this.isInputInvalid(this.unit.outageCycle, true, 3) ||
            startDateInvalid || endDateInvalid || outageIdInvalid || costInvalid) {
            if (startDateInvalid || endDateInvalid || outageIdInvalid || costInvalid) {
                this.errorMessage = !this.tabs.planning.active ? this.$t('tabValidationIssues', { tabName: this.$t('planning') }).toString() : '';
            } else {
                this.errorMessage = !this.tabs.item.active ? this.$t('tabValidationIssues', { tabName: this.$t('unit') }).toString() : this.errorFieldMessage;
            }
            return;
        }

        if (this.editingPlanning()) {
            this.confirmSnackbarOpen = true;
            return;
        }

        if (this.tabHandler.hasUnvisitedTabs() && this.unit.id === undefined) {
            this.showUnvisitedTabsMessage = true;
            return;
        }
        this.showUnvisitedTabsMessage = false;
        this.waitingForSave = true;
        this.showResultBox = false;
        try {
            await this.$store.dispatch(actions.UNIT__RESET_UNIT_WITH_ATTRIBUTES);

            if(this.unit.genevaEOHFactor === '') {
                this.unit.genevaEOHFactor = 0;
            }

            if (this.unit.id > 0) {
                await this.masterService.unitService.editUnit(this.unit.id, {
                    commissioningDate: this.unit.commissioningDate,
                    fuelType: this.unit.fuelType,
                    grossCapacity: this.unit.grossCapacity,
                    minCapacity: this.unit.minCapacity,
                    maxCapacity: parseInt(this.unit.maxCapacity.toString()),
                    outageCycle: this.unit.outageCycle,
                    plannedEndDate: this.unit.plannedEndDate,
                    summerOperatingRegime: this.unit.summerOperatingRegime,
                    winterOperatingRegime: this.unit.winterOperatingRegime,
                    machineSID: this.unit.machineSID,
                    genevaEOHFactor: this.unit.genevaEOHFactor,
                    technology: this.unit.technology,
                    enablePiConnection: this.unit.enablePiConnection,
                    piStartDate: this.unit.piStartDate,
                    piId: this.unit.piId,
                    enableForecast: this.unit.enableForecast,
                    isSubUnit: this.unit.isSubUnit,
                    unitMappingId: this.unit.id,
                    enerlyticsId: this.unit.enerlyticsId
                });
            } else {
                const result = await this.masterService.unitService.addUnit({
                    commissioningDate: this.unit.commissioningDate,
                    fuelType: this.unit.fuelType,
                    grossCapacity: this.unit.grossCapacity,
                    minCapacity: this.unit.minCapacity,
                    maxCapacity: this.unit.maxCapacity,
                    outageCycle: this.unit.outageCycle,
                    plannedEndDate: this.unit.plannedEndDate,
                    summerOperatingRegime: this.unit.summerOperatingRegime,
                    winterOperatingRegime: this.unit.winterOperatingRegime,
                    machineSID: this.unit.machineSID,
                    genevaEOHFactor: this.unit.genevaEOHFactor,
                    technology: this.unit.technology,
                    enablePiConnection: this.unit.enablePiConnection,
                    piStartDate: this.unit.piStartDate,
                    piId: this.unit.piId,
                    enableForecast: this.unit.enableForecast,
                    isSubUnit: this.unit.isSubUnit,
                    enerlyticsId: this.unit.enerlyticsId
                });
                this.unit = { ...result };
                if (this.data.selected.unit) {
                    this.unit = { ...this.unit, name: this.data.selected.unit.name, sid: this.data.selected.unit.sid };
                }
            }

            this.data.selected.endDate = new Date(this.unit.plannedEndDate).getFullYear();
            const success = await this.saveRelatedData(this.unit);

            // Do not close the modal if the related data has not been saved.
            if (!success) {
                this.data.selected.unit = this.unit;
                this.data.saveSelected();
                this.waitingForSave = false;
                return;
            }

            EventBus.$emit(EventBus.GLOBAL.REFRESH);
            EventBus.$emit(EventBus.UNIT.ADD_TRIGGER, { ...this.unit, name: this.unit.name });
            this.$refs.unitModal.close();
        } catch (error) {
            if (error.response) {
                const { errors } = error.response.data;

                if ((Object.keys(errors).length === 1 && errors['PiStartDate']) ||
                    (Object.keys(errors).length === 1 && errors['PiId']) ||
                    (Object.keys(errors).length === 2 && errors['PiStartDate'] && errors['PiId'])) {
                    this.tabHandler.toggleTab(this.tabs.unitOH);
                } else {
                    this.tabHandler.toggleTab(this.tabs.item);
                }
                this.triggerErrorMessage(errors[Object.keys(errors)[0]][0]);
            } else if (error.message) {
                DataInputHelper.showResult(error.message, true, this);
            }
        }
        this.waitingForSave = false;
    }

    private confirmDeleteUnit(): void {
        if (!this.userHasWriteAccessToPlant) {
            return;
        }

        this.deleteSnackbarOpen = true;
    }

    private async deleteUnit(): Promise<void> {
        if (!this.userHasWriteAccessToPlant) {
            return;
        }

        try {
            if (this.data.selected.plant) {
                await this.masterService.unitService.deleteUnit(this.data.selected.plant.plantSID, this.unit.id);
                this.$refs.unitModal?.close();
                EventBus.$emit(EventBus.UNIT.ADD_TRIGGER, null);
            }   // Will set the selected unit to the first unit of the plant.
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorDeletingUnit')
            });
        }
    }

    private machineSIDChange(): void {
        this.tabs.geneva.visited = false;
        this.tabs.unitOH.visited = false;
    }

    /**
     * *******************************************************
     * BUDGET
     * *******************************************************
     */

    private async loadPlanning(): Promise<void> {
        const unitId = this.unit.id ? this.unit.id : null;
        if (unitId === null) {
            return;
        }

        if (this.data.selected.unit) {

            const startYear = this.unit.id && DateHelper.getYear(this.data.selected.unit.commissioningDate);
            const endYear = this.unit.id && DateHelper.getYear(this.data.selected.unit.plannedEndDate);
            const units = this.unit.isSubUnit && this.data.selected.plant ? await this.masterService.unitService.getDBUnits(this.data.selected.plant.sid) : [];
            const unitIds = units.length > 0 ? units.map((unit: DbUnit) => unit.id) : [];

            try {
                if (this.unit.isSubUnit && this.data.selected.plant) {
                    this.unitPlanning = await this.masterService.unitBudgetService.getAllForPlant(unitIds, startYear, endYear);
                } else {
                    const units = [];
                    units.push(unitId);
                    this.unitPlanning = await this.masterService.unitBudgetService.getAllForPlant(units, startYear, endYear);
                }

                if (this.unitPlanning && this.unitPlanning.length) {
                    this.unitPlanning.forEach(planning => {
                        planning.originalOutageId = planning.outageId;
                        planning.originalStartDate = planning.startDate as Date | undefined;
                        planning.startDate = this.formatDateForDisplay(planning.startDate);
                        planning.endDate = this.formatDateForDisplay(planning.endDate);
                    });
                }
            } catch (error) {
                if (error !== undefined && error.response.status !== 401) {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: this.$i18n.t('errorGettingUnitPlanning')
                    });
                }
            }
        }
    }

    private editPlanning(unitPlanning: object[], item: UnitPlanningObject): void {
        this.modalCommon.openEdit(unitPlanning, item);
    }

    private setInputChanged(value = true): void {
        this.errorMessage = '';
        this.unitPlanning.forEach(item => item.startDateChanged = value);
        this.unitPlanning.forEach(item => item.endDateChanged = value);
        this.unitPlanning.forEach(item => item.outageIdChanged = value);
        this.unitPlanning.forEach(item => item.costChanged = value);
        this.commissioningDateItemChanged = value;
        this.plannedEndDateItemChanged = value;
        this.genevaEOHFactorItemChanged = value;
    }

    private closeEditPlanning(item: UnitPlanningObject): void {
        this.setInputChanged();
        let startDateInvalid = false;
        let endDateInvalid = false;
        let outageIdInvalid = false;
        let costInvalid = false;

        this.unitPlanning.forEach(unitPlanning => {
            if (this.isInputInvalid(unitPlanning.startDate, unitPlanning.startDateChanged || true)) {
                startDateInvalid = true;
            }

            if (this.isInputInvalid(unitPlanning.endDate, unitPlanning.endDateChanged || true)) {
                endDateInvalid = true;
            }

            if (this.isInputInvalid(unitPlanning.outageId, unitPlanning.outageIdChanged || true)) {
                outageIdInvalid = true;
            }

            if (this.isInputInvalid(unitPlanning.cost, unitPlanning.costChanged || true)) {
                costInvalid = true;
            }
        })

        if (startDateInvalid || endDateInvalid || outageIdInvalid || costInvalid) {
            return;
        }

        const startDate = item.startDate ? new Date(item.startDate).getTime() : null;
        const originalStartDate = item.originalStartDate ? new Date(item.originalStartDate).getTime() : null;
        if (item.id && (startDate !== originalStartDate || item.outageId !== item.originalOutageId)) {
            this.planningItemNeedingConfirmation = item;
            ($('#unitPlanningChangeConfirmationModal') as JQuery<HTMLElement>).modal('show');
        } else {
            this.modalCommon.closeEdit(item);
        }
    }

    private cancelPlanningChange(): void {
        if (this.planningItemNeedingConfirmation) {
            this.planningItemNeedingConfirmation.startDate = this.planningItemNeedingConfirmation.originalStartDate || new Date();
            this.planningItemNeedingConfirmation.outageId = this.planningItemNeedingConfirmation.originalOutageId;
            this.modalCommon.closeEdit(this.planningItemNeedingConfirmation);
            this.planningItemNeedingConfirmation = null;
        }
    }

    private confirmPlanningChange(): void {
        this.modalCommon.closeEdit(this.planningItemNeedingConfirmation);
        this.planningItemNeedingConfirmation = null;
    }

    private confirmPlanningDelete(item: UnitPlanningObject): void {
        if (item.id) {
            this.planningItemNeedingConfirmation = item;
            ($('#unitPlanningDeleteConfirmationModal') as JQuery<HTMLElement>).modal('show');
        } else {
            this.modalCommon.deleteItem(this.unitPlanning, item);
        }
    }

    private deletePlanning(): void {
        this.modalCommon.deleteItem(this.unitPlanning, this.planningItemNeedingConfirmation);
        this.planningItemNeedingConfirmation = null;
        this.validate = null;
    }

    private async saveBudgets(unitId: number, isSubUnit: boolean): Promise<void> {
        this.unitPlanning = !isSubUnit ? this.unitPlanning.map((el) => { el.unitId = unitId; return el; }) : [];
        if (this.data.selected.unit && this.data.selected.plant) {
            await this.masterService.unitBudgetService.updateUnitPlanning(unitId,
                {
                    unitMappingId: this.data.selected.unit.id,
                    plantName: this.data.selected.plant.name,
                    unitName: this.data.selected.unit.name,
                    // eslint-disable-next-line
                    planningCommands: this.unitPlanning.map(({originalStartDate, ...rest}) => ({
                        ...rest,
                        startDate: this.formatDateForSave(rest.startDate),
                        endDate: this.formatDateForSave(rest.endDate)
                    }))
                });
        }
    }

    private getCurrencySymbol(): string {
        return this.data.selected.plant !== null
            ? this.currencyHelper.getCurrencySymbolByValue(this.data.selected.plant.currency)
            : '';
    }

    private unitPlanningNewLine(): void {
        let startDateInvalid = false;
        let endDateInvalid = false;
        let costInvalid = false;
        let outageIdInvalid = false;
        for (const planning of this.unitPlanning) {
            if (this.isInputInvalid(planning.startDate, planning.startDateChanged || true)) {
                startDateInvalid = true;
                break;
            }
            if (this.isInputInvalid(planning.endDate, planning.endDateChanged || true)) {
                endDateInvalid = true;
                break;
            }
            if (this.isInputInvalid(planning.cost, planning.costChanged || true)) {
                costInvalid = true;
                break;
            }
            if (this.isInputInvalid(planning.outageId, planning.outageIdChanged || true)) {
                outageIdInvalid = true;
                break;
            }
        }
        if (startDateInvalid || endDateInvalid || costInvalid || outageIdInvalid){
            return;
        }
        this.modalCommon.closeAllEditors(this.unitPlanning);
        if (this.data.selected.plant) {
            this.unitPlanning.push({
                id: 0,
                cost: 0,
                duration: 0,
                editMode: true,
                currency: this.data.selected.plant.currency,
                outageId: '',
                outageTitle: '',
                outageDescription: '',
                status: 'I',
                unitId: 0
            } as UnitPlanningObject);
        }
    }

    private editingPlanning(): boolean {
        return this.unitPlanning.find((el) => el.editMode === true) !== undefined;
    }

    private validateDateLimits(planning: UnitPlanningObject): void {
        const date = new Date(planning.startDate);
        if (this.data.selected.unit) {
            const lowerLimit = new Date(this.data.selected.unit.commissioningDate);
            const upperLimit = new Date(this.data.selected.unit.plannedEndDate);

            if (Helper.dateLessThan(date, lowerLimit)) {
                planning.startDate = Helper.parseStringFromDate(lowerLimit);
            } else if (Helper.dateGreaterThan(date, upperLimit)) {
                planning.startDate = Helper.parseStringFromDate(upperLimit);
            }
        }
    }

    private onDateChange(planningItem: UnitPlanningObject): void {
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const startDate = new Date(planningItem.startDate);
        const utcStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes());
        const endDate = new Date(planningItem.endDate);
        const utcEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes());

        const duration = ((utcEndDate.getTime() - utcStartDate.getTime()) / millisecondsPerDay);
        planningItem.duration = Number.isInteger(duration) ? duration : Number(duration.toFixed(1));
    }

    /**
     * *******************************************************
     * OPERATING HOURS
     * *******************************************************
     */

    private async loadOperatingHours(): Promise<void> {
        try {
            const startDate = this.unit.commissioningDate ? DateHelper.getYear(this.unit.commissioningDate) : Constants.DEFAULTS.START_YEAR;
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const endYear = currentMonth === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
            this.unitOperatingHours = await this.masterService.unitOHService.getAll(this.unit.id, startDate, endYear);
            const lastYearIndex = this.unitOperatingHours.years.length - 1;
            this.unitOperatingHours.years[lastYearIndex].months = OHHelper.filterFutureMonths(this.unitOperatingHours.years[lastYearIndex].months, currentMonth);
            this.initYears(this.unitOHYearValue, false);
        } catch (error) {
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingOH')
                });
            }
        }
    }

    private async loadUnitPiTags(): Promise<void> {
        this.unitPiTags = await this.masterService.unitService.getUnitPiTags();
    }

    private async saveUnitOperatingHours(unitId: number): Promise<void> {
        const { years } = this.unitOperatingHours;
        const startYear = this.unit.commissioningDate ? DateHelper.getYear(this.unit.commissioningDate) : Constants.DEFAULTS.START_YEAR;
        const endYear = this.unit.plannedEndDate ? DateHelper.getYear(this.unit.plannedEndDate) : Constants.DEFAULTS.CURRENT_YEAR;
        const payload = {
            years,
            startYear,
            endYear,
            unitMappingId: this.unit.id
        };
        await this.masterService.unitOHService.update(unitId, payload);
    }

    /**
     * *******************************************************
     * GENEVA
     * *******************************************************
     */

    private async loadGeneva(): Promise<void> {
        try {
            if (this.data.selected.unit) {
                const endDate = this.unit.plannedEndDate ? DateHelper.getYear(this.unit.plannedEndDate) : Constants.DEFAULTS.CURRENT_YEAR + 10;
                if (!this.genevaData.years || !this.genevaData.years.length) {
                    await this.$store.dispatch(actions.GENEVA__SET_GENEVA, await this.masterService.genevaService.getAll(this.data.selected.unit.sid, endDate));
                }
                this.genevaOperatingHours = this.genevaData;
                this.initYears(this.genevaYearValue, true);

                this.expertViewDate = (await this.masterService.genevaService.getExpertViewDate(this.data.selected.unit.sid)).expertViewDate;
            }
        } catch (error) {
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingGenevaOH')
                });
            }
        }
    }

    private async saveUnitGeneva(endDate: Date | number): Promise<void> {
        const { years } = this.genevaOperatingHours;
        if (this.data.selected.unit && this.data.selected.startDate) {
            const payload = {
                machineSID: this.data.selected.unit.sid.toString(),
                endYear: Number(endDate),
                startYear: this.data.selected.startDate,
                unitMappingId: this.data.selected.unit.id,
                years
            };
            await this.masterService.genevaService.update(this.data.selected.unit.sid, payload);
        }
    }

    /**
     * *******************************************************
     * COMMON
     * *******************************************************
     */

    private cancel(): void {
        Object.assign(this.unit, this.unitBackup);
        this.$refs.unitModal?.close();
    }

    /**
     * @description Save the related data of the unit sequentially,
     * returns true if saved successfully, false if there are errors.
     * @param unit Unit for which the related data is saved.
     * @returns boolean
     */
    private async saveRelatedData(unit: Unit): Promise<boolean> {
        if (this.tabs.planning.visited === true || unit.isSubUnit) {
            try {
                await this.saveBudgets(unit.id, unit.isSubUnit);
            } catch (error) {
                if (error) {
                    const errorMessage = error.response.data.errors.join(' ');
                    this.triggerErrorMessage(errorMessage);
                    this.validate = error.response.data;
                    this.tabHandler.toggleTab(this.tabs.planning);
                    return false;
                }
            }
        }
        if (this.tabs.unitOH.visited === true) {
            try {
                await this.saveUnitOperatingHours(unit.id);
            } catch (error) {
                if (error) {
                    this.validate = error.response.data;
                    this.tabHandler.toggleTab(this.tabs.unitOH);
                    this.expandAccordionsWithErrors(true);
                    return false;
                }
            }
        }
        if (this.tabs.geneva.visited === true) {
            try {
                if (this.data.selected.endDate) {
                    await this.saveUnitGeneva(this.data.selected.endDate);
                }
            } catch (error) {
                if (error) {
                    this.validate = error.response.data;
                    this.tabHandler.toggleTab(this.tabs.geneva);
                    this.expandAccordionsWithErrors(false);
                    return false;
                }
            }
        }
        return true;
    }

    private async toggleTab(tab: TabItem): Promise<void> {
        this.errorMessage = '';
        await this.tabHandler.toggleTab(tab, [this.loadTabs(tab)]);
        if (!this.tabHandler.hasUnvisitedTabs()) {
            this.showUnvisitedTabsMessage = false;
        }
    }

    private expandAccordionsWithErrors(oh: boolean): void {
        setTimeout(() => {
            let element = null;
            if (oh) {
                element = $('#unit-oh-accordion').find('.is-invalid').first().parent().parent().parent();
            } else {
                // geneva
                element = $('#geneva-accordion').find('.is-invalid').first().parent().parent().parent();
            }
            if (element) {
                element.addClass('show');
            }
        }, 50);
    }

    private collapseAccordions(): void {
        const modal = document.getElementById('unit-modal');
        if (modal !== null) {
            const els = modal.getElementsByClassName('collapse show');
            if (els.length > 0) {
                Array.prototype.forEach.call(els, (el) => { if (el.classList) { el.classList.remove('show'); } });
            }
        }
    }

    private initYears(yearValue: object, geneva: boolean): void {
        if (geneva) {
            GenevaHelper.initYears(this.genevaOperatingHours, yearValue as Geneva[]);
        } else {
            OHHelper.initYears(this.unitOperatingHours, yearValue as Record<number, OH>);
        }
    }

    private yearChange(year: OperatingHoursYear | GenevaOperatingHoursYear, t: number, geneva: boolean): void {
        if (geneva === true) {
            this.checkStarts(this.genevaYearValue[year.year], true);
            GenevaHelper.yearChange(year as GenevaOperatingHoursYear, t, this.genevaYearValue[year.year]);
        } else {
            this.checkStarts(this.unitOHYearValue[year.year], false);
            OHHelper.yearChange(year as OperatingHoursYear, t, this.unitOHYearValue[year.year]);
        }
    }

    private monthChange(year: OperatingHoursYear | GenevaOperatingHoursYear, geneva: boolean): void {
        if (geneva === true) {
            this.checkMonthStarts(year as GenevaOperatingHoursYear, geneva);
            GenevaHelper.monthChange(year as GenevaOperatingHoursYear, this.genevaYearValue);
        } else {
            this.checkMonthStarts(year as OperatingHoursYear, geneva);
            OHHelper.monthChange(year as OperatingHoursYear, this.unitOHYearValue);
        }
    }

    private checkStarts(object: OH | Geneva, geneva: boolean): void {
        if (geneva) {
            Helper.enforcePositiveNumber(object, 'starts');
        } else {
            Helper.enforcePositiveNumber(object, 'hotStarts');
            Helper.enforcePositiveNumber(object, 'warmStarts');
            Helper.enforcePositiveNumber(object, 'coldStarts');
        }
    }

    private checkMonthStarts(year: Record<keyof OperatingHoursYear, any>, geneva: boolean): void {
        year.months.forEach((m: OH | Geneva) => {
            geneva
                ? this.checkStarts(m as Geneva, geneva)
                : this.checkStarts(m as OH, geneva);
        });
    }

    private getOperatingRegimeTypes(): SelectItem[] {
        return [
            { label: 'baseload', value: 1 },
            { label: 'midload', value: 2 },
            { label: 'peakload', value: 3 },
            { label: 'capacityReserve', value: 4 },
            { label: 'dailyCycling', value: 5 }
        ];
    }

    private getTechnologyTypes(): SelectItem[] {
        return [
            { label: 'ccgtSingleShaft', value: 1 },
            { label: 'ccgtDoubleShaft', value: 2 },
            { label: 'ccgtCogenPlant', value: 3 },
            { label: 'circFluidizedBedBoiler', value: 4 },
            { label: 'gasFiredSteamBoiler', value: 5 },
            { label: 'pulverizedFuelDrumBoiler', value: 6 },
            { label: 'pulverizedFuelSupercritBoiler', value: 7 },
            { label: 'steamBoilerCogenPlant', value: 8 },
            { label: 'pemElectrolyzer', value: 9 },
            { label: 'alkalineElectrolyzer', value: 10 },
            { label: 'hydro', value: 11 },
        ];
    }

    private getFuelTypes(): SelectItem[] {
        return [
            { label: 'hardCoal', value: 1 },
            { label: 'lignite', value: 2 },
            { label: 'gas', value: 3 },
            { label: 'oilReserve', value: 4 },
            { label: 'biomass', value: 5 },
            { label: 'woodPellets', value: 6 },
            { label: 'renewableElectricity', value: 7 },
            { label: 'nonRenewableElectricity', value: 8 },
            { label: 'water', value: 9 },
        ];
    }

    private buildTabs(): TabElement {
        return {
            item: {
                active: true,
                visited: true,
                name: 'item',
                load: async (): Promise<void> => { }
            },
            planning: {
                active: false,
                visited: false,
                name: 'planning',
                load: async (): Promise<void> => {
                    if (this.unit.id !== undefined) {
                        await this.loadPlanning();
                    }
                }
            },
            unitOH: {
                active: false,
                visited: false,
                name: 'unitOH',
                load: async (): Promise<void> => {
                    await this.loadUnitPiTags();
                    await this.loadOperatingHours();
                }
            },
            geneva: {
                active: false,
                visited: false,
                name: 'geneva',
                load: async (): Promise<void> => {
                    await this.loadGeneva();
                }
            }
        };
    }

    private async handleOpenUnitModal(item: Unit): Promise<void> {
        if (item !== null) {
            this.unit = item;
            this.validate = {} as UnitValidate;
            this.result = '';
            this.showResultBox = false;
            this.resultError = false;
            this.showUnvisitedTabsMessage = false;
            this.unitPlanning = [];
            this.resetMainTab();
            this.unitBackup = Object.assign({}, this.unit);
        } else {
            this.unit.acmCode = null;
            this.unit.genevaEOHFactor = 0;
        }
    }

    private async handleInitModal(): Promise<void> {
        await this.initUnit();
        ($(this.selector) as JQuery<HTMLElement>).modal('show');
    }


    private async handleInit(): Promise<void> {
        await this.populateUnitModal();
        ($(this.selector) as JQuery<HTMLElement>).modal('show');
    }

    private handleCloseConfirmSnackbar(): void {
        this.confirmSnackbarOpen = false;
        this.deleteSnackbarOpen = false;
    }

    private formatDateForSave(date: string | Date): string {
        return `${format(new Date(date), 'yyyy-MM-dd')}T${format(new Date(date), 'HH:mm:ss')}`;
    }

    private formatDateForDisplay(date: string | Date): string {
        return format(new Date(date), this.DATE_FORMAT_TO_DISPLAY);
    }
}
