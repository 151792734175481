import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator';
import { Data, ACMHelper, EventBus } from '@/utils';
import { ACMData , ACMAlert, AlertFilter, DonutStyle } from '@/utils/interfaces';
import './acm-tooltip.less';

@Component({
})
export default class ACMComponent extends Vue {
    private loading = true;
    private data: Data = Data.Instance;
    private searchInput = '';
    private alertStatusOpen = false;
    private alertStatusSelectAll = false;
    private searchInputAlertStatus = '';
    private feedbackReceivedSelected = false;
    private maintenanceConsideredSelected = false;
    private maintenancePlannedSelected = false;
    private ongoingSelected = false;
    private pendingReturnServiceSelected = false;
    private requiresFeedbackSelected = false;
    private alertStatusFilter: AlertFilter[] = [];
    private alertStatusFilterFiltered: AlertFilter[] = [];
    private numberAlertStatusesFiltered = 0;
    private indexFirstAlertStatusSelected = -1;
    private viewModeValue = 'shortView';
    private acm: ACMData = {} as ACMData;
    private filteredAlerts: ACMAlert[] = [];
    private acmHelper: ACMHelper = new ACMHelper();
    private acmDonutStyles = {} as Record<string, DonutStyle>;
    private ACMHelper = ACMHelper;
    private readonly ACM_TOOLTIP_DONUT = 'acm-tooltip-donut';

    constructor() {
        super();
        this.initListener();
    }

    created(): void {
        this.alertStatusFilter = [
            { label: this.$i18n.t('statusOngoing'), value: ACMHelper.alertStatuses.ONGOING, model: this.ongoingSelected},
            { label: this.$i18n.t('statusRequiresFeedback'), value: ACMHelper.alertStatuses.REQUIRES_FEEDBACK, model: this.requiresFeedbackSelected },
            { label: this.$i18n.t('statusMaintenancePlanned'), value: ACMHelper.alertStatuses.MAINTENANCE_PLANNED, model: this.maintenancePlannedSelected },
            { label: this.$i18n.t('statusPendingReturnService'), value: ACMHelper.alertStatuses.PENDING_RETURN_TO_SERVICE, model: this.pendingReturnServiceSelected },
            { label: this.$i18n.t('statusFeedbackReceived'), value: ACMHelper.alertStatuses.FEEDBACK_RECEIVED, model: this.feedbackReceivedSelected },
            { label: this.$i18n.t('statusMaintenanceConsidered'), value: ACMHelper.alertStatuses.MAINTENANCE_CONSIDERED, model: this.maintenanceConsideredSelected }
        ];
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.TABLE.OPEN_ACM_TOOLTIP_ITEM, this.handleOpenAcmTooltipItem);
    }

    private initListener(): void {
        EventBus.$on(EventBus.TABLE.OPEN_ACM_TOOLTIP_ITEM, this.handleOpenAcmTooltipItem);
    }

    private filterClickHandler(): void {
        $('#acmModal').on('click', () => {
            this.alertStatusOpen = false;
        });
        $('#acmModal #filterPopupContainer').on('click', (event) => {
            event.stopPropagation();

            /* if the event was triggered by the input click */
            if ($(event.target).is('#acmModal .filter-status')) {
                const filterTooltipPosition = {
                    top: ($(event.target) as any).offset().top,
                    left: ($(event.target) as any).offset().left,
                    height: $(event.target).height()
                };

                $('#acmModal .filter-status-tooltip')
                    .css('left', filterTooltipPosition.left)
                    .css('top', filterTooltipPosition.top + filterTooltipPosition.height);
            }
        });
    }

    private alertStatusFilterChanged(): void {
        this.filteredAlerts = this.acm.alerts;
        // create array of selected statuses
        const statusesSelected: string[] = [];
        for (const status of this.alertStatusFilter) {
            if (status.model) {
                statusesSelected.push(status.value);
            }
        }
        // get index of first selected item
        this.indexFirstAlertStatusSelected = statusesSelected.length > 0 ? this.alertStatusFilter.findIndex(obj => obj.value === statusesSelected[0]) : -1;
        // set number of selected statuses
        this.numberAlertStatusesFiltered = statusesSelected.length;
        // if any statuses selected, filter the list of alerts
        if ( this.numberAlertStatusesFiltered > 0) {
            // set checkbox select all
            if (this.numberAlertStatusesFiltered < this.alertStatusFilter.length) {
                this.alertStatusSelectAll = false;
            } else if (this.numberAlertStatusesFiltered === this.alertStatusFilter.length) {
                this.alertStatusSelectAll = true;
            }
            // filter alerts by status
            this.filteredAlerts = this.filteredAlerts.filter(obj => statusesSelected.indexOf(obj.status) > -1);
        }
        // filter alerts by name
        if (this.searchInput.length > 1) {
            this.filteredAlerts = this.filteredAlerts.filter(obj => obj.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
        }
    }

    @Watch('searchInput')
    private filterByName(newVal: string, oldVal: string): void {
        if (newVal && newVal !== oldVal && newVal.length > 1) {
            this.alertStatusFilterChanged();
        }
    }

    @Watch('searchInputAlertStatus')
    private filterStatusesByName(newVal: string, oldVal: string): void {
        if (newVal && newVal !== oldVal && newVal.length > 0) {
            this.alertStatusFilterFiltered = this.alertStatusFilter.filter(obj => obj.label.toLowerCase().indexOf(newVal.toLowerCase()) > -1);
        } else {
            this.alertStatusFilterFiltered = this.alertStatusFilter;
        }
    }

    private toggleStatusSelects(): void {
        for (const status of this.alertStatusFilter) {
            status.model = this.alertStatusSelectAll;
        }
        if (this.alertStatusSelectAll) {
            this.filteredAlerts = this.acm.alerts;
            this.numberAlertStatusesFiltered = this.alertStatusFilter.length;
            this.indexFirstAlertStatusSelected = 0;
        } else {
            this.numberAlertStatusesFiltered = 0;
            this.indexFirstAlertStatusSelected = -1;
        }
    }

    private setRequiresFeedbackFilter(): void {
        this.alertStatusFilter.filter(obj => obj.value === ACMHelper.alertStatuses.REQUIRES_FEEDBACK).forEach(obj => obj.model = true);
        this.alertStatusFilterChanged();
    }

    private setFeedbackReceivedFilter(): void {
        this.alertStatusFilter.filter(obj => obj.value === ACMHelper.alertStatuses.FEEDBACK_RECEIVED).forEach(obj => obj.model = true);
        this.alertStatusFilterChanged();
    }

    private setAcknowledgedFilters(): void {
        this.alertStatusFilter.filter(obj => obj.value !== ACMHelper.alertStatuses.REQUIRES_FEEDBACK && obj.value !== ACMHelper.alertStatuses.FEEDBACK_RECEIVED).forEach(obj => obj.model = true);
        this.alertStatusFilterChanged();
    }

    private removeFilter(value: string): void {
        this.alertStatusFilter.filter(obj => obj.value === value).forEach(obj => obj.model = false);
        this.alertStatusFilterChanged();
    }

    private handleOpenAcmTooltipItem(item: ACMData): void {
        this.searchInput = '';
        this.alertStatusOpen = false;
        this.alertStatusSelectAll = false;
        this.searchInputAlertStatus = '';
        this.feedbackReceivedSelected = false;
        this.maintenanceConsideredSelected = false;
        this.maintenancePlannedSelected = false;
        this.ongoingSelected = false;
        this.pendingReturnServiceSelected = false;
        this.requiresFeedbackSelected = false;
        this.viewModeValue = 'shortView';
        this.acm = item;
        this.alertStatusFilterFiltered = this.alertStatusFilter;
        this.filteredAlerts = this.acm.alerts;
        this.numberAlertStatusesFiltered = 0;
        this.indexFirstAlertStatusSelected = -1;
        this.acmDonutStyles[this.ACM_TOOLTIP_DONUT] = ACMHelper.getACMDonutChart(this.acm.alerts);
        this.filterClickHandler();
    }
}
