import { Connection } from '@/utils';
import { CancelToken } from 'axios';
import { MaintenanceSummaryFilter, MaintenancePlanningFilterPayload } from '@/utils/interfaces';

export class MaintenanceSummaryService {
    private api: Connection = new Connection();
    private url = 'maintenance-summary/';
    private pmlTemplateUrl = 'pml-template/';
    public async getMaintenances(
        language: string,
        startYear: number,
        endYear: number,
        simulation: any,
        filter: MaintenancePlanningFilterPayload,
        showPlannedTotals: boolean,
        cancelToken: CancelToken
    ): Promise<any> {
        return (
            await this.api.post(this.url,
                {
                    language,
                    startYear,
                    endYear,
                    simulation,
                    showPlannedTotals,
                    ...filter,
                },
                { cancelToken }
            )
        ).data.result;
    }

    public async populateFilter(language: string, units: any[], cancelToken: CancelToken): Promise<MaintenanceSummaryFilter> {
        return (
            await this.api.post(this.url + 'filter',
                {
                    language,
                    units,
                },
                { cancelToken }
            )
        ).data.result;
    }

    public async exportFinancialView(
        startYear: number,
        endYear: number,
        filter: any,
        showPlannedTotals: boolean
    ): Promise<any> {
        const data = {
            startYear,
            endYear,
            showPlannedTotals,
            ...filter,
        };

        return (await this.api.post(this.url + 'export-financial-view', data)).data.result;
    }

    public async generatePmlTemplate(payload: any): Promise<any> {
        return (
            await this.api.post(this.pmlTemplateUrl, payload)).data.result;
    }

    public async exportComponentsStrategyView(
        startYear: number,
        endYear: number,
        filter: any,
        showPlannedTotals: boolean
    ): Promise<any> {
        const data = {
            startYear,
            endYear,
            showPlannedTotals,
            ...filter,
        };

        return (await this.api.post(this.url + 'export-component-strategy', data)).data.result;
    }
}
