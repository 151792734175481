import { MaintenanceItem, UelResponse } from '@/utils/interfaces';
import { Connection, Constants } from '@/utils';


export class PlantMaintenanceItemService {
    private api: Connection = new Connection();

    public async addPlantMaintenanceItem(item: MaintenanceItem): Promise<any> {
        return (
            await this.api.post(Constants.PLANT_ITEM_MAINTENANCE.MAPPING, item)).data.result;
    }

    public async editPlantMaintenanceItem(
        item: MaintenanceItem
    ): Promise<any> {
        return (await this.api.put(Constants.PLANT_ITEM_MAINTENANCE.MAPPING, item)).data.result;
    }

    public async deleteMaintenanceItem(itemId: number, plantSid: number): Promise<any> {
        return (
            await this.api.delete(Constants.PLANT_ITEM_MAINTENANCE.MAPPING + itemId + '/' + plantSid)).data.result;
    }

    public async getPtRisk(ptRiskNumber: string): Promise<any> {
        return (await this.api.get(Constants.PLANT_ITEM_MAINTENANCE.MAPPING + Constants.PLANT_ITEM_MAINTENANCE.PT_RISK + ptRiskNumber)).data.result;
    }

    public async getOpportunity(opportunityNumber: string): Promise<any> {
        return (await this.api.get(Constants.PLANT_ITEM_MAINTENANCE.MAPPING + Constants.PLANT_ITEM_MAINTENANCE.OPPORTUNITY + opportunityNumber)).data.result;
    }

    public async getUel(category: number | null, ptRiskNumber: string | null): Promise<UelResponse> {
        return (await this.api.get(`${Constants.PLANT_ITEM_MAINTENANCE.MAPPING}${Constants.PLANT_ITEM_MAINTENANCE.UEL}?MaintenanceCategory=${category}&PtRiskNumber=${ptRiskNumber}`)).data.result;
    }
}
