import { Plant, UnitAttributes, PlantFilter, UnitFilter, PlantFilterPayload } from '@/utils/interfaces';

export class OutageCommon {

    private static instance: OutageCommon;

    public showOutagePlanning: boolean;

    constructor() {
        this.showOutagePlanning = JSON.parse(localStorage.getItem('mspt.showOutagePlanning') as string);
    }

    public getPlantFilter(plants: Plant[], unitsAttributes: UnitAttributes[], selectAll = true, selectedPlantBusinessName = ''): PlantFilter[] {
        const plantFilter = plants.reduce((plants: PlantFilter[], plant) => {
            const existingPlant = plants.find((p) => p.name === plant.businessName);
            const units = unitsAttributes?.filter((unit) => unit.plantSid === plant.sid);
            const mappedUnits = units.map((unit) => ({
                machineSID: unit.machineSid.toString(),
                name: units?.find((mdUnit) => mdUnit.unitName === unit.unitName)?.businessAbbreviation || unit.unitName,
                selected: selectAll || plant.businessName === selectedPlantBusinessName ? true : false,
                plantId: plant.sid,
                plantName: plant.name
            }));
            if (existingPlant) {
                existingPlant.plantSIDS = existingPlant.plantSIDS.concat(plant.sid);
                existingPlant.units = existingPlant.units.concat(mappedUnits);
            } else {
                plants.push({
                    plantSIDS: [plant.sid],
                    name: plant.businessName,
                    selected: selectAll || plant.businessName === selectedPlantBusinessName ? true : false,
                    units: mappedUnits
                });
            }
            return plants.filter((plant) => plant.units.length);
        }, []);

        plantFilter.forEach((plant) => plant.units.sort((a, b) => a.name.localeCompare(b.name)));

        return plantFilter;
    }

    public setShowOutagePlanning(showOutagePlanning: boolean): void {
        this.showOutagePlanning = showOutagePlanning;
        localStorage.setItem('mspt.showOutagePlanning', this.showOutagePlanning ? 'true' : 'false');
    }
    public selectAll(value: boolean, plantFilter: PlantFilter[]): void {
        plantFilter.map(((p) => {
            p.units.map((u) => u.selected = value); p.selected = value; return p;
        }));
    }

    public buildFilter(plantFilter: PlantFilter[]): PlantFilterPayload[] {
        const result: PlantFilterPayload[] = [];

        plantFilter.forEach((p) => {
            p.units.map((u) => {
                if (u.selected === true) {
                    const unit = {
                        machineSID: u.machineSID.toString(),
                        name: u.name,
                    };
                    const existingPlant = result.find((p) => p.plantSID === u.plantId);
                    if (existingPlant) {
                        existingPlant.units = existingPlant.units.concat(unit);
                    } else {
                        result.push({
                            plantSID: u.plantId,
                            name: u.plantName,
                            units: [unit]
                        });
                    }
                }
            });
        });

        return result;
    }

    private checkIfUnitsFromPlant(plant: PlantFilter): boolean {
        let unitsSelected = false;
        plant.units.forEach((unit) => {
            if (unit.selected === true) {
                unitsSelected = true;
            }
        });

        return unitsSelected;
    }

    public buildFilterWithSelectedOption(plantFilter: PlantFilter[]): PlantFilterPayload[] {
        const result: PlantFilterPayload[] = [];

        plantFilter.forEach((p) => {
            p.units.map((u) => {
                const unit = {
                    machineSID: u.machineSID.toString(),
                    name: u.name,
                    selected: u.selected
                };
                const existingPlant = result.find((p) => p.plantSID === u.plantId);
                if (existingPlant) {
                    existingPlant.units = existingPlant.units.concat(unit);
                } else {
                    result.push({
                        plantSID: u.plantId,
                        name: u.plantName,
                        units: [unit],
                        selected: this.checkIfUnitsFromPlant(p)
                    });
                }
            });
        });

        return result;
    }

    public handleFilterUnitClick(event: Event, plant: PlantFilter, unit: UnitFilter): void {
        if (!(event.target as HTMLInputElement).checked) {
            plant.selected = false;
            return;
        }

        let everyUnitChecked = true;
        plant.units.forEach((u) => {
            if (u.machineSID !== unit.machineSID && !u.selected) {
                everyUnitChecked = false;
            }
        });

        if (everyUnitChecked) {
            plant.selected = true;
        }
    }

    public handlePlantFilterChange(event: Event, plant: PlantFilter): void {
        plant.units.forEach((u) => {
            u.selected = (event.target as HTMLInputElement).checked;
        });
    }

    public checkIfAllSelected(plantFilter: PlantFilter[]): boolean {
        let allSelected = true;
        plantFilter.forEach((p) => {
            if (p.selected === false) {
                allSelected = false;
            }
            p.units.forEach((u) => {
                if (u.selected === false) {
                    allSelected = false;
                }
            });
        });
        return allSelected;
    }
}
