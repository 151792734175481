import { ApiPaginatedResponse, ApiResponse, Application } from '@/models'
import { coodeSdk } from '@/utils/coode-sdk-config'

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService {
    public async getApps(): Promise<Application[]> {
        return (await coodeSdk.core.metaData.request.api.get<ApiResponse<ApiPaginatedResponse<Application>>>('/api/v1/use-cases')).data.result.items;
    }
}
