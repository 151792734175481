import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { EventBus } from './eventbus';
import { Data } from './data';
import { Helper } from './helper';
import { coodeSdk, MSP_SCOPE } from './coode-sdk-config'

export class MasterDataConnection {
    private static url: string | undefined = process.env.VUE_APP_MASTER_DATA;

    private eohApi: AxiosInstance;
    private data: Data;

    public constructor() {
        this.data = Data.Instance;
        this.eohApi = axios.create({
            baseURL: MasterDataConnection.url,
        });

        this.eohApi.interceptors.request.use(async (config) => {
            const token = await coodeSdk.auth.getToken(MSP_SCOPE);
            const authHeader = 'Bearer ' + token;
            config.headers['Authorization'] = authHeader;
            config.headers['Accept-Language'] = this.data.getLanguage();

            return config;
        });

        this.eohApi.interceptors.response.use(
            (success) => {
                return success;
            },
            (error) => {
                if (
                    !navigator.onLine ||
                    error === undefined ||
                    error.response === undefined ||
                    error.response.status === undefined
                ) {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: 'checkNetwork',
                        translate: true,
                    });
                } else if (
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    Helper.removeOpenBackdrops();
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: 'unauthorized',
                        translate: true,
                    });
                }
                return Promise.reject(error);
            }
        );
    }

    public get(url: string, config?: AxiosRequestConfig): Promise<any> {
        return this.eohApi.get(url, config);
    }

    public post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.eohApi.post(url, data, config);
    }

    public put(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.eohApi.put(url, data, config);
    }

    public delete(url: string, config?: AxiosRequestConfig): Promise<any> {
        return this.eohApi.delete(url, config);
    }
}
