import { TabItem, TabElement } from './interfaces';

export class TabHandler {
    private tabs: any = {};

    constructor(tabs: TabElement) {
        this.tabs = tabs;
    }

    public async toggleTab(
        tab: TabItem,
        customLogic: any[] = [],
        load = false,
        firstLoad = false
    ): Promise<void> {
        if (this.sameTab(tab) && firstLoad === false) {
            return;
        }
        if (customLogic.length > 0) {
            await Promise.all(customLogic);
        }
        if (load) {
            await tab.load();
        }
        for (const idx in this.tabs) {
            if (this.tabs[idx] !== tab) {
                this.tabs[idx].active = false;
            }
        }
        this.tabs[tab.name].visited = true;
        this.tabs[tab.name].active = true;
    }

    public hasUnvisitedTabs(): boolean {
        for (const idx in this.tabs) {
            if (this.tabs[idx].visited === false) {
                return true;
            }
        }
        return false;
    }

    public resetTabs(): void {
        for (const idx in this.tabs) {
            this.tabs[idx].visited = false;
        }
    }

    private sameTab(tab: TabItem): boolean {
        for (const idx in this.tabs) {
            if (this.tabs[idx].active === true && this.tabs[idx] === tab) {
                return true;
            }
        }
        return false;
    }
}
