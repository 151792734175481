import Component from 'vue-class-component';
import { Prop, Mixins, Watch } from 'vue-property-decorator';
import OutageClockMixin from '@/mixins/outage-clock.mixin';
import { arc, pie } from 'd3-shape';
import { select } from 'd3-selection';
import { scaleOrdinal } from 'd3-scale';
import { nest } from 'd3-collection';
import { sum } from 'd3-array';
import { NameValuePair, PieSectionData } from '@/utils/interfaces';
import { Constants } from '@/utils';
import { PUICOLOR_WHITE } from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({
})
export default class CountDownClockComponent extends Mixins<OutageClockMixin>(OutageClockMixin) {
    @Prop({ required: true })
    radialSectionData!: NameValuePair[];

    @Prop({ required: true })
    pieSectionData!: PieSectionData[];

    @Prop({ required: false, default: 200 })
    width!: number;

    @Prop({ required: false, default: 200 })
    height!: number;

    @Prop({ required: true })
    activePhase!: string;

    @Prop({ required: true })
    activePhaseProgressValue!: number;

    @Prop({ required: true })
    activePhaseStatus!: string;

    @Prop({ required: true })
    componentId!: string;

    @Prop({ default: PUICOLOR_WHITE })
    strokeColor!: string;

    private innerCircleData = [
        {
            name: 'ActivePhase',
            value: '100',
            color: null,
        },
    ];

    private color!: Function;

    @Watch('radialSectionData')
    private watchRadialSectionData(): void {
        this.updateCountDownClock();
    }

    @Watch('pieSectionData')
    private watchPieSectionData(): void {
        this.updateCountDownClock();
    }

    mounted(): void {
        this.createCountDownClock();
    }

    /**
     * This methods returns color required to be displayed for a particular phase
     */
    getColorRange(): string[] {
        return Object.keys(Constants.PHASES_COLORS_MAP).map((key) => {
            return (Constants.PHASES_COLORS_MAP as any)[key];
        });
    }

    /**
     * This method returns the phase name as domain for the color scale
     */
    getColorDomain(): string[] {
        return Object.keys(Constants.PHASES_COLORS_MAP).map((key) => {
            return key;
        });
    }

    /**
     * This method builds the component and appends it to the dom
     */
    createCountDownClock(): void {
        const radius = Math.min(this.width, this.height) / 2;
        let pieChartData = this.pieSectionData;

        // Update the pieChartData with the data generating Gradient section.
        if (this.pieSectionData && this.pieSectionData.length > 0 && this.activePhase && this.activePhaseStatus) {
            pieChartData = this.getPieDataWithGradient(this.pieSectionData, this.activePhase);
        }

        // Update the innerCircleData withe the activePhase name.This will be be used to pick the fill color for the inner circle.
        if (this.activePhase) {
            this.innerCircleData[0].name = this.activePhase;
        }

        // Sets the color scale for the pie section
        this.color = scaleOrdinal().range(this.getColorRange()).domain(this.getColorDomain());

        // Setting the radius and generating path data for the outerRadial section
        const radialArc = arc()
            .outerRadius(radius - radius * 0.03)
            .innerRadius(radius - radius * 0.12);

        // Setting the radius and generating path data for the pie section
        const wedge = arc()
            .outerRadius(radius - radius * 0.17)
            .innerRadius(radius - radius * 0.94);

        // Setting the radius and generating path data for the activeWedge.This basically represents the active phase
        const activewedge = arc()
            .outerRadius(radius - radius * 0.19)
            .innerRadius(radius - radius * 0.92)
            .endAngle((d: any) => {
                if (d.data.status === 'Gradient' || d.data.status === this.activePhaseStatus) {
                    d.endAngle -= 0.04;
                }
                return d.endAngle;
            })
            .startAngle((d: any) => {
                if (d.data.status === 'Gradient' && this.activePhaseProgressValue === 0) {
                    d.startAngle += 0.04;
                }
                return d.startAngle;
            });

        // Setting the radius and generating path data for inner circle
        const innerarc = arc()
            .outerRadius(radius - radius * 0.95)
            .innerRadius(0);

        const pieSection = pie()
            .sort(null)
            .value(function (d: any) {
                return d.value;
            });

        const radialSection = pie()
            .sort(null)
            .value(function (d: any) {
                return d.value;
            });

        const innercircle = pie()
            .sort(null)
            .value(function (d: any) {
                return d.value;
            });

        const id = '#' + this.componentId;
        const svgId = this.componentId + '_svg';
        const svg = select(id)
            .append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('id', svgId)
            .attr('class', 'count-down-clock')
            .append('g')
            .attr('transform', 'translate(' + this.width / 2 + ',' + this.height / 2 + ')');

        // Build radialSection of countDownClock
        this.createRadialSection(svg, radialSection, radialArc);

        /**
         * Build PieSection of countDownClock
         */
        this.createPieSection(svg, pieSection, pieChartData, activewedge, wedge);

        /**
         * Creating a svg grp for the Inner Circle section
         * Setting up of path data and style
         */
        const innerCircleGroup = svg.selectAll('.innercircle').data(innercircle(this.innerCircleData as any)).enter().append('g');

        innerCircleGroup.append('path').attr('d', innerarc as any).style('fill', this.getFillColorForRadialSection);
    }

    /**
     * This methods gets called when count-down-clock related data is updated
     * This internally invokes createCountDownClock method after deleting any existing instance of count-down-clock
     */
    updateCountDownClock(): void {
        const id = '#' + this.componentId + '_svg';
        select(id).remove();
        this.createCountDownClock();
    }

    /**
     * This method builds the data for the pie section
     * @param {*} innerPieData
     * @param {*} activePhase
     * @returns {Array} Pie Data
     */
    getPieDataWithGradient(innerPieData: PieSectionData[], activePhase: string): PieSectionData[] {
        const innerPiedata = innerPieData;
        let index = 0;
        let value = 0;
        for (let i = 0; i < innerPiedata.length; i++) {
            if (innerPiedata[i].status === this.activePhaseStatus) {
                index = i;
                value = innerPiedata[i].value;
                break;
            }
        }
        const currentPhaseArr = [] as PieSectionData[];
        const currentPhaseObj = {} as PieSectionData;
        currentPhaseObj.name = activePhase;
        currentPhaseObj.status = this.activePhaseStatus;
        currentPhaseObj.value = Math.round((value * this.activePhaseProgressValue) / 100);
        currentPhaseArr.push(currentPhaseObj);

        const gradientPhaseObj = {} as PieSectionData;
        gradientPhaseObj.name = activePhase;
        gradientPhaseObj.status = 'Gradient';
        gradientPhaseObj.value = value - currentPhaseObj.value;
        currentPhaseArr.push(gradientPhaseObj);
        innerPiedata.splice(index, 1, ...currentPhaseArr);

        return innerPiedata;
    }

    /**
     * This method  constructs the Pie Section for the CountDownClock
     * Sets the style for the pie section
     */
    createPieSection(svg: any, pieSection: Function, pieChartData: PieSectionData[], activewedge: Function, wedge: Function): void {
        if (pieChartData && pieChartData.length > 0) {
            const wedgeGroup = svg.selectAll('.wedge').data(pieSection(pieChartData)).enter().append('g');

            wedgeGroup
                .append('path')
                .style('fill', this.getFillColorForPieSection)
                .style('stroke', this.getStrokeColorForPieSection)
                .style('stroke-width', this.getStrokeWidthForPieSection)
                .style('opacity', this.getOpacityForPieSection)
                .attr('d', function (d: any) {
                    return d.data.status === 'Gradient' ? activewedge(d) : wedge(d);
                });
        }
    }

    /**
     * This method constructs the radial section of count down clock
     * Sets the data and style for the radial section
     */
    createRadialSection(svg: any, radialSection: Function, radialArc: Function): void {
        if (this.radialSectionData && this.radialSectionData.length > 0) {
            const nested = nest()
                .key(function (d: any) {
                    return d.name;
                })
                .rollup((function (d: any) {
                    return sum(d, function (r: any) {
                        return r.value;
                    });
                }) as any)
                .entries(this.radialSectionData);

            nested.forEach(function (d: any) {
                const value = d.value;
                d.name = d.key;
                d.value = value;
                delete d['key'];
                delete d['values'];
            });

            const radialGroup = svg.selectAll('.arc').data(radialSection(nested)).enter().append('g');

            radialGroup
                .append('path')
                .attr('d', radialArc)
                .style('fill', this.getFillColorForRadialSection)
                .style('stroke', this.strokeColor);
        }
    }

    /**
     * This method returns the fill color for the radial section
     */
    getFillColorForRadialSection(d: any): string {
        return this.color(d.data.name);
    }

    /**
     * This method returns the fill color for the pie section
     */
    getFillColorForPieSection(d: any): string {
        return d.data.status === 'Gradient' || d.data.status === this.activePhaseStatus
            ? this.color(d.data.name)
            : this.color(d.data.status);
    }

    /**
     * This method returns stroke color for the pie section
     * @param {Object,Object}
     * @returns String
     */
    getStrokeColorForPieSection(d: any): string {
        return d.data.status === 'Gradient' ? this.color(d.data.name) : this.strokeColor;
    }

    /**
     * This method returns the stroke width for the pie section
     * @param {Object}
     * @returns String
     */
    getStrokeWidthForPieSection(d: any): string {
        return d.data.status === 'Gradient' ? '1px' : '2px';
    }

    /**
     * This method returns the opacity for the pie section
     * @param {Object}
     */
    getOpacityForPieSection(d: any): string {
        return d.data.status === 'Gradient' ? '0.25' : '1';
    }
}
