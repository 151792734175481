import { Connection } from '@/utils';

export class MaintenancePlanningService {
    private api: Connection = new Connection();

    public async getMaintenancePlanning(
        maintenanceItemId: number
    ): Promise<any> {
        return (await this.api.get('plant-item-maintenance-planning/' + maintenanceItemId)).data.result.plantItemMaintenancePlanningEntryViews;
    }

    public async updateMaintenancePlanning(
        maintenanceItemId: number,
        planningList: any
    ): Promise<any> {
        return (await this.api.put('plant-item-maintenance-planning/' + maintenanceItemId , planningList)).data.result;
    }
}
