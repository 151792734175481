import { Constants, Connection } from '@/utils';
import { coodeSdk } from '@/utils/coode-sdk-config'

export class GroupService {
    private api: Connection = new Connection();

    public async getGroups(): Promise<any> {
        return (
            await coodeSdk.core.masterData.request.api.get<any>(
                Constants.GROUPS.FLEETS + process.env.MSPT_CODE
            )
        ).data.result.items;
    }

    public async addGroup(group: any): Promise<any> {
        return (await this.api.post(Constants.GROUPS.ALL, group)).data.result;
    }

    public async editGroup(groupId: number, group: any): Promise<any> {
        return (await this.api.put(Constants.GROUPS.ALL + '/' + groupId, group))
            .data.result;
    }
}
