import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Data, EventBus } from '@/utils';
import { DateTime } from 'luxon';
import { MasterService } from '@/services/master-service';
import OutageClockComponent from './../outage-clock/clock/outage-clock.vue';
import ColumnSortingComponent from './../outage-clock/scorecard/column-sorting/column-sorting-component.vue';
import DashboardMapComponent from './map/dashboard-map.vue';
import DasboardFlagComponent from './flag/dashboard-flag.vue';
import { Outage, ScheduledMajorOutage, OutageStrategyPlantFilter, PlantAttributes, UnitAttributes, Country, GenericSort } from '@/utils/interfaces';
import { actions, getters } from '@/store/types';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';
@Component({
    components: {
        'outage-clock': OutageClockComponent,
        'column-sorting': ColumnSortingComponent,
        'dashboard-map': DashboardMapComponent,
        'dashboard-flag': DasboardFlagComponent,
    }
})

export default class HomeComponent extends Vue {
    private readonly axios = require('axios');
    private masterService: MasterService = MasterService.Instance;
    private data: Data = Data.Instance;
    private mutableOutages: ScheduledMajorOutage[] = [];
    private displayedOutages = [] as ScheduledMajorOutage[];
    private selectedCountry = '';
    private loading = false;
    private shouldDisplayTable = false;
    private activeSort: GenericSort | null = null;
    private viewButtonText: string;
    private groupSid: number | undefined;
    private source = {} as CancelTokenSource;
    private currencySymbol = this.data.getCurrencySymbol;

    get plantsWithAttributes(): PlantAttributes[] { return this.$store.getters[getters.PLANT__GET_PLANTS_WITH_ATTRIBUTES]; }
    get unitsWithAttributes(): UnitAttributes[] { return this.$store.getters[getters.UNIT__GET_UNITS_WITH_ATTRIBUTES]; }
    get outages(): Outage[] { return this.$store.getters[getters.OUTAGE__GET_OUTAGES]; }
    get countries(): Country[] { return this.$store.getters[getters.COUNTRY_GET_COUNTRIES]; }

    constructor() {
        super();
        this.viewButtonText = (this.$i18n.t('viewAll') as string);
    }

    async created(): Promise<void> {
        this.loadData();
    }

    beforeDestroy(): void {
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }

    @Watch('displayedOutages')
    private handleDisplayedOutagesChanged(newVal: ScheduledMajorOutage[], oldVal: ScheduledMajorOutage[]): void {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            EventBus.$emit(EventBus.OUTAGE_CLOCK.UPDATE_DISPLAYED_OUTAGES, [...this.displayedOutages]);
        }
    }

    @Watch('data', { deep: true })
    private handleDataChanged(newVal: Data): void {
        if (this.groupSid !== newVal?.selected?.group?.sid) {
            this.loadData();
        }
        this.groupSid = newVal?.selected?.group?.sid;
    }

    async loadData(): Promise<void> {
        try {
            this.loading = true;
            await this.loadOutages();
        } finally {
            this.loading = false;
        }
    }

    handleCountryClicked(countryCode: string): void {
        if (this.selectedCountry === countryCode) {
            this.selectedCountry = '';
            this.displayedOutages = [...this.mutableOutages];
        } else {
            this.selectedCountry = countryCode;
            this.displayedOutages = this.mutableOutages.filter((outage) => outage.countryCode === countryCode);
        }
        this.displayedOutages = this.shouldDisplayTable ? this.displayedOutages : this.displayedOutages.slice(0, 5);
    }

    async loadOutages(): Promise<void> {
        if (!this.data.selected.group) return;

        const payload: OutageStrategyPlantFilter[] = [];

        this.countries?.length || await this.$store.dispatch(actions.COUNTRY_SET_COUNTRIES, await this.masterService.countryService.getCountries());

        this.plantsWithAttributes?.length || await this.$store.dispatch(actions.PLANT__SET_PLANTS_WITH_ATTRIBUTES, await this.masterService.plantService.getPlantsWithAttributes());

        const plantIds = this.plantsWithAttributes.map((plant) => plant.plantSid);
        this.unitsWithAttributes?.length || await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes(plantIds));

        this.plantsWithAttributes.forEach((plant) => {
            const selectedUnits = this.unitsWithAttributes?.filter((unit) => unit.plantSid === plant.plantSid);
            payload.push({
                plantSID: plant.plantSid,
                name: plant.plantName,
                units: selectedUnits.map((unit) => ({
                    machineSID: unit.machineSid.toString(),
                    name: unit.unitName
                }))
            });
        });
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
        this.source = this.axios.CancelToken.source();
        try {
            this.outages?.length || await this.$store.dispatch(actions.OUTAGE__SET_OUTAGES, await this.masterService.outageClockService.getOutageClock({plants: payload}, this.axios.cancelToken ));
        } catch (error) {
            if (this.axios.isCancel(error)) {
                return;
            }
        }
        if (!this.outages?.length) {
            EventBus.$emit(EventBus.OUTAGE_CLOCK.UPDATE_DISPLAYED_OUTAGES, []);
            return;
        }
        this.mutableOutages = this.outages.map((outage) => {
            const endDate = DateTime.fromISO(outage.startDate).plus({ days: outage.duration });
            const countryName = this.plantsWithAttributes.find((plantWithAttributes) => plantWithAttributes.plantName === outage.plantName)?.countryName;
            if (endDate > DateTime.now()) {
                return {
                    ...outage,
                    endDate: endDate.toFormat('dd/MM/yyyy(hh:mm)'),
                    startDate: DateTime.fromISO(outage.startDate).toFormat('dd/MM/yyyy(hh:mm)'),
                    countryName: countryName,
                    countryCode: this.countries.find((country: Country) => country.name === countryName)?.code,
                    unitName: this.unitsWithAttributes?.find((unit) => unit.unitName === outage.unitName)?.businessAbbreviation || outage.unitName
                }
            }
        }).filter(outage => outage !== undefined) as ScheduledMajorOutage[];

        this.mutableOutages.sort((a, b) => (a.cost > b.cost ? -1 : 1));
        this.displayedOutages = [...this.mutableOutages.slice(0,5)];
    }

    private sort(fields: Array<keyof ScheduledMajorOutage>, direction: number): void {
        this.activeSort = { fields: fields, direction: direction };

        this.displayedOutages.sort(function (a, b): number {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                const firstVal = a[field] || 0;
                const secondVal = b[field] || 0;
                if (firstVal > secondVal)
                    return direction * 1;
                if (firstVal < secondVal)
                    return direction * -1;
            }
            return 0;
        });
    }

    private handleToggleViewAll(): void {
        this.selectedCountry = '';
        this.shouldDisplayTable = !this.shouldDisplayTable;
        this.viewButtonText = this.shouldDisplayTable ? (this.$i18n.t('viewLess') as string) : (this.$i18n.t('viewAll') as string);
        this.displayedOutages = this.shouldDisplayTable ? [...this.mutableOutages] : this.mutableOutages.slice(0, 5);
    }
}
