























/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */

import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { Helper } from '@/utils';

@Component({})
export default class ConfirmSnackbarComponent extends Vue {

    private id: string;

    @Prop()
    private open: boolean;

    @Prop()
    private message: any;

    @Prop()
    private callbackFunction: (() => void) | undefined;

    constructor () {
        super();
        this.id = Helper.generateGUID();
        this.open = false;
    }

    @Watch('open')
    private watchOpen(newVal: boolean, oldVal: boolean): void {
        if (newVal !== oldVal) {
            const self = this;
            if (newVal === true) {
                $('#' + self.id).addClass('open');
                this.addOverlay();
            } else {
                $('#' + this.id).removeClass('open');
                this.removeOverlay();
            }
        }
    }

    private callback(): void {
        this.$parent.$emit('closeConfirmSnackbar');
        setTimeout(() => {
            if (this.callbackFunction && typeof this.callbackFunction === 'function') {
                this.callbackFunction();
            }
        }, 250);
    }

    private dismiss(): void {
        this.$parent.$emit('closeConfirmSnackbar');
    }

    private addOverlay(): void {
        $('body').append('<div class="snackbar-overlay"></div>');
    }

    private removeOverlay(): void {
        $('.snackbar-overlay').remove();
    }
}
