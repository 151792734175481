/* eslint-disable */

import { CoodeSdk, CoodeSdkOptions, LogLevel } from '@coode/fe-sdk'

export const MSP_SCOPE = `api://${process.env.VUE_APP_CLIENT_ID}/coode`;
export const PML_SCOPE = `api://${process.env.VUE_APP_PML_CLIENT_ID}/coode`;

const coodeSdkConfig: CoodeSdkOptions = {
    environment: process.env.VUE_APP_ENVIRONMENT,
    auth: {
        msal: {
            auth: {
                clientId: process.env.VUE_APP_CLIENT_ID,
                authority: process.env.VUE_APP_AUTHORITY_URL,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: `${window.location.origin}/authorization/logged-out.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            },
            system: {
                loggerOptions: {
                    logLevel: LogLevel.Error,
                },
            },
        },
    },
    insights: {
        appInsightsConnectionString: 'InstrumentationKey=0beb7ffe-fde5-49c2-add3-86f9a7356f0a;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    },
};

export const coodeSdk = new CoodeSdk(coodeSdkConfig);
