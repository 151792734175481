
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Unit, BudgetItem, PlannedCalculatedBudgetRow } from '@/utils/interfaces';
import { getters } from '@/store/types';
import { Data } from '@/utils';
import LegendItemComponent from '../legend-item/legend-item.vue';
import {
    PUICOLOR_SEVERITY_10,
    PUICOLOR_SEVERITY_30,
    PUICOLOR_SEVERITY_60
} from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({
    components: {
        'legend-item': LegendItemComponent,
    }
})
export default class TableViewComponent extends Vue {
    private data: Data = Data.Instance;
    private currencySymbol = this.data.getCurrencySymbol;
    private readonly budgetDeviationColors = [PUICOLOR_SEVERITY_10, PUICOLOR_SEVERITY_30, PUICOLOR_SEVERITY_60];

    @Prop({ default: null })
    private unit: Unit | undefined;

    get budget(): BudgetItem { return this.$store.getters[getters.OVERVIEW__GET__BUDGET_BY_UNIT_ID](this.unit?.sid); }

    private get tableColumns(): string[] {
        const currentYear = new Date().getFullYear();
        const defaultOption = [
            currentYear.toString(),
            (currentYear + 1).toString(),
            (currentYear + 2).toString(),
            (currentYear + 3).toString()
        ];
        return this.budget && this.budget.budgetDeviationTableView && this.budget.budgetDeviationTableView.columns !== undefined ? this.budget.budgetDeviationTableView.columns : defaultOption;
    }

    private get tableRows(): PlannedCalculatedBudgetRow[] {
        const defaultOption = [
            {
                difference: -2,
                columns: []
            },
            {
                difference: -1,
                columns: []
            },
            {
                difference: 0,
                columns: []
            },
            {
                difference: 1,
                columns: []
            },
            {
                difference: 2,
                columns: []
            },
            {
                difference: 3,
                columns: []
            }
        ];
        return this.budget && this.budget.budgetDeviationTableView && this.budget.budgetDeviationTableView.rows !== undefined ? this.budget.budgetDeviationTableView.rows : defaultOption;
    }

    getLabelForCalculated(difference: number): string {
        let label = '';
        if (difference > 0) {
            label += '+';
        }
        if (difference > 1 || difference < -1) {
            label += difference + ' ' + this.$t('years');
        } else if (difference === 0) {
            label += this.$t('sameYear');
        } else {
            label += difference + ' ' + this.$t('year');
        }
        return label;
    }

    getYear(date: string): string {
        return new Date(date).getFullYear().toString();
    }

    getSeverityForMaintenance(difference: number): number {
        let severity = 60;
        if (difference < 0) {
            severity = 10;
        } else if (difference === 0) {
            severity = 30;
        }
        return severity;
    }
}
