import * as Services from '.'

export class MasterService {
    private static instance: MasterService;

    /* unit services */
    public unitService: Services.UnitService = new Services.UnitService();
    public unitOHService: Services.UnitOperatingParamsService =
        new Services.UnitOperatingParamsService();
    public unitBudgetService: Services.UnitPlanningService =
        new Services.UnitPlanningService();
    public genevaService: Services.GenevaService = new Services.GenevaService();
    public dataCopyService: Services.DataCopyService =
        new Services.DataCopyService();

    /* plant services */
    public plantService: Services.PlantService = new Services.PlantService();

    /* plant item maintenance services */
    public plantMaintenanceItemService: Services.PlantMaintenanceItemService =
        new Services.PlantMaintenanceItemService();
    public maintenanceService: Services.MaintenanceService =
        new Services.MaintenanceService();
    public driverService: Services.DriverService = new Services.DriverService();
    public maintenancePlanningService: Services.MaintenancePlanningService =
        new Services.MaintenancePlanningService();
    public maintenanceGroupService: Services.MaintenanceGroupService =
        new Services.MaintenanceGroupService();

    /* plant items services */
    public plantItemService: Services.PlantItemService =
        new Services.PlantItemService();
    public itemOhService: Services.PlantItemOperatingParametersService =
        new Services.PlantItemOperatingParametersService();
    public kksService: Services.KksService = new Services.KksService();

    /* group services */
    public groupService: Services.GroupService = new Services.GroupService();

    /* app drawer service */
    public appService: Services.AppService = new Services.AppService();

    /* enerlytics services */
    public enerlyticsService: Services.EnerlyticsService =
        new Services.EnerlyticsService();
    public acmService: Services.ACMService = new Services.ACMService();

    /* table services */
    public tableService: Services.TableService = new Services.TableService();

    /* outage summary services */
    public outageSummaryService: Services.OutageSummaryService =
        new Services.OutageSummaryService();

    /* outage strategy services */
    public outageStrategyService: Services.OutageStrategyService =
        new Services.OutageStrategyService();

    /* outage documents services */
    public outageDocumentsService: Services.OutageDocumentsService =
        new Services.OutageDocumentsService();

    /* outage scorecard services */
    public outageScorecardService: Services.OutageScorecardService =
        new Services.OutageScorecardService();

    /* outage clock services */
    public outageClockService: Services.OutageClockService =
        new Services.OutageClockService();

    /* maintenance summary overview services */
    public maintenanceSummaryService: Services.MaintenanceSummaryService =
        new Services.MaintenanceSummaryService();

    /* remaining life services */
    public remainingLifeService: Services.RemainingLifeService =
        new Services.RemainingLifeService();

    /* overview service */
    public overviewService: Services.OverviewService =
        new Services.OverviewService();

    public static get Instance(): MasterService {
        return this.instance || (this.instance = new this());
    }

    /* country services */
    public countryService: Services.CountryService =
        new Services.CountryService();

    /* file service */
    public fileService: Services.FileService =
        new Services.FileService();
}
