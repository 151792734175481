import { Module } from 'vuex';
import { MaintenanceSummaryFilter, MaintenanceSummary } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    filterData: {} as MaintenanceSummaryFilter,
    maintenanceData: {} as MaintenanceSummary
});

const MaintenanceSummaryModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setFilterData(state, filterData): void {
            state.filterData = filterData;
        },
        setMaintenanceData(state, maintenanceData): void {
            state.maintenanceData = maintenanceData;
        },
        resetState(state): void {
            Object.assign(state, getDefaultState());
        },
        resetMaintenanceData(state): void {
            state.maintenanceData = {};
        }
    },

    actions: {
        setFilterData({ commit }, filterData): void {
            commit('setFilterData', filterData);
        },
        setMaintenanceData({ commit }, maintenanceData): void {
            commit('setMaintenanceData', maintenanceData);
        },
        resetState({ commit }): void {
            commit('resetState');
        },
        resetMaintenanceData({ commit }): void {
            commit('resetMaintenanceData');
        },
    },

    getters: {
        getFilterData(state): MaintenanceSummaryFilter {
            return state.filterData;
        },
        getMaintenanceData(state): MaintenanceSummary {
            return state.maintenanceData;
        },
    },
    namespaced: true
}

export default MaintenanceSummaryModule;
