import { Module } from 'vuex';
import { Outage } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    outagesForFleet: [] as Outage[],
    outages: [] as Outage[],
});

const OutageModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setOutages(state, outages): void {
            state.outages = outages;
        },
        setOutagesForFleet(state, outages): void {
            state.outagesForFleet = outages;
        },
        resetOutagesForFleet(state): void {
            state.outagesForFleet = [];
        },
        resetState(state): void {
            Object.assign(state, getDefaultState());
        }
    },

    actions: {
        setOutages({ commit }, payload): void {
            commit('setOutages', payload);
        },
        setOutagesForFleet({ commit }, payload): void {
            commit('setOutagesForFleet', payload);
        },
        resetOutagesForFleet({ commit }): void {
            commit('resetOutagesForFleet');
        },
        resetState({ commit }): void {
            commit('resetState');
        }
    },

    getters: {
        getOutages(state): Outage[] {
            return state.outages;
        },
        getOutagesForFleet(state): Outage[] {
            return state.outagesForFleet;
        },
    },
    namespaced: true
}

export default OutageModule;
