import Component from 'vue-class-component';
import { Prop, Mixins, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import OutageClockMixin from '@/mixins/outage-clock.mixin';
import { MasterService } from '@/services/master-service';
import { Data, Constants } from '@/utils';
import { NextOutage, Unit } from '@/utils/interfaces';
import { differenceInDays } from '@enerlytics/time-helper/dist/date-fns';
import CountDownClockComponent from '../countdown-clock/countdown-clock.vue';
import { actions, getters } from '@/store/types';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';

@Component({
    components: {
        'count-down-clock': CountDownClockComponent,
    }
})
export default class NextOutageComponent extends Mixins<OutageClockMixin>(OutageClockMixin) {
    private readonly axios = require('axios');
    private masterService: MasterService = MasterService.Instance;
    private data: Data = Data.Instance;
    private source = {} as CancelTokenSource;
    private currencySymbol = this.data.getCurrencySymbol;

    private currentPhase = '';
    private currentPhaseName = '';

    @Prop()
    private unit: Unit | undefined;

    get nextOutageData(): NextOutage { return this.$store.getters[getters.OVERVIEW__GET_NEXT_OUTAGE_BY_UNIT_ID](this.unit?.sid); }

    async created(): Promise<void> {
        await this.load();
    }

    private beforeDestroy(): void {
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }

    async load(): Promise<void> {
        try {
            this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
            this.source = this.axios.CancelToken.source();
            this.nextOutageData || await this.$store.dispatch(actions.OVERVIEW__SET_NEXT_OUTAGE,
                await this.masterService.overviewService.getNextOutageByUnitId(this.data?.selected?.plant?.sid || 0, this.unit?.sid || 0, this.source.token));
        }
        catch (error) {
            if (this.axios.isCancel(error)) {
                return;
            }
        }
        finally {
            this.$emit('mspNextOutageLoaded');
        }
    }

    @Watch('nextOutageData', { immediate: true })
    private watchNextOutageData(): void {
        this.setCurrentPhaseDetails();
    }

    setCurrentPhaseDetails(): void {
        if (!this.nextOutageData || !this.nextOutageData.startDate || !this.nextOutageData.endDate) {
            return;
        }
        this.currentPhase = this.getCurrentPhase(
            this.nextOutageData.startDate,
            this.nextOutageData.endDate,
        );
        this.currentPhaseName = this.getCurrentPhaseName(
            this.nextOutageData.startDate,
            this.nextOutageData.endDate,
        );
    }

    private get hasOutageData(): boolean {
        return this.nextOutageData && this.nextOutageData.currentPhase !== null;
    }

    private get color(): string {
        return 'msp-next-outage__data__group__color--' + this.currentPhase;
    }

    private get dotColor(): { backgroundColor: string} {
        return {
            backgroundColor: (Constants.PHASES_COLORS_MAP as any)[
                this.getRadialColorName(this.nextOutageData.milestoneCompletionPercent)
            ],
        };
    }

    private get outageInfoText(): string {
        const defaultValue = this.$t('timeUntilOutage');
        return ({
            Implementation: this.$t('timeUntilFinish'),
            CloseOut: this.$t('timeAfterFinish'),
        } as any)[this.currentPhase] || defaultValue;
    }

    private get noDaysUntilOutage(): number {
        const currentDate = new Date();

        if (!this.nextOutageData) {
            return 0;
        }

        const implStartDate = new Date(this.nextOutageData.startDate);
        const implEndDate = new Date(this.nextOutageData.endDate);

        if (this.currentPhase === 'Implementation') {
            return differenceInDays(implEndDate, currentDate);
        } else if (this.currentPhase === 'CloseOut') {
            return differenceInDays(currentDate, implEndDate);
        }
        return differenceInDays(implStartDate, currentDate);
    }

    private get startDate(): string {
        if (!this.nextOutageData) {
            return '';
        }
        return DateTime.fromISO(this.nextOutageData.startDate).toFormat('dd/MM/yyyy(hh:mm)');
    }

    private get endDate(): string {
        if (!this.nextOutageData) {
            return '';
        }
        return DateTime.fromISO(this.nextOutageData.endDate).toFormat('dd/MM/yyyy(hh:mm)');
    }

    private get leadingItem(): string {
        return this.nextOutageData.leadingItemsDescription.join(', ');
    }

    private get outageDuration(): number {
        return differenceInDays(new Date(this.nextOutageData.endDate), new Date(this.nextOutageData.startDate));
    }

    private get commercialValue(): string {
        return this.getCostValue(this.nextOutageData.intrinsicCommercialValue, this.nextOutageData.extrinsicCommercialValue);
    }

    private get extensionCostValue(): string {
        return this.getCostValue(this.nextOutageData.intrinsicExtensionCost, this.nextOutageData.extrinsicExtensionCost);
    }

    private getCostValue(intrinsicCostValue: number | null, extrinsicCostValue: number | null): string {
        let commercialValue = '';
        if (intrinsicCostValue) {
            commercialValue += `IV: ${intrinsicCostValue} k${this.currencySymbol}; `;
        }
        if (extrinsicCostValue) {
            commercialValue += `EV: ${extrinsicCostValue} k${this.currencySymbol};`;
        }
        return commercialValue;
    }
}
