import { Constants, Connection } from '@/utils';
import { OperatingHours, GenevaPayload, ExpertView } from '@/utils/interfaces';

export class GenevaService {
    private api: Connection = new Connection();

    public async getAll(machineSID: number, endYear: number): Promise<OperatingHours> {
        return (
            await this.api.get(
                Constants.GENEVA.MAPPING +
                    machineSID +
                    '?startYear=' +
                    Constants.DEFAULTS.CURRENT_YEAR +
                    '&endYear=' +
                    endYear
            )
        ).data.result;
    }

    public async update(machineSID: number, geneva: GenevaPayload): Promise<void> {
        return (
            await this.api.put(Constants.GENEVA.MAPPING + machineSID, geneva)
        ).data.result;
    }

    public async getExpertViewDate(machineSID: number): Promise<ExpertView> {
        return (
            await this.api.get(
                Constants.GENEVA.MAPPING +
                    Constants.GENEVA.EXPERT_VIEW +
                    machineSID
            )
        ).data.result;
    }
}
