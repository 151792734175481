/* eslint-disable sonarjs/no-duplicate-string */
export class Common {

    public static range(from: number, to: number, step: number): any[] {
        const result = [];
        for (let i = from; i <= to; i += step) {
            result.push(i);
        }
        return result;
    }

    public static calculateQuarter(date: Date): number {
        return Math.floor((date.getMonth()) / 3) + 1;
    }

    public static getMonthsInQuarter(quarter: number): number[] {
        const months = [];
        if (quarter === 1) {
            months.push(1, 2, 3);
        } else if (quarter === 2) {
            months.push(4, 5, 6);
        } else if (quarter === 3) {
            months.push(7, 8, 9);
        } else if (quarter === 4) {
            months.push(10, 11, 12);
        }
        return months;
    }

    public static getDaysInMonth(month: number, year: number): number {
        return new Date(year, month, 0).getDate();
    }

    // Get number of days in a quarter
    public static calculateQuarterLength(quarter: number, year: number): number {
        let days = 0;
        const months = this.getMonthsInQuarter(quarter);
        for (const month of months) {
            days += this.getDaysInMonth(month, year);
        }
        return days;
    }

    public static getDaysInYear(year: number): number {
        return this.isLeapYear(year) ? 366 : 365;
    }

    public static isLeapYear(year: number): boolean {
        return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    }

    public static getNumberDaysBetween(startDate: Date, endDate: Date): number {
        const startDateDays = startDate.getFullYear() * 372 + (startDate.getMonth() + 1) * 31 + startDate.getDate();
        const daysInMonth = this.getDaysInMonth(endDate.getMonth() + 1, endDate.getFullYear());
        return (endDate.getFullYear() * 372 + (endDate.getMonth() + 1) * 31 + (endDate.getDate() * 31 / daysInMonth)) - startDateDays;
    }

    public static initHorizontalScrollHandler(): any {
        $('#vertical-scroll').scroll(function () {
            const sc: any = $('#vertical-scroll').scrollLeft();
            $('.freezeView').css('left', sc);
        });
    }
    public static initVerticalScrollHandler(): any {
        $('#vertical-scroll').scroll(function () {
            const sc: any = $('#vertical-scroll').scrollTop();
            $('.maint-table-header').css('top', sc);
            $('.summary-table-content-header').css('top', sc);
            $('.title').css('top', sc);
        });
    }

    public static getProgressBarClass(remainingLife: number): any {
        const result = ['progress-bar'];
        if (remainingLife < 33) {
            result.push('bg-danger');
        }
        else if (remainingLife < 66) {
            result.push('bg-warning');
        }
        else result.push('bg-success');
        return result;
    }

    public static getProgressBarColor(remainingLife: number): any {
        const result = ['probar'];
        if (remainingLife < 33) {
            result.push('bg-danger');
        }
        else if (remainingLife < 66) {
            result.push('bg-warning');
        }
        else result.push('bg-success');
        return result;
    }

    public static createTableHeader(instance: any): string[] {
        let result = [];
        const startYear = instance.startDate.getFullYear();
        const endYear = instance.endDate.getFullYear();
        // create header for year view
        if (instance.viewMode === instance.viewModes.YEARS) {
            result = Common.range(startYear, endYear, 1);
        }
        // create header for quarter view
        else if (instance.viewMode === instance.viewModes.QUARTERS) {
            for (let i = startYear; i <= endYear; i++) {
                for (let j = 1; j <= 4; j++) {
                    result.push('<div class="format-text-header1">Q' + j + '</div>' + '<div class="format-text-header2">' + i + '</div>');
                }
            }
        }
        // create header for monthly view
        else if (instance.viewMode === instance.viewModes.MONTHS) {
            for (let i = startYear; i <= endYear; i++) {
                for (let j = 1; j <= 12; j++) {
                    const month = new Date(startYear, j, 0).toLocaleString('en', { month: 'long' }).toLowerCase();
                    result.push(instance.$i18n.t(month) + '<br/>' + i);
                }
            }
        }
        instance.numOfCols = result.length;
        return result;
    }

    /**
     * @name getViewModes
     * @description Get the view modes for the summary pages.
     * @returns object
     */
    public static getViewModes(): any {
        return {
            YEARS: 0,
            QUARTERS: 1,
            MONTHS: 2
        };
    }

    /**
     * @name computeItemPosition
     * @description Compute the left position of a summary item in the table.
     * @param item Budget or calculated item.
     * @param startDate Start date of the item.
     * @param endDate End date of the item.
     * @param dayWidthInPixel Calculated day width in pixels.
     */
    public static computeItemPosition(item: any, startDate: any, endDate: any, dayWidthInPixel: number): any {
        let itemStartDate = new Date(item.startDate);
        if (itemStartDate < startDate)
            itemStartDate = startDate;

        const itemEndYear = new Date(item.endDate).getFullYear();
        if (itemEndYear > endDate) {
            item.width = Common.getNumberDaysBetween(itemStartDate, new Date(endDate, 11, 31)) * dayWidthInPixel;
            item.left = Common.getNumberDaysBetween(startDate, itemStartDate) * dayWidthInPixel;
        } else {
            if (new Date(item.startDate) < startDate) {
                item.width = Common.getNumberDaysBetween(itemStartDate, new Date(item.endDate)) * dayWidthInPixel;
                item.left = Common.getNumberDaysBetween(startDate, itemStartDate) * dayWidthInPixel;
            } else {
                item.width = item.duration * dayWidthInPixel;
                item.left = Common.getNumberDaysBetween(startDate, itemStartDate) * dayWidthInPixel;
            }
        }
        return item;
    }
}
