import { Constants, Connection } from '@/utils';
import { PlantItemKks } from '@/utils/interfaces';

export class PlantItemService {
    private api: Connection = new Connection();
    public async addPlantItem(plantItem: any): Promise<any> {
        return (await this.api.post(Constants.PLANT_ITEM.MAPPING, plantItem)).data.result;
    }

    public async updatePlantItem(
        plantItem: any
    ): Promise<any> {
        return (await this.api.put(Constants.PLANT_ITEM.MAPPING, plantItem)).data.result;
    }

    public async deletePlantItem(plantItemId: number): Promise<any> {
        return (await this.api.delete(Constants.PLANT_ITEM.MAPPING + plantItemId)).data.result;
    }

    public async getPlantItems(unitMappingId: number): Promise<any> {
        return (await this.api.get( Constants.PLANT_ITEM.MAPPING + Constants.PLANT_ITEM.LIST + unitMappingId)).data.result.plantItems;
    }

    public async getPlantItemSelection(unitMappingId: number): Promise<PlantItemKks[]> {
        return (await this.api.get( Constants.PLANT_ITEM.MAPPING + Constants.PLANT_ITEM.BY_UNIT + Constants.PLANT_ITEM.LIST + unitMappingId)).data.result.plantItems;
    }

    //Atonyx mappings endpoint
    public async getPlantItemPiTags(unitPiId: string): Promise<any> {
        return (await this.api.get(Constants.UNIT.MSP_ATONYX_MAPPING + Constants.UNIT.PLANT_ITEMS + unitPiId)).data.result.unitPiTags;
    }
}
