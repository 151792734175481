/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { OperatingHoursYear } from './../../../utils/interfaces';
import { DateHelper } from '@/utils/date-helper';
import { Constants } from '@/utils/constants';
import { MasterService } from '@/services/master-service';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ModalCommon } from '@/components/table/modal-common';
import { Data, OHHelper, TabHandler, DataInputHelper, ACMHelper, Helper } from '@/utils';
import '@/assets/bootstrap-treeview/js/bootstrap-treeview.js';
import '../../../assets/bootstrap-treeview/js/bootstrap-treeview.js';
import { KKS, PlantItemValidate, TabItem, OH, OperatingHours, TabElement, SelectedKks, ServerError, EnerlyticsData, KKSNode, PlantItemTransferItem } from '@/utils/interfaces';
import { EventBus } from '@/utils/eventbus';
import ConfirmSnackbarComponent from '@/components/confirm-snackbar/confirm-snackbar.vue';
import AppSecurity from '@/utils/app-security';

@Component({
    components: {
        confirmSnackbar: ConfirmSnackbarComponent
    }
})
export default class PlantItemModalComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private data: Data;
    private appSecurity = new AppSecurity();
    private modalCommon: ModalCommon = new ModalCommon();
    private tabs: TabElement = this.buildTabs();
    private tabHandler = new TabHandler(this.tabs);

    /* switches */
    private loading = false;
    private deleteSnackbarOpen = false;
    private showUnvisitedTabsMessage = false;
    private sapIdChanged = false;
    private showResultBox = false;
    private resultError = false;
    private waitingForSave: boolean;
    private userHasWriteAccess = false;

    /* data */
    private kks: KKS = {} as KKS;
    private selectedkks: SelectedKks = {};
    private itemOperatingHours: OperatingHours = {} as OperatingHours;
    private plantItem: PlantItemTransferItem = {} as PlantItemTransferItem;
    private result = '';
    private validate: PlantItemValidate;
    private yearValue: Record<number, OH> = {};
    private searchInput = '';
    private acmHelper = new ACMHelper();
    private plantItemPiTags = [];
    private enerlyticsData: EnerlyticsData[] = [];
    private plantItemChange = false;
    private ohFactorChange = false;
    private kksTextChanged = false;
    private errorMessage = '';

    private loadTabs = async (tab: TabItem): Promise<void> => {
        if (!tab.visited || tab.name === 'item') {  // need to reload kks tree
            await tab.load();
        }
    }

    constructor() {
        super();
        this.data = Data.Instance;
        this.validate = {} as PlantItemValidate;
        if (this.data.selected.unit && this.data.selected.unit.id !== null) {
            this.plantItem.unitMappingId = this.data.selected.unit.id;
        }
        this.waitingForSave = false;
        this.initListener();
        this.showUnvisitedTabsMessage = false;
        this.sapIdChanged = false;
    }

    private getValidateObject(name: string) {
        return DataInputHelper.getValidateObject(name, this.validate);
    }

    mounted() {
        this.$nextTick(() => {
            this.load();
        });
        this.$on('closeConfirmSnackbar', this.handleCloseConfirmSnackbar);
    }

    beforeDestroy(): void {
        EventBus.$off(EventBus.TABLE.OPEN_PLANT_ITEM_MODAL, this.handleOpenPlantItemModal);
        this.$off('closeConfirmSnackbar', this.handleCloseConfirmSnackbar);
    }

    private resetInputValues(): void {
        this.errorMessage = '';
        this.plantItemChange = false;
        this.ohFactorChange = false;
        this.kksTextChanged = false;
        this.selectedkks = {};
    }

    private initListener() {
        EventBus.$on(EventBus.TABLE.OPEN_PLANT_ITEM_MODAL, this.handleOpenPlantItemModal);
    }

    private async loadPlantItemPiTags(): Promise<void> {
        if (!this.data.selected.unit || !this.data.selected.unit.piId || !this.data.selected.unit.enablePiConnection)
            return;

        this.plantItemPiTags = await this.masterService.plantItemService.getPlantItemPiTags(this.data.selected.unit.piId);
    }

    private selectKksNode(level: number) {
        const regex = /([A-Z]+)/;
        const code = this.plantItem.kksCode;
        const nodes = $('.list-group-item.node-kkstree');
        for (const node of nodes as any) {
            const textMatch = ($(node) as any).text().match(regex).pop();
            if (textMatch !== null && textMatch.length > 0 && code && textMatch === code.substring(0, level)) {
                const expandIcon = $(node).find('.icon.expand-icon').first();
                if (code === textMatch) {
                    $(node).click();
                    return;
                } else if (level < 3 && expandIcon.length > 0) {
                    expandIcon.click();
                } else if (level === 3) {
                    return;
                }
                this.selectKksNode(++level);
            }
        }
    }

    private mapPlantItems() {
        const data = this.masterService.enerlyticsService.siteData;
        if (this.data.selected.plant) {
            switch (this.data.selected.plant.enerlyticsSiteId) {
                case Constants.HARDCODED_PLANTS.DEN_HAAG:
                case Constants.HARDCODED_PLANTS.LEIDEN:
                case Constants.HARDCODED_PLANTS.STAUDINGER:
                case Constants.HARDCODED_PLANTS.GONYU:
                    return data.items;
                case Constants.HARDCODED_PLANTS.GRAIN:
                    return data.items.filter((unit) => this.data.selected.unit && unit.unit_id === this.data.selected.unit.enerlyticsId || unit.unit_id === Constants.HARDCODED_UNIT_IDS.GRAIN.COMMON_SYSTEMS);
                case Constants.HARDCODED_PLANTS.IRSCHING:
                    return data.items.filter((unit) => this.data.selected.unit && unit.unit_id === this.data.selected.unit.enerlyticsId ||
                        !!~Constants.HARDCODED_UNIT_IDS.IRSCHING.U5.indexOf(unit.unit_id)
                    );
                default:
                    return data.items.filter((unit) => this.data.selected.unit && unit.unit_id === this.data.selected.unit.enerlyticsId);
            }
        }
    }

    private async getEnerlyticsACMData() {
        if (Object.keys(this.masterService.enerlyticsService.siteData).length === 0 && this.data.selected.plant && this.data.selected.unit) {
            await this.masterService.enerlyticsService.getUnits(this.data.selected.plant.enerlyticsSiteId, this.data.selected.unit.id);
        }

        /* if no data was provided from enerlytics */
        if (Object.keys(this.masterService.enerlyticsService.siteData).length === 0 || this.masterService.enerlyticsService.siteData.items.length === 0) {
            this.enerlyticsData = [{ itemEnerlyticsId: '', itemName: 'N/A', areaName: 'N/A' }];
            return;
        }

        const data = this.masterService.enerlyticsService.siteData;
        const filteredItems = this.mapPlantItems();
        const areaEnerlyticsOptions = filteredItems ? Helper.sortAlphabetically(filteredItems.map((entry) => ({
            itemName: entry.item_name,
            itemEnerlyticsId: entry.item_id,
            areaName: data.areas.find((area) => area.area_id === entry.area_id)?.area_name
        })), 'itemName') : [];

        this.enerlyticsData = [{ itemEnerlyticsId: '', itemName: 'N/A', areaName: 'N/A' }, ...areaEnerlyticsOptions];
    }

    private async load() {
        this.loading = true;
        try {
            this.kks = await this.masterService.kksService.getKks(this.data.getLanguage());
            this.initKks(this.kks);
            if (this.data.selected.plant) {
                this.userHasWriteAccess = await this.appSecurity.hasWriteAccessToPlant(this.data.selected.plant.plantSID);
            }
            this.loading = false;
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingKKsTree')
                });
            }
            this.loading = false;
        }
    }

    private isInputInvalid(value: any, inputType = 0): boolean {
        const validationConfig = {
            0: (value === undefined || value === null || value === '' || value === -1 || this.isEmptyObject(value)),
            1: (value === undefined || value === null || value === '' || value < 0)
        } as any;

        return validationConfig[inputType];
    }

    private isEmptyObject(obj: any): boolean {
        return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
    }

    private handlePlantItemChange(): void {
        this.plantItemChange = true;
    }

    private handleOhFactorChange(): void {
        this.ohFactorChange = true;
    }

    private handleKksTextChanged(): void {
        this.kksTextChanged = true;
    }

    private setInputChanged(): void {
        this.errorMessage = '';
        this.plantItemChange = true;
        this.ohFactorChange = true;
        this.kksTextChanged = true;
    }

    private async addPlantItem() {
        this.setInputChanged();
        if (this.isInputInvalid(this.plantItem.name) ||
            this.isInputInvalid(this.selectedkks) ||
            this.isInputInvalid(this.plantItem.ohFactor, 1)) {
            this.errorMessage = !this.tabs.item.active ? this.$t('tabValidationIssues', { tabName: this.$t('plantItem') }).toString() : '';
            return;
        }
        this.waitingForSave = true;
        this.showUnvisitedTabsMessage = false;
        const plantModal = '#plant-item-modal';

        try {
            if (this.data.selected.unit) {
                const { name, kksCode, acmCode, ohFactor, plantArea, sapId, sapLocationCode, piTag, enerlyticsId } = this.plantItem;
                const payload = {
                    unitMappingId: this.data.selected.unit.id,
                    name,
                    kksCode,
                    acmCode,
                    ohFactor,
                    plantArea,
                    sapId,
                    sapLocationCode,
                    piTag,
                    enerlyticsId
                };
                payload.ohFactor = parseInt(payload.ohFactor.toString());

                const startYear = this.data.selected.unit.commissioningDate ? DateHelper.getYear(this.data.selected.unit.commissioningDate) : Constants.DEFAULTS.START_YEAR;
                const endYear = this.data.selected.unit.plannedEndDate ? DateHelper.getYear(this.data.selected.unit.plannedEndDate) : Constants.DEFAULTS.END_YEAR;
                const { years } = this.itemOperatingHours;

                if (this.plantItem.id > 0) {
                    if (this.tabs.operatingHours.visited === false && this.sapIdChanged === true) {
                        this.waitingForSave = false;
                        this.showUnvisitedTabsMessage = true;
                        return;
                    }
                    await this.masterService.plantItemService.updatePlantItem({ ...payload, plantItemId: this.plantItem.id });
                    if (this.tabs.operatingHours.visited === true) {
                        try {
                            await this.masterService.itemOhService.updateOperatingHours(this.plantItem.id, {
                                unitMappingId: this.data.selected.unit.id,
                                plantItemId: this.plantItem.id,
                                startYear,
                                endYear,
                                years
                            });
                            EventBus.$emit(EventBus.GLOBAL.REFRESH);
                            ($(plantModal) as any).modal('hide');
                        } catch (error) {
                            this.validate = (error as ServerError).response.data;
                            this.tabHandler.toggleTab(this.tabs.operatingHours);
                            this.expandAccordionsWithErrors();
                        }
                    } else {
                        EventBus.$emit(EventBus.GLOBAL.REFRESH);
                        ($(plantModal) as any).modal('hide');
                    }
                    this.waitingForSave = false;
                } else {
                    if (this.tabHandler.hasUnvisitedTabs()) {
                        this.waitingForSave = false;
                        this.showUnvisitedTabsMessage = true;
                        return;
                    }
                    const result = await this.masterService.plantItemService.addPlantItem(payload);
                    try {
                        await this.masterService.itemOhService.updateOperatingHours(result.id, {
                            unitMappingId: this.data.selected.unit.id,
                            plantItemId: result.id,
                            startYear,
                            endYear,
                            years
                        });
                        EventBus.$emit(EventBus.GLOBAL.REFRESH);
                        ($(plantModal) as any).modal('hide');
                    } catch (error) {
                        this.validate = (error as ServerError).response.data;
                        this.tabHandler.toggleTab(this.tabs.operatingHours);
                        this.expandAccordionsWithErrors();
                    }
                    this.waitingForSave = false;
                }
            }
        } catch (error) {
            if ((error as ServerError).response) {
                await this.tabs.item.load();
                this.tabHandler.toggleTab(this.tabs.item);
                this.validate = (error as ServerError).response.data;
                this.showUnvisitedTabsMessage = true;
                const errorMessage = (error as ServerError).response.data.errors.join(' ');
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: errorMessage
                });
            } else if ((error as ServerError).message) {
                DataInputHelper.showResult((error as ServerError).message, true, this);
            } else {
                this.showUnvisitedTabsMessage = true;
            }
            this.waitingForSave = false;
        }
    }

    private initPlantItem() {
        this.plantItem = {} as PlantItemTransferItem;
        this.plantItem.ohFactor = 100;
        if (this.data.selected.unit) {
            this.plantItem.unitMappingId = this.data.selected.unit.id;
        }
        this.validate = {} as PlantItemValidate;
    }

    private initKks(kks: KKS): void {
        setTimeout(() => {
            const tree = ($('#kkstree') as any).treeview({
                data: kks,
                levels: 1,
                expandIcon: 'iplus',
                collapseIcon: 'iminus',
                showTags: true,
                onNodeSelected: (event: MouseEvent, data: KKSNode) => {
                    if (data.href !== undefined) {
                        this.selectedkks = data;
                        this.plantItem.kksCode = data.code;
                        if (data.plantArea !== undefined) {
                            this.plantItem.plantArea = data.plantArea;
                        }
                    }
                },
                onSearchComplete: function () {
                    // list-group-item node-kkstree search-result
                    const searchResult = $('#kkstree .node-kkstree.search-result');
                    if (searchResult.length > 0) {
                        tree.scrollTop(tree.scrollTop() - tree.offset().top + (searchResult.offset() as any).top);
                    }
                },
                onSearchCleared: function () {
                    // list-group-item node-kkstree search-result
                    tree.scrollTop(0);
                }
            });

            $('#btn-search').on('click', function () {
                const pattern = $('#input-search').val();
                const options = {
                    ignoreCase: tree,
                    exactMatch: false,
                    revealResults: true
                };
                tree.treeview('search', [pattern, options]);
            });

            $('#btn-clear-search').on('click', () => {
                tree.treeview('clearSearch');
                $('#input-search').val('');
                tree.treeview('unselectNode', this.selectedkks.nodeId);
                this.selectedkks = {};
                this.plantItem.kksCode = null;
                this.handleKksTextChanged();
                tree.treeview('collapseAll', { silent: true });
            });

            const treeEl = document.getElementById('kkstree');
            if (treeEl === null || (treeEl !== null && treeEl.childElementCount === 0)) {
                // hack to reinit tree if it does not render
                this.initKks(this.kks);
            }
            if (this.plantItem.id > 0) {
                this.selectKksNode(1);
                tree.scrollTop(0);
            }
        }, 150);
    }

    private confirmDeletePlantItem() {
        this.deleteSnackbarOpen = true;
    }

    private async deletePlantItem() {
        try {
            await this.masterService.plantItemService.deletePlantItem(this.plantItem.id);
            EventBus.$emit(EventBus.GLOBAL.REFRESH);
            ($('#plant-item-modal') as any).modal('hide');
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorDeletingPlantItem')
                });
            }
        }
    }

    /**
     * *******************************************************
     * OPERATING HOURS
     * *******************************************************
     */

    private async loadOh() {
        this.loading = true;
        if (this.data.selected.unit) {
            try {
                const startYear = this.data.selected.unit.commissioningDate ? DateHelper.getYear(this.data.selected.unit.commissioningDate) : Constants.DEFAULTS.START_YEAR;
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const endYear = currentMonth === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
                this.itemOperatingHours = await this.masterService.itemOhService.getOperatingHours(this.plantItem.id, startYear, endYear);
                const lastYearIndex = this.itemOperatingHours.years.length - 1;
                this.itemOperatingHours.years[lastYearIndex].months = OHHelper.filterFutureMonths(this.itemOperatingHours.years[lastYearIndex].months, currentMonth);
                this.initYears();
                this.loading = false;
            } catch (error) {
                if (error !== undefined && (error as ServerError).response.status !== 401) {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: this.$i18n.t('errorGettingOH')
                    });
                }
                this.loading = false;
            }
        }
    }

    private initYears() {
        OHHelper.initYears(this.itemOperatingHours, this.yearValue);
    }

    private yearChange(year: OperatingHoursYear, t: number) {
        OHHelper.yearChange(year, t, this.yearValue[year.year]);
    }

    private monthChange(year: OperatingHoursYear) {
        OHHelper.monthChange(year, this.yearValue);
    }

    private async copyOperatingHoursFromUnit(): Promise<void> {
        try {
            if (this.data.selected.unit) {
                const startYear = new Date(this.data.selected.unit.commissioningDate).getFullYear();
                const endYear = new Date(this.data.selected.unit.plannedEndDate).getFullYear();
                this.itemOperatingHours = await this.masterService.unitOHService.getAll(this.data.selected.unit.id, startYear, endYear);
                this.itemOperatingHours.years.forEach(year => {
                    let remainder = 0;
                    let eohRemainder = 0;
                    year.months.forEach(month => {
                        const value = this.calculateMonthlyOHFactor(month.operatingHours);
                        const eohValue = this.calculateMonthlyOHFactor(month.eoh);
                        remainder = this.calculateMonthlyOHRemainder(value, remainder);
                        eohRemainder = this.calculateMonthlyOHRemainder(eohValue, eohRemainder);
                        month.operatingHours = Math.floor(value);
                        month.eoh = Math.floor(eohValue);
                    });
                    if (year.months.length > 0) {
                        year.months[0].operatingHours = remainder > 0 ? Math.floor(year.months[0].operatingHours + remainder) : year.months[0].operatingHours;
                        year.months[0].eoh = eohRemainder > 0 ? Math.floor(year.months[0].eoh + eohRemainder) : year.months[0].eoh;
                    }
                });
                this.initYears();
            }
        } catch (error) {
            if (error && (error as ServerError).response && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingOHFromUnit')
                });
            }
        }
    }

    private calculateMonthlyOHFactor(hours: number): number {
        return hours * (this.plantItem.ohFactor / 100);
    }

    private calculateMonthlyOHRemainder(value: number, remainder: number): number {
        return value % Math.floor(value) ? remainder + (value % Math.floor(value)) : remainder;
    }

    private expandAccordionsWithErrors(): void {
        setTimeout(() => {
            const element = $('#plant-item-accordion').find('.is-invalid').first().parent().parent().parent();
            if (element) {
                element.addClass('show');
            }
        }, 50);
    }

    /**
     * *******************************************************
     * COMMON
     * *******************************************************
     */

    private async toggleTab(tab: TabItem): Promise<void> {
        this.errorMessage = '';
        await this.tabHandler.toggleTab(tab, [this.loadTabs(tab)]);
        if (!this.tabHandler.hasUnvisitedTabs()) {
            this.showUnvisitedTabsMessage = false;
        }
    }

    private buildTabs(): TabElement {
        return {
            item: {
                active: true,
                visited: true,
                name: 'item',
                load: async (): Promise<void> => { this.initKks(this.kks); }
            },
            operatingHours: {
                active: false,
                visited: false,
                name: 'operatingHours',
                load: async (): Promise<void> => { await this.loadOh(); }
            }
        };
    }

    private sapIdChange(): void {
        this.tabs.operatingHours.visited = false;
        this.sapIdChanged = true;
    }

    private async handleOpenPlantItemModal(item: PlantItemTransferItem): Promise<void> {
        this.tabHandler.resetTabs();
        this.tabHandler.toggleTab(this.tabs.item, [this.loadTabs(this.tabs.item)]);
        this.tabs.item.visited = true;
        this.initKks(this.kks);
        this.showUnvisitedTabsMessage = false;
        this.sapIdChanged = false;
        this.deleteSnackbarOpen = false;
        this.itemOperatingHours = {} as OperatingHours;
        this.validate = {} as PlantItemValidate;
        this.searchInput = '';
        this.resetInputValues();

        await this.getEnerlyticsACMData();
        await this.loadPlantItemPiTags();
        if (item === null) {
            // means it's new
            this.initPlantItem();
        } else {
            this.plantItem = item;
            if (!this.plantItemPiTags.find((i) => i === this.plantItem.piTag) && this.plantItem.piTag !== this.data.selected.unit?.piId) {
                this.plantItem.piTag = '';
            }
            if (!this.enerlyticsData.find((i) => i.itemEnerlyticsId === this.plantItem.enerlyticsId)) {
                this.plantItem.enerlyticsId = '';
            }
            this.selectedkks = {
                text: this.plantItem.kksCode
            };
        }
    }

    private handleCloseConfirmSnackbar() {
        this.deleteSnackbarOpen = false;
    }
}
