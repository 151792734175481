import { Module } from 'vuex';
import { UnitAttributes, Unit } from '@/utils/interfaces';

const getDefaultState = (): any => ({
    unitsWithAttributes: [] as UnitAttributes[],
    units: [] as Unit[],
});

const UnitModule: Module<any, any> = {
    state: getDefaultState(),

    mutations: {
        setUnits(state, { units, plantId }): void {
            state.units = state.units.filter((unit: Unit) => unit.plantId !== plantId);
            const unitsWithPlantId = units.map((unit: any) => ({
                ...unit,
                plantId
            }));
            state.units = state.units.concat(unitsWithPlantId);
        },
        setUnitsWithAttributes(state, unitsWithAttributes): void {
            state.unitsWithAttributes = unitsWithAttributes;
        },
        resetUnitWithAttributes(state): void {
            state.unitsWithAttributes = [...getDefaultState().unitsWithAttributes];
        },
    },

    actions: {
        setUnits({ commit }, payload): void {
            commit('setUnits', payload);
        },
        setUnitsWithAttributes({ commit }, payload): void {
            commit('setUnitsWithAttributes', payload);
        },
        resetUnitWithAttributes({ commit }): void {
            commit('resetUnitWithAttributes');
        }
    },

    getters: {
        getUnits(state): Unit[] {
            return state.units;
        },
        getUnitsWithAttributes(state): UnitAttributes[] {
            return state.unitsWithAttributes;
        },
    },
    namespaced: true
}

export default UnitModule;
