/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import Vue from 'vue';
import {
    MaintenanceItem, PlantItem, MaintenanceItemValidate,
    MaintenancePlanning, Driver, Model, MaintenanceActivity, TabItem, TabElement,
    ServerError, GroupType, MaintenanceAreas, PlantItemKks, UnitOutage, PlantItemItems,
    MaintenanceGroupItem, MaintenanceGroup, CustomInputs, MaintenanceItemDataItem, CustomInputChanged
} from '@/utils/interfaces';
import { MasterService } from '@/services/master-service';
import { Component } from 'vue-property-decorator';
import { ModalCommon } from '@/components/table/modal-common';
import ConfirmSnackbarComponent from '@/components/confirm-snackbar/confirm-snackbar.vue';
import { Data, EventBus, CurrencyHelper, Helper, TabHandler, DataInputHelper } from '@/utils';
import { DRIVERS } from '@/utils/driver-helper';
import { MODELS } from '@/utils/model-helper';
import { MAINTENANCE_ACTIVITIES, MAINTENANCEGROUPTYPES, MAINTENANCECATEGORIES, UEL_STATES } from '@/utils/maintenance-helper';
import AppSecurity from '@/utils/app-security';

@Component({
    components: {
        confirmSnackbar: ConfirmSnackbarComponent
    }
})
export default class PlantItemMaintenanceModalComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private appSecurity = new AppSecurity();
    private modalCommon: ModalCommon = new ModalCommon();
    private data: Data = Data.Instance;
    private tabs: TabElement = this.buildTabs();
    private tabHandler = new TabHandler(this.tabs);
    private currencyHelper: CurrencyHelper = new CurrencyHelper();

    /* switches */
    private secondDriver = false;
    private loading = false;
    private confirmSnackbarOpen = false;
    private deleteSnackbarOpen = false;
    private resultError: boolean;
    private showResultBox: boolean;
    private userHasWriteAccess = false;
    private onceDriverPlanningDisabled = false;
    private waitingForSave: boolean;

    /* static data */
    private maintenanceActivitiesEnum = MAINTENANCE_ACTIVITIES;
    private maintenanceCategoryTypes = MAINTENANCECATEGORIES;
    private driverTypes = DRIVERS;
    private modelTypes = MODELS;
    private alternateGroupType = MAINTENANCEGROUPTYPES.ALTERNATE;
    private supportGroupType = MAINTENANCEGROUPTYPES.SUPPORT;
    private selector = '#add-maintenance-item-modal';
    private uelStates = UEL_STATES;

    /* data */
    private plantItems: PlantItem[] = [];
    private maintenanceActivities: MaintenanceActivity[] = [];
    private filteredMaintenanceActivities: MaintenanceActivity[] = [];
    private maintenanceTypes: MaintenanceActivity[] = [];
    private drivers: Driver[] = [];
    private firstDrivers: Driver[] = [];
    private secondDrivers: Driver[] = [];
    private item: MaintenanceItem = {} as MaintenanceItem;
    private result: string;
    private validate: MaintenanceItemValidate;
    private onceDriverDate: string | null;
    private onceDriverDate2: string | null;
    private outageIds: UnitOutage[] = [];
    private planning: MaintenancePlanning[] = [];
    private outageIdsMap: Record<string, Record<string, number>> = {};
    private maintenanceCategories: MaintenanceActivity[] = [];
    private groupTypes: GroupType[] = [];
    private maintenanceGroup = this.createEmptyMaintenanceGroup();
    private allItems: PlantItemItems[] = [];
    private itemsFilteredByPlantItem: PlantItemItems[] = [];
    private pendingItemForAdd: PlantItemItems | null = null;
    private originalMaintenanceId: string | null = '';
    private originalMaintenanceCategory: number | null = 0;
    private selectedCategory: number | null = null;
    private nullValue = null;
    private supportGroupPlantItems: PlantItemKks[] = [];
    private selectedSupportGroupPlantItemId = null;
    private kksCodeSelectedItem: string | null;
    private plantItemChanged = false;
    private activityItemChanged = false;
    private maintenanceTypeChanged = false;
    private driverItemChanged = false;
    private driver2ItemChanged = false;
    private costItemChanged = false;
    private durationItemChanged = false;
    private toleranceItemChanged = false;
    private tolerance2ItemChanged = false;
    private intervalItemChanged = false;
    private interval2ItemChanged = false;
    private conditionalDateChanged = false
    private conditionalDate2Changed = false
    private modelItemChanged = false;
    private model2ItemChanged = false;
    private customInputChanged: CustomInputChanged = {
        kb: false,
        boh: false,
        kg: false,
        k1: false,
        k2: false,
        k3: false,
        s: false,
        ez: false,
        en: false,
        ks: false,
        dtDr: false,
        oHr: false,
        linkedWithOH: false,
    };
    private selectedSupportGroupPlantItemIdChanged = false;
    private pendingItemForAddChanged = false;
    private uelChanged = false;
    private errorMessage = '';
    private isDragActive = false;
    private dragPosition = -1;
    private keepUelValueModalRef = 'keepUelValueModal';
    private importedUel: number | null = null;

    private get secondDriverModels(): Model[] {
        const models = (this.drivers.find(x => x.id === this.item.driver2) || {}).models as Model[]
        return models?.filter(model => model.id != this.modelTypes.Custom && model.id != this.modelTypes['Linked_with_OH'])
    }

    private get isMaintenanceOpportunityOrRisk(): boolean {
        return this.selectedCategory === this.maintenanceCategoryTypes.Opportunity || this.selectedCategory === this.maintenanceCategoryTypes.Risk;
    }

    private get uelInfoText(): string {
        let uelInfoText = '';
        if (this.isMaintenanceOpportunityOrRisk) {
            const infoTexts = {
                [this.uelStates.Imported]: this.$t('valueImportedPTRisk'),
                [this.uelStates.Overwritten]: this.$t('valueOverwritten'),
                [this.uelStates.NoValue]: this.$t('valueImportedPTRisk'),
            };
            uelInfoText = (this.item.uelLabel != null && infoTexts[this.item.uelLabel]) || '';
        }
        return uelInfoText;
    }

    private get uelPlaceholder(): string {
        let uelPlaceholder = '';
        if (this.isMaintenanceOpportunityOrRisk && (this.item.uelLabel === this.uelStates.Imported || this.item.uelLabel === this.uelStates.NoValue) && this.item.uEL == null) {
            uelPlaceholder = this.$t('noValuePTRisk');
        }
        return uelPlaceholder;
    }

    private get hasMandatoryMaintananceItem(): boolean {
        const isMandatoryMaintananceItemPresent = this.maintenanceGroup.items.find(item => item.plantItemMaintenanceId === this.item.plantItemMaintenanceId);
        return isMandatoryMaintananceItemPresent ? true : false;
    }

    constructor() {
        super();
        this.validate = {} as MaintenanceItemValidate;
        this.result = '';
        this.resultError = false;
        this.showResultBox = false;
        this.onceDriverDate = null;
        this.onceDriverDate2 = null;
        this.item.driver = 0;
        this.item.itemModel = 0;
        this.item.customInputs = this.resetCustomInputs();
        this.waitingForSave = false;
        this.initializeTooltips();
        this.kksCodeSelectedItem = null;
    }

    private get isItemLinkedWithSupport(): boolean {
        return this.item.linked === true && this.maintenanceGroup.type === this.supportGroupType;
    }

    private getValidateObject(name: string): string {
        return DataInputHelper.getValidateObject(name, this.validate);
    }

    private handleMaintenanceTypeChanged(): void {
        this.maintenanceTypeChanged = true;
        this.$forceUpdate();
    }

    private handleCostChanged(): void {
        this.costItemChanged = true;
    }

    private handleToleranceChanged(secondDriver = false): void {
        if (secondDriver) {
            this.tolerance2ItemChanged = true;
        } else {
            this.toleranceItemChanged = true;
        }
    }

    private handleDurationChanged(): void {
        this.durationItemChanged = true;
    }

    private handleIntervalChanged(secondDriver = false): void {
        if (secondDriver) {
            this.interval2ItemChanged = true;
        } else {
            this.intervalItemChanged = true;
        }
    }

    private handleModelChanged(secondDriver = false): void {
        if (secondDriver) {
            this.model2ItemChanged = true;
        } else {
            this.modelItemChanged = true;
        }
    }

    private handleCustomInputChanged(input: keyof CustomInputChanged): void {
        this.customInputChanged[input] = true;
    }

    private handlePlanningDateItemChanged(index: number): void {
        this.planning[index].maintenanceDateChanged = true;
    }

    private isInputInvalid(value: any, inputChanged: boolean, inputType = 0): boolean {
        const valueNotSet = value == null || value === ''
        const validationConfig = {
            0: (valueNotSet || value === -1 || value === 0),
            1: (valueNotSet || value < 0),
            2: valueNotSet,
            3: (valueNotSet || value < 0 || value > 100),
            4: (valueNotSet || value < 1),
            5: this.isMaintenanceOpportunityOrRisk ? value < 0 : (valueNotSet || value < 0),
        } as any;

        return inputChanged && validationConfig[inputType];
    }

    private isOutageAlreadySelected(outage: MaintenancePlanning): boolean {
        return !!this.planning.find(i => i.unitMaintenancePlanningId === outage.unitMaintenancePlanningId)
    }

    private created(): void {
        const self = this;
        this.$on('closeConfirmSnackbar', function () {
            self.confirmSnackbarOpen = false;
            self.deleteSnackbarOpen = false;
        });
        EventBus.$on(EventBus.TABLE.OPEN_MAINTENANCE_ITEM, this.handleOpenMaintenanceItem);
    }

    private mounted(): void {
        this.$nextTick(() => {
            $(this.selector).on('hidden.bs.modal', () => {
                if (this.maintenanceCopyData.openCopyModal) {
                    this.maintenanceCopyData.openCopyModal = false;
                    ($('#copyMaintenanceModal') as any).modal('show');
                    $('body').addClass('modal-open');
                }
            });
            $('#copyMaintenanceModal').on('hidden.bs.modal', async () => {
                if (this.maintenanceCopyData.openMaintenanceModal) {
                    this.maintenanceCopyData.openMaintenanceModal = false;
                    ($(this.selector) as any).modal('show');
                    $('body').addClass('modal-open');
                }
            });
        });
    }

    beforeDestroy(): void {
        EventBus.$off(EventBus.TABLE.OPEN_MAINTENANCE_ITEM, this.handleOpenMaintenanceItem);
    }

    private async initItem(item: MaintenanceItem): Promise<void> {
        this.setInputChanged(false);
        this.validate = {} as MaintenanceItemValidate;
        this.loading = true;
        this.confirmSnackbarOpen = false;
        this.deleteSnackbarOpen = false;
        this.result = '';
        this.resultError = false;
        this.showResultBox = false;
        this.tabHandler.toggleTab(this.tabs.item);
        this.planning = [];
        this.onceDriverDate = '';
        this.onceDriverPlanningDisabled = false;
        this.getGroupTypes();

        try {
            this.getMaintenanceActivities();
            this.getMaintenanceTypes();
            this.getMaintenanceCategories();
            this.getDrivers();
            await Promise.all([
                this.getPlantItems(),
                this.getMaintenanceGroup(item.plantItemMaintenanceId)]);

            this.item = Object.assign({}, item);
            this.filterMaintenanceActivities();

            this.originalMaintenanceId = item.maintenanceId;
            this.originalMaintenanceCategory = item.category;
            this.selectedCategory = item.category;

            // in case of edit, filter drivers so they cannot be selected twice
            if (this.item.plantItemMaintenanceId > 0) {
                if (this.item.driver2) {
                    this.firstDrivers = this.filterDrivers(this.drivers, this.item.driver2);
                }
                this.secondDrivers = this.filterDrivers(this.filterDrivers(this.drivers, this.item.driver), DRIVERS.Once);
                this.itemsFilteredByPlantItem = this.allItems.filter(item => item.plantItemId === this.item.plantItemId);
            }

            this.secondDriver = item.driver2 != null;
            if (this.item.customInputs === undefined) {
                this.item.customInputs = this.resetCustomInputs();
            }
            if (this.item.driver === DRIVERS.Once || this.item.driver === DRIVERS.Condition_Based) {
                this.onceDriverDate = this.parseOnceDriverDate(this.item.interval);
            }
            if ((this.item.driver2 === DRIVERS.Once || this.item.driver2 === DRIVERS.Condition_Based) && this.item.interval2) {
                this.onceDriverDate2 = this.parseOnceDriverDate(this.item.interval2);
            }
            this.item.plantItemMaintenanceId = item.plantItemMaintenanceId;
            await this.loadPlanningData();
            if (this.data.selected.plant) {
                this.userHasWriteAccess = await this.appSecurity.hasWriteAccessToPlant(this.data.selected.plant.plantSID);
            }
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }

    private handleInputEmptyStringNumber(item: MaintenanceGroupItem): void {
        const emptyStringToNull = (value: any): any => {
            if (!value || isNaN(value)) {
                return null;
            }
            return value
        }

        if (item) {
            item.p50Limit = emptyStringToNull(item.p50Limit);
            item.p50Limit2 = emptyStringToNull(item.p50Limit2);
            item.p80Limit = emptyStringToNull(item.p80Limit);
            item.p80Limit2 = emptyStringToNull(item.p80Limit2);
        }
    }

    private setInputChanged(value = true): void {
        this.errorMessage = '';
        this.plantItemChanged = value;
        this.activityItemChanged = value;
        this.maintenanceTypeChanged = value;
        this.driverItemChanged = value;
        this.driver2ItemChanged = value;
        this.costItemChanged = value;
        this.durationItemChanged = value;
        this.toleranceItemChanged = value;
        this.tolerance2ItemChanged = value;
        this.intervalItemChanged = value;
        this.interval2ItemChanged = value;
        this.conditionalDateChanged = value;
        this.conditionalDate2Changed = value;
        this.modelItemChanged = value;
        this.model2ItemChanged = value;
        this.selectedSupportGroupPlantItemIdChanged = value;
        this.pendingItemForAddChanged = value;
        this.uelChanged = value;
        Object.values(this.customInputChanged).forEach((item) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            item = value;
        });
        this.planning.forEach((planning) => {
            planning.maintenanceDateChanged = value;
        });
    }

    private async saveChanges(item: MaintenanceItem): Promise<void> {
        this.setInputChanged();
        let maintenanceInvalid = false;
        for (const planning of this.planning) {
            if (this.isInputInvalid(planning.maintenanceDate, planning.maintenanceDateChanged || true)) {
                maintenanceInvalid = true;
                break;
            }
        }
        let secondDriverInvalid = false;
        if (item.driver2 != null) {
            const conditionalDateInvalid = (item.driver2 === this.driverTypes.Once || item.driver2 === this.driverTypes.Condition_Based) && this.isInputInvalid(this.onceDriverDate2, this.conditionalDate2Changed);
            const intervalInvalid = item.driver2 !== this.driverTypes.Once && item.driver2 !== this.driverTypes.Condition_Based && this.isInputInvalid(item.interval2, this.interval2ItemChanged, 1);
            const driver = this.drivers.find(x => x.id === item.driver2);
            const modelInvalid = driver?.models ? driver.models.length > 0 && (item.driver2 !== this.driverTypes.OH || item.driver2 === this.driverTypes.OH && item.maintenanceActivity === this.maintenanceActivitiesEnum['Day_To_Day_Maintenance']) && this.isInputInvalid(item.model2, this.model2ItemChanged) : false;
            const customInputsInvalid = this.customInputsInvalid(item.driver2, item.model2, item.customInputs);
            secondDriverInvalid = this.isInputInvalid(item.driver2, this.driver2ItemChanged) || intervalInvalid || this.isInputInvalid(item.tolerance2, this.tolerance2ItemChanged, 3) || conditionalDateInvalid || modelInvalid || customInputsInvalid;
        }
        const conditionalDateInvalid = (item.driver === this.driverTypes.Once || item.driver === this.driverTypes.Condition_Based) && this.isInputInvalid(this.onceDriverDate, this.conditionalDateChanged);
        const intervalInvalid = item.driver !== this.driverTypes.Once && item.driver !== this.driverTypes.Condition_Based && this.isInputInvalid(item.interval, this.intervalItemChanged, 1);
        const driver = this.drivers.find(x => x.id === item.driver);
        const modelInvalid = driver?.models ? driver.models.length > 0 && (item.driver !== this.driverTypes.OH || item.driver === this.driverTypes.OH && item.maintenanceActivity === this.maintenanceActivitiesEnum['Day_To_Day_Maintenance']) && this.isInputInvalid(item.itemModel, this.modelItemChanged) : false;
        const customInputsInvalid = this.customInputsInvalid(item.driver, item.itemModel, item.customInputs);
        if (this.isInputInvalid(item.plantItemId, this.plantItemChanged) ||
            this.isInputInvalid(item.maintenanceActivity, this.activityItemChanged) ||
            this.isInputInvalid(item.maintenanceType, this.maintenanceTypeChanged, 2) ||
            this.isInputInvalid(item.driver, this.driverItemChanged) || secondDriverInvalid ||
            this.isInputInvalid(item.cost, this.costItemChanged, 1) ||
            this.isInputInvalid(item.tolerance, this.toleranceItemChanged, 3) ||
            intervalInvalid ||
            this.isInputInvalid(item.duration, this.durationItemChanged, 4) ||
            conditionalDateInvalid ||
            maintenanceInvalid ||
            modelInvalid ||
            customInputsInvalid ||
            (this.isItemLinkedWithSupport && this.maintenanceGroup.items.length === 0 &&  (this.isInputInvalid(this.selectedSupportGroupPlantItemId, this.selectedSupportGroupPlantItemIdChanged, 2) ||
            this.isInputInvalid(this.pendingItemForAdd, this.pendingItemForAddChanged, 2))) || this.isInputInvalid(item.uEL, this.uelChanged, 5)) {
            if (maintenanceInvalid) {
                this.errorMessage = !this.tabs.planning.active ? this.$t('tabValidationIssues', { tabName: this.$t('planning') }).toString() : '';
            } else {
                this.errorMessage = !this.tabs.item.active ? this.$t('tabValidationIssues', { tabName: this.$t('maintItem') }).toString() : '';
            }
            return;
        }
        const isEditMode = this.planning.find(el => el.editMode === true) !== undefined;

        if (isEditMode === true) {
            this.confirmSnackbarOpen = true;
            return;
        }

        this.waitingForSave = true;
        this.processOnceDriverDateBeforeSave(item);

        if (this.secondDriver === false) {
            item.driver2 = null;
            item.model2 = null;
            item.interval2 = null;
            item.tolerance2 = null;
        }

        try {
            let proceedSave = true;
            if (item.plantItemMaintenanceId > 0) {
                if (item.linked && !this.hasMandatoryMaintananceItem) {
                    proceedSave = false;
                    this.showIncorrectLinkErrorMessage();
                } else {
                    await this.saveMaintenanceGroup(item.linked);
                    item.plantSid = this.data.selected.plant?.plantSID;
                    item.category = item.category && !isNaN(item.category) ? parseInt(item.category?.toString()) : 0;
                    item.maintenanceActivity = item.maintenanceActivity && !isNaN(item.maintenanceActivity) ? parseInt(item.maintenanceActivity?.toString()) : 0;
                    item.itemModel = item.itemModel && !isNaN(item.itemModel) ? parseInt(item.itemModel?.toString()) : 0;
                    item.model2 = item.model2 && !isNaN(item.model2) ? parseInt(item.model2?.toString()) : 0;
                    item.uEL = item.uEL ? parseInt(item.uEL?.toString()) : null;
                    item.itemList = this.maintenanceGroup.items;
                    if (!isNaN(item.driver)) {
                        item.driver = parseInt(item.driver.toString());
                    } else {
                        item.driver = 0;
                    }
                    await this.masterService.plantMaintenanceItemService.editPlantMaintenanceItem(item);
                }
            } else {
                if (item.linked && !this.hasMandatoryMaintananceItem) {
                    proceedSave = false;
                    this.showIncorrectLinkErrorMessage();
                } else {
                    item.plantSid = this.data.selected.plant?.plantSID;
                    item.uEL = item.uEL ? parseInt(item.uEL?.toString()) : null;
                    item.itemList = this.maintenanceGroup.items;
                    const result = await this.masterService.plantMaintenanceItemService.addPlantMaintenanceItem(item);
                    this.item = result;
                    this.item.plantItemMaintenanceId = result.id;
                    await this.saveMaintenanceGroup(item.linked, result.id);
                }
            }

            if (proceedSave) {
                const success = await this.savePlanning(this.item.plantItemMaintenanceId);
                if (!success) {
                    this.waitingForSave = false;
                    return;
                }
                this.modalCommon.hideModal(this.selector);
                EventBus.$emit(EventBus.GLOBAL.REFRESH);
            }

        } catch (error) {
            if (error && (error as ServerError).response) {
                this.validate = (error as ServerError).response.data;
                this.tabHandler.toggleTab(this.tabs.item);
                if ((error as ServerError).response.data.errors) {
                    const errorMessage = (error as ServerError).response.data.errors.join(' ');
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: errorMessage,
                    });
                } else if ((error as ServerError).response.data.message) {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: (error as ServerError).response.data.message,
                    });
                } else {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: this.$t('errorSavingMaintenanceItem')
                    });
                }
            }
        }
        this.waitingForSave = false;
    }

    private showIncorrectLinkErrorMessage(): void {
        EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
            class: 'error',
            message: this.$i18n.t('currentMaintenanceNotFound')
        });
    }

    private cancel(): void {
        this.modalCommon.hideModal(this.selector);
    }

    private confirmDeleteMaintenance(): void {
        this.deleteSnackbarOpen = true;
    }

    private async deleteMaintenance(): Promise<void> {
        this.waitingForSave = true;
        try {
            const plantSid = this.data.selected.plant?.plantSID ?? 0;
            await this.masterService.plantMaintenanceItemService.deleteMaintenanceItem(this.item.plantItemMaintenanceId, plantSid);
            this.waitingForSave = false;
            EventBus.$emit(EventBus.GLOBAL.REFRESH);
            this.modalCommon.hideModal(this.selector);
        } catch (error) {
            this.waitingForSave = false;
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorDeletingMaintenance')
                });
            }
        }
    }

    private parseOnceDriverDate(interval: number): string {
        const intervalString = interval.toString();
        return `${intervalString.substring(0, 4)}-${intervalString.substring(4, 6)}-${intervalString.substring(6, 8)}`;
    }

    private processOnceDriverDateBeforeSave(item: MaintenanceItem): void {
        // In case of once or condition based driver the date is saved as a long series of numbers.
        if (item.driver === DRIVERS.Once || item.driver === DRIVERS.Condition_Based) {
            if (this.onceDriverDate) {
                const onceDriverDate = new Date(this.onceDriverDate);
                item.interval = parseInt(
                    `${onceDriverDate.getFullYear()}` +
                    `${('0' + (onceDriverDate.getMonth() + 1)).slice(-2)}` +
                    `${('0' + onceDriverDate.getDate()).slice(-2)}`
                );
            }
        }
        if (item.driver2 === DRIVERS.Once || item.driver2 === DRIVERS.Condition_Based) {
            if (this.onceDriverDate2) {
                const onceDriverDate2 = new Date(this.onceDriverDate2);
                item.interval2 = parseInt(
                    `${onceDriverDate2.getFullYear()}` +
                    `${('0' + (onceDriverDate2.getMonth() + 1)).slice(
                        -2
                    )}` +
                    `${('0' + onceDriverDate2.getDate()).slice(-2)}`
                );
            }
        }
    }

    private onceDriverDateChange(date: string, secondDriver = false): void {
        if (secondDriver) {
            this.conditionalDate2Changed = true;
        } else {
            this.conditionalDateChanged = true;
        }
        const planning =
            this.item.driver === DRIVERS.Once
                ? this.planning
                : this.item.driver === DRIVERS.Condition_Based
                    ? this.planning.filter((p) => !p.unitMaintenancePlanningId)
                    : [];

        if (!planning || planning.length === 0)
            return;

        planning.forEach(p => {
            p.maintenanceDate = date;
        });
    }

    private isPtRiskOrOpportunity(item: MaintenanceItem): boolean {
        return (
            item.ptRiskNumber != null &&
            item.ptRiskNumber !== '' &&
            (item.category === MAINTENANCECATEGORIES.Risk ||
                item.category === MAINTENANCECATEGORIES.Opportunity)
        );
    }

    private async onPtRiskNumberChange(): Promise<void> {
        if (!this.item.ptRiskNumber)
            return;

        this.waitingForSave = true;
        try {
            const self = this;

            if (this.item.category === MAINTENANCECATEGORIES.Risk) {
                const ptRisk = await this.masterService.plantMaintenanceItemService.getPtRisk(this.item.ptRiskNumber);
                self.item.cost = ptRisk.strategyCost;
                self.item.maintenanceDescription = ptRisk.riskTitle;
            } else if (this.item.category === MAINTENANCECATEGORIES.Opportunity) {
                const opportunity = await this.masterService.plantMaintenanceItemService.getOpportunity(this.item.ptRiskNumber);
                self.item.cost = opportunity.investmentCost;
                self.item.maintenanceDescription = opportunity.opportunityTitle;
            }

            self.validate['ptRiskNumber'] = null;

            self.planning.forEach(function (p) {
                p.cost = self.item.cost;
                p.maintenanceDesc = self.item.maintenanceDescription;
            });

            this.waitingForSave = false;

            const uelResponse = await this.masterService.plantMaintenanceItemService.getUel(this.selectedCategory, this.item.ptRiskNumber);

            if (uelResponse.isFoundInUTG) {
                if (this.item.uEL) {
                    this.importedUel = uelResponse.uel || null;
                    (this.$refs[this.keepUelValueModalRef] as Pui.Lightbox)?.open();
                } else {
                    this.item.uEL = uelResponse.uel || null;
                    this.item.uelLabel = this.uelStates.Imported;
                }
            }
        } catch (error) {
            this.waitingForSave = false;
            if (error) {
                if ((error as ServerError).response.status === 404) {
                    this.validate['ptRiskNumber'] = [this.$i18n.t('invalidPtRiskNumber').toString()];
                } else {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: this.$i18n.t('errorGettingPtRisk')
                    });
                }
            }
        }
    }

    private onUELChanged(): void {
        this.uelChanged = true;
        this.item.uelLabel = this.uelStates.Overwritten;
    }

    /**
     * *******************************************************
     * PLANNING
     * *******************************************************
     */

    private async loadPlanningData(): Promise<void> {
        await Promise.all([this.getPlanning(), this.getOutageIds()]);
    }

    private async getPlanning(): Promise<void> {
        if (this.item.plantItemMaintenanceId > 0) {
            try {
                this.planning = await this.masterService.maintenancePlanningService.getMaintenancePlanning(this.item.plantItemMaintenanceId);
                this.planning.forEach(planning => planning.maintenanceDateChanged = false);
                this.populateGlobalProjectIds(this.planning);
            } catch (error) {
                if (error !== undefined && (error as ServerError).response.status !== 401) {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                        class: 'error',
                        message: this.$i18n.t('errorGettingPlanning')
                    });
                }
            }
        }
    }

    private populateGlobalProjectIds(plannings: MaintenancePlanning[]): void {
        const self = this;
        plannings.forEach(function (planningItem) {
            if (!planningItem.globalProjectId)
                planningItem.globalProjectId = self.item.globalProjectId;
        });
    }

    private async getOutageIds(): Promise<void> {
        try {
            if (this.data.selected.unit) {
                this.outageIds = await this.masterService.unitBudgetService.getOutageIds(this.data.selected.unit.id);
                this.outageIdsMap = Helper.createMap(this.outageIds, 'unitMaintenancePlanningId');
            }
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingOutageIds')
                });
            }
        }
    }

    private async savePlanning(itemId: number): Promise<boolean> {
        try {
            this.planning.forEach((p) => {
                if (p.costTwoYearsPrior === null) {
                    p.costTwoYearsPrior = 0;
                }
                if (p.costPreviousYear === null) {
                    p.costPreviousYear = 0;
                }
                if (p.costNextYear === null) {
                    p.costNextYear = 0;
                }
                if (this.data.selected.unit) {
                    p.unitId = this.data.selected.unit.id;
                }
            });

            const data = {
                planningCommands: this.planning,
                riskNumber: this.item.ptRiskNumber,
                category: this.item.category,
                driver: this.item.driver,
                plantItemMaintenanceId: this.item.plantItemMaintenanceId
            };
            await this.masterService.maintenancePlanningService.updateMaintenancePlanning(itemId, data);
            return true;
        } catch (error) {
            if (error && (error as ServerError).response) {
                this.validate = (error as ServerError).response.data;
            }
            this.toggleTab(this.tabs.planning);
            return false;
        }
    }

    private addPlanning(): void {
        let isDateInvalid = false;
        for (const planningItem of this.planning) {
            if (this.isInputInvalid(planningItem.maintenanceDate, planningItem.maintenanceDateChanged || true)) {
                isDateInvalid = true;
                break;
            }
        }
        if (isDateInvalid) {
            return;
        }
        this.modalCommon.closeAllEditors(this.planning);
        const maintenanceDate =
            this.item.driver === DRIVERS.Once ||
                this.item.driver === DRIVERS.Condition_Based
                ? this.onceDriverDate
                : null;

        if (this.data.selected.plant && this.data.selected.unit) {
            this.planning.push({
                cost: this.item.cost,
                costTwoYearsPrior: null,
                costPreviousYear: null,
                costNextYear: null,
                maintenanceDate: maintenanceDate,
                duration: this.item.duration,
                id: -1,
                maintenanceItemId: -1,
                editMode: true,
                leadingItem: false,
                maintenanceDesc: this.item.maintenanceDescription,
                outageId: '',
                currency: this.data.selected.plant.currency,
                status: 'I',
                globalProjectId: this.item.globalProjectId,
                unitId: this.data.selected.unit.id,
                unitMaintenancePlanningId: null,
                maintenanceDateChanged: false
            } as MaintenancePlanning);
        }
    }

    private outageIdChange(item: MaintenancePlanning): void {
        if (this.item.driver === DRIVERS.Once)
            return;

        if (!item.unitMaintenancePlanningId) {
            item.maintenanceDate = null;
        }

        const itemInMap = item.unitMaintenancePlanningId ? this.outageIdsMap[item.unitMaintenancePlanningId] : null;
        if (itemInMap) {
            item.maintenanceDate = itemInMap.date;
        }
    }

    private validateDateLimits(planning: MaintenancePlanning): void {
        const date = new Date(planning.maintenanceDate);
        if (this.data.selected.unit) {
            const lowerLimit = new Date(
                this.data.selected.unit.commissioningDate
            );
            const upperLimit = new Date(this.data.selected.unit.plannedEndDate);

            if (Helper.dateLessThan(date, lowerLimit)) {
                planning.maintenanceDate = Helper.parseStringFromDate(lowerLimit);
            } else if (Helper.dateGreaterThan(date, upperLimit)) {
                planning.maintenanceDate = Helper.parseStringFromDate(upperLimit);
            }
        }
    }

    private anyPlanningItemInEditMode(): boolean {
        return (
            this.planning
                .filter(function (p) {
                    return p.editMode;
                })
                .map(function (p) {
                    return p.editMode;
                })
                .indexOf(true) !== -1
        );
    }

    /**
     * *******************************************************
     * LINKED MAINTENANCES
     * *******************************************************
     */

    private createEmptyMaintenanceGroup(): MaintenanceGroup {
        return {
            id: 0,
            type: 0,
            unitId: null,
            items: [],
        };
    }

    private getGroupTypes(): void {
        this.groupTypes = [
            {
                'id': 0,
                'name': 'Alternative'
            },
            {
                'id': 1,
                'name': 'Parallel'
            },
            {
                'id': 2,
                'name': 'Support'
            }
        ];
    }

    private async getMaintenanceGroup(id: number): Promise<void> {
        if (id > 0) {
            const group = await this.masterService.maintenanceGroupService.getForPlantItemMaintenance(id);
            this.maintenanceGroup = {
                id: group.id,
                type: group.type,
                unitId: group.unitId,
                items: [...group.items],
            };
            group.items.forEach((groupItem) => {
                const itemInList = this.allItems.find(
                    (item) =>
                        item.plantItemMaintenanceId ===
                        groupItem.plantItemMaintenanceId
                );

                if (itemInList) {
                    groupItem.plantArea = itemInList.plantArea;
                    groupItem.category = itemInList.category;
                    groupItem.maintenanceActivityString = itemInList.maintenanceActivityString;
                    groupItem.plantItemId = itemInList.plantItemId;
                    groupItem.driver = itemInList.driver;
                    groupItem.driver2 = itemInList.driver2;
                    groupItem.maintenanceDescription = itemInList.maintenanceDescription;
                    groupItem.tolerance = itemInList.tolerance;
                    groupItem.tolerance2 = itemInList.tolerance2;
                }
            });
        }
    }

    private async saveMaintenanceGroup(
        isLinked: boolean,
        newPlantItemMaintenanceId: number | null = null
    ): Promise<void> {
        if (this.data.selected.unit) {
            const maintenanceGroupId = newPlantItemMaintenanceId && newPlantItemMaintenanceId > 0 ? 0 : this.maintenanceGroup.id;
            const maintenanceGroupRequest = {
                id: maintenanceGroupId,
                type: this.maintenanceGroup.type,
                unitId: this.data.selected.unit.id,
                currentPlantItemMaintenanceId : this.item.plantItemMaintenanceId,
                isCurrentMaintenanceBeingLinked : isLinked,
                items: isLinked
                    ? this.maintenanceGroup.items.map((item) => {
                        return {
                            id: item.id,
                            position: item.position,
                            maintenanceGroupId,
                            plantItemMaintenanceId: item.plantItemMaintenanceId,
                            interval: item.interval,
                            interval2: item.interval2
                        };
                    })
                    : []
            };

            // If the maintenance item was newly added, set the id in the group before saving.
            if (newPlantItemMaintenanceId) {
                maintenanceGroupRequest.items.forEach((item) => {
                    if (item.plantItemMaintenanceId === 0) {
                        item.plantItemMaintenanceId = newPlantItemMaintenanceId;
                    }
                });
            }

            if (this.maintenanceGroup.id > 0 && !newPlantItemMaintenanceId) {
                maintenanceGroupRequest.id = this.maintenanceGroup.id;
                await this.masterService.maintenanceGroupService
                    .update(maintenanceGroupRequest)
                    .catch(this.onMaintenanceGroupAddOrUpdateFailed);
            } else {
                await this.masterService.maintenanceGroupService
                    .add(maintenanceGroupRequest)
                    .catch(this.onMaintenanceGroupAddOrUpdateFailed);
            }
        }
    }

    private onMaintenanceGroupAddOrUpdateFailed(response: ServerError): void {
        if (
            !response ||
            !response.response ||
            response.response.status !== 400 ||
            !response.response.data
        )
            return;

        if (Array.isArray(response.response.data)) {
            for (let i = 0; i < this.maintenanceGroup.items.length; i++) {
                if (
                    response.response.data.indexOf(
                        this.maintenanceGroup.items[i].plantItemMaintenanceId
                    ) !== -1
                ) {
                    this.maintenanceGroup.items[i].alreadyAssigned = true;
                }
            }

            throw new Error(this.$i18n.t('maintenanceItemLinkingError').toString());
        } else if (response.response.data.message) {
            throw response;
        }
    }

    private onSupportGroupPlantItemChanged(): void {
        if (
            !this.item.linked ||
            this.maintenanceGroup.type !== MAINTENANCEGROUPTYPES.SUPPORT
        )
            return;

        this.selectedSupportGroupPlantItemIdChanged = true;
        this.itemsFilteredByPlantItem = this.selectedSupportGroupPlantItemId
            ? this.allItems.filter(
                (item) =>
                    item.plantItemId ===
                    this.selectedSupportGroupPlantItemId &&
                    item.plantItemMaintenanceId !==
                    this.item.plantItemMaintenanceId
            )
            : [];

        this.pendingItemForAdd = null;
        this.setMaintenanceItemDetailsForSupportGroup(this.pendingItemForAdd);
    }

    private onMaintenanceItemChanged(): void {
        if (
            !this.item.linked ||
            this.maintenanceGroup.type !== MAINTENANCEGROUPTYPES.SUPPORT
        )
            return;

        this.maintenanceGroup.items = [];
        if (this.pendingItemForAdd) {
            this.setMaintenanceItemDetailsForSupportGroup(
                this.pendingItemForAdd
            );
            this.addPendingItemToList();
            const newItem = this.getNewGroupItem(
                this.maintenanceGroup,
                {
                    plantArea: this.item.plantArea,
                    maintenanceActivityString: this.item.maintenanceActivityString,
                    category: this.item.category?.toString(),
                    plantItemId: this.item.plantItemId,
                    plantItemMaintenanceId: this.item.plantItemMaintenanceId,
                    maintenanceDescription: this.item.maintenanceDescription,
                    maintenanceId: this.item.maintenanceId?.toString(),
                    driver: this.item.driver.toString(),
                    driver2: this.item.driver2?.toString() || null,
                    tolerance: this.item.tolerance,
                    tolerance2: this.item.tolerance2
                }
            );
            this.maintenanceGroup.items.push(newItem);
        }
    }

    private addPendingItemToList(): void {
        if (this.maintenanceGroup.type !== MAINTENANCEGROUPTYPES.ALTERNATE) {
            const foundItem = this.maintenanceGroup.items.find(
                (item) =>
                    this.pendingItemForAdd &&
                    item.plantItemMaintenanceId ===
                    this.pendingItemForAdd.plantItemMaintenanceId
            );

            if (foundItem) return;
        }
        const newItem = this.getNewGroupItem(
            this.maintenanceGroup,
            this.pendingItemForAdd
        );
        this.maintenanceGroup.items.push(newItem);
    }

    private getNewGroupItem(maintenanceGroup: MaintenanceGroup, pendingItemForAdd: PlantItemItems | null): MaintenanceGroupItem {
        return {
            id: 0,
            position: maintenanceGroup.items.length,
            plantArea: pendingItemForAdd?.plantArea,
            maintenanceActivityString: pendingItemForAdd?.maintenanceActivityString,
            category: pendingItemForAdd?.category,
            plantItemId: pendingItemForAdd?.plantItemId,
            plantItemMaintenanceId: pendingItemForAdd?.plantItemMaintenanceId,
            maintenanceGroupId: pendingItemForAdd?.maintenanceGroupId,
            maintenanceDescription: pendingItemForAdd?.maintenanceDescription,
            maintenanceId: pendingItemForAdd?.maintenanceId,
            driver: maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? pendingItemForAdd?.driver : null,
            driver2: maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? pendingItemForAdd?.driver2 : null,
            tolerance: maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? pendingItemForAdd?.tolerance : null,
            tolerance2: maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? pendingItemForAdd?.tolerance2 : null
        };
    }

    private setMaintenanceItemDetailsForSupportGroup(selectedMaintenance: PlantItemItems | null): void {
        this.item.driver = selectedMaintenance?.driverId || 0;
        this.item.driver2 = selectedMaintenance?.driver2Id || null;
        this.item.itemModel = selectedMaintenance?.modelId || 0;
        this.item.model2 = selectedMaintenance?.model2Id || null;
        this.item.interval = selectedMaintenance?.interval || 0;
        this.item.interval2 = selectedMaintenance?.interval2 || null;
        this.item.tolerance = selectedMaintenance?.tolerance || 10;
        this.item.tolerance2 = selectedMaintenance?.tolerance2 || null;
        this.item.customInputs = selectedMaintenance?.customInputs || null;
        this.item.pleLimit = selectedMaintenance?.pleLimit || null;
        this.item.pleLimit2 = selectedMaintenance?.pleLimit2 || null;
    }

    private onLinkedRadioChanged(isLinked: boolean): void {
        this.maintenanceGroup.type = MAINTENANCEGROUPTYPES.ALTERNATE;
        this.resetMaintenanceGroupItems(isLinked);
    }

    private resetMaintenanceGroupItems(value: boolean): void {
        this.maintenanceGroup.items = [];
        this.pendingItemForAdd = null;
        this.selectedSupportGroupPlantItemId = null;


        this.itemsFilteredByPlantItem = this.maintenanceGroup.type !== MAINTENANCEGROUPTYPES.SUPPORT
            ? this.allItems.filter(item => item.plantItemId === this.item.plantItemId)
            : [];

        if (this.maintenanceGroup.type === MAINTENANCEGROUPTYPES.SUPPORT)
            this.setMaintenanceItemDetailsForSupportGroup(this.pendingItemForAdd);

        if (value === true && this.maintenanceGroup.type !== MAINTENANCEGROUPTYPES.SUPPORT) {
            const maintenanceActivity = this.maintenanceActivities.find(m => m.id === this.item.maintenanceActivity);
            const category = this.maintenanceCategories.find((c) => c.id === this.item.category);
            const newItem = {
                id: 0,
                plantArea: this.item.plantArea,
                maintenanceActivity: maintenanceActivity && maintenanceActivity.name,
                maintenanceActivityString: this.item.maintenanceActivityString,
                category: category && category.name,
                plantItemId: this.item.plantItemId,
                position: this.maintenanceGroup.items.length,
                plantItemMaintenanceId: this.item.plantItemMaintenanceId,
                maintenanceGroupId: this.maintenanceGroup.id,
                maintenanceDescription: this.item.maintenanceDescription,
                driver: this.maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? this.item.driverString : null,
                driver2: this.maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? this.item.driverString2 : null,
                tolerance: this.maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? this.item.tolerance : null,
                tolerance2: this.maintenanceGroup.type === MAINTENANCEGROUPTYPES.ALTERNATE ? this.item.tolerance2 : null
            };
            this.maintenanceGroup.items.push(newItem);

            if (this.item.plantItemMaintenanceId === 0) {
                this.itemsFilteredByPlantItem.push(newItem);
            }
        }
        this.$forceUpdate();
    }

    private drag(event: any, item: MaintenanceGroupItem): void {
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('maintenanceItem', JSON.stringify(item));
    }

    private allowDrop(event: any): void {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }

    private handleDragEnter(event: any, index: number): void {
        event.preventDefault();
        this.dragPosition = index;
    }

    private handleDragLeave(): void {
        this.dragPosition = -1;
    }

    private drop(event: any): void {
        event.preventDefault();

        const transferedItem = JSON.parse(event.dataTransfer.getData('maintenanceItem'));

        this.maintenanceGroup.items.splice(this.maintenanceGroup.items.findIndex((i) => i.position === transferedItem.position), 1);
        this.maintenanceGroup.items.splice(this.dragPosition, 0, transferedItem);
        this.maintenanceGroup.items.forEach((el, index: number) => el.position = index);
        this.handleDragLeave();
    }

    private dragEnd(event: any): void {
        event.preventDefault();
        this.handleDragLeave();
    }

    private dragStarted(): void {
        this.isDragActive = true;
    }

    private dragEnded(): void {
        this.isDragActive = false;
    }

    private setGroupItemDataOnChange(): void {
        if (this.item.linked) {
            const item = this.maintenanceGroup.items.find(
                (i) =>
                    i.plantItemMaintenanceId ===
                    this.item.plantItemMaintenanceId
            );
            if (item) {
                const activity = this.maintenanceActivities.find(
                    (m) => m.id === this.item.maintenanceActivity
                );
                item.maintenanceActivity = activity && activity.name;
                item.category = this.maintenanceCategories.find(
                    (c) => c.id === this.item.category
                )?.name;
            }
        }
    }

    private getPlantItemName(plantItemId: number): string | null {
        const plantItem = this.plantItems.filter(
            (pi) => pi.id === plantItemId
        )[0];
        return plantItem ? plantItem.name : null;
    }

    private shouldShowPleLimit(kksCode: string | null, driver: number): boolean {
        if (kksCode === null) {
            kksCode = this.kksCodeSelectedItem;
        }
        return (
            !!kksCode &&
            kksCode.startsWith('MB') &&
            (driver === DRIVERS.EOH ||
                driver === DRIVERS.OH ||
                driver === DRIVERS.Starts)
        );
    }

    /**
     * *******************************************************
     * PLANT ITEM TYPES
     * *******************************************************
     */

    private async getPlantItems(): Promise<void> {
        try {
            if (this.data.selected.unit) {
                this.plantItems =
                    await this.masterService.plantItemService.getPlantItems(
                        this.data.selected.unit.id
                    );
            }
            this.supportGroupPlantItems = this.plantItems
                .map((pi) => {
                    return {
                        id: pi.id,
                        plantArea: pi.plantArea,
                        name: pi.name,
                    };
                })
                .sort((a, b) => (a.plantArea > b.plantArea) ? 1 : ((b.plantArea > a.plantArea) ? -1 : (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0))));
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingPlantItems')
                });
            }
        }
    }

    private async plantItemChange(item: MaintenanceItem): Promise<void> {
        this.plantItemChanged = true;
        const plantItem = this.plantItems.find(function (el): boolean {
            return el.id === item.plantItemId;
        });
        if (plantItem) {
            this.kksCodeSelectedItem = plantItem.kksCode;
            item.plantArea = plantItem.plantArea;
            this.itemsFilteredByPlantItem = this.allItems.filter(
                (item) => plantItem && item.plantItemId === plantItem.id
            );
        }

        if (this.itemsFilteredByPlantItem.length > 0) {
            await this.getMaintenanceGroup(
                this.itemsFilteredByPlantItem[0].plantItemMaintenanceId
            );
        }

        this.$forceUpdate();
    }

    /**
     * *******************************************************
     * MAINTENANCE TYPES
     * *******************************************************
     */

    private getMaintenanceActivities(): void {
        try {
            this.maintenanceActivities = this.masterService.outageStrategyService.getMaintenanceActivities();
            this.filteredMaintenanceActivities = this.maintenanceActivities.slice();
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingMaintTypes')
                });
            }
        }
    }

    private getMaintenanceTypes(): void {
        try {
            this.maintenanceTypes = this.masterService.maintenanceService.getMaintenanceTypes();
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingMaintTypes')
                });
            }
        }
    }

    private getMaintenanceCategories(): void {
        try {
            this.maintenanceCategories = this.masterService.maintenanceService.getCategories();
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingMaintCategories')
                });
            }
        }
    }

    private categoryChange(): void {
        const needsConfirmation = this.item.maintenanceId && this.selectedCategory !== this.originalMaintenanceCategory;
        if (needsConfirmation)
            ($('#categoryChangeConfirmationModal') as any).modal('show');
        else
            this.confirmCategoryChange();
    }

    private confirmCategoryChange(): void {
        this.item.category = this.selectedCategory;
        this.filterMaintenanceActivities();
        this.setGroupItemDataOnChange();
        this.item.ptRiskNumber = null;
        this.item.maintenanceId =
            this.item.category === this.originalMaintenanceCategory
                ? this.originalMaintenanceId
                : null;
        this.$forceUpdate();
        ($('#categoryChangeConfirmationModal') as any).modal('hide');
    }

    private cancelCategoryChange(): void {
        this.selectedCategory = this.item.category;
        ($('#categoryChangeConfirmationModal') as any).modal('hide');
    }

    private filterMaintenanceActivities(): void {
        if (this.item.category === MAINTENANCECATEGORIES.Statutory) {
            this.item.maintenanceActivity =
                MAINTENANCE_ACTIVITIES.Statutory_Inspection;
            this.filteredMaintenanceActivities =
                this.maintenanceActivities.filter(
                    (m) => m.id !== MAINTENANCE_ACTIVITIES.Upgrade
                );
        } else {
            this.filteredMaintenanceActivities =
                this.maintenanceActivities.filter(
                    (m) => m.id !== MAINTENANCE_ACTIVITIES.Statutory_Inspection
                );
            if (this.item.category !== MAINTENANCECATEGORIES.Risk) {
                this.filteredMaintenanceActivities =
                    this.filteredMaintenanceActivities.filter(
                        (m) => m.id !== MAINTENANCE_ACTIVITIES.Upgrade
                    );
            }
        }
    }

    private maintenanceActivityChange(): void {
        this.activityItemChanged = true;
        if (this.item.maintenanceActivity === MAINTENANCE_ACTIVITIES.Day_To_Day_Maintenance) {
            this.item.category = MAINTENANCECATEGORIES.Maintenance;
            this.item.driver = DRIVERS.OH;
            this.item.itemModel = MODELS.Linked_with_OH;
            this.item.isProject = false;
            this.item.isOpex = true;
            this.item.lumpSum = true;
            this.item.customInputs = this.resetCustomInputs();
        } else {
            this.item.itemModel = MODELS.None;
        }
        this.setGroupItemDataOnChange();
        this.$forceUpdate();
    }

    /**
     * *******************************************************
     * DRIVER TYPES
     * *******************************************************
     */

    private async getDrivers(): Promise<void> {
        try {
            this.drivers = (await this.masterService.driverService.getDrivers()).items;
            this.firstDrivers = this.drivers.slice();
            this.secondDrivers = this.filterDrivers(this.drivers.slice(), DRIVERS.Once);
        } catch (error) {
            if (error !== undefined && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingDriverTypes')
                });
            }
        }
    }

    private setDriver(secondDriver: boolean): void {
        if (!secondDriver) {
            this.driverItemChanged = true;
        }
        else {
            this.driver2ItemChanged = true;
        }

        if (secondDriver) {
            this.item.model2 = MODELS.None;
            if (this.item.driver2) {
                this.firstDrivers = this.filterDrivers(this.drivers, this.item.driver2);
            }
        }
        else {
            this.item.itemModel = MODELS.None;
            this.secondDrivers = this.filterDrivers(this.filterDrivers(this.drivers, this.item.driver), DRIVERS.Once);

            if (this.item.driver === DRIVERS.Once || this.item.driver === DRIVERS.Condition_Based) {
                if (this.onceDriverDate) {
                    this.onceDriverDateChange(this.onceDriverDate);
                }
                if (this.item.driver === DRIVERS.Once) {
                    this.item.driver2 = null;
                    this.item.model2 = null;
                    this.item.interval2 = null;
                    this.item.tolerance2 = null;
                    this.secondDriver = false;
                }
            }
        }
        this.$forceUpdate();

    }

    private filterDrivers(driversToFilter: Driver[], id: number): Driver[] {
        return driversToFilter.filter((d) => d.id !== id);
    }

    private setModel(secondDriver: boolean): void {
        this.$forceUpdate();
        this.item.customInputs = this.resetCustomInputs();
        this.handleModelChanged(secondDriver);
    }

    /**
     * *******************************************************
     * COPY MAINTENANCE ITEM
     * *******************************************************
     */

    private maintenanceCopyData = {
        inProgress: false,
        openCopyModal: false,
        openMaintenanceModal: false,
        selectedPlantItemId: null as number | null,
        selectedArea: null as string | null,
        maintenanceDescription: null as string | null,
        areas: [] as MaintenanceAreas[],
        plantItemSelection: [] as PlantItemKks[],
        maintenanceDescriptionValid: true,
        plantItemIdValid: true,
        areaValid: true,
        validate: {
            plantItemIdValid: (): boolean => {
                return this.maintenanceCopyData.selectedPlantItemId !== null;
            },
            areaValid: (): boolean => {
                return this.maintenanceCopyData.selectedArea !== null;
            },
            descriptionValid: (): boolean => {
                return this.maintenanceCopyData.maintenanceDescription !== null;
            }
        }
    };

    private async initMaintenanceCopy(): Promise<void> {
        if (!this.item.plantItemMaintenanceId || this.item.plantItemMaintenanceId === 0) {
            return;
        }

        this.maintenanceCopyData.openCopyModal = true;
        this.maintenanceCopyData.selectedArea = null;
        this.maintenanceCopyData.selectedPlantItemId = null;
        this.maintenanceCopyData.maintenanceDescription = this.item.maintenanceDescription;
        this.maintenanceCopyData.areas = [];
        this.maintenanceCopyData.plantItemSelection = [];
        this.maintenanceCopyData.areaValid = true;
        this.maintenanceCopyData.maintenanceDescriptionValid = true;
        this.maintenanceCopyData.plantItemIdValid = true;
        ($(this.selector) as any).modal('hide');

        try {
            if (this.data.selected.unit) {
                const selection = await this.masterService.plantItemService.getPlantItemSelection(this.data.selected.unit.id);
                for (const item of selection) {
                    const area = this.maintenanceCopyData.areas.find((a) => a.name === item.plantArea);
                    if (!area) {
                        this.maintenanceCopyData.areas.push({
                            name: item.plantArea,
                            items: [item]
                        });
                    } else {
                        area.items.push(item);
                    }
                }
                const item = selection.find((i) => i.id === this.item.plantItemId);
                if (item) {
                    this.maintenanceCopyData.selectedArea = item.plantArea;
                    this.maintenanceCopyData.selectedPlantItemId = item.id;
                }
                this.areaChange(this.maintenanceCopyData.selectedArea);
            }
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorGettingPlantItems')
            });
        }
    }

    private cancelCopy(): void {
        this.maintenanceCopyData.openMaintenanceModal = true;
        ($('#copyMaintenanceModal') as any).modal('hide');
    }

    private async copyMaintenance(): Promise<void> {
        this.maintenanceCopyData.plantItemIdValid = true;
        this.maintenanceCopyData.areaValid = true;
        this.maintenanceCopyData.maintenanceDescriptionValid = true;
        let invalid = false;
        if (!this.maintenanceCopyData.validate.plantItemIdValid()) {
            this.maintenanceCopyData.plantItemIdValid = false;
            invalid = true;
        }
        if (!this.maintenanceCopyData.validate.areaValid()) {
            this.maintenanceCopyData.areaValid = false;
            invalid = true;
        }
        if (!this.maintenanceCopyData.validate.descriptionValid()) {
            this.maintenanceCopyData.maintenanceDescriptionValid = false;
            invalid = true;
        }

        if (invalid) {
            return;
        }

        this.maintenanceCopyData.inProgress = true;
        try {
            if (this.data.selected.unit && this.data.selected.plant) {
                const request = {
                    plantItemMaintenanceId: this.item.plantItemMaintenanceId,
                    plantItemId: this.maintenanceCopyData.selectedPlantItemId,
                    maintenanceDescription: this.maintenanceCopyData.maintenanceDescription,
                    unitMappingId: this.data.selected.unit.id,
                    plantMappingId: this.data.selected.plant.id
                };

                await this.masterService.dataCopyService.copyPlantItemMaintenance(request);
            }
            this.maintenanceCopyData.inProgress = false;
            ($('#copyMaintenanceModal') as any).modal('hide');

            EventBus.$emit(EventBus.GLOBAL.REFRESH);
        } catch (err) {
            this.maintenanceCopyData.inProgress = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorCopyingMaintenance')
            });
        }
    }

    private areaChange(selected: string | null): void {
        if (selected) {
            const area = this.maintenanceCopyData.areas.find(
                (a) => a.name === selected
            );
            if (area && area.items) {
                this.maintenanceCopyData.plantItemSelection = area.items;
                if (area.items.length === 1) {
                    this.maintenanceCopyData.selectedPlantItemId = area.items[0].id;
                }
            }
        }
    }

    /**
     * *******************************************************
     * COMMON
     * *******************************************************
     */

    private async toggleTab(selectedTab: TabItem): Promise<void> {
        this.errorMessage = '';
        await this.tabHandler.toggleTab(selectedTab);
        this.onceDriverPlanningDisabled = selectedTab === this.tabs.planning && this.item.driver === DRIVERS.Once;
        if (this.onceDriverPlanningDisabled && this.planning.length === 0 && this.data.selected.plant && this.data.selected.unit) {
            this.planning = [
                {
                    cost: this.item.cost,
                    costTwoYearsPrior: null,
                    costPreviousYear: null,
                    costNextYear: 0,
                    maintenanceDate: this.onceDriverDate,
                    duration: this.item.duration,
                    id: -1,
                    maintenanceItemId: -1,
                    editMode: true,
                    leadingItem: false,
                    maintenanceDesc: this.item.maintenanceDescription,
                    outageId: '',
                    currency: this.data.selected.plant.currency,
                    status: 'I',
                    globalProjectId: this.item.globalProjectId,
                    unitId: this.data.selected.unit.id,
                    unitMaintenancePlanningId: null,
                    maintenanceItemChanged: false
                } as MaintenancePlanning
            ];
        }
    }

    private initializeTooltips(): void {
        Helper.initializeTooltips();
    }

    private getCurrencySymbol(): string {
        return this.data.selected.plant !== null ?
            this.currencyHelper.getCurrencySymbolByValue(this.data.selected.plant.currency) : '';
    }

    private deleteItem(list: MaintenanceItem[], item: MaintenanceItem): void {
        list.splice(list.indexOf(item), 1);
    }

    private openEdit(list: MaintenanceItem[], item: MaintenanceItem): void {
        this.modalCommon.openEdit(list, item);
    }

    private closeEdit(item: MaintenancePlanning): void {
        this.setInputChanged();
        if (this.isInputInvalid(item.maintenanceDate, item.maintenanceDateChanged || true)) {
            return;
        }
        this.modalCommon.closeEdit(item);
    }

    private displayHours(item: MaintenanceItem): boolean {
        return (
            item.driver !== this.driverTypes.Years &&
            item.driver !== this.driverTypes.Starts &&
            item.driver !== this.driverTypes.Once &&
            item.driver !== this.driverTypes.Condition_Based &&
            item.driver !== this.driverTypes.Months
        );
    }

    private resetCustomInputs(): CustomInputs {
        return {
            kb: 1,
            boh: 1,
            kg: 1,
            k1: 1,
            k2: 1,
            k3: 1,
            s: 1,
            ez: 1,
            en: 1,
            ks: 1,
            linkedWithOH: 1,
            dtDr: 1,
            oHr: 1
        };
    }

    private buildTabs(): TabElement {
        return {
            item: {
                active: false,
                visited: false,
                name: 'item',
                load: async (): Promise<void> => { }
            },
            planning: {
                active: false,
                visited: false,
                name: 'planning',
                load: async (): Promise<void> => { }
            }
        };
    }

    private async handleOpenMaintenanceItem(data: MaintenanceItemDataItem): Promise<void> {
        this.tabHandler.resetTabs();
        this.allItems = [...data.list];
        this.maintenanceGroup = this.createEmptyMaintenanceGroup();
        this.initItem(data.item);
        this.selectedSupportGroupPlantItemId = null;
        this.pendingItemForAdd = null;
    }

    private customInputsInvalid(driver: number, model: number | null, customInputs: CustomInputs): boolean {
        if (driver === this.driverTypes.EOH && model === this.modelTypes['VGB_Generator']) {
            return this.isInputInvalid(customInputs.linkedWithOH, this.customInputChanged.linkedWithOH, 3) || this.isInputInvalid(customInputs.s, this.customInputChanged.s, 1);
        } else if (driver === this.driverTypes.OH && model === this.modelTypes['Linked_with_OH']) {
            return this.isInputInvalid(customInputs.dtDr, this.customInputChanged.dtDr, 2) || this.isInputInvalid(customInputs.oHr, this.customInputChanged.oHr, 2) || this.isInputInvalid(customInputs.linkedWithOH, this.customInputChanged.linkedWithOH, 2);
        } else if (driver === this.driverTypes.EOH && model === this.modelTypes.Custom) {
            return this.isInputInvalid(customInputs.k1, this.customInputChanged.k1, 2) || this.isInputInvalid(customInputs.k2, this.customInputChanged.k2, 2) || this.isInputInvalid(customInputs.k3, this.customInputChanged.k3, 2);
        } else if (driver === this.driverTypes['LTE/Ageing'] && model === this.modelTypes.Custom) {
            return this.isInputInvalid(customInputs.ez, this.customInputChanged.ez, 2) || this.isInputInvalid(customInputs.en, this.customInputChanged.en, 2) || this.isInputInvalid(customInputs.ks, this.customInputChanged.ks, 2) ||
                this.isInputInvalid(customInputs.k1, this.customInputChanged.k1, 2) || this.isInputInvalid(customInputs.k2, this.customInputChanged.k2, 2) || this.isInputInvalid(customInputs.k3, this.customInputChanged.k3, 2);
        }
        return false;
    }

    private removeLinkedMaintenanceItem(itemPosition: number): void {
        this.maintenanceGroup.items = this.maintenanceGroup.items.filter((item) => item.position !== itemPosition);
        this.maintenanceGroup.items.forEach((el, index: number) => el.position = index);
    }

    private overwriteUELValue(): void {
        this.item.uEL = this.importedUel;
        this.item.uelLabel = this.uelStates.Imported;
        (this.$refs[this.keepUelValueModalRef] as Pui.Lightbox)?.close();
    }
}
