

















import { Component, Vue } from 'vue-property-decorator';
import NavbarComponent from './components/navbar/navbar.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loading-spinner/loader.vue';
import './main.less';
import { coodeSdk, MSP_SCOPE } from './utils/coode-sdk-config'

@Component({
    name: 'app',
    components: {
        'navbar': NavbarComponent,
        'snackbar': Snackbar,
        'loader': Loader,
    },
})
export default class App extends Vue {
    private signedIn = false;
    private isDataLoaded = true;

    private async created(): Promise<void> {
        await this.setupCoodeSdk();
    }

    private async setupCoodeSdk(): Promise<void> {
        await coodeSdk.auth.loadAuthModule();
        await coodeSdk.auth.attemptSsoSilent('loginRedirect');

        coodeSdk.backend.request.api.setBaseUrl(process.env.VUE_APP_API_BASE_URL);

        coodeSdk.backend.request.api.setTokenCallback(
            async () => coodeSdk.auth.getToken(MSP_SCOPE)
        );

        this.signedIn = coodeSdk.auth.isAuthenticated();

        if (this.signedIn) {
            const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
            await coodeSdk.core.userAccessManagement.setPermissions(useCaseId);
        }
    }

    private handleNavbarLoading(loading: boolean): void {
        this.isDataLoaded = loading;
    }
}
