import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Data } from '@/utils';
import { BudgetItem, Unit } from '@/utils/interfaces';
import { getters } from '@/store/types';
import { EnerlyticsECharts, getDefaultOption, getDefaultBarSeries } from '@enerlytics/echarts';
import {
    PUICOLOR_SEVERITY_10,
    PUICOLOR_SEVERITY_60,
    PUICOLOR_WARM_GREY,
    PUICOLOR_SEVERITY_30
} from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({
    components: {
        echarts: EnerlyticsECharts,
    }
})
export default class BudgetDeviationComponent extends Vue {
    private data: Data = Data.Instance;
    private currencySymbol = this.data.getCurrencySymbol;

    private readonly budgetDeviationColors = [PUICOLOR_SEVERITY_10, PUICOLOR_SEVERITY_30, PUICOLOR_SEVERITY_60];

    private readonly colorGrey = PUICOLOR_WARM_GREY;
    private tooLate: number[][] = [];
    private ok: number[][] = [];
    private tooEarly: number[][] = [];

    @Prop()
    private unit: Unit | undefined;

    get budget(): BudgetItem { return this.$store.getters[getters.OVERVIEW__GET__BUDGET_BY_UNIT_ID](this.unit?.sid); }

    @Watch('budget', { immediate: true })
    private watchBudgetDeviationData(): void {
        this.setBudgetDeviation();
    }

    private get budgetDeviationOption(): any {
        const currentYear = new Date().getFullYear();
        const defaultOption = [
            [currentYear, 0],
            [currentYear + 1, 0],
            [currentYear + 2, 0],
            [currentYear + 3, 0]
        ];
        const option = getDefaultOption({
            legendShow: true,
            tooltipShow: true,
            xAxisType: 'category',
            yAxisType: 'value',
            yAxisFormatter: this.yAxisFormat,
            series: [
                {
                    ...getDefaultBarSeries({ color: this.budgetDeviationColors[0] }),
                    data: this.tooLate.length ? this.tooLate : defaultOption,
                    name: this.$t('tooLate'),
                    stack: 'budgetDeviation',
                },
                {
                    ...getDefaultBarSeries({ color: this.budgetDeviationColors[1] }),
                    data: this.ok.length ? this.ok : defaultOption,
                    name: this.$t('ok'),
                    stack: 'budgetDeviation',
                },
                {
                    ...getDefaultBarSeries({ color: this.budgetDeviationColors[2] }),
                    data: this.tooEarly.length ? this.tooEarly : defaultOption,
                    name: this.$t('tooEarly'),
                    stack: 'budgetDeviation',
                },
            ],
        });
        option.xAxis.axisLabel.overflow = 'none';
        option.xAxis.splitLine = false;
        option.yAxis.splitNumber = 2;
        option.yAxis.axisLine = false;
        option.yAxis.axisLabel.formatter = this.yAxisFormat;
        return option;
    }

    setBudgetDeviation(): void {
        const budget = this.budget;
        if (budget?.budgetDeviations) {
            this.tooLate = budget.budgetDeviations.map((item) => [
                new Date(item.date).getFullYear(),
                item.tooLateBudgetInThousands,
            ]);
            this.ok = budget.budgetDeviations.map((item) => [
                new Date(item.date).getFullYear(),
                item.okBudgetInThousands,
            ]);
            this.tooEarly = budget.budgetDeviations.map((item) => [
                new Date(item.date).getFullYear(),
                item.tooEarlyBudgetInThousands,
            ]);
        }
    }

    yAxisFormat(value: string): string {
        return `${value} k${this.currencySymbol}`;
    }
}
