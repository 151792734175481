


















import Vue from 'vue'
import Component from 'vue-class-component';
import OutageStrategyComponent from './scorecard/scorecard-component.vue';
import OutageClockComponent from './clock/outage-clock.vue';


@Component({
    name: 'outage-view',
    components: {
        'outage-clock': OutageClockComponent,
        'outage-scorecard': OutageStrategyComponent,
    }
})
export default class OutageView extends Vue {
    private tabs = ['outageClock', 'scorecard'];
    private currentTab = 0;


    constructor() {
        super();
    }

    private toggle(tab: number): void {
        this.currentTab = tab;
    }
}
