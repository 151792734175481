import Vue from 'vue';

export class ModalCommon {
    public editMode(item: any, edit: any): void {
        Vue.set(item, 'editMode', edit);
    }

    public closeAllEditors(list: any[]): void {
        for (const element of list) {
            this.editMode(element, false);
        }
    }

    public closeEdit(item: any): void {
        this.editMode(item, false);
        if (item.status == null) {
            Vue.set(item, 'status', 'U');
        }
    }

    public openEdit(list: any[], item: any): void {
        this.closeAllEditors(list);
        this.editMode(item, true);
    }

    public deleteItem(list: any[], item: any): void {
        list.splice(list.indexOf(item), 1);
    }

    public hideModal(selector: string): void {
        if ($(selector).length === 0) {
            console.warn('Could not close modal: ' + selector + ' not found.');
            return;
        }
        ($(selector) as any).modal('hide');
    }
}
