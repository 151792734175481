/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-this-alias */
import Vue from 'vue';
import Component from 'vue-class-component';
import { MasterService } from '@/services/master-service';
import { Data, EventBus, Helper } from '@/utils';
import { UnitAttributes, ServerError, OutageScorecardPayload, OutageScorecardItem, GenericSort, OutageScorecardItemValue } from '@/utils/interfaces';
import ColumnSortingComponent from './column-sorting/column-sorting-component.vue';
import AppSecurity from '@/utils/app-security';
import ScorecardListItemComponent from './scorecard-list-item.vue'
import MilestoneAccordionComponent from './milestone-accordion.vue'
import PhaseHeaderComponent from './phase-header-component.vue'
import { PHASES } from '@/utils/scorecard-constants';
import { actions, getters } from '@/store/types';

@Component({
    components: {
        'phase-header': PhaseHeaderComponent,
        'scorecard-list-item': ScorecardListItemComponent,
        'milestone-accordion': MilestoneAccordionComponent,
        'column-sorting': ColumnSortingComponent
    }
})
export default class ScorecardComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private appSecurity = new AppSecurity();
    private data: Data;

    /* switches */
    private loading = false;
    private pleaseWait = false;
    private userHasWriteAccessToPlant = false;

    /* data */
    private scorecards: OutageScorecardItem[] = [];
    private selectedScorecard: OutageScorecardItem | null = null;
    private kpisGroupedByPhases = this.initKpiGroups();
    private phases = PHASES;
    private activeSort: GenericSort | null = null;

    get unitsWithAttributes(): UnitAttributes[] { return this.$store.getters[getters.UNIT__GET_UNITS_WITH_ATTRIBUTES]; }

    constructor() {
        super();
        this.data = Data.Instance;
        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }


    async created(): Promise<void> {
        if (this.data.selected.plant && this.data.selected.unit)
            await this.load();
    }

    mounted(): void {
        $('#scorecardModal').on('hidden.bs.modal', () => {
            this.selectedScorecard = null;
            this.kpisGroupedByPhases = this.initKpiGroups();
        });
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        $('#scorecardModal').off('hidden.bs.modal');
    }

    private async load(): Promise<void> {
        this.loading = true;
        try {
            const selectedPlant = this.data.selected.plant;
            let units: UnitAttributes[] = [];
            if (selectedPlant) {
                if (this.unitsWithAttributes?.length) {
                    units = this.unitsWithAttributes.filter((unit: UnitAttributes) => unit.plantSid === selectedPlant.sid);
                }

                if (units.length === 0) {
                    units = await this.masterService.unitService.getUnitsAttributes([selectedPlant.sid]);
                }

                const payload: OutageScorecardPayload = {
                    plant: {
                        plantSID: selectedPlant.plantSID,
                        name: selectedPlant.name,
                        units: units?.map((unit: UnitAttributes) => ({
                            machineSID: unit.machineSid.toString(),
                            name: unit.unitName
                        }))
                    }
                };
                this.scorecards = await this.masterService.outageScorecardService.getForPlant(payload);

                this.scorecards = this.scorecards.map((scorecard) => ({
                    ...scorecard,
                    unitName: units?.find((unit) => unit.unitName === scorecard.unitName)?.businessAbbreviation || scorecard.unitName,
                }));

                Helper.initializeTooltips();
                this.userHasWriteAccessToPlant = this.data.selected.plant
                    ? await this.appSecurity.hasWriteAccessToPlant(this.data.selected.plant.plantSID) || await this.appSecurity.hasAdminRights()
                    : false;
            }
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorGettingScorecards')
            });
        }
        this.loading = false;
    }

    private showScorecardDetails(scorecard: OutageScorecardItem): void {
        this.selectedScorecard = Object.assign({}, scorecard);
        if (this.selectedScorecard) {
            this.kpisGroupedByPhases.initiation = this.selectedScorecard.outageScorecardValues.
                filter((v) => v.phase === this.phases.initiation);
            this.kpisGroupedByPhases.scoping = this.selectedScorecard.outageScorecardValues.
                filter((v) => v.phase === this.phases.scoping);
            this.kpisGroupedByPhases.preparation = this.selectedScorecard.outageScorecardValues.
                filter((v) => v.phase === this.phases.preparation);
            this.kpisGroupedByPhases.implementation = this.selectedScorecard.outageScorecardValues.
                filter((v) => v.phase === this.phases.implementation);
            this.kpisGroupedByPhases.closeOut = this.selectedScorecard.outageScorecardValues.
                filter((v) => v.phase === this.phases.closeOut);

            const phaseStrings: Record<number, string> = {
                0: 'initiation',
                1: 'scoping',
                2: 'preparation',
                3: 'implementation',
                4: 'closeOut'
            };
            const current = phaseStrings[this.selectedScorecard.currentPhase];
            for (const idx in phaseStrings) {
                const phase = phaseStrings[idx];

                if (phase === current) {
                    continue;
                }
                const button = $(`.${phase}-accordion`).find('button').first();
                const listContainer = $(`#collapse-${phase}`);
                if (button) {
                    button.addClass('collapsed');
                    button.attr('aria-expanded', 'false');
                    listContainer.removeClass('show');
                }
            }
        }
    }

    private isValid(): boolean {
        const hasNoCompletionDate = (kpi: OutageScorecardItemValue): boolean => !kpi.canHaveMaxScoreAnyTime && !kpi.completionDate;
        const hasNoAdditionalDate = (kpi: OutageScorecardItemValue): boolean => kpi.hasAdditionalDate && !kpi.additionalDate;

        const invalidKpi = (kpi: OutageScorecardItemValue): boolean => kpi.booleanValue ? hasNoCompletionDate(kpi) || hasNoAdditionalDate(kpi) : false;

        return !this.selectedScorecard?.outageScorecardValues.some(invalidKpi);
    }

    private async saveChanges(): Promise<void> {
        if (!this.isValid()) return;
        this.pleaseWait = true;

        try {
            this.scorecards = await this.masterService.outageScorecardService.updateForPlant(this.selectedScorecard as OutageScorecardItem);
            await this.load();
            ($('#scorecardModal') as any).modal('hide');
            await this.$store.dispatch(actions.OUTAGE__RESET_STATE);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                class: 'error',
                message: this.$i18n.t('errorSavingScorecard')
            });

            if ((err as ServerError).response) {
                EventBus.$emit(EventBus.OUTAGE_SCORECARD.VALIDATION_ERROR, (err as ServerError).response.data);
            }
        }

        this.pleaseWait = false;
    }

    private initKpiGroups(): Record<string, OutageScorecardItemValue[]> {
        return {
            initiation: [],
            scoping: [],
            preparation: [],
            implementation: [],
            closeOut: []
        };
    }

    private sort(fields: Array<keyof OutageScorecardItem>, direction: number): void {
        this.activeSort = { fields: fields, direction: direction };

        this.scorecards.sort(function (card1, card2): number {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                const firstVal = card1[field] || 0;
                const secondVal = card2[field] || 0;

                if (firstVal > secondVal)
                    return direction * 1;

                if (firstVal < secondVal)
                    return direction * -1;
            }

            return 0;
        });
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }
}
