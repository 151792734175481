import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show.snackbar',
                REFRESH: 'global.refresh',
                ADD_COMPANY: 'global.add-company',
                ADD_GROUP: 'global.add-group',
                ADD_PLANT: 'global.add-plant',
                COMPONENT_REFRESH: 'global.component-refresh'
            },
            LOGIN: {
                SHOW: 'login.show',
                LOGIN: 'login.success',
            },
            UNIT: {
                OPEN_UNIT_MODAL: 'unit.open-unit-modal',
                ADD_TRIGGER: 'unit.add-trigger',
                CANCEL_ADD_TRIGGER: 'unit.cancel-add-trigger',
                INIT_MD: 'unit.init-with-md',
                INIT: 'unit.init',
            },
            PLANT: {
                INIT_MD: 'plant.init-with-md',
                INIT_EXISTING: 'plant.init'
            },
            TABLE: {
                OPEN_PLANT_ITEM_MODAL: 'table.open-plant-item',
                OPEN_MAINTENANCE_ITEM: 'table.open-maintenance-item',
                OPEN_ACM_TOOLTIP_ITEM: 'table.open-acm-tooltip-item',
                OPEN_RISK_DETAILS_MODAL: 'table.open-risk-details-modal',
                OPEN_OPPORTUNITY_DETAILS_MODAL:
                    'table.open-opportunity-details-modal',
            },
            OUTAGE_CLOCK: {
                UPDATE_DISPLAYED_OUTAGES: 'outage-clock.update-displayed-outages',
            },
            OUTAGE_SCORECARD: {
                VALIDATION_ERROR: 'outage-scorecard.validation-error'
            }
        };
    },
});
