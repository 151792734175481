import { Constants, Connection } from '@/utils';
import { UnitPlanning, UnitOutage } from '@/utils/interfaces';
import qs from 'qs';

export class UnitPlanningService {
    private api: Connection = new Connection();

    public async getAllForPlant(
        unitMappingIds: number[],
        startYear: number,
        endYear: number
    ): Promise<any> {
        return (await this.api.get(Constants.UNIT_PLANNING.MAPPING + Constants.UNIT_PLANNING.MULTIPLE, {
            params: { unitMappingIds, startYear, endYear, },
            paramsSerializer: (params: any) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
        })).data.result.unitMappingMaintenancePlannings;
    }

    public async updateUnitPlanning(
        unitId: number,
        unitPlanning: UnitPlanning
    ): Promise<any> {
        return (await this.api.put(Constants.UNIT_PLANNING.MAPPING + unitId, unitPlanning)).data.result;
    }

    public async getOutageIds(unitId: number): Promise<UnitOutage[]> {
        return (await this.api.get(Constants.UNIT_PLANNING.MAPPING + Constants.UNIT_PLANNING.OUTAGES + unitId)).data.result.outageIds;
    }
}
