import { Connection, Constants } from '@/utils';
import { CancelToken } from 'axios';
import { OutageStrategyFilter, MaintenanceFilterIdName, OutageStrategyData, OutageStrategyPdf, OutageStrategyAllFiltersPayload, OutageStrategyUnitFilter } from '@/utils/interfaces';

export class OutageStrategyService {
    private api: Connection = new Connection();
    public getMaintenanceActivities(): MaintenanceFilterIdName[] {
        return [
            {
                id: 1,
                name: 'Major Overhaul / C-Revision',
                selected: false,
            },
            {
                id: 2,
                name: 'Minor Overhaul / B-Revision',
                selected: false,
            },
            {
                id: 3,
                name: 'Inspection / A-Revision',
                selected: false,
            },
            {
                id: 4,
                name: 'Inspection / B-Revision',
                selected: false,
            },
            {
                id: 5,
                name: 'Inspection / C-Revision',
                selected: false,
            },
            {
                id: 6,
                name: 'Overhaul',
                selected: false,
            },
            {
                id: 7,
                name: 'Day to day maintenance',
                selected: false,
            },
            {
                id: 8,
                name: 'Requalification',
                selected: false,
            },
            {
                id: 9,
                name: 'Replacement',
                selected: false,
            },
            {
                id: 10,
                name: 'Statutory Inspection',
                selected: false,
            },
            {
                id: 11,
                name: 'Upgrade',
                selected: false,
            },
            {
                id: 12,
                name: 'Modification',
                selected: false,
            },
            {
                id: 13,
                name: 'Lessons learned',
                selected: false,
            },
        ];
    }

    public async populateFilter(language: string, units: OutageStrategyUnitFilter[], cancelToken: CancelToken): Promise<OutageStrategyFilter> {
        return (
            await this.api.post(Constants.OUTAGE_STRATEGY.MAPPING + Constants.OUTAGE_STRATEGY.FILTER,
                {
                    language,
                    units,
                },
                { cancelToken }
            )
        ).data.result;
    }

    public async getOutageStrategy(filter: OutageStrategyAllFiltersPayload, cancelToken: CancelToken): Promise<OutageStrategyData> {
        return (await this.api.post(Constants.OUTAGE_STRATEGY.MAPPING, filter, { cancelToken })).data.result;
    }

    public async exportOutageStrategyPdf(filter: OutageStrategyAllFiltersPayload): Promise<OutageStrategyPdf> {
        const res = (await this.api.post(Constants.OUTAGE_STRATEGY.MAPPING + Constants.OUTAGE_STRATEGY.EXPORT_PDF, filter)).data.result;
        console.log(res)
        return res;
    }
}
