import { Connection } from '@/utils';
import { MAINTENANCE_ACTIVITIES } from '@/utils/maintenance-helper';
import { MaintenanceActivity, MaintenanceActivityObj } from '@/utils/interfaces';

export class MaintenanceService {
    private api: Connection = new Connection();

    public getMaintenanceActivities(): MaintenanceActivityObj {
        return MAINTENANCE_ACTIVITIES;
    }

    public getCategories(): MaintenanceActivity[] {
        return [
            { id: 0, name : 'Maintenance' },
            { id: 1, name : 'Risk' },
            { id: 2, name : 'Opportunity' },
            { id: 3, name : 'Statutory' }
        ]
    }

    public getMaintenanceTypes(): MaintenanceActivity[] {
        return [
            { id: 0, name : 'Corrective' },
            { id: 1, name : 'Mandatory Preventive' },
            { id: 2, name : 'Non Mandatory Preventive' }
        ]
    }
}
