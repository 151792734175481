import { Constants, Connection } from '@/utils';

export class ACMService {
    private api: Connection = new Connection();

    public async getSites(): Promise<any> {
        return (await this.api.get('acm/sites')).data.result;
    }

    public async getUnits(siteId: string): Promise<any> {
        return (await this.api.get('acm/units/' + siteId)).data.result;
    }

    public async getUnitItems(unitId: number): Promise<any> {
        return (await this.api.get('acm/unitItems/' + unitId)).data.result;
    }

    public async getUnitAlerts(unitId: number): Promise<any> {
        return (await this.api.get('acm/alerts/' + unitId)).data.result;
    }

    /* new config for acm alerts */
    public async getCredentials(): Promise<any> {
        return (
            await this.api.get(
                Constants.ACM_SERVICE.MAPPING +
                    Constants.ACM_SERVICE.CREDENTIALS
            )
        ).data.result;
    }
}
