import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, EventBus } from '@/utils';
import { Common } from '@/components/summary/common';
import { MasterService } from '@/services/master-service';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';
import { RemainingLifeSummary } from '@/utils/interfaces';

@Component({
})
export default class RemainingLifeSummaryComponent extends Vue {
    private readonly axios = require('axios');
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* utils */
    private data: Data = Data.Instance;

    /* switches */
    private loading = false;

    /* data */
    private result: RemainingLifeSummary;
    private source = {} as CancelTokenSource;

    constructor() {
        super();
        this.result = { areas: [] };
        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }

    async created(): Promise<void> {
        if (this.data.selected.unit) {
            await this.load();
        }
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }

    private async load(): Promise<void> {
        this.loading = true;
        try {
            const language = this.data.getLanguage();
            const selectedPlant = this.data.selected.plant;
            const selectedUnit = this.data.selected.unit;

            if (selectedPlant && selectedUnit) {

                const payload = {
                    plantName: selectedPlant.name,
                    unitName: selectedUnit.name,
                    unitMappingId: selectedUnit.id,
                    plantMappingId: selectedPlant.id,
                    unitSID: selectedUnit.sid.toString(),
                    plantSID: selectedPlant.sid,
                    language
                };
                this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
                this.source = this.axios.CancelToken.source();
                this.result = await this.masterService.remainingLifeService.getRemainingLife(payload, this.axios.cancelToken);
            }
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
            if (this.axios.isCancel(error)) {
                return;
            }
            if (error !== undefined && error.response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$i18n.t('errorGettingRemainingLife')
                });
            }
        }
    }

    private getProgressBarClass(remainingLife: number): string[] {
        return Common.getProgressBarClass(remainingLife);
    }

    private getProgressBarColor(remainingLife: number): string[] {
        return Common.getProgressBarColor(remainingLife);
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }
}
