



















































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { TranslateResult } from 'vue-i18n';
import { PHASES } from '@/utils/scorecard-constants';
import { OutageScorecardItem } from '@/utils/interfaces';

@Component({
})
export default class ScorecardListItemComponent extends Vue {
    private phases = PHASES;

    @Prop()
    private scorecard!: OutageScorecardItem;

    @Prop()
    private index!: number;

    constructor() {
        super();
    }

    private showDetails(): void {
        this.$emit('details-clicked', this.scorecard);
    }

    private getBadgeClass(phase: number): string {
        switch (phase) {
            case this.phases.initiation:
                return 'initiation';
            case this.phases.scoping:
                return 'scoping';
            case this.phases.preparation:
                return 'preparation';
            case this.phases.implementation:
                return 'implementation';
        }
        return 'closeOut';
    }

    private getPhaseTitle(phase: number): TranslateResult {
        switch (phase) {
            case this.phases.initiation:
                return this.$i18n.t('initiation');
            case this.phases.scoping:
                return this.$i18n.t('scoping');
            case this.phases.preparation:
                return this.$i18n.t('preparation');
            case this.phases.implementation:
                return this.$i18n.t('implementation');
            case this.phases.closeOut:
                return this.$i18n.t('closeOut');
        }
        return '';
    }
}
