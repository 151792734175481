/* eslint-disable sonarjs/cognitive-complexity */
import Vue from 'vue';
import VueRouter from 'vue-router';
import AppSecurity from '@/utils/app-security';
import { PERMISSIONS } from '@/utils/permissions-helper';
import HomeComponent from '@/components/home/home.vue';
import OverviewComponent from '@/components/overview/overview.vue';
import TableComponent from '@/components/table/table.vue';
import RemainingLifeSummaryComponent from '@/components/summary/remaining-life/remaining-life-summary.vue'
import MaintenanceSummaryComponent from '@/components/summary/maintenance/maintenance-summary.vue'
import OutageSummaryComponent from '@/components/summary/outage/outage-summary.vue'
import ActivityLogComponent from '@/components/activity-log/activity-log.vue';
import JobsComponent from '@/components/jobs/jobs.vue';
import OutageView from '@/components/outage-clock/outage-view.vue'
import OutageDocumentsComponent from '@/components/outage-documents/outage-documents.vue';
import NotFoundComponent from '@/components/not-found/not-found.vue';
import OutageStrategyComponent from '@/components/outage-strategy/outage-strategy.vue';
import { LocalStorageHelper } from '@coode/fe-sdk';
import { coodeSdk } from '@/utils/coode-sdk-config'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeComponent,
            meta: {
                requiresAuthentication: true,
            },
        },
        {
            path: '/overview',
            name: 'overview',
            component: OverviewComponent,
            meta: {
                requiresAuthentication: true,
            },
        },
        {
            path: '/table',
            name: 'table',
            component: TableComponent,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAccessToTable,
            },
        },
        {
            path: '/outage-plan',
            name: 'outagePlan',
            component: OutageSummaryComponent,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAccessToOutagePlan,
            },
        },
        {
            path: '/remaining-life-status',
            name: 'remainingLifeStatus',
            component: RemainingLifeSummaryComponent,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAccessToRemainingLife,
            },
        },
        {
            path: '/maintenance-overview',
            name: 'maintenanceOverview',
            component: MaintenanceSummaryComponent,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAccessToMaintenanceSummary,
            },
        },
        {
            path: '/activity-log',
            name: 'activity',
            component: ActivityLogComponent,
            meta: {
                requiresAuthentication: true,
            },
        },
        {
            path: '/jobs',
            name: 'jobs',
            component: JobsComponent,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAdminRights,
            },
        },
        {
            path: '/outage-clock',
            name: 'outageClock',
            component: OutageView,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAccessToOutageClock && PERMISSIONS.HasAccessToOutageScorecard,
            },
        },
        {
            path: '/outage-documents',
            name: 'outageDocuments',
            component: OutageDocumentsComponent,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAccessToOutageDocuments,
            },
        },
        {
            path: '/outage-strategy',
            name: 'outageStartegy',
            component: OutageStrategyComponent,
            meta: {
                requiresAuthentication: true,
                requiresPermission: PERMISSIONS.HasAccessToOutageStrategy,
            },
        },
        {
            // matches everything else
            path: '*',
            name: 'notFound',
            component: NotFoundComponent,
        },
    ],
});

const appSecurity = new AppSecurity();

router.beforeEach(async (to, from, next) => {
    if (to && to.meta && to.meta.requiresAuthentication) {
        const user = LocalStorageHelper.getPermissionsLocalStorage();
        if (user) {
            if (to && to.meta && to.meta.requiresPermission) {
                appSecurity
                    .hasPermission(to.meta.requiresPermission)
                    .then((hasPermission: boolean) => {
                        if (hasPermission) {
                            next();
                        } else {
                            router.push({
                                name: 'notFound',
                                params: { '0': to.path },
                            });
                        }
                    });
            } else {
                next();
            }
        } else {
            await coodeSdk.auth.login('loginRedirect');
        }
    } else {
        next();
    }
});

export const Router = router;
