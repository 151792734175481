import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, Helper, DataInputHelper } from '@/utils';
import { Group, GroupValidate } from '@/utils/interfaces';
import { EventBus } from '@/utils/eventbus';
import AppSecurity from '@/utils/app-security';
import { MasterService } from '@/services/master-service';

@Component({})
export default class GroupModalComponent extends Vue {
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private appSecurity = new AppSecurity();
    private data: Data = Data.Instance;

    /* switches */
    private showResultBox = false;
    private resultError = false;
    private waitingForSave: boolean;
    private isUserAdmin = false;

    /* data */
    private group: Group = {} as Group;
    private result = '';
    private validate: GroupValidate;

    constructor() {
        super();
        this.waitingForSave = false;
        this.validate = {} as GroupValidate;
    }

    async mounted(): Promise<void> {
        this.isUserAdmin = await this.appSecurity.hasAdminRights();
    }

    private initGroup(): void {
        this.group = { id: 0, name: '' } as unknown as Group;
        this.result = '';
        this.showResultBox = false;
        this.resultError = false;
        this.validate = {} as GroupValidate;
    }

    private editGroup(): void {
        if (!this.data.selected.group) {
            return;
        }
        this.validate = {} as GroupValidate;
        this.group = Helper.deepCopy(this.data.selected.group, {});
        this.validate = {} as GroupValidate;
    }

    private getValidateObject(name: string): boolean {
        return DataInputHelper.getValidateObject(name, this.validate);
    }

    private async saveGroup(): Promise<void> {
        this.waitingForSave = true;
        this.showResultBox = false;
        try {
            if (this.group.id === 0) {
                const result = await this.masterService.groupService.addGroup(this.group);
                EventBus.$emit(EventBus.GLOBAL.ADD_GROUP, result);
            } else if (this.group.id > 0) {
                await this.masterService.groupService.editGroup(this.group.id, this.group);
                EventBus.$emit(EventBus.GLOBAL.ADD_GROUP, this.group);
            }
            this.waitingForSave = false;
            ($('#group-modal') as JQuery<HTMLElement>).modal('hide');
        }
        catch (error) {
            DataInputHelper.handleError(error, this);
        }
    }
}
