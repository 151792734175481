/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-this-alias */
import { MasterService } from '@/services/master-service';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, EventBus, Helper, OutageCommon } from '@/utils';
import {
    OutageSummary, OutageSummaryItem, Plant, OutageSummaryDetailItem,
    OutagePlant, OutageUnit, UnitAttributes, OutageSummaryData, ServerError, OutagePlanModalData,
    OutageStrategyPlantFilter, SelectItem, BudgetShortInfo,  PlantFilter,
    PlantFilterPayload, UnitFilter
} from '@/utils/interfaces';
import { Common } from '..';
import { CurrencyHelper } from '@/utils/currency';
import html2canvas from 'html2canvas';

import { dateFormat } from 'vue-filter-date-format';
import { actions, getters } from '@/store/types';
import { CancelTokenSource } from 'axios';
import { CANCEL_MESSAGE } from '@/utils/constants';

const ELEMENT_TO_PRINT = '#vertical-scroll';
const ELEMENT_TO_SCREENSHOT = '#app';

interface StartEndYear {
    startYear: number;
    endYear: number;
}
interface NullableStartEndYear {
    startYear: number | null;
    endYear: number | null;
}

@Component({
})
export default class OutageSummaryComponent extends Vue {
    private readonly axios = require('axios');
    /* services */
    private masterService: MasterService = MasterService.Instance;

    /* helpers */
    private currencyHelper: CurrencyHelper = new CurrencyHelper();
    private outageCommon: OutageCommon = new OutageCommon();
    private data: Data = Data.Instance;
    private viewModes = Common.getViewModes();
    private viewMode: number = this.viewModes.QUARTERS;

    /* switches */
    private loading = false;
    private showZoom = false;
    private filterVisible = false;
    private allSelected = true;
    private showMostImportantOutages = false;
    private showOutagePlanning = false;
    private modalDetailsExpanded = false;
    private showBudgetValuesOnly = false;

    /* static data */
    private viewOptions = {
        noText: 0,
        dateAndCost: 1,
        dateAndDuration: 2,
        costAndDuration: 3
    };
    private visibleValuesOutageSummary = {
        budgetAndCalculated: 0,
        budgetOnly: 1,
        calculatedOnly: 2
    };
    private visibleValuesOutageAnnualPreview = {
        budgetAndNominatedDates: 0,
        budgetOnly: 1,
        nominatedDatesOnly: 2
    };

    private tabs = [
        'previewOutagePlanning',
        'outageSummary'
    ]

    /* data */
    private startDate: Date = new Date();
    private endDate: Date = new Date();

    private modalData: OutagePlanModalData = { title: null, details: [] };
    private tableData: OutageSummary = {} as OutageSummary;
    private tableHeader: string[] = [];

    private cellCoefficient = 0.5;
    private leftSideCellWidth = 150;
    private cellWidth = 183;
    private dayWidthInPixel = 1;
    private numOfCols = 0;
    private headerCellWidth = 93;
    private tableRowHeight = 41;
    private minCellWidthPlanning = 70;
    private outageData: OutageSummary = {
        startDate: '',
        endDate: '',
        plants: []
    };
    private yearLines: number[] = [];
    private outageFilter: PlantFilter[] = [];
    private rowCounter = 0;
    private selectedYear: number = new Date().getFullYear();
    private yearSelection: number[] = [];
    private viewSelected = 0;
    private viewSelection: SelectItem[] = [];
    private visibleValuesSelected = 0;
    private visibleValuesSelection: SelectItem[] = [];
    private monthMap: Record<string, string> = {};
    private budgetShortInfos: BudgetShortInfo[] = [];
    private calculatedShortInfos: BudgetShortInfo[] = [];
    private source = {} as CancelTokenSource;

    get plants(): Plant[] { return this.$store.getters[getters.PLANT__GET_PLANTS]; }
    get unitsWithAttributes(): UnitAttributes[] { return this.$store.getters[getters.UNIT__GET_UNITS_WITH_ATTRIBUTES]; }
    get outageSummaryData(): OutageSummaryData { return this.$store.getters[getters.OUTAGE__SUMMARY_GET_OUTAGE_SUMMARY_DATA]; }

    constructor() {
        super();
        EventBus.$on(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
    }

    async created() {
        if (this.data && this.data.selected.unit) {
            this.initSelections(false);
            await this.load();
        }
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.COMPONENT_REFRESH, this.handleComponentRefresh);
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }

    beforeUpdate() {
        this.rowCounter = 0;
    }

    private initSelections(forceReset: boolean): void {
        this.initViewSelection(forceReset);
        this.initVisibleValues(forceReset);
        if (!this.data || !this.data.selected.unit)
            return;

        this.yearSelection = this.buildYearSelection();
        const months = Helper.getMonths();
        for (const idx in months) {
            this.monthMap[months.indexOf(months[idx]).toString()] = months[idx];
        }
    }

    private initViewSelection(forceReset: boolean): void {
        if (!forceReset && this.viewSelection && this.viewSelection.length > 0 && this.viewSelected)
            return;

        this.viewSelection = [
            { label: this.$t('noText').toString(), value: this.viewOptions.noText },
            { label: this.$t('dateAndCost').toString(), value: this.viewOptions.dateAndCost },
            { label: this.$t('dateAndDuration').toString(), value: this.viewOptions.dateAndDuration },
            { label: this.$t('costAndDuration').toString(), value: this.viewOptions.costAndDuration }
        ];
        this.viewSelected = Number(this.viewSelection[0].value);
    }

    private initVisibleValues(forceReset: boolean): void {
        if (!forceReset && this.visibleValuesSelection && this.visibleValuesSelection.length > 0 && this.visibleValuesSelected)
            return;

        this.visibleValuesSelection = this.showOutagePlanning
            ? [
                { label: this.$t('budgetAndNominated').toString(), value: this.visibleValuesOutageAnnualPreview.budgetAndNominatedDates },
                { label: this.$t('budgetOnly').toString(), value: this.visibleValuesOutageAnnualPreview.budgetOnly },
                { label: this.$t('nominatedOnly').toString(), value: this.visibleValuesOutageAnnualPreview.nominatedDatesOnly }
            ]
            : [
                { label: this.$t('budgetAndCalculated').toString(), value: this.visibleValuesOutageSummary.budgetAndCalculated },
                { label: this.$t('budgetOnly').toString(), value: this.visibleValuesOutageSummary.budgetOnly },
                { label: this.$t('calculatedOnly').toString(), value: this.visibleValuesOutageSummary.calculatedOnly }
            ];
        this.visibleValuesSelected = this.visibleValuesSelection[0] && Number(this.visibleValuesSelection[0].value);
    }

    private getCurrentViewMode(viewMode: number): string {
        switch (viewMode) {
            case this.viewModes.YEARS:
                return this.$t('years').toString();
            case this.viewModes.QUARTERS:
                return this.$t('quarters').toString();
            case this.viewModes.MONTHS:
                return this.$t('months').toString();
            default:
                return '';
        }
    }

    private async load() {
        this.loading = true;
        this.showOutagePlanning = false;

        try {
            /* load filter menu */
            await this.getOutageFilter();
        } catch (error) {
            this.loading = false;
            if (error !== undefined && (error as ServerError).response && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$t('errorGettingOutageSummary')
                });

            }
        }

        if (this.outageCommon.showOutagePlanning) {
            await this.toggleOutagePlanning();
            return;
        }
        this.rowCounter = 0;
        try {
            this.headerCellWidth = 93;
            if (this.data && this.data.selected.startDate && this.data.selected.endDate) {
                await this.getOutageData(this.data.selected.startDate, this.data.selected.endDate, false, this.outageCommon.buildFilter(this.outageFilter), this.showMostImportantOutages);
                this.startDate = new Date(this.data.selected.startDate, 0, 1);
                this.endDate = new Date(this.data.selected.endDate, 11, 31);
                this.setViewMode(this.viewModes.QUARTERS);
                Common.initHorizontalScrollHandler();
                this.populateShortInfos();
            }
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
            if (error !== undefined && (error as ServerError).response && (error as ServerError).response.status !== 401) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, {
                    class: 'error',
                    message: this.$t('errorGettingOutageSummary')
                });
            }
        }
    }

    private async getOutageData(startDate: number, endDate: number, isAnnualPreview: boolean, filter: OutageStrategyPlantFilter[], showImportantOutages = false): Promise<void> {
        if(this.data) {
            const lang = this.data.getLanguage();
            const startEndYear = this.getRequestStartEndYear(isAnnualPreview, startDate, endDate);
            const existingStartEndYear = this.getExistingStartEndYear(isAnnualPreview);
            if (!this.outageSummaryData || !this.outageSummaryData[isAnnualPreview.toString()] ||
                (existingStartEndYear.startYear && existingStartEndYear.startYear > startEndYear.startYear) ||
                (existingStartEndYear.endYear && existingStartEndYear.endYear < endDate)) {
                this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
                this.source = this.axios.CancelToken.source();
                try {
                    await this.$store.dispatch(actions.OUTAGE__SUMMARY__SET_OUTAGE_SUMMARY_DATA, { outageSummaryData: await this.masterService.outageSummaryService.getOutage(startEndYear.startYear, startEndYear.endYear, isAnnualPreview, this.visibleValuesSelected, filter, showImportantOutages, lang, this.axios.cancelToken), isAnnualPreview });
                } catch (error) {
                    if (this.axios.isCancel(error)) {
                        return;
                    }
                }
            }
            this.outageData = this.outageSummaryData[isAnnualPreview.toString()];
        }
    }

    private async getOutageFilter(): Promise<void> {
        const plantIds = this.plants.map(plant => plant.sid);
        this.unitsWithAttributes?.length || await this.$store.dispatch(actions.UNIT__SET_UNITS_WITH_ATTRIBUTES, await this.masterService.unitService.getUnitsAttributes(plantIds));

        this.outageFilter = this.outageCommon.getPlantFilter(this.plants, this.unitsWithAttributes);
    }

    private populateModal(item: OutageSummaryItem, selectedUnit: string, currency: number) {
        this.modalData.title = selectedUnit;
        this.modalData.currency = this.currencyHelper.getCurrencySymbolByValue(currency);
        this.modalDetailsExpanded = false;

        if (this.showOutagePlanning) {
            this.modalData.details = item.annualPreviewDetails;
            for (let i = 0; i < this.modalData.details.length; i++) {
                this.modalData.details[i].hidden = i >= 2;
                if (this.modalData.details[i].title === 'Commercial Nomination' &&
                    !this.isUnlinkedNominatedDate() &&
                    new Date(this.modalData.details[i].startDate.replace('Z', '')).getFullYear() === 1 &&
                    new Date(this.modalData.details[i].endDate.replace('Z', '')).getFullYear() === 1) {
                    this.modalData.details[i].startDate = '';
                    this.modalData.details[i].endDate = '';
                }
            }

            setTimeout(() => {
                ($('#annual-outage-details-modal') as any).modal();
            }, 25);
        } else {
            this.modalData.details = item.details;
            setTimeout(() => {
                ($('#detailOutage') as any).modal();
            }, 25);
        }
    }

    private toggleModalDetails(expanded: boolean) {
        this.modalDetailsExpanded = !expanded;
        for (let i = 2; i < this.modalData.details.length; i++) {
            this.modalData.details[i].hidden = expanded;
        }
    }

    private isUnlinkedNominatedDate(): boolean {
        return this.modalData.details.length === 1 && !this.modalData.details[0].description;
    }

    private setViewMode(viewMode: number) {
        this.viewMode = viewMode;
        this.showZoom = false;
        this.tableHeader = Common.createTableHeader(this);
        if (this.viewMode === this.viewModes.YEARS) {
            this.dayWidthInPixel = this.headerCellWidth / 372;
        } else if (this.viewMode === this.viewModes.QUARTERS) {
            this.dayWidthInPixel = this.headerCellWidth / 93;
        } else if (this.viewMode === this.viewModes.MONTHS) {
            this.dayWidthInPixel = this.headerCellWidth / 31;
        }
        if (this.data && this.data.selected.endDate) {
            this.tableData = this.createTableData(this.startDate, this.data.selected.endDate, this.outageData);
            const diff = this.endDate.getFullYear() - this.startDate.getFullYear();
            this.yearLines = [];
            for (let i = 1; i <= diff; i++) {
                this.yearLines.push(i);
            }
        }
    }

    private changeViewMode(viewMode: number) {
        this.setViewMode(viewMode);
        this.populateShortInfos();
    }

    private createTableData(startDate: Date, endDate: number, data: OutageSummary): OutageSummary {
        data.plants = data.plants.map((plant) => {
            plant.units = plant.units.map((unit) => {
                unit.budgets = unit.budgets.map(budget => Common.computeItemPosition(budget, startDate, endDate, this.dayWidthInPixel));
                unit.calculated = unit.calculated.map(calc => Common.computeItemPosition(calc, startDate, endDate, this.dayWidthInPixel));
                unit.nominatedDates = unit.nominatedDates.map(d => Common.computeItemPosition(d, startDate, endDate, this.dayWidthInPixel));
                return unit;
            });
            return plant;
        });
        return data;
    }

    private async refresh(): Promise<void> {
        await this.$store.dispatch(actions.OUTAGE__SUMMARY__RESET_STATE);
        this.filterVisible = false;

        if (this.showOutagePlanning) {
            await this.loadOutagePlaning(false);
        } else {
            if (this.data && this.data.selected.startDate && this.data.selected.endDate) {
                this.loading = true;
                await this.getOutageData(
                    this.data.selected.startDate, this.data.selected.endDate,
                    this.showOutagePlanning,
                    this.outageCommon.buildFilter(this.outageFilter), this.showMostImportantOutages);
                this.setViewMode(this.viewMode);
                this.populateShortInfos();
            }
            this.loading = false;
        }

        const container = document.getElementById('vertical-scroll');
        container && container.scrollLeft++;
        container && container.scrollLeft--;
    }

    private togglePlant(index: number): void {
        $('#plant-' + index).find('.item-wrapper-collapsed').toggleClass('expanded');
        $('#plus-' + index).toggle();
        $('#minus-' + index).toggle();
    }

    private getRowClass(index: number): string {
        return index% 2 === 0 ? 'bg-white' : 'bg-gray';
    }

    private initTooltips(): void {
        Helper.initializeTooltips();
    }

    private async toggleOutagePlanning(tab = 0): Promise<void> {
        this.showOutagePlanning = tab === 0;
        this.showMostImportantOutages = false;
        this.outageCommon.setShowOutagePlanning(this.showOutagePlanning);
        this.filterVisible = false;
        this.initSelections(true);

        if (this.showOutagePlanning) {
            await this.loadOutagePlaning(false);
        } else {
            await this.load();
        }
    }

    private async loadOutagePlaning(resetFilter: boolean): Promise<void> {
        this.showZoom = false;
        this.filterVisible = false;
        this.startDate = new Date(this.selectedYear, 0, 1);
        this.endDate = new Date(this.selectedYear, 11, 31);
        this.loading = true;
        this.viewMode = this.viewModes.MONTHS;
        const filter = resetFilter ? [] : this.outageCommon.buildFilter(this.outageFilter);
        await this.getOutageData(this.selectedYear, this.selectedYear, this.showOutagePlanning, filter, this.showMostImportantOutages);
        this.tableHeader = Common.createTableHeader(this);
        const containerWidth = $('#outagePlanningContainer').width();
        const quarter = containerWidth && containerWidth / 4;
        const yearContainerWidth = containerWidth && quarter && (containerWidth - quarter > this.minCellWidthPlanning * 12) ? containerWidth - quarter : this.minCellWidthPlanning * 12;
        this.headerCellWidth = yearContainerWidth / 12;
        this.dayWidthInPixel = this.headerCellWidth / 31;
        this.tableData = this.createTableData(this.startDate, this.selectedYear, this.outageData);
        Common.initHorizontalScrollHandler();
        this.populateShortInfos();
        this.loading = false;
    }

    private deselectInvisibleUnitsInFilter(filteredOutUnitIds: PlantFilterPayload[]): void {
        if (!this.outageFilter)
            return;

        this.outageFilter.forEach(function (plant) {
            let allUnitsSelected = true;
            plant.units.forEach(function (unit: any) {
                if (filteredOutUnitIds.indexOf(unit.id) !== -1) {
                    unit.selected = false;
                    allUnitsSelected = false;
                }
            });
            if (!allUnitsSelected) {
                plant.selected = false;
            }
        });
    }

    private onSelectAllChange(value: boolean): void {
        this.outageCommon.selectAll(value, this.outageFilter);
    }

    private onPlantFilterChange(event: Event, plant: PlantFilter): void {
        this.outageCommon.handlePlantFilterChange(event, plant);
        this.allSelected = this.outageCommon.checkIfAllSelected(this.outageFilter);
    }

    private onUnitFilterChange(event: Event, plant: PlantFilter, unit: UnitFilter): void {
        this.outageCommon.handleFilterUnitClick(event, plant, unit);
        this.allSelected = this.outageCommon.checkIfAllSelected(this.outageFilter);
    }

    private async handleYearChange(): Promise<void> {
        await this.$store.dispatch(actions.OUTAGE__SUMMARY__RESET_STATE);
        await this.loadOutagePlaning(false);
    }

    private getShortDate(dateString: string): string {
        const date = new Date(dateString);
        return date.getDate() + ' ' + this.monthMap[date.getMonth()];
    }

    private getShortInfo(isBudget: boolean, plantId: number, unitId: number, left: number): string {
        let shortInfos = isBudget ? this.budgetShortInfos : this.calculatedShortInfos;
        shortInfos = shortInfos.filter(i => { return i.plantId === plantId && i.unitId === unitId && i.left === left; });

        if (!shortInfos || shortInfos.length === 0)
            return '';

        let text = shortInfos[0].text;
        for (let i = 1; i < shortInfos.length; i++) {
            text += ', ' + shortInfos[i].text;
        }

        return text;
    }

    private getItemShortInfo(item: OutageSummaryItem,  currency: number, detailCostIndex: number): string {
        if (!this.viewSelected) {
            this.initViewSelection(true);
        }

        const cost = this.showOutagePlanning ? item.annualPreviewDetails[detailCostIndex].cost : item.details[detailCostIndex].cost;

        switch (this.viewSelected) {
            case this.viewOptions.costAndDuration:
                return cost + this.getItemCurrencyLabel(currency) + ' (' + item.duration + ')';
            case this.viewOptions.dateAndCost:
                return this.getShortDate(item.startDate) + ' (' + cost + this.getItemCurrencyLabel(currency) + ')';
            case this.viewOptions.dateAndDuration:
                return this.getShortDate(item.startDate) + ' (' + item.duration + ')';
            default:
                return '';
        }
    }

    private populateShortInfos(): void {
        const self = this;
        self.budgetShortInfos = [];
        self.calculatedShortInfos = [];

        this.tableData.plants.forEach((plant: OutagePlant) => {
            plant.units.forEach((unit: OutageUnit) => {
                unit.budgets.forEach((budget: OutageSummaryItem) => {
                    const text = self.getItemShortInfo(budget, plant.currency, 0);
                    const sameItems = self.budgetShortInfos.filter(i => {
                        return i.plantId === unit.plantId && i.unitId === unit.id && i.left === budget.left && i.text === text;
                    });

                    if (!sameItems || sameItems.length === 0) {
                        self.budgetShortInfos.push({ plantId: unit.plantId, unitId: unit.id, left: budget.left, text: text });
                    }
                });

                if (!self.showOutagePlanning && unit.calculated) {
                    unit.calculated.forEach((calculated: OutageSummaryItem) => {
                        const text = self.getItemShortInfo(calculated, plant.currency, 1);
                        const sameItems = self.calculatedShortInfos.filter(i => {
                            return i.plantId === unit.plantId && i.unitId === unit.id && i.left === calculated.left && i.text === text;
                        });

                        if (!sameItems || sameItems.length === 0) {
                            self.calculatedShortInfos.push({ plantId: unit.plantId, unitId: unit.id, left: calculated.left, text: text });
                        }
                    });
                }
            });
        });
    }

    private getItemCurrencyLabel(currency: number): string {
        return 'K' + this.currencyHelper.getCurrencySymbolByValue(currency);
    }

    private buildYearSelection(): number[] {
        const result = [];
        if (this.data && this.data.selected.unit) {
            const plannedEndDate = new Date(this.data.selected.unit.plannedEndDate).getFullYear();
            for (let i = 2005; i <= plannedEndDate; i++) {
                result.push(i);
            }
        }
        return result;
    }

    /**
     * @name print
     * @description Take a snapshot of the table and send for printing.
     */
    private print(): void {
        const height = window.innerHeight * 0.8;
        const width = window.innerWidth * 0.8;
        const printWindow = window.open('', 'PRINT', 'height=' + height + ',width=' + width);
        if (printWindow) {
            html2canvas((document.querySelector(ELEMENT_TO_PRINT) as any)).then((canvas: any) => {
                const encodedString = canvas.toDataURL('image/png');
                this.hideScreenshotTitle();
                printWindow.document.write('<html><head><title></title>');
                printWindow.document.write('</head><body>');
                const img = printWindow.document.createElement('img');
                img.setAttribute('src', encodedString);
                img.style.width = '100%';
                img.style.height = 'auto';
                printWindow.document.body.appendChild(img);
                printWindow.document.write('</body></html>');
                setTimeout(() => {
                    printWindow.print();
                    printWindow.document.close();
                }, 50);
            });
        }
    }

    /**
     * @name takeScreenShot
     * @description Take a screenshot of the table and download as an image.
     */
    private takeScreenShot(): void {
        if (this.data && this.data.selected.group) {
            const fileName = this.showOutagePlanning
                ? this.data.selected.group.name + ' ' + this.$t('outagePlan') + ' ' + this.selectedYear + '.png'
                : this.data.selected.group.name + ' ' + this.$t('outageSummary') + '.png';
            html2canvas((document.querySelector(ELEMENT_TO_SCREENSHOT) as any)).then((canvas: any) => {
                const encodedString = canvas.toDataURL('image/png');
                this.hideScreenshotTitle();
                const link = document.createElement('a');
                link.setAttribute('href', encodedString);
                link.setAttribute('download', fileName);
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    }

    private displayScreenshotTitle(): void {
        (document.getElementById('printTitle') as any).style.display = 'block';
    }

    private hideScreenshotTitle(): void {
        (document.getElementById('printTitle') as any).style.display = 'none';
    }

    private getBudgetTitle(budget: OutageSummaryItem, plant: OutagePlant): string {
        const cost = budget && budget.details.length > 0 ? budget.details[0].cost : 0;
        const commercialValueIndex = budget && budget.details ? budget.details.map((d: OutageSummaryDetailItem) => d.title).indexOf('Commercial Value') : -1;
        const commercialValueIntrinsic = commercialValueIndex !== -1 ? budget.details[commercialValueIndex].cost : 0;
        const commercialValueExtrinsic = commercialValueIndex !== -1 ? budget.details[commercialValueIndex].cost2 : 0;

        return this.getTitle(cost, plant.currency, budget.duration, commercialValueIndex === -1, commercialValueIntrinsic, commercialValueExtrinsic);
    }

    private getCalculatedItemTitle(calculatedItem: OutageSummaryItem, plant: OutagePlant): string {
        const cost = calculatedItem && calculatedItem.details.length > 1 ? calculatedItem.details[1].cost : 0;
        const commercialValueIndex = calculatedItem && calculatedItem.details ? calculatedItem.details.map((d: OutageSummaryDetailItem) => d.title).indexOf('Commercial Value') : -1;
        const commercialValueIntrinsic = commercialValueIndex !== -1 ? calculatedItem.details[commercialValueIndex].cost : 0;
        const commercialValueExtrinsic = commercialValueIndex !== -1 ? calculatedItem.details[commercialValueIndex].cost2 : 0;

        return this.getTitle(cost, plant.currency, calculatedItem.duration, commercialValueIndex === -1, commercialValueIntrinsic, commercialValueExtrinsic);
    }

    private getTitle(cost: number, currency: number, duration: number, hasCommercialValue: boolean, commercialValueIntrinsic: number, commercialValueExtrinsic: number): string {
        const title =
            this.$t('cost') + ': ' + cost + '(K' + this.currencyHelper.getCurrencySymbolByValue(currency) + '), ' + // cost
            this.$t('duration') + ': ' + duration; // duration

        if (hasCommercialValue)
            return title;

        return title + ', ' +
            this.$t('oeCommercialValue') + ': ' +
            commercialValueIntrinsic + '(K' + this.currencyHelper.getCurrencySymbolByValue(currency) + ') / ' +
            commercialValueExtrinsic + '(K' + this.currencyHelper.getCurrencySymbolByValue(currency) + ')'; // commercial value intrinsic
    }

    private navigateToOutageDocuments(): void {
        this.$router.push('outage-documents');
    }

    private getFormattedDate(date: string): string {
        if (!date || date === '0001-01-01 00:00')
            return '';

        return dateFormat(new Date(date), 'YYYY-MM-DD HH:mm');
    }

    private async handleComponentRefresh(): Promise<void> {
        await this.load();
    }

    private getRequestStartEndYear(isAnnualPreview: boolean, startDate: number, endDate: number): StartEndYear {
        let startYear = startDate;
        let endYear = endDate;
        if (this.outageSummaryData && this.outageSummaryData[isAnnualPreview.toString()]) {
            const existingStartYear = new Date(this.outageSummaryData[isAnnualPreview.toString()].startDate).getFullYear();
            const existingEndYear = new Date(this.outageSummaryData[isAnnualPreview.toString()].endDate).getFullYear();
            if (endYear >= existingStartYear && endYear <= existingEndYear) {
                endYear = existingStartYear;
            } else if (startYear >= existingStartYear && startYear <= existingEndYear) {
                startYear = existingEndYear;
            }
        }
        return {
            startYear,
            endYear
        }
    }

    private getExistingStartEndYear(isAnnualPreview: boolean): NullableStartEndYear {
        let startYear = null;
        let endYear = null;
        if (this.outageSummaryData && this.outageSummaryData[isAnnualPreview.toString()]) {
            startYear = new Date(this.outageSummaryData[isAnnualPreview.toString()].startDate).getFullYear();
            endYear = new Date(this.outageSummaryData[isAnnualPreview.toString()].endDate).getFullYear();
        }
        return {
            startYear,
            endYear
        }
    }

    private getOutagesToDisplay(outages: OutageSummaryItem[]): OutageSummaryItem[] {
        return outages.filter((outage) => new Date(outage.startDate).getTime() >= new Date(this.startDate).getTime() && new Date(outage.endDate).getTime() <= new Date(this.endDate).getTime());
    }
}
